export default {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
  spacing: [0, '.25rem', '.5rem', '1rem', '2rem', '4rem', '8rem', '16rem'],
  gutterSpace: '40px',
  width: [
    0,
    '1rem',
    '2rem',
    '4rem',
    '8rem',
    '16rem',
    '24rem',
    '32rem',
    '40rem',
    '48rem',
    '64rem',
    '96rem',
  ],
  height: [0, '1rem', '2rem', '4rem', '8rem', '16rem'],
  font: {
    family: 'BTLFontRegular',
    lh: {
      solid: 1,
      title: 1.25,
      copy: 1.5,
    },
    size: [0, '3rem', '2.25rem', '1.5rem', '1.25rem', '1rem', '.875rem', '.75rem', '.65rem'],
    weight: [0, 100, 200, 300, 400, 500, 600, 700, 800, 900],
    color: {
      primary: 'rgb(64, 68, 82)',
      secondary: 'rgba(0,0,0,0.5)',
      muted: 'rgb(104, 115, 133)',
    },
  },
  color: {
    nearBlack: 'rgb(17,17,17)',
    background: 'rgb(240,242,245)',
    primary: 'rgb(3,207,158)',
    danger: '#EB5757',
    disabled: {
      font: 'rgba(0,0,0,0.25)',
      border: 'rgb(217,217,217)',
      background: 'rgb(245,245,245)',
    },
    calendar: {
      program: '#56CCF2',
      courtBooking: '#6FCF97',
      blocked: '#BDBDBD',
    },
    white: '#FFF',
    gray: ['#F2F2F2', '#E0E0E0', '#BDBDBD', '#828282', '#4F4F4F', '#333333'],
    green: ['#6FCF97', '#27AE60', '#219653'],
    blue: ['#56CCF2', '#2D9CDB', '#2F80ED'],
    purple: ['#BB6BD9', '#9B51E0'],
    yellow: ['#F2C94C'],
    orange: ['#F2994A'],
    red: ['#EB5757'],
  },
  br: [0, '.125rem', '.25rem', '.5rem', '1rem'], // border-radius
}
