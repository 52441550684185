import React, { useState, useEffect } from 'react'
import { Radio } from 'antd'
import { InputGroup, Title, Desc } from './step'
import { cancellationPolicies } from '../../../constants'
import { typeLabel, cancellationPolicyLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import Attr from '../../../components/Attr'

const CancellationPolicy = ({ onChange, clinic }) => {
  const [cancellationPolicy, setCancellationPolicy] = useState(clinic.cancellationPolicy)

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({ cancellationPolicy })
    }
  }, [cancellationPolicy])

  const policies = Object.values(cancellationPolicies).map(pol => (
    <Radio key={pol.name} value={pol.name}>
      {pol.label}
    </Radio>
  ))
  policies.unshift(
    <Radio key="none" value={null}>
      None
    </Radio>,
  )
  return (
    <>
      <InputGroup>
        <Title>Cancellation Policy</Title>
        <Desc>
          How strict are your pariticipant cancellations for this {lower(typeLabel(clinic))}?
        </Desc>
        <Radio.Group
          value={cancellationPolicy}
          onChange={e => setCancellationPolicy(e.target.value)}
        >
          {policies}
        </Radio.Group>
      </InputGroup>
    </>
  )
}

const Viewer = ({ program }) => {
  if (program.cancellationPolicy === null) {
    return <Attr>None</Attr>
  }
  return <Attr>{cancellationPolicyLabel(program.cancellationPolicy)}</Attr>
}

const step = CancellationPolicy

const complete = ({ cancellationPolicy }) => {
  if (cancellationPolicy === null) {
    return true
  }
  return !!cancellationPolicy && cancellationPolicy in cancellationPolicies
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
