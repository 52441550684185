import { useEffect, useState } from 'react'
import { useTheme, ThemeContext } from 'styled-components'
import { actions as acts, paths } from '../../../constants'
import programConsts from '../../../constants/program'
import { useStatus, useStatusMsg } from '../../../reducers'
import { useDispatch } from 'react-redux'
import { clearStatus } from '../../../actions/status'
import { Tooltip, Checkbox, Modal, Menu, Dropdown, Input, Table } from 'antd'
import {
  DownOutlined,
  EllipsisOutlined,
  StopTwoTone,
  LoadingOutlined,
  ExclamationCircleTwoTone,
  CheckCircleTwoTone,
} from '@ant-design/icons'
import { InfoTags } from '../../../components/program'
import { Type, Status } from '../../../components/program'
import { Button, Noner } from '../../../components/common'
import { BulkInput } from '../../../components/bulk'
import Attr from '../../../components/Attr'
import programUtils from '../../../util/program'
import { cancelProgram, quickSearchPrograms } from '../../../actions/admin'

const { TextArea } = Input

const programLoaded = p => {
  if (p._status === statuses.success) {
    return true
  }
  return false
}

const statuses = {
  pending: 'pending',
  success: 'success',
  failure: 'failure',
  notFound: 'notFound',
}

const columns = actionMenu => [
  {
    title: 'ID',
    key: 'id',
    width: 10,
    dataIndex: 'id',
    render: (val, record) => {
      return (
        <small>
          <pre style={{ marginBottom: 0 }}>{val}</pre>
        </small>
      )
    },
  },
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    render: (val, record) => {
      if (record._status === statuses.pending) {
        return (
          <span>
            <LoadingOutlined /> <Noner none="Loading..." />{' '}
          </span>
        )
      }
      if (record._status === statuses.notFound) {
        return (
          <span>
            <ExclamationCircleTwoTone twoToneColor="#fa8c16" /> <Noner none="Not found" />
          </span>
        )
      }
      if (record._status === statuses.failure) {
        return (
          <span>
            <StopTwoTone twoToneColor="#f5222d" /> <Noner none="Failed to load" />{' '}
          </span>
        )
      }

      let cancelIcon = null
      if (record._cancelStatus === statuses.pending) {
        cancelIcon = <LoadingOutlined />
      }
      if (record._cancelStatus === statuses.success) {
        cancelIcon = (
          <Tooltip placement="left" title="Cancel success">
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          </Tooltip>
        )
      }
      if (record._cancelStatus === statuses.failure) {
        cancelIcon = (
          <Tooltip placement="left" title="Cancel failed">
            <StopTwoTone twoToneColor="#f5222d" />
          </Tooltip>
        )
      }

      return (
        <div>
          {cancelIcon} {val}
        </div>
      )
    },
  },
  {
    title: 'Info',
    key: 'type',
    dataIndex: 'type',
    render: (val, record) => {
      if (!programLoaded(record)) {
        return null
      }
      return (
        <div style={{ display: 'flex' }}>
          <Status status={record.status} /> <Type type={record.type} />{' '}
          <InfoTags program={record} style={{ fontSize: '10px' }} />
        </div>
      )
    },
  },
  {
    title: 'Time',
    key: 'date',
    dataIndex: 'date',
    render: (val, record) => {
      if (!programLoaded(record)) {
        return null
      }
      const dateSummary = programUtils.dateSummary(record)
      const timeSummary = programUtils.timeSummary(record)
      return (
        <div>
          <div>
            <small>
              <Noner none="No date">{dateSummary}</Noner>
            </small>
          </div>
          <div style={{ lineHeight: 0.35 }}>
            <small>
              <Noner none="No time">{timeSummary}</Noner>
            </small>
          </div>
        </div>
      )
    },
  },
  {
    title: 'Bookings',
    key: 'bookings',
    dataIndex: 'bookings',
    render: (val, record) => {
      if (!programLoaded(record)) {
        return null
      }
      const bookings = programUtils.countBookings(val)
      return (
        <div>
          <small>
            {bookings} / {record.maxParticipants} booked
          </small>
        </div>
      )
    },
  },
  {
    title: 'Org/venue',
    key: 'org',
    dataIndex: 'org',
    render: (val, record) => {
      if (!programLoaded(record)) {
        return null
      }
      return (
        <div>
          <div>{!val ? <Noner none="No org"></Noner> : val.name}</div>
          <div>{!record.venue ? <Noner none="No venue"></Noner> : record.venue.name}</div>
        </div>
      )
    },
  },
  {
    title: '',
    width: 10,
    render: (val, record) => {
      if (!programLoaded(record)) {
        return null
      }
      return (
        <Dropdown overlay={actionMenu(record)} trigger={['click']}>
          <Button>
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      )
    },
  },
]

const BulkCancelProgramModal = ({ programs, complete }) => {
  const theme = useTheme(ThemeContext)
  const completer = typeof complete === 'function' ? complete : () => {}
  const [email, setEmail] = useState(false)
  const [sendNote, setSendNote] = useState(false)
  const [note, setNote] = useState(null)

  const onOk = () => {
    completer({ programs, note, sendEmails: email })
  }

  const onEmailChange = e => {
    setEmail(e.target.checked)
  }

  const onSendNote = e => {
    setSendNote(e.target.checked)
    if (!e.target.checked) {
      setNote(null)
    }
  }

  const num = programs.length
  const plur = num !== 1
  return (
    <Modal
      title="Cancel programs"
      visible
      okText={`Cancel program${plur ? 's' : ''}`}
      onOk={onOk}
      okButtonProps={{ danger: true }}
      onCancel={() => completer()}
    >
      <div>
        <div>
          {num} program{plur && 's'} will be cancelled with the following effects:
        </div>
        <div>
          <ul>
            <li>Program{plur && 's'} will be cancelled</li>
            <li>All booked users will be cancelled</li>
            <li>All resource reservations will be removed</li>
          </ul>
        </div>
      </div>
      <div>
        <div style={{ marginBottom: theme.spacing[2] }}>
          <Checkbox onChange={onEmailChange} checked={email}>
            {' '}
            Email cancelled users
          </Checkbox>
        </div>
        <div>
          <Checkbox onChange={onSendNote} checked={sendNote}>
            {' '}
            Send a user note
          </Checkbox>
          <div style={{ marginTop: theme.spacing[2], visibility: sendNote ? 'visible' : 'hidden' }}>
            <Input value={note} onChange={e => setNote(e.target.value)} placeholder="Note" />
          </div>
        </div>
      </div>
    </Modal>
  )
}

// returns a new list of programs with program updated
const updateProgram = (programs, updatedProgram) => {
  const next = [...programs]
  const idx = next.findIndex(prg => prg.id === updatedProgram.id)
  if (idx >= 0) {
    next[idx] = { ...next[idx], ...updatedProgram }
  }
  return next
}

// get a list of programs, paginated(?)
// display programs
// allow individual cancel, allow bulk cancel
// right now only support programs, through program ids
const Sandbox = () => {
  const theme = useTheme(ThemeContext)
  const dispatch = useDispatch()
  const [programs, setPrograms] = useState(null)
  const [loadSummary, setLoadSummary] = useState(null)
  const [selected, setSelected] = useState(null)
  const [cancelConfirm, setCancelConfirm] = useState(false)
  const [rowCancel, setRowCancel] = useState(null)
  const status = useStatus(acts.QUICK_SEARCH_PROGRAMS)

  useStatusMsg(status, { error: 'Failed to search programs' })
  useEffect(() => {
    return () => dispatch(clearStatus(acts.QUICK_SEARCH_PROGRAMS))
  }, [])

  useEffect(() => {
    if (Array.isArray(programs) && programs.length) {
      const summary = { pending: 0, failure: 0, success: 0, notFound: 0 }
      programs.map(p => {
        summary[p._status] = summary[p._status] += 1
      })
      setLoadSummary(summary)
    }
  }, [programs])

  const cancelPrograms = async (progs, { note, sendEmails }) => {
    progs.map(async p => {
      setPrograms(prev => updateProgram(prev, { ...p, _cancelStatus: statuses.pending }))
      dispatch(cancelProgram({ programId: p.id, note, sendEmails }))
        .then(e => dispatch(quickSearchPrograms({ limit: 1, searchInput: p.id })))
        .then(searchResults => {
          const updatedProgram = searchResults.data[0]
          setPrograms(prev => {
            return updateProgram(prev, { ...updatedProgram, _cancelStatus: statuses.success })
          })
        })
        .catch(e => {
          console.log(e)
          setPrograms(prev => {
            return updateProgram(prev, { ...p, _cancelStatus: statuses.failure })
          })
        })
    })
  }

  const searchPrograms = async progs => {
    if (Array.isArray(progs) && progs.length > 0) {
      await Promise.all(
        progs.map(async p => {
          let res
          try {
            res = await dispatch(quickSearchPrograms({ limit: 1, searchInput: p.id }))
          } catch (err) {
            setPrograms(prev => {
              return updateProgram(prev, { ...p, _status: statuses.failure })
            })
            return
          }
          if (res.numResults === 0) {
            setPrograms(prev => {
              return updateProgram(prev, { ...p, _status: statuses.notFound })
            })
            return
          }
          // ...p is used in case the user did not input an id, but a string search
          setPrograms(prev => {
            return updateProgram(prev, { ...res.data[0], ...p, _status: statuses.success })
          })
        }),
      )
    }
  }

  const onChange = async parts => {
    const ids = Array.isArray(parts) ? parts : []
    const progs = ids.map(i => ({ id: i, _status: statuses.pending }))
    setPrograms(progs)
    await searchPrograms(progs)
  }

  const handleActionMenu = e => {
    if (e.key === 'cancel') {
      setCancelConfirm(true)
    }
  }

  const actionMenu = (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="cancel" disabled={!(Array.isArray(selected) && selected.length > 0)} danger>
        Cancel
      </Menu.Item>
    </Menu>
  )

  const handleRowActionMenu = record => e => {
    if (e.key === 'view') {
      window.open(paths.org.PROGRAM(record.org.sid, record.id), '_blank')
    }
    if (e.key === 'cancel') {
      setRowCancel(record)
    }
  }

  const rowActionMenu = record => {
    const canCancel = record.status !== programConsts.statuses.CANCELED.name
    let copy = 'Cancel'
    if (!canCancel) {
      copy = (
        <Tooltip placement="left" title="Already cancelled">
          {copy}
        </Tooltip>
      )
    }
    return (
      <Menu onClick={handleRowActionMenu(record)}>
        <Menu.Item key="view">View</Menu.Item>
        <Menu.Item key="cancel" disabled={!canCancel} danger>
          {copy}
        </Menu.Item>
      </Menu>
    )
  }

  return (
    <div style={{ padding: '1em', fontSize: '12px' }}>
      <div style={{ marginBottom: theme.spacing[4] }}>
        <BulkInput onChange={onChange} style={{ fontSize: '12px' }} />
      </div>
      <div>
        {Array.isArray(programs) && programs.length > 0 && (
          <div>
            <div
              style={{
                marginBottom: theme.spacing[3],
                display: 'flex',
                justifyContent: 'right',
                alignItems: 'center',
              }}
            >
              <div style={{ marginRight: theme.spacing[2] }}>
                {Array.isArray(selected) && selected.length > 0 && `${selected.length} selected`}
              </div>
              <Dropdown overlay={actionMenu} trigger={['click']}>
                <Button>
                  Actions <DownOutlined style={{ fontSize: '12px' }} />{' '}
                </Button>
              </Dropdown>
            </div>
            <Table
              size="small"
              columns={columns(rowActionMenu)}
              rowKey={record => record.id}
              dataSource={programs}
              pagination={false}
              style={{ fontSize: '12px' }}
              rowSelection={{
                type: 'checkbox',
                onChange: (selectedRowKeys, selectedRows) => {
                  setSelected(selectedRows)
                },
                getCheckboxProps: record => ({
                  name: record.id,
                  disabled: record._status !== statuses.success,
                }),
              }}
            />
          </div>
        )}
      </div>
      {(cancelConfirm || rowCancel) && (
        <BulkCancelProgramModal
          programs={rowCancel ? [rowCancel] : selected}
          complete={args => {
            if (args) {
              cancelPrograms(args.programs, { note: args.note, sendEmails: args.sendEmails })
            }
            setCancelConfirm(false)
            setRowCancel(null)
          }}
        />
      )}
    </div>
  )
}

export default Sandbox
