import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Select } from 'antd'
import { US_STATES } from '../constants'

const states = Object.entries(US_STATES).map(([abbrv, state]) => (
  <Select.Option key={abbrv} value={abbrv}>
    {state}
  </Select.Option>
))

const StateSelector = ({ ...props }) => {
  const theme = useContext(ThemeContext)
  return (
    <Select
      readOnly
      showSearch
      placeholder="Select a state"
      style={{ width: theme.width[5] }}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      {...props}
    >
      {states}
    </Select>
  )
}

export default StateSelector
