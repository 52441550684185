import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'
import { useStatus, useStatusMsg } from '../reducers'
import { Select } from 'antd'
import { actions as acts, paths } from '../constants'
import { fetchOrgs } from '../actions/admin'
import { Link } from 'react-router-dom'

const { Option } = Select

// org can be an org object or an org id/sid
export const AdminOrgLink = ({ children, org, ...rest }) => {
  let sig = null
  let childs = children

  if (org) {
    sig = typeof org === 'string' ? org : org.sid
  }

  if (!sig) {
    return childs
  }

  // render something if children is not passed
  if (!childs) {
    childs = typeof org === 'string' ? org : org.name
  }

  return (
    <Link to={paths.admin.ORG(sig)} {...rest}>
      {childs}
    </Link>
  )
}

// this selector shows all orgs in the systems and should probably only be used in admin contexts
export const OrgSelect = ({ value, onChange, ...props }) => {
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const [orgs, setOrgs] = useState({}) // map of org id to org object
  const [val, setVal] = useState(null)
  const status = useStatus(acts.FETCH_ALL_ORGS)

  useEffect(() => {
    dispatch(fetchOrgs()).then(os => {
      setOrgs(
        os.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {}),
      )
    })
  }, [])

  useEffect(() => {
    // convert value to org id when org list or value changes
    if (orgs && Object.keys(orgs).length > 0 && value) {
      // supports value being either a org object or a org id
      setVal(typeof value === 'string' ? value : value.id)
    }
  }, [orgs, value])

  useStatusMsg(status, { error: 'Failed to fetch orgs' })

  if (!status.success) {
    return 'Loading...'
  }

  const opts = Object.keys(orgs).map(id => {
    const o = orgs[id]
    return (
      <Option key={o.id} value={o.id} data-name={o.name}>
        <div>{o.name}</div>
        <div style={{ color: theme.font.color.secondary }}>
          <small>{o.sid}</small>
        </div>
      </Option>
    )
  })

  const handleChange = id => {
    setVal(id)
    if (onChange) {
      onChange(orgs[id])
    }
  }

  return (
    <Select
      value={val}
      onChange={handleChange}
      showSearch
      filterOption={(input, option) => {
        const inp = input ? input.toLowerCase() : ''
        const matchesOption = option['data-name'].trim().toLowerCase().indexOf(inp) >= 0
        return matchesOption || option.value.toLowerCase().indexOf(inp) >= 0
      }}
      {...props}
    >
      {opts}
    </Select>
  )
}
