import React, { useState, useEffect } from 'react'
import { useStatus, useStatusMsg } from '../../reducers'
import { clearStatus } from '../../actions/status'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Table, Tag } from 'antd'
import Container from './Container'
import { formatDateTimeTz } from '../../util/time'
import { Cta as BTLButton } from '../../components/common'
import { TagItem, TagList, TagSelect } from '../../components/tag.jsx'
import { actions as acts, paths } from '../../constants'
import * as orgActs from '../../actions/org'

const cols = [
  {
    title: 'Tag',
    dataIndex: 'tag',
    key: 'tag',
    sorter: (a, b) => a.name.localeCompare(b.name),
    render: (val, record) => {
      return <TagItem tag={val} link />
    },
  },
  {
    title: 'Added',
    dataIndex: 'createdAt',
    key: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => formatDateTimeTz(record.createdAt),
  },
]

const AddTagsModal = ({ complete }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const status = useStatus(acts.SET_PROGRAM_ORG_TAGS)
  const program = useSelector(s => s.currentProgram)
  const [selectedTags, setSelectedTags] = useState(program.tags.map(t => t.tag))
  const completer = typeof complete === 'function' ? complete : () => {}

  useStatusMsg(status, {
    error: 'Failed to set tags',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.SET_PROGRAM_ORG_TAGS))
    }
  }, [])

  const onOk = () => {
    const tagIds = selectedTags.filter(Boolean).map(t => t.id)
    dispatch(orgActs.setProgramTags({ osid, programId: program.id, tagIds })).then(res =>
      completer(res),
    )
  }

  const onAddTag = tag => {
    const tagExists = selectedTags.find(t => t.id === tag.id)
    if (!tagExists) {
      setSelectedTags(prev => [...prev, tag])
    }
  }

  const onRemoveTag = tag => {
    setSelectedTags(prev => prev.filter(t => t.id !== tag.id))
  }

  return (
    <Modal
      title={`Tags for ${program.name}`}
      visible={true}
      onOk={onOk}
      confirmLoading={Boolean(status.pending)}
      onCancel={() => completer()}
    >
      <TagSelect value={null} onChange={onAddTag} />
      <div style={{ marginTop: '1em' }}>
        <TagList tags={selectedTags} onRemove={onRemoveTag} />
      </div>
    </Modal>
  )
}

const ProgramTags = () => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [changingTags, setChangingTags] = useState(false)
  const program = useSelector(s => s.currentProgram)

  return (
    <Container>
      <h1>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>Tags</span>
          <BTLButton onClick={() => setChangingTags(true)}>Edit tags</BTLButton>
        </div>
      </h1>
      <Table rowKey="id" columns={cols} dataSource={program.tags} pagination={false} />
      {changingTags && <AddTagsModal complete={() => setChangingTags(false)} />}
    </Container>
  )
}

export default ProgramTags
