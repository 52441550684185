import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Link, useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Tabs, Tag, Typography, Button, Dropdown, Breadcrumb, Modal, Menu } from 'antd'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { useStatus, useStatusMsg } from '../../../reducers'
import { paths, actions as acts } from '../../../constants'
import * as actions from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import * as errors from '../../../util/error'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'
import PromoCodeEditor from './PromoCodeEditor'
import Overview from './Overview'
import Uses from './Uses'
import { isExpired } from './utils'

const { TabPane } = Tabs

const Errors = ({ status }) => {
  if (status.fetch.error) {
    if (status.fetch.error instanceof errors.NotFoundError) {
      return (
        <Typography.Text type="danger">
          {status.fetch.error.message ? status.fetch.error.message : 'Not found'}
        </Typography.Text>
      )
    }
  }
  return null
}

const PromoCode = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useContext(ThemeContext)
  const [activeTab, setActiveTab] = useState('overview')
  const [promoCode, setPromoCode] = useState(null)
  const [editingCode, setEditingCode] = useState(false)
  const status = {
    fetch: useStatus(acts.FETCH_PROMO_CODE),
    delete: useStatus(acts.DELETE_PROMO_CODE),
    update: useStatus(acts.UPDATE_PROMO_CODE),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.FETCH_PROMO_CODE))
      dispatch(clearStatus(acts.DELETE_PROMO_CODE))
      dispatch(clearStatus(acts.UPDATE_PROMO_CODE))
    }
  }, [])

  useStatusMsg(status.fetch, { error: 'Failed to fetch promo code' })
  useStatusMsg(status.delete, {
    error: 'Failed to delete promo code',
    success: 'Promo code deleted',
  })
  useStatusMsg(status.update, {
    error: 'Failed to update promo code',
    success: 'Promo code updated',
  })

  useEffect(() => {
    dispatch(actions.fetchPromoCode(id)).then(code => setPromoCode(code))
  }, [id, dispatch])

  const onDeletePromoCode = () =>
    dispatch(actions.deletePromoCode(id)).then(() =>
      history.push(paths.admin.billing.PROMO_CODES()),
    )

  const handleMenuClick = e => {
    if (e.key === 'delete') {
      Modal.confirm({
        title: 'Delete promo code?',
        okText: 'Delete',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: onDeletePromoCode,
      })
    } else if (e.key === 'toggle-active') {
      if (promoCode.active) {
        dispatch(actions.deactivatePromoCode(id)).then(code => setPromoCode(code))
      } else {
        dispatch(actions.activatePromoCode(id)).then(code => setPromoCode(code))
      }
    } else if (e.key === 'edit') {
      setEditingCode(true)
    }
  }

  if (status.fetch.error || status.update.error || status.delete.error) {
    return <Errors status={status} />
  }

  if (!status.fetch.success || !promoCode) {
    return <LoadingOutlined />
  }

  const actionMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="toggle-active">{promoCode.active ? 'Deactivate' : 'Activate'}</Menu.Item>
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  )

  return (
    <>
      <Breadcrumb separator=">" style={{ marginBottom: theme.spacing[2] }}>
        <Breadcrumb.Item>
          <Link to={paths.admin.billing.PROMO_CODES()}>Promo Codes</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{promoCode.code}</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeading
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <PageTitle style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: theme.spacing[2] }}>{promoCode.code}</span>
          <Tag>{promoCode.active ? 'Active' : 'Inactive'}</Tag>
          {isExpired(promoCode) && <Tag>Expired</Tag>}
        </PageTitle>
        <Dropdown overlay={actionMenu} trigger={['click']}>
          <Button>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      </PageHeading>
      <Tabs defaultActiveKey={activeTab} activeTab={activeTab} onChange={tab => setActiveTab(tab)}>
        <TabPane tab="Overview" key="overview">
          <Overview promoCode={promoCode} />
        </TabPane>
        <TabPane tab="Uses" key="uses">
          <div style={{ maxWidth: 850 }}>
            <Uses promoCode={promoCode} />
          </div>
        </TabPane>
      </Tabs>
      {editingCode && (
        <PromoCodeEditor
          existingCode={promoCode}
          isEdit={editingCode}
          complete={updatedCode => {
            if (updatedCode) {
              setPromoCode(updatedCode)
            }
            setEditingCode(false)
          }}
        />
      )}
    </>
  )
}

export default PromoCode
