export const downloadDataAsFile = ({ data, filename, filetype }) => {
  const downloadLink = document.createElement('a')
  // byte order mark, may only be useful for csv, depends on the response
  const fileData = ['\ufeff' + data]

  const blobObject = new Blob(fileData, { type: filetype })

  const url = URL.createObjectURL(blobObject)
  downloadLink.href = url
  downloadLink.download = filename

  document.body.appendChild(downloadLink)
  downloadLink.click()
  document.body.removeChild(downloadLink)
  URL.revokeObjectURL(url)
}
