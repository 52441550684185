import React, { useEffect, useState } from 'react'
import { EllipsisOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons'
import {
  Select,
  Breadcrumb,
  Checkbox,
  Tag,
  Menu,
  Dropdown,
  Button,
  Input,
  Table,
  Modal,
} from 'antd'
import { useDispatch } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import Attr from '../../../components/Attr'
import { Cta as BTLButton } from '../../../components/common'
import * as adminActs from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import { useStatus, useStatusMsg } from '../../../reducers'
import { paths, actions as acts } from '../../../constants'
import { formatDateTimeTz } from '../../../util/time'
import { UserPermitsTable } from './UserPermits'

const { TextArea } = Input
const { Option } = Select

const Active = ({ card }) => {
  return card.active ? <Tag color="green">Active</Tag> : <Tag>Deactivated</Tag>
}

export const NycPermitCardSelector = ({ value, onChange, ...props }) => {
  const dispatch = useDispatch()
  const [cards, setCards] = useState(null)
  const [val, setVal] = useState()
  const status = useStatus(acts.FETCH_NYC_PERMIT_CARDS)

  useEffect(() => {
    dispatch(adminActs.fetchNycPermitCards()).then(crds => {
      setCards(
        crds.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {}),
      )
    })
    return () => {
      dispatch(clearStatus(acts.FETCH_NYC_PERMIT_CARDS))
    }
  }, [])

  useEffect(() => {
    // convert value to card id when card list or value changes
    if (cards && Object.keys(cards).length > 0 && value) {
      setVal(value.id)
    }
  }, [cards, value])

  const handleChange = id => {
    if (typeof onChange === 'function') {
      onChange(cards[id])
    }
  }

  if (!cards) {
    return <LoadingOutlined />
  }

  const options = Object.keys(cards).map(cid => {
    const c = cards[cid]
    return (
      <Option key={c.id} value={c.id} disabled={!c.active}>
        <div>{c.cardNumber}</div>
        <div>
          {c.cardExpMonth}/{c.cardExpYear}
        </div>
        <div>{c.cardCvv}</div>
        <div>
          <Active card={c} />
        </div>
      </Option>
    )
  })

  return (
    <Select value={val} onChange={handleChange} {...props}>
      {options}
    </Select>
  )
}

export const NycPermitCardInfo = ({ card }) => {
  return (
    <>
      <Attr>
        <Active card={card} />
      </Attr>
      <Attr name="Card number">
        <div>{card.cardNumber}</div>
      </Attr>
      <Attr name="Card expiration">
        <div>
          {card.cardExpMonth}/{card.cardExpYear}
        </div>
      </Attr>
      <Attr name="Card CVV">
        <div>{card.cardCvv}</div>
      </Attr>
      <Attr name="Notes">
        <div>{card.notes ? card.notes : <i>None</i>}</div>
      </Attr>
      <Attr name={card.creator ? 'Created by' : 'Created'}>
        <div>
          {card.creator && <Link to={paths.admin.USER(card.creator.id)}>{card.creator.name}</Link>}
          <div>{formatDateTimeTz(card.createdAt)}</div>
        </div>
      </Attr>
    </>
  )
}

export const NycPermitCard = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [card, setCard] = useState(null)
  const status = useStatus(acts.FETCH_NYC_PERMIT_CARD)

  useEffect(() => {
    dispatch(adminActs.fetchNycPermitCard(id)).then(c => setCard(c))
    return () => {
      dispatch(clearStatus(acts.FETCH_NYC_PERMIT_CARD))
    }
  }, [id])

  useStatusMsg(status, { error: 'Failed to load NYC permit card' })

  if (!card) {
    return <LoadingOutlined />
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.admin.PERMITS()}>Permits</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={paths.admin.NYC_PERMIT_CARDS()}>NYC permit cards</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{id}</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1 style={{ marginRight: '.5em' }}>Card</h1>
      </div>
      <NycPermitCardInfo card={card} />
      <Attr
        name="User permits"
        tooltip="User permits that have been assigned to be purchased with this card"
      >
        <div>
          <UserPermitsTable permits={card.userPermits} size="small" />
        </div>
      </Attr>
    </div>
  )
}

const cols = ({ setEditing }) => [
  {
    title: '',
    width: '10px',
    dataIndex: 'active',
    key: 'active',
    render: (val, record) => <Active card={record} />,
  },
  {
    title: 'Card number',
    dataIndex: 'cardNumber',
    key: 'cardNumber',
    render: (val, record) => <Link to={paths.admin.NYC_PERMIT_CARD(record.id)}>{val}</Link>,
  },
  {
    title: 'Card expiration',
    dataIndex: 'cardExpMonth',
    render: (val, record) => `${record.cardExpMonth}/${record.cardExpYear}`,
  },
  {
    title: 'Card CVV',
    dataIndex: 'cardCvv',
    key: 'cardCvv',
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    key: 'notes',
  },
  {
    title: '',
    width: '10px',
    render: (val, record) => {
      const handleMenuClick = e => {
        if (e.key === 'edit') {
          setEditing(record)
        }
      }
      const actionMenu = (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="edit">Edit</Menu.Item>
        </Menu>
      )
      return (
        <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
          <Button size="small">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      )
    },
  },
]

const EditCardModal = ({ card, complete }) => {
  const dispatch = useDispatch()
  const [notes, setNotes] = useState(card.notes)
  const [active, setActive] = useState(card.active)

  const onOk = () => {
    dispatch(adminActs.updateNycPermitCard(card.id, { notes, active })).then(updated => {
      if (typeof complete === 'function') {
        complete(updated)
      }
    })
  }

  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  return (
    <Modal title="Edit NYC permit card" visible onOk={onOk} onCancel={onCancel}>
      <Attr name="Card number">
        <div>{card.cardNumber}</div>
      </Attr>
      <Attr name="Card expiration">
        <div>{`${card.cardExpMonth}/${card.cardExpYear}`}</div>
      </Attr>
      <Attr name="Card CVV">
        <div>{card.cardCvv}</div>
      </Attr>
      <Attr name="Notes">
        <div>
          <TextArea value={notes} onChange={e => setNotes(e.target.value)} />
        </div>
      </Attr>
      <div>
        <Checkbox checked={active} onChange={e => setActive(e.target.checked)}>
          Active
        </Checkbox>
      </div>
    </Modal>
  )
}

const AddCardModal = ({ complete }) => {
  const dispatch = useDispatch()
  const [cardNumber, setCardNumber] = useState(null)
  const [cardExpMonth, setCardExpMonth] = useState(null)
  const [cardExpYear, setCardExpYear] = useState(null)
  const [cardCvv, setCardCvv] = useState(null)
  const [notes, setNotes] = useState(null)
  const status = useStatus(acts.CREATE_NYC_PERMIT_CARD)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_NYC_PERMIT_CARD))
    }
  }, [])

  const onOk = () => {
    dispatch(
      adminActs.createNycPermitCard({ cardNumber, cardExpMonth, cardExpYear, cardCvv, notes }),
    )
      .then(newCard => {
        if (typeof complete === 'function') {
          complete(newCard)
        }
      })
      .catch(e => console.log(e))
  }

  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  return (
    <Modal
      title="Add NYC permit card"
      visible
      okText="Save"
      onOk={onOk}
      okButtonProps={{
        disabled: status.pending,
        loading: !!status.pending,
      }}
      onCancel={onCancel}
    >
      <Attr name="Card number">
        <div>
          <Input
            value={cardNumber}
            onChange={e => setCardNumber(e.target.value)}
            placeholder="4242424242424242"
          />
        </div>
      </Attr>
      <Attr name="Card expiration month">
        <div>
          <Input
            value={cardExpMonth}
            onChange={e => setCardExpMonth(e.target.value)}
            placeholder="04"
          />
        </div>
      </Attr>
      <Attr name="Card expiration year">
        <div>
          <Input
            value={cardExpYear}
            onChange={e => setCardExpYear(e.target.value)}
            placeholder="25"
          />
        </div>
      </Attr>
      <Attr name="Card CVV">
        <div>
          <Input value={cardCvv} onChange={e => setCardCvv(e.target.value)} placeholder="334" />
        </div>
      </Attr>
      <Attr name="Notes">
        <div>
          <TextArea value={notes} onChange={e => setNotes(e.target.value)} />
        </div>
      </Attr>
    </Modal>
  )
}

const NycPermitCards = () => {
  const dispatch = useDispatch()
  const [cards, setCards] = useState(null)
  const [adding, setAdding] = useState(false)
  const [editing, setEditing] = useState(null)
  const status = useStatus(acts.FETCH_NYC_PERMIT_CARDS)

  useEffect(() => {
    dispatch(adminActs.fetchNycPermitCards()).then(crds => setCards(crds))

    return () => {
      dispatch(clearStatus(acts.FETCH_NYC_PERMIT_CARDS))
    }
  }, [])

  const onAddComplete = newCard => {
    if (newCard) {
      setCards(prev => [...prev, newCard])
    }
    setAdding(false)
  }

  const onEditComplete = editedCard => {
    if (editedCard) {
      setCards(prev => {
        const next = [...prev]
        const idx = next.findIndex(a => a.id === editedCard.id)
        if (idx > -1) {
          next[idx] = editedCard
        }
        return next
      })
    }
    setEditing(null)
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.admin.PERMITS()}>Permits</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>NYC permit cards</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1 style={{ marginRight: '.5em' }}>NYC Permit Cards</h1>
        <BTLButton onClick={() => setAdding(true)}>
          <PlusOutlined /> New card
        </BTLButton>
      </div>
      <Table rowKey="id" columns={cols({ setEditing })} dataSource={cards} pagination={false} />
      {adding && <AddCardModal complete={onAddComplete} />}
      {editing && <EditCardModal card={editing} complete={onEditComplete} />}
    </div>
  )
}

export default NycPermitCards
