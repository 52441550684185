import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { useDropzone } from 'react-dropzone'
import { v4 as uuid } from 'uuid'
import ImagePreview from './ImagePreview'

const SingleImageEditor = ({ img, onChange, actionText = 'Select image' }) => {
  const [image, setImage] = useState(img)

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(image)
    }
  }, [image])

  useEffect(() => {
    return () => {
      if (image && image.hasOwnProperty('preview')) {
        URL.revokeObjectURL(image.preview)
      }
    }
  }, [])
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: 'image/*',
    onDrop: accepted => {
      if (accepted.length === 1) {
        const file = accepted[0]
        setImage(
          Object.assign(file, {
            id: uuid(),
            preview: URL.createObjectURL(file),
          }),
        )
      }
    },
  })

  let imgDisplay = null
  if (image) {
    let img = null
    let imgName = null
    if (image.hasOwnProperty('preview')) {
      img = (
        <img
          src={image.preview}
          width={100}
          height={100}
          alt="img"
          style={{ objectFit: 'cover' }}
        />
      )
      imgName = image.path
    } else {
      // it's an asset
      img = <ImagePreview asset={image} width={100} height={100} style={{ objectFit: 'cover' }} />
      imgName = image.filename
    }
    imgDisplay = (
      <div>
        <div>{img}</div>
        <div style={{ display: 'flex' }}>
          <div style={{ marginRight: '1em' }}>{imgName}</div>
          <Button danger type="text" size="small" onClick={() => setImage(null)}>
            <DeleteOutlined />
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {imgDisplay}
        {!imgDisplay && (
          <Button style={{ marginRight: '1em' }} onClick={open}>
            {actionText}
          </Button>
        )}
      </div>
    </div>
  )
}

export default SingleImageEditor
