import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Select, Typography, Tag, DatePicker, Radio, Input, Form, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import * as actions from '../../../actions/admin'
import { actions as acts } from '../../../constants'
import { useStatus, useStatusMsg } from '../../../reducers'
import { clearStatus } from '../../../actions/status'
import moment from 'moment-timezone'
import Attr from '../../../components/Attr'
import Address from '../../../components/Address'
import { ProgramSelect } from '../../../components/program'
import UserSelect from '../../../components/UserSelect'
import { PurchasableSkuSelect } from '../../../components/sku-selectors'
import programUtils from '../../../util/program'
import * as errors from '../../../util/error'
import numUtils from '../../../util/number'
import formatUtils from '../../../util/format'
import { neverExpires } from './utils'

const { RangePicker } = DatePicker

const getValidPeriodType = promoCode => {
  if (promoCode) {
    if (promoCode.hasOwnProperty('validFrom') || promoCode.hasOwnProperty('validTo')) {
      if (neverExpires(promoCode)) {
        return 'expiration-none'
      }
      return 'expiration-range'
    }
  }
  return null
}

const getApplicabilityType = promoCode => {
  if (promoCode) {
    const { entireInvoiceApplicable, applicablePrograms, applicableSkus } = promoCode
    if (entireInvoiceApplicable) {
      return 'applicable-invoice'
    }
    if (Array.isArray(applicablePrograms) && applicablePrograms.length > 0) {
      return 'applicable-programs'
    }
    if (Array.isArray(applicableSkus) && applicableSkus.length > 0) {
      return 'applicable-skus'
    }
  }
  return null
}

const getUsabilityType = promoCode => {
  if (promoCode) {
    const { applicableUsers } = promoCode
    if (Array.isArray(applicableUsers) && applicableUsers.length > 0) {
      return 'usable-users'
    }
    return 'usable-all'
  }
  return null
}

const getDiscountType = promoCode => {
  if (promoCode) {
    const { discountCents, discountPercent } = promoCode
    if (discountCents && !discountPercent) {
      return 'discount-amount'
    }
    if (!discountCents && discountPercent) {
      return 'discount-percent'
    }
    return 'discount-both'
  }
  return null
}

const disabledDate = current => {
  // Disable selecting dates before today
  return current && current < moment().startOf('day')
}

const PeriodSelector = ({ ...props }) => {
  return <RangePicker {...props} disabledDate={disabledDate} />
}

const UserList = ({ users, onRemove }) => {
  const theme = useContext(ThemeContext)
  if (!Array.isArray(users) || !users.length > 0) {
    return null
  }
  const elems = users.map(u => (
    <div
      key={u.id}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing[2],
        padding: `${theme.spacing[1]} ${theme.spacing[2]}`,
        borderRadius: theme.br[2],
        border: '1px solid hsl(220,12%,95%)',
      }}
    >
      <div>
        {u.name} <Tag>{u.type}</Tag>
        <div style={{ fontSize: '12px', color: theme.font.color.secondary }}>
          <span>{u.email}</span>
        </div>
      </div>
      <a onClick={() => onRemove && onRemove(u)}>Remove</a>
    </div>
  ))
  return <div>{elems}</div>
}

const SkuList = ({ skus, onRemove }) => {
  const theme = useContext(ThemeContext)
  if (!Array.isArray(skus) || !skus.length > 0) {
    return null
  }
  const elems = skus.map(sku => (
    <div
      key={sku}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing[2],
        padding: `${theme.spacing[1]} ${theme.spacing[2]}`,
        borderRadius: theme.br[2],
        border: '1px solid hsl(220,12%,95%)',
      }}
    >
      <div>
        <Tag>{sku}</Tag>
      </div>
      <a onClick={() => typeof onRemove === 'function' && onRemove(sku)}>Remove</a>
    </div>
  ))
  return <div>{elems}</div>
}

const ProgramList = ({ programs, onRemove }) => {
  const theme = useContext(ThemeContext)
  if (!Array.isArray(programs) || !programs.length > 0) {
    return null
  }
  const elems = programs.map(p => (
    <div
      key={p.id}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing[2],
        padding: `${theme.spacing[1]} ${theme.spacing[2]}`,
        borderRadius: theme.br[2],
        border: '1px solid hsl(220,12%,95%)',
      }}
    >
      <div>
        {p.name}
        <div style={{ fontSize: '12px', color: theme.font.color.secondary }}>
          <span>{p.date ? programUtils.dateTimeSummary(p) : null}</span>
          <span> {p.hidden ? <Tag>Hidden</Tag> : null}</span>
          <span> {p.draft ? <Tag>Draft</Tag> : null}</span>
          <div>
            <div>{p.location ? p.location.address.name : null}</div>
            <div>{p.location ? <Address.SummaryLine address={p.location.address} /> : null}</div>
          </div>
        </div>
      </div>
      <a onClick={() => onRemove && onRemove(p)}>Remove</a>
    </div>
  ))
  return <div>{elems}</div>
}

// defaultValue: true for yes, false for no, null for neither
const YesNoSelect = ({ defaultValue = null, onChange, yesRender, noRender }) => {
  const theme = useContext(ThemeContext)
  const [answer, setAnswer] = useState(defaultValue)
  const change = e => {
    setAnswer(e.target.value)
    if (onChange) {
      onChange(e)
    }
  }
  return (
    <>
      <Radio.Group value={answer} onChange={change}>
        <Radio value={true}>Yes</Radio>
        <Radio value={false}>No</Radio>
      </Radio.Group>
      {((answer !== null && yesRender) || noRender) && (
        <div style={{ marginTop: theme.spacing[2] }}>
          {answer === true && yesRender && yesRender()}
          {answer === false && noRender && noRender()}
        </div>
      )}
    </>
  )
}

const Errors = ({ status }) => {
  if (status.create.error) {
    if (status.create.error instanceof errors.UniqueError) {
      if ('code' in status.create.error.fields) {
        return <Typography.Text type="danger">Code already exists</Typography.Text>
      }
      return <Typography.Text type="danger">A unique field already exists</Typography.Text>
    }
  }
  if (status.create.error) {
    if (status.create.error.message) {
      return <Typography.Text type="danger">{status.create.error.message}</Typography.Text>
    }
  }
  return null
}

const maxValToYesNo = val => {
  if (typeof val === 'number') {
    if (val > 0) {
      return true
    }
    return false
  }
  return null // indicates neither yes or no
}

const PromoCodeEditor = ({ complete, existingCode, isEdit = false }) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const id = isEdit ? existingCode.id : null
  const [code, setCode] = useState(existingCode && existingCode.code)
  const [note, setNote] = useState(existingCode && existingCode.note)
  const [discountCents, setDiscountCents] = useState(existingCode && existingCode.discountCents)
  const [discountPercent, setDiscountPercent] = useState(
    existingCode && existingCode.discountPercent,
  )
  const [maxTotalUses, setMaxTotalUses] = useState(existingCode && existingCode.maxTotalUses)
  const [maxUserUses, setMaxUserUses] = useState(existingCode && existingCode.maxUserUses)
  const [firstPurchaseOnly, setFirstPurchaseOnly] = useState(
    existingCode && existingCode.firstPurchaseOnly,
  )
  const [validFrom, setValidFrom] = useState(
    existingCode ? (existingCode.validFrom ? moment(existingCode.validFrom) : null) : null,
  )
  const [validTo, setValidTo] = useState(
    existingCode ? (existingCode.validTo ? moment(existingCode.validTo) : null) : null,
  )
  const [entireInvoiceApplicable, setEntireInvoiceApplicable] = useState(
    existingCode && existingCode.entireInvoiceApplicable,
  )
  const [applicablePrograms, setApplicablePrograms] = useState(
    existingCode && existingCode.applicablePrograms,
  )
  const [applicableSkus, setApplicableSkus] = useState(
    existingCode && existingCode.applicableSkus.map(aps => aps.sku),
  )
  const [usableUsers, setUsableUsers] = useState(existingCode && existingCode.applicableUsers)
  const [validPeriodType, setValidPeriodType] = useState(getValidPeriodType(existingCode))
  const [applicabilityType, setApplicabilityType] = useState(getApplicabilityType(existingCode))
  const [usabilityType, setUsabilityType] = useState(getUsabilityType(existingCode))
  const [discountType, setDiscountType] = useState(getDiscountType(existingCode))
  const status = {
    create: useStatus(acts.CREATE_PROMO_CODE),
    update: useStatus(acts.UPDATE_PROMO_CODE),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_PROMO_CODE))
      dispatch(clearStatus(acts.UPDATE_PROMO_CODE))
    }
  }, [])

  useStatusMsg(status.create, { error: 'Failed to create promo code' })
  useStatusMsg(status.update, { error: 'Failed to update promo code' })

  const onOk = async () => {
    const data = {
      code,
      note,
      discountCents,
      discountPercent,
      maxTotalUses,
      maxUserUses,
      firstPurchaseOnly,
      entireInvoiceApplicable,
      validFrom,
      validTo,
    }
    if (validPeriodType === 'expiration-none') {
      data.validFrom = moment().startOf('day')
      data.validTo = null
    }
    if (Array.isArray(applicablePrograms)) {
      data.applicablePrograms = applicablePrograms.map(ap => (ap.hasOwnProperty('id') ? ap.id : ap))
    }
    if (Array.isArray(applicableSkus)) {
      data.applicableSkus = applicableSkus
    }

    if (applicabilityType !== 'applicable-skus') {
      data.applicableSkus = []
    }
    if (applicabilityType !== 'applicable-programs') {
      data.applicablePrograms = []
    }

    if (Array.isArray(usableUsers)) {
      data.applicableUsers = usableUsers.map(uu => (uu.hasOwnProperty('id') ? uu.id : uu))
    }
    if (usabilityType == 'usable-all') {
      data.applicableUsers = []
    }
    if (discountType == 'discount-amount') {
      data.discountPercent = null
    }
    if (discountType == 'discount-percent') {
      data.discountCents = null
    }
    if (data.maxTotalUses === null) {
      data.maxTotalUses = 0
    }
    if (data.maxUserUses === null) {
      data.maxUserUses = 0
    }
    if (isEdit) {
      dispatch(actions.updatePromoCode(id, data))
        .then(updatedCode => complete(updatedCode))
        .catch(() => {})
    } else {
      dispatch(actions.createPromoCode(data))
        .then(promoCode => complete(promoCode))
        .catch(() => {})
    }
  }

  const formComplete = [
    code && code.trim().length > 0,
    note && note.trim().length > 0,

    discountType != null &&
      ((discountType == 'discount-amount' && discountCents != null) ||
        (discountType == 'discount-percent' && discountPercent != null) ||
        (discountType == 'discount-both' && discountPercent != null && discountCents != null)),
    validPeriodType != null &&
      (validPeriodType === 'expiration-none' || (validFrom != null && validTo != null)),

    firstPurchaseOnly != null,
    maxTotalUses != null,
    maxUserUses != null,

    usabilityType != null &&
      (usabilityType === 'usable-all' || (usableUsers && usableUsers.length > 0)),
    applicabilityType != null &&
      (applicabilityType == 'applicable-invoice' ||
        (applicabilityType == 'applicable-programs' &&
          applicablePrograms &&
          applicablePrograms.length > 0) ||
        (applicabilityType == 'applicable-skus' && applicableSkus && applicableSkus.length > 0)),
  ].reduce((acc, curr) => curr && acc, true)

  return (
    <Modal
      title={`${isEdit ? `Update ${existingCode.code}` : 'Create promo code'}`}
      visible
      okText={isEdit ? 'Save' : 'Create'}
      okButtonProps={{ disabled: !formComplete }}
      onOk={onOk}
      onCancel={() => complete()}
      confirmLoading={status.create.pending || status.update.pending || false}
    >
      <Errors status={status} />
      <Form>
        <Attr name="Code">
          <div>
            <Input
              placeholder="10OFF"
              value={code}
              onChange={e => setCode(e.target.value.toUpperCase())}
              style={{ width: theme.width[5] }}
            />
          </div>
        </Attr>
        <Attr name="Note" description="Notes are internal and not visible to the user">
          <Input.TextArea value={note} onChange={e => setNote(e.target.value)} />
        </Attr>
        <Attr
          name="Discount"
          description="If both selected, will apply both amount and percentage to item or invoice"
        >
          <Radio.Group
            buttonStyle="solid"
            value={discountType}
            onChange={e => setDiscountType(e.target.value)}
          >
            <Radio.Button value="discount-amount">Amount</Radio.Button>
            <Radio.Button value="discount-percent">Percent</Radio.Button>
            <Radio.Button value="discount-both">Both</Radio.Button>
          </Radio.Group>
          <div>
            {['discount-amount', 'discount-both'].includes(discountType) && (
              <div style={{ marginTop: theme.spacing[3] }}>
                <Input
                  addonBefore="$"
                  type="number"
                  placeholder="10.00"
                  value={discountCents / 100}
                  onChange={e => {
                    const val = e.target.value
                    if (val) {
                      setDiscountCents(Number(val) * 100)
                    } else {
                      setDiscountCents(val)
                    }
                  }}
                  style={{ width: theme.width[4] }}
                />
              </div>
            )}
            {['discount-percent', 'discount-both'].includes(discountType) && (
              <div style={{ marginTop: theme.spacing[3] }}>
                <Input
                  addonAfter="%"
                  type="number"
                  placeholder="10"
                  value={discountPercent * 100}
                  onChange={e => {
                    const val = e.target.value
                    if (val) {
                      setDiscountPercent(Number(val) / 100)
                    } else {
                      setDiscountPercent(val)
                    }
                  }}
                  style={{ width: theme.width[4] }}
                />
              </div>
            )}
          </div>
        </Attr>
        <Attr name="Valid period" description="Period of time that this promo code is useable">
          <Radio.Group
            buttonStyle="solid"
            value={validPeriodType}
            onChange={e => setValidPeriodType(e.target.value)}
          >
            <Radio.Button value="expiration-none">Never expires</Radio.Button>
            <Radio.Button value="expiration-range">Date range</Radio.Button>
          </Radio.Group>
          {validPeriodType === 'expiration-range' && (
            <div style={{ marginTop: theme.spacing[3] }}>
              <PeriodSelector
                value={[validFrom, validTo]}
                onChange={range => {
                  if (range) {
                    setValidFrom(range[0].startOf('day'))
                    setValidTo(range[1].endOf('day'))
                  } else {
                    setValidFrom(null)
                    setValidTo(null)
                  }
                }}
              />
            </div>
          )}
        </Attr>
        <Attr
          name="First purchase only"
          description="Only allow users who have never made a purchase before to use this promo code?"
        >
          <YesNoSelect
            defaultValue={firstPurchaseOnly}
            onChange={e => setFirstPurchaseOnly(e.target.value)}
          />
        </Attr>
        <Attr
          name="Max total uses"
          description="Limit number of times this promo code can be used across the entire system?"
        >
          <YesNoSelect
            defaultValue={maxValToYesNo(maxTotalUses)}
            onChange={e => {
              if (e.target.value === false) {
                setMaxTotalUses(0)
              }
            }}
            yesRender={() => (
              <Input
                type="number"
                addonAfter="total uses"
                placeholder="100"
                value={maxTotalUses}
                onChange={e => setMaxTotalUses(e.target.value)}
                style={{ width: theme.width[5] }}
              />
            )}
          />
        </Attr>
        <Attr
          name="Max user uses"
          description="Limit the number of times a user can use this promo code?"
        >
          <YesNoSelect
            defaultValue={maxValToYesNo(maxUserUses)}
            onChange={e => {
              if (e.target.value === false) {
                setMaxUserUses(0)
              }
            }}
            yesRender={() => (
              <Input
                type="number"
                addonAfter="user uses"
                placeholder="1"
                value={maxUserUses}
                onChange={e => setMaxUserUses(e.target.value)}
                style={{ width: theme.width[5] }}
              />
            )}
          />
        </Attr>
        <Attr name="Usability" description="Who can use this promo code?">
          <Radio.Group
            buttonStyle="solid"
            value={usabilityType}
            onChange={e => setUsabilityType(e.target.value)}
          >
            <Radio.Button value="usable-all">All users</Radio.Button>
            <Radio.Button value="usable-users">Specific users</Radio.Button>
          </Radio.Group>
          {usabilityType === 'usable-users' && (
            <div style={{ marginTop: theme.spacing[3] }}>
              <UserSelect
                placeholder="Search users by name"
                style={{ width: '100%', marginBottom: theme.spacing[2] }}
                value={null}
                onChange={e =>
                  setUsableUsers(prev => {
                    if (Array.isArray(prev)) {
                      return [...prev, e]
                    } else {
                      return [e]
                    }
                  })
                }
              />
              <UserList
                users={usableUsers}
                onRemove={user => {
                  setUsableUsers(prev => {
                    if (Array.isArray(prev)) {
                      return prev.filter(u => u.id !== user.id)
                    }
                  })
                }}
              />
            </div>
          )}
        </Attr>
        <Attr name="Applicability" description="What can this promo code be applied to?">
          <Radio.Group
            buttonStyle="solid"
            value={applicabilityType}
            onChange={e => {
              setApplicabilityType(e.target.value)
              if (e.target.value === 'applicable-invoice') {
                setEntireInvoiceApplicable(true)
              } else {
                setEntireInvoiceApplicable(false)
              }
            }}
          >
            <Radio.Button value="applicable-invoice">Entire invoice</Radio.Button>
            <Radio.Button value="applicable-programs">Specific programs</Radio.Button>
            <Radio.Button value="applicable-skus">Specific SKUs</Radio.Button>
          </Radio.Group>
          {applicabilityType === 'applicable-programs' && (
            <div style={{ marginTop: theme.spacing[3] }}>
              <ProgramSelect
                placeholder="Search programs by name and id"
                style={{ width: '100%', marginBottom: theme.spacing[2] }}
                value={null}
                onChange={e =>
                  setApplicablePrograms(prev => {
                    if (Array.isArray(prev)) {
                      return [...prev, e]
                    } else {
                      return [e]
                    }
                  })
                }
              />
              <ProgramList
                programs={applicablePrograms}
                onRemove={program => {
                  setApplicablePrograms(prev => {
                    if (Array.isArray(prev)) {
                      return prev.filter(prg => prg.id !== program.id)
                    }
                  })
                }}
              />
            </div>
          )}
          {applicabilityType === 'applicable-skus' && (
            <div style={{ marginTop: theme.spacing[3] }}>
              <PurchasableSkuSelect
                placeholder="Search purchasable skus"
                style={{ width: '100%', marginBottom: theme.spacing[2] }}
                value={null}
                onChange={sku =>
                  setApplicableSkus(prev => (Array.isArray(prev) ? [...prev, sku] : [sku]))
                }
              />
              <SkuList
                skus={applicableSkus}
                onRemove={sku => {
                  setApplicableSkus(prev => {
                    if (Array.isArray(prev)) {
                      return prev.filter(prevSku => prevSku !== sku)
                    }
                  })
                }}
              />
            </div>
          )}
        </Attr>
      </Form>
    </Modal>
  )
}

export default PromoCodeEditor
