import React, { useEffect, useState } from 'react'
import {
  EditOutlined,
  DownOutlined,
  LoadingOutlined,
  CreditCardOutlined,
  IdcardOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons'
import { Link, useParams } from 'react-router-dom'
import { Tooltip, Input, Modal, Dropdown, Menu, Breadcrumb, Collapse } from 'antd'
import { useDispatch } from 'react-redux'
import { Cta as BTLButton, Head, Title } from '../../../components/common'
import * as adminActs from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import { actions as acts, paths } from '../../../constants'
import Attr from '../../../components/Attr'
import { LinkedImagePreview } from '../../../components/ImagePreview'
import { NycPermitAccountInfo, NycPermitAccountSelector } from './NycPermitAccounts'
import { NycPermitCardInfo, NycPermitCardSelector } from './NycPermitCards'
import { useStatus, useStatusMsg } from '../../../reducers'
import { formatPermitDateUtc, formatDateTimeTz } from '../../../util/time'
import * as permitUtils from '../../../util/permit'
import { Invoice } from '../invoice/Invoice'

const { Panel } = Collapse
const { TextArea } = Input

const appKeyNames = [
  { key: 'appFirstName', label: 'First name' },
  { key: 'appMiddleInitial', label: 'Middle initial' },
  { key: 'appLastName', label: 'Last name' },
  {
    key: 'appBirthdate',
    label: 'Birthdate',
    fmt: formatPermitDateUtc,
    desc: "Remember to verify this birthdate with the birthdate on the user's ID card",
  },
  { key: 'appPhoneNumber', label: 'Phone number' },
  { key: 'appEmail', label: 'Email' },
  { key: 'appAddressLine1', label: 'Address line 1' },
  { key: 'appAddressLine2', label: 'Address line 2' },
  { key: 'appAddressCity', label: 'City' },
  { key: 'appAddressState', label: 'State' },
  { key: 'appAddressZipcode', label: 'Zip code' },
  { key: 'appAddressCountry', label: 'Country' },
]

const DisabledTip = ({ disabled, title, children, ...props }) => {
  if (!disabled) {
    return children
  }
  return (
    <Tooltip title={title} {...props}>
      {children}
    </Tooltip>
  )
}

const NycPermitAccount = ({ permit }) => {
  if (!permit.nycPermitAccount) {
    return (
      <div>
        <i>No account allocated</i>
      </div>
    )
  }
  return (
    <Collapse style={{ maxWidth: '600px' }}>
      <Panel header="NYC permit account" key="nyc-permit-account">
        <NycPermitAccountInfo account={permit.nycPermitAccount} />
      </Panel>
    </Collapse>
  )
}

const NycPermitCard = ({ permit }) => {
  if (!permit.nycPermitCard) {
    return (
      <div>
        <i>No card allocated</i>
      </div>
    )
  }
  return (
    <Collapse style={{ maxWidth: '600px' }}>
      <Panel header="NYC permit card" key="nyc-permit-card">
        <NycPermitCardInfo card={permit.nycPermitCard} />
      </Panel>
    </Collapse>
  )
}

export const EditNotesModal = ({ permit, complete }) => {
  const dispatch = useDispatch()
  const [notes, setNotes] = useState(permit.internalNotes)
  const status = useStatus(acts.EDIT_USER_PERMIT_NOTES)

  useStatusMsg(status, {
    error: 'Failed to update notes',
    success: 'Notes updated',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.EDIT_USER_PERMIT_NOTES))
    }
  }, [])

  const onOk = () => {
    dispatch(adminActs.editUserPermitNotes({ permitId: permit.id, notes }))
      .then(updatedPermit => {
        if (typeof complete === 'function') {
          complete(updatedPermit)
        }
      })
      .catch(e => console.log(e))
  }

  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  return (
    <Modal
      title="Edit notes"
      visible
      okText="Save"
      onOk={onOk}
      okButtonProps={{
        disabled: status.pending,
        loading: !!status.pending,
      }}
      onCancel={onCancel}
    >
      <Attr name="Notes">
        <div>
          <TextArea value={notes} onChange={e => setNotes(e.target.value)} />
        </div>
      </Attr>
    </Modal>
  )
}

const EditNycAccountModal = ({ permit, complete }) => {
  const dispatch = useDispatch()
  const [nycAccount, setNycAccount] = useState(permit.nycPermitAccount)
  const status = useStatus(acts.SET_USER_PERMIT_NYC_ACCOUNT)

  useStatusMsg(status, {
    error: 'Failed to set NYC permit account',
    success: 'NYC permit account set',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.SET_USER_PERMIT_NYC_ACCOUNT))
    }
  }, [])

  const onOk = () => {
    dispatch(
      adminActs.setUserPermitNycAccount({ permitId: permit.id, nycAccountId: nycAccount.id }),
    )
      .then(updatedPermit => {
        if (typeof complete === 'function') {
          complete(updatedPermit)
        }
      })
      .catch(e => console.log(e))
  }

  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  return (
    <Modal
      title="Edit NYC permit account"
      visible
      okText="Save"
      onOk={onOk}
      okButtonProps={{
        disabled: status.pending,
        loading: !!status.pending,
      }}
      onCancel={onCancel}
    >
      <Attr name="NYC permit account">
        <div>
          <NycPermitAccountSelector
            value={nycAccount}
            onChange={v => setNycAccount(v)}
            style={{ width: '100%' }}
          />
        </div>
      </Attr>
    </Modal>
  )
}

const EditNycCardModal = ({ permit, complete }) => {
  const dispatch = useDispatch()
  const [nycCard, setNycCard] = useState(permit.nycPermitCard)
  const status = useStatus(acts.SET_USER_PERMIT_NYC_CARD)

  useStatusMsg(status, {
    error: 'Failed to set NYC permit card',
    success: 'NYC permit card set',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.SET_USER_PERMIT_NYC_CARD))
    }
  }, [])

  const onOk = () => {
    dispatch(adminActs.setUserPermitNycCard({ permitId: permit.id, nycCardId: nycCard.id }))
      .then(updatedPermit => {
        if (typeof complete === 'function') {
          complete(updatedPermit)
        }
      })
      .catch(e => console.log(e))
  }

  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  return (
    <Modal
      title="Edit NYC permit card"
      visible
      okText="Save"
      onOk={onOk}
      okButtonProps={{
        disabled: status.pending,
        loading: !!status.pending,
      }}
      onCancel={onCancel}
    >
      <Attr name="NYC permit card">
        <div>
          <NycPermitCardSelector
            value={nycCard}
            onChange={v => setNycCard(v)}
            style={{ width: '100%' }}
          />
        </div>
      </Attr>
    </Modal>
  )
}

const UserPermit = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [permit, setPermit] = useState(null)
  const [editingNotes, setEditingNotes] = useState(false)
  const [editingNycAccount, setEditingNycAccount] = useState(false)
  const [editingNycCard, setEditingNycCard] = useState(false)
  const status = useStatus(acts.FETCH_USER_PERMIT)

  useEffect(() => {
    dispatch(adminActs.fetchUserPermit(id)).then(p => setPermit(p))
    return () => {
      dispatch(clearStatus(acts.FETCH_USER_PERMIT))
    }
  }, [id])

  if (!permit) {
    return <LoadingOutlined />
  }

  const applicationAttrs = appKeyNames.map(akn => (
    <Attr key={akn.key} name={akn.label} description={akn.desc}>
      <div>{typeof akn.fmt === 'function' ? akn.fmt(permit[akn.key]) : permit[akn.key]}</div>
    </Attr>
  ))

  const onManuallyFulfill = () => {
    dispatch(adminActs.manuallyFulfillUserPermit({ permitId: id })).then(updatedPermit =>
      setPermit(updatedPermit),
    )
  }

  const handleMenu = e => {
    if (e.key === 'edit-notes') {
      setEditingNotes(true)
    } else if (e.key === 'edit-nyc-account') {
      setEditingNycAccount(true)
    } else if (e.key === 'edit-nyc-card') {
      setEditingNycCard(true)
    } else if (e.key === 'manual-fulfillment') {
      Modal.confirm({
        title: 'Mark permit as manually fulfilled?',
        okText: 'Mark as fulfilled',
        content: `Only mark a permit as manually fulfilled when someone has purchased a permit on the NYC parks website. Marking a permit as manually fulfilled will cancel any pending scraper jobs`,
        onOk: onManuallyFulfill,
      })
    }
  }

  const disableNycEdits = !permitUtils.canChangePermitNycDetails(permit)
  const disableManualFulfillment = permitUtils.isPermitFulfilled(permit)

  const actionMenu = (
    <Menu onClick={handleMenu}>
      <Menu.Item key="edit-notes">
        <EditOutlined /> Edit notes
      </Menu.Item>
      <Menu.Item key="edit-nyc-account" disabled={disableNycEdits}>
        <DisabledTip
          disabled={disableNycEdits}
          title="Cannot set NYC account if scraper has started or permit fulfilled"
          placement="left"
        >
          <IdcardOutlined /> Set NYC account
        </DisabledTip>
      </Menu.Item>
      <Menu.Item key="edit-nyc-card" disabled={disableNycEdits}>
        <DisabledTip
          disabled={disableNycEdits}
          title="Cannot set NYC card if scraper has started or permit fulfilled"
          placement="left"
        >
          <CreditCardOutlined /> Set NYC card
        </DisabledTip>
      </Menu.Item>
      <Menu.Item key="manual-fulfillment" disabled={disableManualFulfillment}>
        <DisabledTip
          disabled={disableManualFulfillment}
          title="Cannot fulfill an already-fulfilled permit"
          placement="left"
        >
          <CheckCircleOutlined /> Mark as fulfilled
        </DisabledTip>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <div>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={paths.admin.PERMITS()}>Permits</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={paths.admin.USER_PERMITS()}>User permits</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{id}</Breadcrumb.Item>
        </Breadcrumb>
        <Head style={{ justifyContent: 'space-between' }}>
          <Title>Permit</Title>
          <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
            <BTLButton>
              <DownOutlined /> Actions
            </BTLButton>
          </Dropdown>
        </Head>
        <Attr name="Purchaser">
          <div>
            <Link to={paths.admin.USER(permit.user.id)}>{permit.user.name}</Link>{' '}
            <small>on {formatDateTimeTz(permit.createdAt)}</small>
          </div>
        </Attr>
        <Attr name="Application status">
          <div>{permit.applicationStatus}</div>
        </Attr>
        <Attr name="Payment status">
          <div>{permit.paymentStatus}</div>
        </Attr>
        <Attr name="Scaper status">
          <div>{permit.scraperStatus}</div>
        </Attr>
        <Attr name="Fulfillment status">
          <div>{permit.fulfillmentStatus}</div>
        </Attr>
        <Attr name="Internal notes">
          <div>{permit.internalNotes ? permit.internalNotes : <i>No internal notes</i>}</div>
        </Attr>
        <Attr name="NYC permit account">
          <NycPermitAccount permit={permit} />
        </Attr>
        <Attr name="NYC permit card">
          <NycPermitCard permit={permit} />
        </Attr>
        <Attr name="Application">
          <div>
            <Collapse style={{ maxWidth: '1024px' }}>
              <Panel header="Application" key="application">
                {applicationAttrs}
                <Attr name="Identification photo">
                  <div>
                    {permit.appIdentificationPhoto ? (
                      <LinkedImagePreview
                        asset={permit.appIdentificationPhoto}
                        width="inherit"
                        height="200px"
                      />
                    ) : (
                      <i>No identification photo</i>
                    )}
                  </div>
                </Attr>
                <Attr name="Permit photo">
                  <div>
                    {permit.appPermitPhoto ? (
                      <LinkedImagePreview
                        asset={permit.appPermitPhoto}
                        width="inherit"
                        height="200px"
                      />
                    ) : (
                      <i>No permit photo</i>
                    )}
                  </div>
                </Attr>
                <Attr name="IDNYC number">
                  <div>
                    {permit.appIdnycNumber ? permit.appIdnycNumber : <i>No IDNYC number</i>}
                  </div>
                </Attr>
                <Attr name="IDNYC photo">
                  <div>
                    {permit.appIdnycPhoto ? (
                      <LinkedImagePreview
                        asset={permit.appIdnycPhoto}
                        width="inherit"
                        height="200px"
                      />
                    ) : (
                      <i>No IDNYC photo</i>
                    )}
                  </div>
                </Attr>
              </Panel>
            </Collapse>
          </div>
        </Attr>
        <Attr name="Invoice">
          {permit.invoice ? (
            <Collapse style={{ maxWidth: '1024px' }}>
              <Panel header="Invoice" key="invoice">
                <Invoice id={permit.invoice} />
              </Panel>
            </Collapse>
          ) : (
            <div>
              <i>No invoice</i>
            </div>
          )}
        </Attr>
      </div>
      {editingNotes && (
        <EditNotesModal
          permit={permit}
          complete={updatedPermit => {
            if (updatedPermit) {
              setPermit(updatedPermit)
            }
            setEditingNotes(false)
          }}
        />
      )}
      {editingNycAccount && (
        <EditNycAccountModal
          permit={permit}
          complete={updatedPermit => {
            if (updatedPermit) {
              setPermit(updatedPermit)
            }
            setEditingNycAccount(false)
          }}
        />
      )}
      {editingNycCard && (
        <EditNycCardModal
          permit={permit}
          complete={updatedPermit => {
            if (updatedPermit) {
              setPermit(updatedPermit)
            }
            setEditingNycCard(false)
          }}
        />
      )}
    </>
  )
}

export default UserPermit
