import React, { useState, useEffect } from 'react'
import { Radio } from 'antd'
import { InputGroup, SelectContainer, Title, Desc } from './step'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'

const Hidden = ({ onChange, clinic }) => {
  const [hidden, setHidden] = useState(clinic.hidden)

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({ hidden })
    }
  }, [hidden])

  return (
    <>
      <InputGroup>
        <Title>Should this {lower(typeLabel(clinic))} be hidden?</Title>
        <Desc>
          Hidden events do not appear on normal site pages and are only accessible via custom
          landing pages and direct links
        </Desc>
        <SelectContainer>
          <Radio.Group onChange={e => setHidden(e.target.value)} value={hidden}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </SelectContainer>
      </InputGroup>
    </>
  )
}

const Viewer = ({ program }) => {
  return <div>{program.hidden.toString()}</div>
}

const step = Hidden

const complete = ({ hidden }) => {
  if (typeof hidden !== 'boolean') {
    return false
  }
  return true
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
