import { useDispatch } from 'react-redux'
import { useContext, useReducer } from 'react'
import styled, { ThemeContext } from 'styled-components'
import * as timeUtils from '../util/time'
import { Form, message, Dropdown, Modal, Button, Menu, Input } from 'antd'
import { EditOutlined, DeleteOutlined, EllipsisOutlined } from '@ant-design/icons'
import { useHistory, useParams } from 'react-router-dom'
import VideoEmbed from './VideoEmbed'
import Editor from './Editor'
import ReactHtmlParser from 'react-html-parser'
import { paths } from '../constants'
import { Reorderable } from './Reorderable'
import { ProductSelect } from './product'
import { parseUrl } from 'query-string'

const VideoCardContainer = styled.div`
  max-width: 475px;
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  background-color: ${props => props.theme.color.white};
  border-radius: ${props => props.theme.br[2]};
  padding-left: ${props => props.theme.spacing[3]};
  padding-right: ${props => props.theme.spacing[3]};
  padding-top: ${props => props.theme.spacing[3]};
  padding-bottom: ${props => props.theme.spacing[3]};
  margin-right: ${props => props.theme.spacing[3]};
  margin-bottom: ${props => props.theme.spacing[3]};
`
const VideoCardDate = ({ date }) => {
  return <small>Created {timeUtils.formatDateTimeTz(date)}</small>
}

export const VideoHeadInfo = ({ video, actionMenu = null }) => {
  const theme = useContext(ThemeContext)
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span style={{ marginBottom: theme.spacing[1] }}>{video.title}</span>
          <VideoCardDate date={video.createdAt} />
        </div>
        {actionMenu}
      </div>
    </>
  )
}

const VideoCard = ({ video, deleteVideo }) => {
  const history = useHistory()
  const theme = useContext(ThemeContext)
  const { osid, id } = useParams()
  const parsedUrl = parseUrl(video.videoUrl)
  const videoEmbedUrl =
    parsedUrl && parsedUrl.query ? `https://www.youtube.com/embed/${parsedUrl.query.v}` : null

  const handleActionMenu = ({ key }) => {
    if (key === 'edit') {
      history.push(paths.org.EDIT_CLUB_VIDEO(osid, id, video.id))
    } else if (key === 'delete-video') {
      Modal.confirm({
        title: 'Delete video',
        content: 'Are you sure you want to delete this video?',
        okText: 'Delete',
        okButtonProps: {
          danger: true,
          type: 'primary',
        },
        onOk: () => deleteVideo(video.id),
      })
    }
  }

  const actionMenu = (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="edit">
        <EditOutlined /> Edit
      </Menu.Item>
      <Menu.Item danger key="delete-video">
        <DeleteOutlined /> Delete
      </Menu.Item>
    </Menu>
  )

  const actionDropdown = (
    <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
      <Button size="small">
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  )

  return (
    <VideoCardContainer>
      <VideoHeadInfo video={video} actionMenu={actionDropdown} />
      {videoEmbedUrl && (
        <VideoEmbed
          url={videoEmbedUrl}
          style={{ margin: `${theme.spacing[4]} ${theme.spacing[1]}` }}
        />
      )}

      {ReactHtmlParser(video.description)}
    </VideoCardContainer>
  )
}

const Label = styled.span`
  color: rgba(0, 0, 0, 0.65);
  font-weight: ${props => props.theme.font.weight[7]};
`

const videoInitState = {
  title: '',
  description: '',
  videoUrl: '',
  products: [],
}

export const VideoEditor = ({ onChange, video, setProducts, products }) => {
  const theme = useContext(ThemeContext)

  if (!video) {
    if (typeof onChange === 'function') {
      onChange(videoInitState)
    }
    return null
  }

  const { title, description, videoUrl } = video

  const change = update => {
    if (typeof onChange === 'function') {
      onChange({ ...video, ...update })
    }
  }

  const changeProducts = products => {
    if (typeof setProducts === 'function') {
      setProducts(products)
    }
  }

  return (
    <Form layout="vertical">
      <Form.Item name="title" label={<Label>Title</Label>} initialValue={title}>
        <Input
          value={title}
          onChange={e => change({ title: e.target.value })}
          placeholder="Title"
        />
      </Form.Item>
      <Form.Item name="description" label={<Label>Description</Label>} initialValue={description}>
        <Editor value={description} onChange={description => change({ description })} />
      </Form.Item>
      <Form.Item name="videoUrl" label={<Label>Video url</Label>} initialValue={videoUrl}>
        <Input
          value={videoUrl}
          onChange={e => change({ videoUrl: e.target.value })}
          placeholder="https://www.youtube.com/embed/someId"
        />
      </Form.Item>
      <Form.Item name="products" label={<Label>Products</Label>} initialValue={products}>
        <ProductSelect
          value={null}
          excludeIds={products.map(p => p && p.id).filter(Boolean)}
          onChange={product => changeProducts([...products, product])}
        />
        <div style={{ marginTop: theme.spacing[3] }}>
          <Reorderable
            items={products}
            keyExtractor={p => p.id}
            onChange={products => changeProducts(products)}
            renderItem={p => (
              <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'nowrap' }}>
                <span>{p.name}</span>
                <Button
                  type="link"
                  danger
                  onClick={() => changeProducts(products.filter(prdct => prdct.id !== p.id))}
                >
                  Remove
                </Button>
              </div>
            )}
          />
        </div>
      </Form.Item>
    </Form>
  )
}

export const Videos = ({ videos, deleteVideo }) => {
  const theme = useContext(ThemeContext)
  return (
    <div style={{ marginTop: theme.spacing[3], marginBottom: theme.spacing[3] }}>
      {videos.map(video => (
        <VideoCard key={video.id} video={video} deleteVideo={deleteVideo} />
      ))}
    </div>
  )
}

const initialState = {
  videos: null,
}

export const useVideos = ({ getVideos, updateVideosOrder, deleteVideo }) => {
  const [state, dp] = useReducer(
    (state, action) => ({ ...state, videos: action.payload }),
    initialState,
  )
  const dispatch = useDispatch()

  return {
    videos: state.videos,
    loadVideos: () => {
      return dispatch(getVideos())
        .then(videos => dp({ payload: videos }))
        .catch(e => {
          console.log(e)
          message.error('Failed to load videos')
        })
    },

    deleteVideo: videoId => {
      return dispatch(deleteVideo(videoId))
        .then(() => {
          dp({ payload: state.videos.filter(v => v.id !== videoId) })
          message.success('Video deleted')
        })
        .catch(e => {
          console.log(e)
          message.error('Failed to delete video')
        })
    },

    updateOrder: videos =>
      dispatch(updateVideosOrder(videos))
        .then(videos => dp({ payload: videos }))
        .catch(e => {
          console.log(e)
          message.error('Failed to set videos')
        }),
    setVideos: videos => dp({ payload: videos }),
  }
}
