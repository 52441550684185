import React, { useState, useEffect, useContext, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { Badge, Tabs, Input, Button } from 'antd'
import { LoadingOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { fetchDraftPrograms } from '../../../actions/class'
import { isInProgress, isUpcoming, isPast } from '../../../util/clinic'
import Drafts from './Drafts'
import { ProgramList } from '../../../components/program'
import { searchUpcomingPrograms, searchPastPrograms } from '../../../actions/org'
import { numberOfPages } from '../../../util/pagination'
import { useStatus } from '../../../reducers'
import { actions as acts } from '../../../constants'
import { clearStatus } from '../../../actions/status'
const { TabPane } = Tabs
const { Search } = Input

const SearchBar = ({ searchFunction, isLoading, activeTab, ...props }) => {
  const search = value => {
    if (typeof searchFunction === 'function') {
      searchFunction(value)
    }
  }
  return (
    <>
      <Search
        placeholder="Search programs by name and id"
        allowClear
        onSearch={search}
        enterButton="Search"
        loading={isLoading}
        {...props}
      />
    </>
  )
}

const ClassList = () => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [programs, setPrograms] = useState(null)
  const [numberOfPrograms, setNumberOfPrograms] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [draftPrograms, setDraftPrograms] = useState(null)
  const [searchInput, setSearchInput] = useState('')
  const [activeTab, setActiveTab] = useState('upcoming')
  const prevTab = useRef()
  const programsPerPage = 20
  const status = {
    upcoming: useStatus(acts.SEARCH_UPCOMING_PROGRAMS),
    past: useStatus(acts.SEARCH_PAST_PROGRAMS),
  }

  useEffect(() => {
    dispatch(fetchDraftPrograms(osid)).then(programs => setDraftPrograms(programs))
  }, [])

  useEffect(() => {
    setPrograms([])
    searchFunction(searchInput)
    if (prevTab.current !== activeTab) {
      //We want to check if the activeTab value in the dependency array is what
      //triggered the useEffect. We can now reduce the number of useEffect hooks.
      setCurrentPage(1)
      prevTab.current = activeTab
    }
  }, [currentPage, activeTab])

  const searchFunction = input => {
    setSearchInput(input)
    const params = {
      osid,
      searchInput: input,
      limit: programsPerPage,
      offset: (currentPage - 1) * programsPerPage,
    }
    let act = searchUpcomingPrograms(params)
    if (activeTab === 'past') {
      act = searchPastPrograms(params)
    }

    dispatch(act).then(res => {
      setPrograms(res.data)
      setNumberOfPrograms(res.totalPrograms)
    })
  }

  const handleForward = () => {
    if (currentPage < numberOfPages(numberOfPrograms, programsPerPage)) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  if (!programs) {
    return <LoadingOutlined />
  }

  const inProgress = programs.filter(isInProgress)
  const upcoming = programs.filter(isUpcoming)

  const draftTitle = (
    <span>
      Drafts {draftPrograms && <Badge count={draftPrograms.length} />}{' '}
      {!draftPrograms && <LoadingOutlined />}
    </span>
  )
  return (
    <div>
      <Tabs defaultActiveKey="in-progress" onChange={key => setActiveTab(key)}>
        {inProgress.length > 0 && (
          <TabPane tab="In Progress" key="in-progress">
            <ProgramList osid={osid} programs={inProgress} />
          </TabPane>
        )}
        <TabPane tab="Upcoming" key="upcoming">
          {!programs && <LoadingOutlined />}
          <SearchBar
            style={{ width: theme.width[6], marginBottom: theme.spacing[3] }}
            searchFunction={searchFunction}
            isLoading={status.upcoming.pending}
          />
          <div style={{ marginBottom: theme.spacing[3] }}>
            <Button type="text" onClick={handleBack}>
              <LeftOutlined />
            </Button>
            {currentPage} / {Math.max(numberOfPages(numberOfPrograms, programsPerPage), 1)}
            <Button type="text" onClick={handleForward}>
              <RightOutlined />
            </Button>
          </div>
          {upcoming && (
            <ProgramList osid={osid} programs={upcoming} isLoading={status.upcoming.pending} />
          )}
        </TabPane>
        <TabPane tab="Past" key="past">
          <SearchBar
            style={{ width: theme.width[6], marginBottom: theme.spacing[3] }}
            searchFunction={searchFunction}
            isLoading={status.past.pending}
          />
          <div style={{ marginBottom: theme.spacing[3] }}>
            <Button type="text" onClick={handleBack}>
              <LeftOutlined />
            </Button>
            {currentPage} / {Math.max(numberOfPages(numberOfPrograms, programsPerPage), 1)}
            <Button type="text" onClick={handleForward}>
              <RightOutlined />
            </Button>
          </div>
          {programs && (
            <ProgramList osid={osid} programs={programs} isLoading={status.past.pending} />
          )}
        </TabPane>
        <TabPane tab={draftTitle} key="draft">
          {!draftPrograms && <LoadingOutlined />}
          {draftPrograms && (
            <Drafts
              osid={osid}
              programs={draftPrograms}
              onDelete={id => setDraftPrograms(prev => prev.filter(p => p.id !== id))}
            />
          )}
        </TabPane>
      </Tabs>
    </div>
  )
}

export default ClassList
