import React, { useContext, useReducer, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import { Tag, Select, Radio } from 'antd'
import { InputGroup, SelectContainer, Title, Desc } from './step'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import { clubMembershipRequirements } from '../../../constants/program'
import { ClubList } from '../../../components/club'
import ClubSelect from '../../../components/ClubSelect'
import Attr from '../../../components/Attr'

const { Option } = Select

const hasRestrictions = program => {
  if (program.draft && program.info) {
    return program.info.hasClubMembershipRestrictions
  } else {
    const rcm = program.requiredClubMemberships
    if (Array.isArray(rcm) && rcm.length > 0) {
      return true
    }
    return false
  }
}

// flattens any program-required-club-membership to a club if they exist
const normalizeClubRestriction = requiredClubMemberships =>
  requiredClubMemberships.map(rcm => (rcm.club ? rcm.club : rcm))

const initState = program => {
  let clubMembershipRequirement = null
  if (clubMembershipRequirements.hasOwnProperty(program.clubMembershipRequirement)) {
    clubMembershipRequirement = program.clubMembershipRequirement
  }
  let requiredClubMemberships = []
  if (program.requiredClubMemberships) {
    requiredClubMemberships = normalizeClubRestriction(program.requiredClubMemberships)
  }

  return {
    requiredClubMemberships,
    clubMembershipRequirement,
    info: {
      hasClubMembershipRestrictions: hasRestrictions(program),
    },
  }
}

const reducer = (state, action) => ({ ...state, ...action })

const ClubMemberships = ({ onChange, clinic }) => {
  const theme = useContext(ThemeContext)
  const [state, dp] = useReducer(reducer, initState(clinic))

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({
        clubMembershipRequirement: state.clubMembershipRequirement,
        requiredClubMemberships: state.requiredClubMemberships,
        info: state.info,
      })
    }
  }, [state])

  const opts = Object.entries(clubMembershipRequirements).map(([key, value]) => (
    <Option key={key} value={key}>
      {value.label}
    </Option>
  ))

  const onSelectChange = e => {
    const update = {
      info: { hasClubMembershipRestrictions: e.target.value },
    }
    if (!e.target.value) {
      // if we selected no, reset all fields
      update.clubMembershipRequirement = null
      update.requiredClubMemberships = []
    } else {
      // if we selected yes, initialize from ALL requirement so that a null requirement does not appear
      update.clubMembershipRequirement = clubMembershipRequirements.ALL.name
      update.requiredClubMemberships = []
    }
    dp(update)
  }

  const subStep = (
    <div>
      <Attr name="Membership requirement">
        <div>
          <Select
            value={state.clubMembershipRequirement}
            onChange={cmr => dp({ clubMembershipRequirement: cmr })}
            style={{ width: theme.spacing[6] }}
          >
            {opts}
          </Select>
        </div>
      </Attr>
      <Attr name="Clubs">
        <div style={{ maxWidth: '400px' }}>
          <ClubSelect
            placeholder="Select a club"
            style={{ width: '100%' }}
            value={null}
            onChange={club =>
              dp({ requiredClubMemberships: [...state.requiredClubMemberships, club] })
            }
          />
          <div style={{ marginTop: '1em' }}>
            <ClubList
              clubs={normalizeClubRestriction(state.requiredClubMemberships)}
              onRemove={clb =>
                dp({
                  requiredClubMemberships: state.requiredClubMemberships.filter(
                    rcm => rcm.id !== clb.id,
                  ),
                })
              }
            />
          </div>
        </div>
      </Attr>
    </div>
  )

  return (
    <>
      <InputGroup>
        <Title>Club membership restrictions</Title>
        <Desc>
          Would you like to restrict bookings for this {lower(typeLabel(clinic))} to users with
          specific club memberships?
        </Desc>
        <SelectContainer>
          <Radio.Group onChange={onSelectChange} value={state.info.hasClubMembershipRestrictions}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </SelectContainer>
      </InputGroup>
      {state.info.hasClubMembershipRestrictions && subStep}
    </>
  )
}

const Viewer = ({ program }) => {
  let requirementLabel = null
  if (program.clubMembershipRequirement) {
    if (clubMembershipRequirements.hasOwnProperty(program.clubMembershipRequirement)) {
      requirementLabel = clubMembershipRequirements[program.clubMembershipRequirement].label
    }
  }
  const clubRequirements = normalizeClubRestriction(program.requiredClubMemberships)
  if (clubRequirements.length === 0) {
    return (
      <div>
        <i>None</i>
      </div>
    )
  }
  return (
    <div style={{ maxWidth: '300px' }}>
      <Attr name="Membership requirement">
        <div>
          <Tag>{requirementLabel}</Tag>
        </div>
      </Attr>
      <Attr name="Clubs">
        <ClubList clubs={clubRequirements} />
      </Attr>
    </div>
  )
}

const step = ClubMemberships

const complete = ({ draft, info, clubMembershipRequirement, requiredClubMemberships }) => {
  const requiredClubsEmpty =
    !Array.isArray(requiredClubMemberships) || requiredClubMemberships.length === 0

  const validMembershipRequirement =
    clubMembershipRequirements.hasOwnProperty(clubMembershipRequirement)

  if (draft) {
    const hasRestrictions =
      info &&
      typeof info.hasClubMembershipRestrictions === 'boolean' &&
      info.hasClubMembershipRestrictions
    const hasNoRestrictions =
      typeof info.hasClubMembershipRestrictions === 'boolean' && !info.hasClubMembershipRestrictions

    if (hasNoRestrictions) {
      return true
    }

    if (hasRestrictions && !requiredClubsEmpty && validMembershipRequirement) {
      return true
    }
  } else {
    if (
      (!requiredClubsEmpty && validMembershipRequirement) ||
      (requiredClubsEmpty && !validMembershipRequirement)
    ) {
      return true
    }
  }
  return false
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
