import React, { useEffect, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Breadcrumb, Button, Calendar, Dropdown, Modal, Tooltip } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { ThemeContext } from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { actions as acts, paths } from '../../constants'
import { PROGRAM } from '../../constants/marketingLabels'
import { OuterContent } from './Container'
import { Container, Content } from '../CreateClass/components/step'
import { DateTag } from '../../components/date'
import { duplicateProgram } from '../../actions/class'
import { useStatus, useStatusMsg } from '../../reducers'
import capitalize from 'capitalize'
import pluralize from 'pluralize'
import ProgramType from '../CreateClass/components/ProgramType'
import BasicInformation from '../CreateClass/components/BasicInformation'
import Location from '../CreateClass/components/Location'
import Timing from '../CreateClass/components/Timing'
import Players from '../CreateClass/components/Players'
import PricingType from '../CreateClass/components/PricingType'
import Inclusions from '../CreateClass/components/Inclusions'
import CancellationPolicy from '../CreateClass/components/CancellationPolicy'
import PublishDate from '../CreateClass/components/PublishDate'
import ImageUploads from '../CreateClass/components/ImageUploads'
import Hidden from '../CreateClass/components/Hidden'
import Attributes from '../CreateClass/components/Attributes'
import Host from '../CreateClass/components/Host'
import Club from '../CreateClass/components/Club'
import ClubMembership from '../CreateClass/components/ClubMembership'
import Sponsors from '../CreateClass/components/Sponsors'
import CardRestriction from '../CreateClass/components/CardRestriction'
import Venue from '../CreateClass/components/Venue'
import dayjs from 'dayjs'

const sections = [
  ProgramType,
  BasicInformation,
  Venue,
  Club,
  ClubMembership,
  CardRestriction,
  Sponsors,
  Host,
  Location,
  Hidden,
  Timing,
  Players,
  PricingType,
  Inclusions,
  CancellationPolicy,
  PublishDate,
  Attributes,
  ImageUploads,
]

const SelectedDatesDisplay = ({ selectedDates, datesStatusMap, onDateRemove }) => {
  const theme = useContext(ThemeContext)

  let elements = null
  if (Array.isArray(selectedDates)) {
    elements = selectedDates.map((date, i) => {
      const displayProps = datesStatusMap[date] ?? {}

      return (
        <Tooltip key={date} title={displayProps.message}>
          <DateTag
            date={date}
            onClose={() => onDateRemove(date)}
            showCalendarIcon
            style={{ marginBottom: theme.spacing[1] }}
            {...displayProps}
          />
        </Tooltip>
      )
    })
  }
  return elements
}

const Duplicate = () => {
  const { osid, id } = useParams()
  const theme = useContext(ThemeContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const program = useSelector(s => s.currentProgram)
  const [allComplete, setAllComplete] = useState(false)
  const [duplicate, setDuplicate] = useState({
    ...program,
    id: null,
  })
  const [multipleDuplicate, setMultipleDuplicate] = useState(false)
  const [selectedDates, setSelectedDates] = useState([])
  const [datesStatusMap, setDatesStatusMap] = useState({})
  const [duplicatingMultiple, setDuplicatingMultiple] = useState(false)

  const status = useStatus(acts.DUPLICATE_PROGRAM)

  useStatusMsg(status, {
    pending: 'Duplicating...',
    error: 'Failed to duplicate program',
    success: prog => {
      if (multipleDuplicate) {
        return 'Programs duplicated'
      }
      return (
        <>
          Program duplicated. <a href={paths.org.PROGRAM(osid, prog.id)}>View</a>
        </>
      )
    },
  })

  useEffect(() => {
    if (duplicate) {
      const complete = sections
        .map(s => s.complete(duplicate, osid))
        .reduce((acc, curr) => curr && acc, true)
      setAllComplete(complete)
    }
  }, [duplicate])

  const onCreate = () => {
    dispatch(duplicateProgram(osid, id, duplicate)).then(newProgram => {
      if (newProgram) {
        history.push(paths.org.PROGRAMS(osid))
      }
    })
  }

  const onDateRemove = date => {
    setSelectedDates(prev => prev.filter(d => d !== date))
    setDatesStatusMap(prev =>
      Object.keys(prev).reduce((acc, curr) => {
        if (curr !== date) {
          acc[curr] = prev[curr]
        }
        return acc
      }, {}),
    )
  }

  const handleMultipleCreation = async () => {
    setDuplicatingMultiple(true)
    let hasError = false
    let datesToDuplicate = selectedDates
    const datesAttemptedToDuplicate = Object.keys(datesStatusMap)

    if (datesAttemptedToDuplicate.length > 0) {
      datesToDuplicate = datesAttemptedToDuplicate
        .map(date => {
          if (datesStatusMap[date].status === 'error') {
            return date
          }
          return null
        })
        .filter(Boolean)
    }

    for (const date of datesToDuplicate) {
      setDatesStatusMap(prev => ({ ...prev, [date]: { ...prev[date], status: 'processing' } }))
      try {
        await dispatch(duplicateProgram(osid, id, { ...duplicate, date }))
        setDatesStatusMap(prev => ({
          ...prev,
          [date]: { ...prev[date], status: 'success', message: 'Program created' },
        }))
      } catch (err) {
        console.log(err)
        setDatesStatusMap(prev => ({
          ...prev,
          [date]: { ...prev[date], status: 'error', message: err.message },
        }))
        setDuplicatingMultiple(false)
        hasError = true
      }
    }
    if (!hasError) {
      history.push(paths.org.PROGRAMS(osid))
    }
  }

  return (
    <OuterContent>
      <Breadcrumb style={{ margin: `${theme.spacing[4]} 0` }}>
        <Breadcrumb.Item>
          <Link to={paths.org.PROGRAMS(osid)}>{capitalize(pluralize(PROGRAM))}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={paths.org.PROGRAM(osid, id)}>{program.name}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Duplicate</Breadcrumb.Item>
      </Breadcrumb>
      <Container>
        <Content>
          {sections.map((s, i) => {
            const Editor = s.step
            return (
              <div key={i} style={{ marginBottom: theme.spacing[5] }}>
                <Editor
                  clinic={duplicate}
                  onChange={e => setDuplicate(prev => ({ ...prev, ...e }))}
                />
              </div>
            )
          })}
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Dropdown
              menu={{
                items: [
                  { key: 'create', label: 'Single program', onClick: onCreate },
                  {
                    key: 'create multiple',
                    label: 'Multiple programs',
                    onClick: () => setMultipleDuplicate(true),
                  },
                ],
              }}
            >
              <Button>
                {status.pending ? 'Creating...' : 'Create'} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
        </Content>
      </Container>
      <Modal
        title="Create multiple programs"
        open={multipleDuplicate}
        onCancel={() => {
          setMultipleDuplicate(false)
          setSelectedDates([])
        }}
        onOk={handleMultipleCreation}
        confirmLoading={duplicatingMultiple}
        okText="Create"
      >
        <Calendar
          fullscreen={false}
          disabledDate={date => date.isBefore(dayjs().startOf('day'))}
          onSelect={date =>
            setSelectedDates(prev => Array.from(new Set([...prev, date.format('YYYY-MM-DD')])))
          }
        />
        <div style={{ marginBottom: theme.spacing[1] }}>
          <span>Selected dates:</span>
        </div>
        <SelectedDatesDisplay
          selectedDates={selectedDates}
          onDateRemove={onDateRemove}
          datesStatusMap={datesStatusMap}
        />
      </Modal>
    </OuterContent>
  )
}

export default Duplicate
