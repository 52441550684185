export class GeneralError extends Error {
  constructor(name, message, data) {
    super(name)
    this.name = name
    this.message = message
    this.data = data
  }
  toString() {
    return this.message
  }
  format() {
    return this.message
  }
  formatData() {
    return String(this.data)
  }
}

export class DbError extends GeneralError {
  constructor(message) {
    super(message)
    this.message = message
  }
}

export class NotFoundError extends GeneralError {
  constructor(message) {
    super(message)
    this.message = message
  }
}

export class UniqueError extends DbError {
  constructor(err) {
    super(err.message)
    this.name = err.name
    this.fields = err.fields
  }
  format() {
    const fields = Object.keys(this.fields)
      .reduce((acc, curr) => [...acc, `${curr} ${this.fields[curr]}`], [])
      .join(', ')
    return `A record already exists with ${fields}`
  }
}

export class StripeError extends GeneralError {
  constructor(stripeErr) {
    super(stripeErr.message)
    this.name = 'StripeError'
    this.message = stripeErr.message
    this.code = stripeErr.code
  }
}

export class BeneficiaryGroupVerificationEmailIsDuplicateError extends GeneralError {
  constructor(message, data) {
    super('BeneficiaryGroupVerificationEmailIsDuplicateError', message, data)
  }
  formatData() {
    // expects data to be an array of { value, type, count } where value is email or domain
    return this.data.map(d => d.value).join(', ')
  }
}

export const parseRemoteError = e => {
  if (e.name) {
    switch (e.name) {
      case 'UniqueConstraintError':
        return new UniqueError(e.message)
      case 'StripeError':
        return new StripeError(e)
      case 'NotFoundError':
        return new NotFoundError(e.message)
      case 'BeneficiaryGroupVerificationEmailIsDuplicateError':
        return new BeneficiaryGroupVerificationEmailIsDuplicateError(e.message, e.data)
      default:
        return new GeneralError(e.name, e.message, e.data)
    }
  }
  return new GeneralError('Error', String(e))
}

export const formatError = error => {
  if (error instanceof GeneralError) {
    return error.format()
  }
  return ''
}
