import React, { useEffect, useState } from 'react'
import { Menu, Dropdown, Breadcrumb, Modal, Button } from 'antd'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Head, Title, PaddedContainer } from '../../components/common'
import { deleteLocation, fetchLocation } from '../../actions/location'
import { clearStatus } from '../../actions/status'
import { useStatusMsg, useStatus } from '../../reducers'
import { actions as acts, paths } from '../../constants'
import Attr from '../../components/Attr'
import MapPreview from '../../components/MapPreview'
import { ProgramList } from '../../components/program'
import Address from '../../components/Address'

const LocationView = ({ location, setLocation }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [editing, setEditing] = useState(false)
  const status = {
    delete: useStatus(acts.DELETE_LOCATION),
  }

  useEffect(() => {
    return () => clearStatus(acts.DELETE_LOCATION)
  }, [])

  const onDelete = () =>
    dispatch(deleteLocation(osid, location.id)).then(() => history.push(paths.org.LOCATIONS(osid)))

  useStatusMsg(status.delete, {
    pending: 'Deleting location...',
    error: 'Failed to delete location',
    success: 'Location deleted',
  })

  if (!location) {
    return null
  }

  const handleMenuClick = e => {
    if (e.key === 'delete') {
      Modal.confirm({
        title: 'Delete location',
        content:
          'Deleting this location will also remove it from all events it is currently associated with.',
        okText: 'Delete',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: onDelete,
      })
    } else if (e.key === 'edit') {
      history.push(paths.org.EDIT_LOCATION(osid, location.id))
    }
  }

  const actionMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  )

  const programs = location.programLocations.map(loc => loc.program)

  return (
    <>
      <div>
        <Breadcrumb style={{ marginBottom: '1em' }}>
          <Breadcrumb.Item href={paths.org.LOCATIONS(osid)}>Locations</Breadcrumb.Item>
          <Breadcrumb.Item>{location.name}</Breadcrumb.Item>
        </Breadcrumb>
        <Head style={{ justifyContent: 'space-between' }}>
          <Title>{location.name}</Title>
          <Dropdown overlay={actionMenu} trigger={['click']}>
            <Button>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        </Head>
        <Attr name="Address">
          <div>
            <Address address={location} />
          </div>
        </Attr>
        {location.locationPage && (
          <Attr name="Location Page">
            <div>
              <a href={location.locationPage}>View page</a>
            </div>
          </Attr>
        )}
        <Attr name="Lat/Lon">
          <div style={{ marginBottom: '1em' }}>
            {location.lat}, {location.lon}
          </div>
          <MapPreview zoom={12} location={location} attributionControl={false} />
        </Attr>
        <Attr name="Events at this location">
          <ProgramList osid={osid} programs={programs} />
        </Attr>
      </div>
    </>
  )
}

const Location = () => {
  const { osid, id } = useParams()
  const dispatch = useDispatch()
  const [location, setLocation] = useState(null)
  const status = {
    fetch: useStatus(acts.FETCH_LOCATION),
    update: useStatus(acts.UPDATE_LOCATION),
    delete: useStatus(acts.DELETE_LOCATION),
  }

  useStatusMsg(status.fetch, { error: 'Failed to fetch location' })

  useEffect(() => {
    dispatch(fetchLocation(osid, id)).then(loc => setLocation(loc))
  }, [])

  return (
    <PaddedContainer>
      {!status.fetch.success && !status.fetch.error && <LoadingOutlined />}
      {status.fetch.success && <LocationView location={location} setLocation={setLocation} />}
    </PaddedContainer>
  )
}

export default Location
