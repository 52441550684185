import React, { useEffect, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Link, useParams } from 'react-router-dom'
import { Head, Title, PaddedContainer } from '../../components/common'
import { useDispatch } from 'react-redux'
import { actions as acts, paths, features } from '../../constants'
import { useStatus } from '../../reducers'
import { fetchRoles } from '../../actions/org'
import { useOrgNotFoundError } from '../../hooks/use-org-not-found-error'

const Roles = () => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [roles, setRoles] = useState(null)
  const status = useStatus(acts.FETCH_ROLES)

  useEffect(() => {
    if (osid) {
      dispatch(fetchRoles(osid)).then(rs => setRoles(rs))
    }
  }, [osid])

  useOrgNotFoundError(status, 'fetch rolws', osid)

  let display = <LoadingOutlined />

  if (status.success && roles) {
    display = roles.map(r => (
      <div key={r.id}>
        <Link to={paths.org.ROLE(osid, r.id)}>{r.name}</Link>
      </div>
    ))
  }

  return (
    <PaddedContainer>
      <Head>
        <Title>{features.role.name.plural}</Title>
      </Head>
      {display}
    </PaddedContainer>
  )
}

export default Roles
