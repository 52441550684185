import React, { useEffect, useState } from 'react'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import SponsorEditor from './SponsorEditor'
import { useStatus, useStatusMsg } from '../../reducers'
import { updateSponsor, fetchSponsor } from '../../actions/org'
import { clearStatus } from '../../actions/status'
import { FocusHeader } from '../../components/header'
import { Cta } from '../../components/common'
import { Focus as FocusContainer } from '../../components/containers'
import { paths, actions as acts } from '../../constants'

const EditSponsor = () => {
  const { osid, id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [sponsor, setSponsor] = useState(null)
  const status = {
    fetch: useStatus(acts.FETCH_SPONSOR),
    edit: useStatus(acts.UPDATE_SPONSOR),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.FETCH_SPONSOR))
      dispatch(clearStatus(acts.UPDATE_SPONSOR))
    }
  }, [])

  useEffect(() => {
    dispatch(fetchSponsor({ osid, id })).then(s => setSponsor(s))
  }, [osid, id])

  useStatusMsg(status.edit, {
    pending: 'Saving...',
    error: 'Failed to save sponsor',
    success: res => {
      return (
        <>
          Sponsor edited. <a href={paths.org.SPONSOR(osid, res.id)}>View</a>
        </>
      )
    },
  })

  const saveSponsor = () => {
    // check if required fields are present, if not, message
    const missing = ['name', 'logo'].filter(k => {
      if (!sponsor[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const errmsg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${errmsg.charAt(0).toUpperCase()}${errmsg.substr(1)}`)
      return
    }
    dispatch(updateSponsor({ osid, sponsorId: id, ...sponsor }))
      .then(s => history.push(paths.org.SPONSOR(osid, s.id)))
      .catch(() => {})
  }

  return (
    <>
      <FocusHeader title="Edit sponsor" actions={<Cta onClick={saveSponsor}>Save sponsor</Cta>} />
      <FocusContainer style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        {(status.fetch.pending || !sponsor) && (
          <center>
            <LoadingOutlined />
          </center>
        )}
        {sponsor && <SponsorEditor sponsor={sponsor} onChange={s => setSponsor(s)} />}
      </FocusContainer>
    </>
  )
}

export default EditSponsor
