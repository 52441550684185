import { useContext, useEffect, useState } from 'react'
import { ConstsContext } from '../contexts/consts'
import { message, Select } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const { Option } = Select

// generates a selector for consts from the backend
// name is the file name, constKey is the from the file's default export
// name of const is returned from onChange and should be used for value
export const ConstSelect = ({ name, constKey, value, onChange, ...props }) => {
  const consts = useContext(ConstsContext)
  const [val, setVal] = useState(value)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [options, setOptions] = useState(null)

  useEffect(() => setVal(value), [value])

  useEffect(() => {
    const res = consts[name]
    if (!res) {
      message(`Failed to find const: name=${name} constKey=${constKey}`)
    }
    const data = res[constKey]
    if (!data) {
      message(`Failed to find const: name=${name} constKey=${constKey}`)
    }
    // considers keyedConsts, descConsts, valuedConsts, and labelConsts
    setOptions(
      Object.keys(data).map(k => {
        const v = data[k]
        if (typeof v === 'string') {
          return {
            name: v,
            label: v,
          }
        }
        if (typeof v === 'obj') {
          const n = v.hasOwnProperty('name') ? v.name : k
          return {
            name: n,
            label: v.hasOwnProperty('label') ? v.label : n,
            description: v.hasOwnProperty('description') ? v.description : undefined,
          }
        }
        return {
          name: k,
          label: k,
        }
      }),
    )
  }, [consts, name, constKey])

  if (!options) {
    return <LoadingOutlined />
  }

  const opts = options.map(o => {
    return (
      <Option key={o.name} value={o.name} data-name={o.label}>
        <div>{o.label}</div>
        {o.description && (
          <div>
            <small>{o.description}</small>
          </div>
        )}
      </Option>
    )
  })

  const handleChange = name => {
    setVal(name)
    changer(name)
  }

  return (
    <Select value={val} onChange={handleChange} {...props}>
      {opts}
    </Select>
  )
}
