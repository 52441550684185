import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dropdown, Tabs, Menu, Modal, Breadcrumb } from 'antd'
import { Noner, Cta, Head, Title, PaddedContainer } from '../../components/common'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { actions as acts, paths, features } from '../../constants'
import { Link, useHistory, useParams } from 'react-router-dom'
import { formatDateTimeTz } from '../../util/time'
import { useStatus, useStatusMsg } from '../../reducers'
import { clearStatus } from '../../actions/status'
import { ProgramList } from '../../components/program'
import BadgeTitle from '../../components/BadgeTitle'
import Attr from '../../components/Attr'
import * as orgActs from '../../actions/org'

const { TabPane } = Tabs

const Overview = ({ tag }) => {
  return (
    <div>
      <Attr name="Name">
        <div>{tag.name}</div>
      </Attr>
      <Attr name="Label">
        <div>{tag.label}</div>
      </Attr>
      <Attr name="Color">
        <div>
          {!tag.color && <Noner none="No color">{null}</Noner>}
          {tag.color && <span>{tag.color}</span>}
        </div>
      </Attr>
      <Attr name="Created">
        <div>
          {formatDateTimeTz(tag.createdAt)} {tag.creator ? `by ${tag.creator.name}` : null}
        </div>
      </Attr>
    </div>
  )
}

const Programs = ({ tag }) => {
  if (Array.isArray(tag.programs) && tag.programs.length > 0) {
    return <ProgramList programs={tag.programs.map(p => p.program)} />
  }
  return <i>No {features.program.name.plural}</i>
}

const Tag = () => {
  const { osid, sig } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [tag, setTag] = useState(null)
  const [activeTab, setActiveTab] = useState(null)
  const status = {
    fetchTag: useStatus(acts.FETCH_ORG_TAG),
    deleteTag: useStatus(acts.DELETE_ORG_TAG),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.DELETE_ORG_TAG))
    }
  }, [])

  useEffect(() => {
    dispatch(orgActs.fetchTag({ osid, sig })).then(t => setTag(t))
    return () => {
      dispatch(clearStatus(acts.FETCH_ORG_TAG))
    }
  }, [osid, sig])

  const loading = status.fetchTag.pending || !tag

  useStatusMsg(status.fetchTag, {
    error: e => `Error fetching tag: ${e.err.message}`,
  })

  useStatusMsg(status.deleteTag, {
    pending: 'Deleting tag...',
    error: 'Failed to delete tag',
    success: 'Tag deleted',
  })

  let display = <LoadingOutlined />

  const deleteTag = () => {
    return dispatch(orgActs.deleteTag({ osid, sig })).then(() => history.push(paths.org.TAGS(osid)))
  }

  if (!loading) {
    const handleActionMenu = e => {
      if (e.key === 'edit') {
        history.push(paths.org.EDIT_ORG_TAG(osid, sig))
      } else if (e.key === 'delete-tag') {
        Modal.confirm({
          title: `Delete tag?`,
          content: `Deleting a tag will remove it from all programs, clubs, etc. to which it is attached.`,
          okText: 'Delete',
          okButtonProps: {
            type: 'primary',
            danger: true,
          },
          onOk: () => deleteTag(),
        })
      }
    }

    const actionMenu = (
      <Menu onClick={handleActionMenu}>
        <Menu.Item key="edit">Edit</Menu.Item>
        <Menu.Item key="delete-tag" danger>
          Delete
        </Menu.Item>
      </Menu>
    )
    const programsTitle = (
      <BadgeTitle
        title={`Tagged ${features.program.name.plural}`}
        count={tag.programs ? tag.programs.length : 0}
      />
    )
    display = (
      <>
        <Head>
          <Title>{tag.name}</Title>
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ display: 'flex' }}>
            <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
              <Cta>
                Actions <DownOutlined />
              </Cta>
            </Dropdown>
          </div>
        </Head>
        <Tabs defaultActiveKey="overview" onChange={k => setActiveTab(k)}>
          <TabPane tab="Overview" key="overview">
            <Overview tag={tag} />
          </TabPane>
          <TabPane key="programs" tab={programsTitle}>
            <Programs tag={tag} />
          </TabPane>
        </Tabs>
      </>
    )
  }

  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.org.ORG_TAGS(osid)}>{features.tag.name.plural}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{loading ? sig : tag.name}</Breadcrumb.Item>
      </Breadcrumb>
      {display}
    </PaddedContainer>
  )
}

export default Tag
