import store from 'store'
import qs from 'query-string'
import { push } from 'connected-react-router'
import { message } from 'antd'
import { paths, urls, actions as acts } from '../constants'
import fetchData from '../util/fetch'

export const self = () => async dispatch => {
  dispatch({ type: acts.FETCH_SELF_PENDING, payload: {} })
  try {
    const self = await fetchData({ url: urls.auth.SELF() })
    dispatch({ type: acts.FETCH_SELF_SUCCESS, payload: self })
    return self
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_SELF_FAILURE, payload: { err } })
  }
}

export const login = (email, password) => async dispatch => {
  dispatch({ type: acts.LOGIN_PENDING, payload: { email } })
  try {
    const res = await fetchData({
      type: 'POST',
      url: urls.auth.LOGIN(),
      body: { email, password },
    })
    const { token } = res
    store.set('user', { token })
    dispatch({ type: acts.LOGIN_SUCCESS, payload: { token } })
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.LOGIN_FAILURE, payload: { err } })
    message.error('Error logging in')
  }
}

export const logout = () => async dispatch => {
  dispatch({ type: acts.LOGOUT_PENDING, payload: {} })
  try {
    await fetchData({ type: 'POST', url: urls.auth.LOGOUT() })
    dispatch({ type: acts.LOGOUT_SUCCESS, payload: {} })
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.LOGOUT_FAILURE, payload: err })
  }
  store.clearAll()
}

export const fetchUsersSearch = type => async dispatch => {
  dispatch({ type: acts.FETCH_USERS_SEARCH_PENDING, payload: { type } })
  let url = urls.partner.USERS_SEARCH()
  if (type) {
    url = qs.stringifyUrl({ url, query: { type } })
  }
  try {
    const users = await fetchData({ url })
    dispatch({ type: acts.FETCH_USERS_SEARCH_SUCCESS, payload: users })
    return users
  } catch (err) {
    dispatch({ type: acts.FETCH_USERS_SEARCH_FAILURE, payload: err })
    console.log(err)
  }
}
