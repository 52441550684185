import React from 'react'
import config from '../config'
import ReactMapboxGl, { Marker, ZoomControl } from 'react-mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { EnvironmentFilled } from '@ant-design/icons'

// accepts a location found in the db
const MapPreview = ({
  location,
  width = '50%',
  height = '250px',
  zoomControl = true,
  zoom = 8,
  ...props
}) => {
  const Map = ReactMapboxGl({
    accessToken: config.mapboxAccessToken,
    ...props,
  })
  return (
    <Map
      style={config.mapboxDefaultStyle}
      containerStyle={{ width, height }}
      center={[location.lon, location.lat]}
      zoom={[zoom]}
    >
      {zoomControl && <ZoomControl />}
      <Marker coordinates={[location.lon, location.lat]} anchor="bottom">
        <EnvironmentFilled
          style={{
            fontSize: 32,
            color: '#ff0045',
          }}
        />
      </Marker>
    </Map>
  )
}

// accepts a programLocation found on a program
const ProgramMapPreview = ({ location, ...props }) => (
  <MapPreview location={location.address} {...props} />
)

MapPreview.Program = ProgramMapPreview
export default MapPreview
