import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Dropdown, Modal, Menu } from 'antd'
import { EllipsisOutlined, LoadingOutlined, DownOutlined } from '@ant-design/icons'
import { actions as acts } from '../../../constants'
import { useStatus, useStatusMsg } from '../../../reducers'
import { fetchAllExploreClubsPositions, deleteExploreClubsPosition } from '../../../actions/admin'
import { Head, Title, Button } from '../../../components/common'
import ExploreClubsPositionEditor from './ExploreClubsPositionEditor'
import { clearStatus } from '../../../actions/status'
import { paths } from '../../../constants'

const columns = ({ editPosition, deletePosition }) => [
  {
    title: 'Club',
    dataIndex: 'club',
    key: 'club',
    width: 275,
    render: (val, record) => (
      <Link to={paths.org.CLUB(record.club.org, record.club.sid)}>{record.club.name}</Link>
    ),
  },
  {
    title: 'Position',
    dataIndex: 'position',
    key: 'position',
    width: 275,
    render: (val, record) => <span>{record.position}</span>,
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: '10',
    render: (val, record) => (
      <Dropdown
        overlay={<ActionsMenu position={record} onDelete={deletePosition} onEdit={editPosition} />}
        trigger={['click']}
      >
        <EllipsisOutlined
          className="ant-dropdown-link"
          style={{ fontSize: '1.75em', fontWeight: 'bold' }}
        />
      </Dropdown>
    ),
  },
]

const ActionsMenu = ({ position, onDelete, onEdit }) => {
  const dispatch = useDispatch()
  const deleteStatus = useStatus(acts.DELETE_CLUB_POSITION)

  useStatusMsg(deleteStatus, {
    pending: 'Deleting club position...',
    error: 'Failed to delete club position',
    success: 'Club position deleted',
  })

  useEffect(() => () => dispatch(clearStatus(acts.DELETE_CLUB_POSITION)), [])

  const handleActionMenu = e => {
    if (e.key === 'edit') {
      onEdit(position)
    } else if (e.key === 'delete') {
      Modal.confirm({
        title: 'Confirm',
        okText: 'Delete club position',
        okButtonProps: {
          danger: true,
          loading: deleteStatus.pending || false, // cannot be null
          disabled: deleteStatus.pending || false, // cannot be null
        },
        content: (
          <div>
            <p>Are you sure you want to delete the club position?</p>
          </div>
        ),
        onOk: () => {
          return dispatch(deleteExploreClubsPosition({ clubId: position.club.id })).then(() => {
            if (typeof onDelete === 'function') {
              onDelete(position)
            }
          })
        },
      })
    }
  }

  return (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete" danger>
        Delete
      </Menu.Item>
    </Menu>
  )
}

const ExploreClubs = () => {
  const dispatch = useDispatch()
  const [creating, setCreating] = useState(false)
  const [editing, setEditing] = useState(false)
  const [existingPosition, setExistingPosition] = useState()
  const [clubsPositions, setClubsPositions] = useState([])
  const status = {
    fetch: useStatus(acts.FETCH_ALL_EXPLORE_CLUBS_POSITIONS),
  }

  useStatusMsg(status.fetch, { error: 'Failed to fetch clubs positions' })

  useEffect(() => {
    dispatch(fetchAllExploreClubsPositions()).then(positions => setClubsPositions(positions))
  }, [creating, columns, editing])

  const cols = columns({
    editPosition: position => {
      setCreating(false)
      setExistingPosition(position)
      setEditing(true)
    },
    deletePosition: position => {
      setClubsPositions(prev => prev.filter(p => p.id !== position.id))
    },
  })

  const handleActionMenu = e => {
    if (e.key === 'create') {
      setCreating(true)
    }
  }

  const actionMenu = (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="create">New</Menu.Item>
    </Menu>
  )

  return (
    <>
      <Head>
        <Title>Explore clubs</Title>
        <div style={{ flexGrow: 1 }}></div>
        <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
          <Button>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
        {!status.fetch.success && <LoadingOutlined />}
      </Head>
      {clubsPositions && <Table columns={cols} dataSource={clubsPositions} pagination={false} />}
      {creating && <ExploreClubsPositionEditor complete={() => setCreating(false)} />}
      {editing && (
        <ExploreClubsPositionEditor
          complete={() => setEditing(false)}
          isEdit={true}
          existingPosition={existingPosition}
        />
      )}
    </>
  )
}

export default ExploreClubs
