import React, { useEffect, useState, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'
import { Radio, Select, Checkbox, Form, Input, InputNumber } from 'antd'
import * as productConsts from '../../constants/product'
import { urls } from '../../constants'
import { Label } from '../../components/common'
import Attr from '../../components/Attr'
import formatUtils from '../../util/format'
import numberUtils from '../../util/number'
import { walmartProductLookup } from '../../actions/api'
import { clearStatus } from '../../actions/status'
import { useStatus, useStatusMsg } from '../../reducers'
import { actions as acts } from '../../constants'
import { MultiImageUpload } from '../../components/image-upload'
import { WalmartProductPreview } from './walmart'

const { Option } = Select

const initState = {
  name: null,
  priceCents: null,
  stockType: null,
  shippingRequired: null,
  images: [],
}

const stockTypeOpts = Object.keys(productConsts.stockTypes).map(k => (
  <Option key={k}>{productConsts.stockTypes[k].label}</Option>
))

export const ProductVariantEditor = ({ onChange, variant }) => {
  const theme = useContext(ThemeContext)

  if (!variant) {
    if (typeof onChange === 'function') {
      onChange(initState)
    }
    return null
  }

  const { name, description, priceCents, stockType, shippingRequired } = variant

  const change = update => {
    if (typeof onChange === 'function') {
      onChange({ ...variant, ...update })
    }
  }

  return (
    <Form layout="vertical">
      <Form.Item name="name" label={<Label>Name</Label>} initialValue={name}>
        <Input
          placeholder="Hat, racquet rental, etc."
          onChange={e => change({ name: e.target.value })}
          value={name}
        />
      </Form.Item>
      <Form.Item name="description" label={<Label>Description</Label>} initialValue={description}>
        <Input.TextArea
          onChange={e => change({ description: e.target.value })}
          value={description}
        />
      </Form.Item>
      <Attr name="Price">
        <div>
          <InputNumber
            prefix="$"
            type="number"
            placeholder="0"
            defaultValue={formatUtils.centsToDollarAmount(priceCents)}
            onChange={val => change({ priceCents: numberUtils.dollarsToCents(parseFloat(val)) })}
            style={{ width: theme.width[4] }}
          />
        </div>
      </Attr>
      <Form.Item name="stock-type" label={<Label>Stock type</Label>} initialValue={stockType}>
        <Select
          value={stockType}
          onChange={val => change({ stockType: val })}
          style={{ maxWidth: theme.width[4] }}
        >
          {stockTypeOpts}
        </Select>
      </Form.Item>
      <Form.Item name="shipping-required" initialValue={shippingRequired}>
        <Checkbox
          checked={shippingRequired}
          onChange={e => change({ shippingRequired: e.target.checked })}
        >
          <div style={{ display: 'grid' }}>
            <Label>Shipping required</Label>
            <span>
              The user will be prompted for a shipping address during checkout if shipping is
              required
            </span>
          </div>
        </Checkbox>
      </Form.Item>
    </Form>
  )
}

const OnsiteEditor = ({
  name,
  description,
  priceCents,
  images,
  stockType,
  shippingRequired,
  onChange,
}) => {
  const theme = useContext(ThemeContext)
  const change = typeof onChange === 'function' ? onChange : () => {}

  return (
    <div>
      <div style={{ marginBottom: '1em', color: theme.font.color.secondary }}>
        Onsite products are transacted on <a href={urls.MAIN_APP_URL()}>{urls.MAIN_APP_URL()}</a>
      </div>
      <CommonFieldsEditor
        name={name}
        description={description}
        priceCents={priceCents}
        images={images}
        onChange={onChange}
      />
      <Form.Item name="stock-type" label={<Label>Stock type</Label>} initialValue={stockType}>
        <Select
          value={stockType}
          onChange={val => change({ stockType: val })}
          style={{ maxWidth: theme.width[4] }}
        >
          {stockTypeOpts}
        </Select>
        {stockType === productConsts.stockTypes.LIMITED.name && (
          <div style={{ marginTop: theme.spacing[1] }}>
            Stock is editable on each product variant once the product is created
          </div>
        )}
      </Form.Item>
      <Form.Item name="shipping-required" initialValue={shippingRequired}>
        <Checkbox
          checked={shippingRequired}
          onChange={e => change({ shippingRequired: e.target.checked })}
        >
          <div style={{ display: 'grid' }}>
            <Label>Shipping required</Label>
            <span>
              The user will be prompted for a shipping address during checkout if shipping is
              required
            </span>
          </div>
        </Checkbox>
      </Form.Item>
    </div>
  )
}

const OffsiteEditor = ({
  name,
  description,
  priceCents,
  images,
  offsiteUrl,
  offsiteCta,
  onChange,
}) => {
  const theme = useContext(ThemeContext)
  const change = typeof onChange === 'function' ? onChange : () => {}

  return (
    <div>
      <div style={{ marginBottom: '1em', color: theme.font.color.secondary }}>
        Offsite products are linked and transacted on websites external to{' '}
        <a href={urls.MAIN_APP_URL()}>{urls.MAIN_APP_URL()}</a>
      </div>
      <CommonFieldsEditor
        name={name}
        description={description}
        priceCents={priceCents}
        images={images}
        onChange={onChange}
      />
      <Form.Item name="offsite-url" label={<Label>Offsite URL</Label>} initialValue={offsiteUrl}>
        <Input
          placeholder="https://example.com"
          onChange={e => change({ offsiteUrl: e.target.value })}
          value={offsiteUrl}
        />
      </Form.Item>
      <Form.Item name="offsite-cta" label={<Label>Offsite CTA</Label>} initialValue={offsiteCta}>
        <Input
          placeholder="Buy now"
          onChange={e => change({ offsiteCta: e.target.value })}
          value={offsiteCta}
        />
      </Form.Item>
    </div>
  )
}

const WalmartEditor = ({ name, description, priceCents, images, walmartItemId, onChange }) => {
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const change = typeof onChange === 'function' ? onChange : () => {}
  const [walmartProduct, setWalmartProduct] = useState(null)
  const [itemId, setItemId] = useState(walmartItemId)
  const status = useStatus(acts.WALMART_PRODUCT_LOOKUP)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.WALMART_PRODUCT_LOOKUP))
    }
  }, [dispatch])

  useEffect(() => {
    change({ walmartItemId: itemId })
  }, [itemId])

  useStatusMsg(status, {
    error: 'Failed to fetch Walmart product preview',
  })

  const helper = e => {
    const newItemId = e.target.value
    setItemId(newItemId)
    dispatch(walmartProductLookup({ itemId: newItemId })).then(product => {
      setWalmartProduct(product)
      change({
        name: product.name,
        description: product.shortDescription,
        walmartItemId: newItemId,
      })
    })
  }

  return (
    <div>
      <div style={{ marginBottom: '1em', color: theme.font.color.secondary }}>
        Walmart products have their information pulled from Walmart and are redirected for purchase
        on <a href="https://www.walmart.com/">walmart.com</a>
      </div>
      <Attr name="Walmart Item ID">
        <div>
          <Input placeholder="85437" onChange={helper} value={itemId} />
        </div>
      </Attr>
      <div>
        <span style={{ visibility: status.pending ? 'visible' : 'hidden' }}>
          Loading preview...
        </span>
        <WalmartProductPreview name={name} description={description} product={walmartProduct} />
      </div>
    </div>
  )
}

const CommonFieldsEditor = ({ name, description, priceCents, images, onChange }) => {
  const theme = useContext(ThemeContext)
  const change = typeof onChange === 'function' ? onChange : () => {}

  const hasImages = Array.isArray(images) && images.filter(Boolean).length > 0

  return (
    <div>
      <Form.Item name="name" label={<Label>Name</Label>} initialValue={name}>
        <Input
          placeholder="Hat, racquet rental, etc."
          onChange={e => change({ name: e.target.value })}
          value={name}
        />
      </Form.Item>
      <Form.Item name="description" label={<Label>Description</Label>} initialValue={description}>
        <Input.TextArea
          onChange={e => change({ description: e.target.value })}
          value={description}
        />
      </Form.Item>
      <Attr name="Price">
        <div>
          <InputNumber
            prefix="$"
            type="number"
            placeholder="0"
            defaultValue={formatUtils.centsToDollarAmount(priceCents)}
            onChange={val => change({ priceCents: numberUtils.dollarsToCents(parseFloat(val)) })}
            style={{ width: theme.width[4] }}
          />
        </div>
      </Attr>
      <Form.Item name="images" label={<Label>Product images</Label>}>
        <MultiImageUpload
          images={images
            .filter(Boolean)
            .sort((a, b) => b.order - a.order)
            .map(img => img.image)}
          onChange={newImages => {
            change({ images: Array.isArray(newImages) ? newImages.filter(Boolean) : newImages })
          }}
        />
      </Form.Item>
    </div>
  )
}

// disableType disables type editing. instead, the current type is displayed
export const ProductEditor = ({ onChange, product, disableType = false }) => {
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const change = update => changer({ ...product, ...update })

  if (!product) {
    changer({ ...initState, type: productConsts.types.ONSITE.name })
    return null
  }

  const {
    type,
    name,
    description,
    priceCents,
    stockType,
    shippingRequired,
    images,
    offsiteUrl,
    offsiteCta,
    walmartItemId,
  } = product

  const typeOptions = [
    { label: productConsts.types.ONSITE.label, value: productConsts.types.ONSITE.name },
    { label: productConsts.types.OFFSITE.label, value: productConsts.types.OFFSITE.name },
    { label: productConsts.types.WALMART.label, value: productConsts.types.WALMART.name },
  ]

  const isOnsite = type === productConsts.types.ONSITE.name
  const isOffsite = type === productConsts.types.OFFSITE.name
  const isWalmart = type === productConsts.types.WALMART.name

  const commonProps = { name, description, priceCents, images }

  const productTypeLabel = type ? productConsts.types[type].label : ''
  return (
    <Form layout="vertical">
      {!disableType && (
        <Form.Item name="type" label={<Label>Type</Label>} initialValue={type}>
          <Radio.Group
            options={typeOptions}
            /* TODO Need to clear out onsite/offsite properties that no longer apply when switching type */
            onChange={e => change({ type: e.target.value })}
            value={type}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
      )}
      {disableType && (
        <Attr name="Type">
          <div>{productTypeLabel}</div>
        </Attr>
      )}

      {isOnsite && (
        <OnsiteEditor
          {...commonProps}
          stockType={stockType}
          shippingRequired={shippingRequired}
          onChange={change}
        />
      )}
      {isOffsite && (
        <OffsiteEditor
          {...commonProps}
          offsiteUrl={offsiteUrl}
          offsiteCta={offsiteCta}
          onChange={change}
        />
      )}
      {isWalmart && (
        <WalmartEditor {...commonProps} walmartItemId={walmartItemId} onChange={change} />
      )}
    </Form>
  )
}
