import { ThemeContext } from 'styled-components'
import { useEffect, useReducer, useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import {
  fetchNetworkVenueAvailableResources,
  createProgramResReservations,
  deleteProgramResReservation,
} from '../../actions/org'
import { clearStatus } from '../../actions/status'
import {
  Menu,
  Dropdown,
  Typography,
  Select,
  Button as AntButton,
  Modal,
  DatePicker,
  TimePicker,
} from 'antd'
import { EllipsisOutlined, PlusOutlined } from '@ant-design/icons'
import { Cta as CtaButton, Button } from '../../components/common'
import { actions as acts } from '../../constants'
import resConsts from '../../constants/resource'
import { useStatus, useStatusMsg } from '../../reducers'
import Attr from '../../components/Attr'
import Container from './Container'
import { formatDate, formatTime, parseTime } from '../../util/time'
import { Table } from 'antd'
import { VenueSelect } from '../../components/venue'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import weekday from 'dayjs/plugin/weekday'
import localeData from 'dayjs/plugin/localeData'

dayjs.extend(customParseFormat)

// https://github.com/ant-design/ant-design/issues/26190#issuecomment-703673400
dayjs.extend(weekday)
dayjs.extend(localeData)

const { Option } = Select
const cols = [
  {
    title: 'Venue',
    dataIndex: 'venue',
    key: 'venue',
    render: (val, record) => <div>{val}</div>,
  },
  {
    title: 'Resource',
    dataIndex: 'resource',
    key: 'resource',
    render: (val, record) => <div>{val}</div>,
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    render: (val, record) => <ReservationDateTime reservation={record} />,
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: 5,
    render: (val, record) => (
      <Dropdown
        overlay={<ReservationActionsMenu program={record.program} reservation={record} />}
        trigger={['click']}
      >
        <AntButton type="text" style={{ display: 'flex', alignItems: 'center' }}>
          <EllipsisOutlined style={{ fontSize: '1.75em', fontWeight: 'bold' }} />
        </AntButton>
      </Dropdown>
    ),
  },
]

const ReservationDateTime = ({ reservation }) => {
  const { date, time } = reservation.date
  return (
    <div>
      <div>
        {formatDate(date[0]) === formatDate(date[1])
          ? formatDate(date[0])
          : formatDate(date[0]) - formatDate(date[1])}
      </div>
      <div>
        {formatTime(parseTime(time[0]))} - {formatTime(parseTime(time[1]))}
      </div>
    </div>
  )
}

const ReservationActionsMenu = ({ program, reservation }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()

  const confirm = () => {
    Modal.confirm({
      title: 'Delete reservation?',
      content: (
        <div>
          <div>{reservation.venue}</div>
          <div>{reservation.resource}</div>
          <ReservationDateTime reservation={reservation} />
        </div>
      ),
      okText: 'Delete reservation',
      okButtonProps: { danger: true },
      onOk: () =>
        dispatch(
          deleteProgramResReservation({
            osid,
            programId: program.id,
            resReservationId: reservation.id,
          }),
        ),
    })
  }

  return (
    <Menu>
      <Menu.Item onClick={confirm}>
        <Typography.Text type="danger">Delete reservation</Typography.Text>
      </Menu.Item>
    </Menu>
  )
}

const initReservationState = ({ venue, resource, dateStart, dateEnd, wallStart, wallEnd }) => ({
  venue,
  resource,
  dateStart,
  dateEnd,
  wallStart,
  wallEnd,
})

const initReservationDateTime = ({ dateStart, dateEnd, wallStart, wallEnd }) => ({
  dateStart,
  dateEnd,
  wallStart,
  wallEnd,
})

const reducer = (state, action) => ({ ...state, ...action })

const ReservationDateTimeEditor = ({ dateStart, dateEnd, wallStart, wallEnd, onChange }) => {
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [state, dp] = useReducer(
    reducer,
    initReservationDateTime({ dateStart, dateEnd, wallStart, wallEnd }),
  )

  useEffect(() => {
    changer(state)
  }, [state])

  return (
    <div>
      <DatePicker
        value={state.dateStart ? dayjs(state.dateStart, 'YYYY-MM-DD') : null}
        onChange={d => {
          const date = d ? d.format('YYYY-MM-DD') : d
          dp({ dateStart: date, dateEnd: date })
        }}
        style={{ marginRight: theme.spacing[2] }}
      />
      <TimePicker
        value={wallStart ? dayjs(wallStart, 'HH:mm:ss') : null}
        use12Hours
        format="h:mma"
        placeholder="Start time"
        onChange={d => dp({ wallStart: d ? d.format('HH:mm:ss') : d })}
        style={{ marginRight: theme.spacing[2] }}
      />
      <TimePicker
        value={wallEnd ? dayjs(wallEnd, 'HH:mm:ss') : null}
        use12Hours
        format="h:mma"
        placeholder="Start end"
        onChange={d => dp({ wallEnd: d ? d.format('HH:mm:ss') : d })}
      />
    </div>
  )
}

// Allows selecting a resource for reservation at a specified date/time
const ReservationResourceEditor = ({
  venue,
  resource,
  dateStart,
  dateEnd,
  wallStart,
  wallEnd,
  onChange,
  ...props
}) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [availableResources, setAvailableResources] = useState(null)
  const status = useStatus(acts.FETCH_NETWORK_VENUE_AVAILABLE_RESOURCES)

  // get resources available a this time (availability is only determined by reservations, not by schedule or special hours)
  // display with resource type
  // if no resources, say so at this time
  useEffect(() => {
    if (venue && dateStart && dateEnd && wallStart && wallEnd) {
      dispatch(
        fetchNetworkVenueAvailableResources({
          osid,
          venueId: typeof venue === 'string' ? venue : venue.id,
          dateStart,
          dateEnd,
          wallStart,
          wallEnd,
        }),
      ).then(available => {
        setAvailableResources(available)
      })
    } else {
      setAvailableResources(null)
    }
  }, [venue, dateStart, dateEnd, wallStart, wallEnd, dispatch])

  const onSelectResource = rid => {
    changer(availableResources.find(ar => ar.id === rid))
  }

  if (status.pending || !availableResources) {
    return 'Loading...'
  }

  const opts = availableResources.map(ar => (
    <Option key={ar.id} value={ar.id} data-name={ar.name}>
      <div>{ar.name}</div>
      <div style={{ color: theme.font.color.secondary }}>
        <small>
          {ar.type.label} | Pricing {resConsts.pricingTypes[ar.pricingType].label.toLowerCase()}
        </small>
      </div>
    </Option>
  ))
  return (
    <Select
      value={resource ? resource.id : resource}
      showSearch
      filterOption={(input, option) => {
        const inp = input ? input.toLowerCase() : ''
        const matchesOption = option['data-name'].trim().toLowerCase().indexOf(inp) >= 0
        return matchesOption || option.value.toLowerCase().indexOf(inp) >= 0
      }}
      onChange={onSelectResource}
      placeholder="Select a resource"
      {...props}
    >
      {opts}
    </Select>
  )
}

const ReservationEditor = ({ reservation, onChange }) => {
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [state, dp] = useReducer(reducer, initReservationState(reservation ? reservation : {}))
  let dateError = null

  useEffect(() => {
    changer(state)
  }, [state])

  const dateComplete = state.dateStart && state.dateEnd && state.wallStart && state.wallEnd
  let dateValid = false
  if (dateComplete) {
    if (state.wallStart.localeCompare(state.wallEnd) >= 0) {
      dateError = 'Start time must be after end time'
      dateValid = false
    } else {
      dateError = null
      dateValid = true
    }
  }

  const onRemove = () => changer(null)

  return (
    <>
      <div style={{ textAlign: 'right', marginTop: '.5em' }}>
        <AntButton size="small" type="link" danger style={{ paddingRight: 0 }} onClick={onRemove}>
          Remove
        </AntButton>
      </div>
      <Attr name="Venue">
        <VenueSelect
          value={state.venue}
          onChange={venue => dp({ venue })}
          style={{ width: '100%' }}
          placeholder="Select a venue"
          autoFocus
        />
      </Attr>
      {state.venue && (
        <Attr name="Reservation date and time">
          <ReservationDateTimeEditor
            dateStart={state.dateStart}
            dateEnd={state.dateEnd}
            wallStart={state.wallStart}
            wallEnd={state.wallEnd}
            onChange={update => dp(update)}
          />
          {dateError ? (
            <div style={{ marginTop: theme.spacing[1] }}>
              <Typography.Text type="danger">{dateError}</Typography.Text>
            </div>
          ) : null}
        </Attr>
      )}
      {state.venue && dateComplete && dateValid && (
        <Attr name="Resource">
          <div>
            <ReservationResourceEditor
              venue={state.venue}
              resource={state.resource}
              dateStart={state.dateStart}
              dateEnd={state.dateEnd}
              wallStart={state.wallStart}
              wallEnd={state.wallEnd}
              onChange={res => dp({ resource: res })}
              style={{ width: '100%' }}
            />
          </div>
        </Attr>
      )}
    </>
  )
}

const isReservationComplete = ({ resource, dateStart, dateEnd, wallStart, wallEnd }) =>
  [resource, dateStart, dateEnd, wallStart, wallEnd].reduce(
    (acc, curr) => Boolean(curr) && acc,
    true,
  )

const serializeReservation = ({ resource, dateStart, dateEnd, wallStart, wallEnd }) => ({
  resource: typeof resource === 'string' ? resource : resource.id,
  dateStart,
  dateEnd,
  wallStart,
  wallEnd,
})

const AddReservationModal = ({ complete }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const program = useSelector(s => s.currentProgram)
  const [newReservations, setNewReservations] = useState([null])
  const status = useStatus(acts.CREATE_PROGRAM_RES_RESERVATIONS)
  const completer = typeof complete === 'function' ? complete : () => {}

  useEffect(() => {
    return () => dispatch(clearStatus(acts.CREATE_PROGRAM_RES_RESERVATIONS))
  }, [])

  useStatusMsg(status, {
    pending: 'Creating reservations...',
    error: 'Failed to create reservations',
    success: 'Reservations created',
  })

  const onCreate = () => {
    const toCreate = newReservations.filter(isReservationComplete).map(serializeReservation)
    dispatch(
      createProgramResReservations({ osid, programId: program.id, reservations: toCreate }),
    ).then(completer)
  }

  const editors = newReservations.map((nr, i) => {
    return (
      <div
        key={i}
        style={{
          borderStyle: 'solid',
          borderWidth: '1px',
          borderColor: theme.color.gray[1],
          borderRadius: theme.br[2],
          padding: '0 1em 1em 1em',
          marginBottom: theme.spacing[2],
        }}
      >
        <ReservationEditor
          reservation={nr}
          onChange={updatedRes => {
            setNewReservations(prev => {
              return prev
                .map((res, j) => {
                  return j === i ? updatedRes : res
                })
                .filter(Boolean)
            })
          }}
        />
      </div>
    )
  })

  return (
    <Modal
      title="Add venue reservations"
      visible={true}
      onOk={onCreate}
      okText="Create reservations"
      onCancel={() => completer()}
    >
      <div style={{ marginBottom: theme.spacing[2] }}>{editors}</div>
      <Button onClick={() => setNewReservations(prev => [...prev, null])}>
        <PlusOutlined /> Add reservation
      </Button>
    </Modal>
  )
}

const Reservations = () => {
  const program = useSelector(s => s.currentProgram)
  const [addingReservation, setAddingReservation] = useState(false)
  const tableData = program.resourceReservations.map(res => ({
    id: res.id,
    venue: res.resource.venue.name,
    resource: res.resource.name,
    date: { time: [res.wallStart, res.wallEnd], date: [res.dateStart, res.dateEnd] },
    program,
  }))

  return (
    <Container>
      <h1>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>Reservations</span>
          <CtaButton onClick={() => setAddingReservation(true)}>
            <PlusOutlined /> New reservation
          </CtaButton>
        </div>
      </h1>
      <Table rowKey="id" columns={cols} dataSource={tableData} pagination={false} />
      {addingReservation && <AddReservationModal complete={() => setAddingReservation(false)} />}
    </Container>
  )
}

export default Reservations
