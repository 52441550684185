import config from '../config'
import { urls, S3_ASSET_URL_BASE } from '../constants'

// fully qualified api url
export const fqau = path => `${urls.API_BASE_URL()}${path}`

// fully qualified site url
export const fqsu = path => `${urls.SITE_URL()}${path}`

export const mainSite = path => {
  if (path) {
    return `${urls.MAIN_APP_URL()}/${path}`
  }
  return `${urls.MAIN_APP_URL()}`
}

export const bos = path => {
  if (path) {
    return `${urls.BOS_URL()}/${path}`
  }
  return `${urls.BOS_URL()}`
}
export const orgBenefits = orgSig => `${bosOrgHomepage(orgSig)}/benefits`
export const orgBenefitHomepage = (orgSig, benefitSig) =>
  `${bosOrgHomepage(orgSig)}/benefits/${benefitSig}`
export const bosOrgHomepage = orgId => bos(`orgs/${orgId}`)
export const bosOrgVenueHomepage = venueSig => bos(`venues/${venueSig}`)
export const bosOrgCheckinDate = (orgSig, date) => bos(`checkin/orgs/${orgSig}?date=${date}`)

export const s3Asset = asset => `${S3_ASSET_URL_BASE}/${asset.key}/${asset.filename}`

export const mainSiteProgram = programId => `${mainSite()}/events/${programId}`
export const mainSiteClub = clubSig => `${mainSite()}/clubs/${clubSig}`
export const mainSiteProPage = proPageUrl => `${mainSite()}/pros/${proPageUrl}`
export const mainSiteProShop = proPageUrl => `${mainSite()}/pros/${proPageUrl}/pro-shop`
export const mainSiteCec = cecSlug => `${mainSite()}/cec/${cecSlug}`
export const mainSiteVenueHomepage = venueSig => `${mainSite()}/play/courts/${venueSig}`

export const stripeDashboard = path => {
  if (path) {
    return `${config.stripeDashboardUrl}${path}`
  }
  return config.stripeDashboardUrl
}
export const stripePayment = id => stripeDashboard(`/payments/${id}`)
export const stripeCustomer = id => stripeDashboard(`/customers/${id}`)

export const sendbirdDashboard = path => {
  const url = `https://dashboard.sendbird.com/${config.sendbirdAppId}`
  if (path) {
    return `${url}${path}`
  }
  return url
}
export const sendbirdUser = userId => sendbirdDashboard(`/users/${userId}`)

export const extractYouTubeVideoId = url => {
  const pattern =
    /(?:\/embed\/|v=|\.be\/|\/\d{1,2}\/|\/embed\/|\/v\/|watch\?v=|ytscreeningroom\?v=|embed\/|user\/\S+\?v=|v\/|\/v\/|e\/|watch\?v=|&v=|\/v=|v=)([a-zA-Z0-9_-]{11})/
  const match = url.match(pattern)

  if (match && match.length === 2) {
    return match[1]
  }

  return null
}

export default {
  fqau,
  fqsu,
  mainSite,
  s3Asset,
  mainSiteClub,
  mainSiteProgram,
  mainSiteProPage,
  mainSiteProShop,
  mainSiteVenueHomepage,
  stripeDashboard,
  stripePayment,
  stripeCustomer,
  sendbirdUser,
  orgBenefits,
  orgBenefitHomepage,
  bosOrgVenueHomepage,
  bosOrgHomepage,
  bosOrgCheckinDate,
}
