import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dropdown, Tabs, Menu, Modal, Breadcrumb } from 'antd'
import { Cta, Head, Title, PaddedContainer } from '../../components/common'
import { DownOutlined, LoadingOutlined } from '@ant-design/icons'
import { actions as acts, paths, features } from '../../constants'
import { Link, useHistory, useParams } from 'react-router-dom'
import { formatDateTimeTz } from '../../util/time'
import { useStatus, useStatusMsg } from '../../reducers'
import { clearStatus } from '../../actions/status'
import ImagePreview from '../../components/ImagePreview'
import BadgeTitle from '../../components/BadgeTitle'
import Attr from '../../components/Attr'
import * as orgActs from '../../actions/org'

const { TabPane } = Tabs

const Overview = ({ sponsor }) => {
  return (
    <div>
      <Attr name="Notes">
        <div>{sponsor.notes}</div>
      </Attr>
      <Attr name="Logo image">
        <div>
          {sponsor.logo && <ImagePreview asset={sponsor.logo} width="auto" height="150px" />}
          {!sponsor.logo && <i>No logo</i>}
        </div>
      </Attr>
      <Attr name="Link">
        <div>
          <a href={sponsor.link}>{sponsor.link}</a>
        </div>
      </Attr>
      <Attr name="Icon image">
        <div>
          {sponsor.icon && <ImagePreview asset={sponsor.icon} width="auto" height="150px" />}
          {!sponsor.icon && <i>No icon</i>}
        </div>
      </Attr>
      <Attr name="Created">
        <div>
          {formatDateTimeTz(sponsor.createdAt)}{' '}
          {sponsor.creator ? `by ${sponsor.creator.name}` : null}
        </div>
      </Attr>
    </div>
  )
}

const Programs = ({ sponsor }) => {
  const { osid } = useParams()
  if (Array.isArray(sponsor.programs) && sponsor.programs.length > 0) {
    const programs = sponsor.programs.map(p => (
      <div key={p.program.id}>
        <Link to={paths.org.PROGRAM(osid, p.program.id)}>{p.program.name}</Link>
      </div>
    ))
    return <div>{programs}</div>
  }
  return <i>No programs</i>
}

const Clubs = ({ sponsor }) => {
  const { osid } = useParams()
  if (Array.isArray(sponsor.clubs) && sponsor.clubs.length > 0) {
    const clubs = sponsor.clubs.map(c => (
      <div key={c.club.id}>
        <Link to={paths.org.CLUB(osid, c.club.id)}>{c.club.name}</Link>
      </div>
    ))
    return <div>{clubs}</div>
  }
  return <i>No clubs</i>
}

const Sponsor = () => {
  const { osid, id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [sponsor, setSponsor] = useState(null)
  const [postCursor, setPostCursor] = useState(null)
  const [activeTab, setActiveTab] = useState(null)
  const status = {
    fetchSponsor: useStatus(acts.FETCH_SPONSOR),
    deleteSponsor: useStatus(acts.DELETE_SPONSOR),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.DELETE_SPONSOR))
    }
  }, [])

  useEffect(() => {
    dispatch(orgActs.fetchSponsor({ osid, id })).then(s => setSponsor(s))
    return () => {
      dispatch(clearStatus(acts.FETCH_SPONSOR))
    }
  }, [osid, id])

  const loading = status.fetchSponsor.pending || !sponsor

  useStatusMsg(status.fetchSponsor, {
    error: e => `Error fetching sponsor: ${e.err.message}`,
  })

  useStatusMsg(status.deleteSponsor, {
    pending: 'Deleting sponsor...',
    error: 'Failed to delete sponsor',
    success: 'Sponsor deleted',
  })

  let display = <LoadingOutlined />

  const deleteSponsor = () => {
    return dispatch(orgActs.deleteSponsor({ osid, id })).then(() =>
      history.push(paths.org.SPONSORS(osid)),
    )
  }

  if (!loading) {
    const handleActionMenu = e => {
      if (e.key === 'edit') {
        history.push(paths.org.EDIT_SPONSOR(osid, id))
      } else if (e.key === 'delete-sponsor') {
        Modal.confirm({
          title: `Delete sponsor?`,
          content: `Deleting a sponsor will remove it from all programs, clubs, etc. to which it is attached.`,
          okText: 'Delete',
          okButtonProps: {
            type: 'primary',
            danger: true,
          },
          onOk: () => deleteSponsor(),
        })
      }
    }

    const actionMenu = (
      <Menu onClick={handleActionMenu}>
        <Menu.Item key="edit">Edit</Menu.Item>
        <Menu.Item key="delete-sponsor" danger>
          Delete
        </Menu.Item>
      </Menu>
    )
    const programsTitle = (
      <BadgeTitle
        title={`Sponsored ${features.program.name.plural}`}
        count={sponsor.programs ? sponsor.programs.length : 0}
      />
    )
    const clubsTitle = (
      <BadgeTitle
        title={`Sponsored ${features.club.name.plural}`}
        count={sponsor.clubs ? sponsor.clubs.length : 0}
      />
    )
    display = (
      <>
        <Head>
          <Title>{sponsor.name}</Title>
          <div style={{ flexGrow: 1 }}></div>
          <div style={{ display: 'flex' }}>
            <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
              <Cta>
                Actions <DownOutlined />
              </Cta>
            </Dropdown>
          </div>
        </Head>
        <Tabs defaultActiveKey="overview" onChange={k => setActiveTab(k)}>
          <TabPane tab="Overview" key="overview">
            <Overview sponsor={sponsor} />
          </TabPane>
          <TabPane key="programs" tab={programsTitle}>
            <Programs sponsor={sponsor} />
          </TabPane>
          <TabPane key="clubs" tab={clubsTitle}>
            <Clubs sponsor={sponsor} />
          </TabPane>
        </Tabs>
      </>
    )
  }

  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.org.SPONSORS(osid)}>{features.sponsor.name.plural}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{loading ? id : sponsor.name}</Breadcrumb.Item>
      </Breadcrumb>
      {display}
    </PaddedContainer>
  )
}

export default Sponsor
