import { useContext, useState, useEffect } from 'react'
import { Tag, Select } from 'antd'
import { ThemeContext } from 'styled-components'
import { useDispatch } from 'react-redux'
import { actions as acts } from '../constants'
import { useStatus, useStatusMsg } from '../reducers'
import { fetchAllProducts } from '../actions/org'
import { useParams } from 'react-router-dom'
import { types as productTypes } from '../constants/product'

const { Option } = Select

export const ProductSelect = ({ value, onChange, excludeIds, ...props }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [products, setProducts] = useState({}) // map of product id to product object
  const [val, setVal] = useState(null)
  const status = useStatus(acts.FETCH_ALL_PRODUCTS)

  useStatusMsg(status, { error: 'Failed to fetch products' })

  useEffect(() => {
    dispatch(fetchAllProducts({ osid })).then(prds => {
      setProducts(
        prds.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {}),
      )
    })
  }, [osid])

  useEffect(() => {
    // convert value to product id when product list or value changes
    if (products && Object.keys(products).length > 0 && value) {
      setVal(value.id)
    }
  }, [products, value])

  if (!status.success) {
    return 'Loading...'
  }

  const opts = Object.keys(products)
    .map(id => {
      if (Array.isArray(excludeIds) && excludeIds.includes(id)) {
        return false
      }
      const p = products[id]
      return (
        <Option key={p.id} value={p.id} data-name={p.name}>
          <div>
            {p.name}
            <div>
              <Tag>{productTypes[p.type]?.label}</Tag>
            </div>
          </div>
        </Option>
      )
    })
    .filter(Boolean)

  const handleChange = pid => {
    setVal(pid)
    if (onChange) {
      onChange(products[pid])
    }
  }

  return (
    <Select
      value={val}
      onChange={handleChange}
      showSearch
      filterOption={(input, option) => {
        const inp = input ? input.toLowerCase() : ''
        const matchesOption = option['data-name'].trim().toLowerCase().indexOf(inp) >= 0
        return matchesOption || option.value.toLowerCase().indexOf(inp) >= 0
      }}
      {...props}
    >
      {opts}
    </Select>
  )
}
