import React from 'react'
import styled from 'styled-components'

const CustomTag = styled.span`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum', 'tnum';
  display: inline-block;
  height: auto;
  margin-right: 8px;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  background: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  cursor: default;
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
`
const Tag = ({ color, backgroundColor, children, ...props }) => (
  <CustomTag style={{ color, backgroundColor }} {...props}>
    {children}
  </CustomTag>
)

const ColoredTag = styled(Tag)`
  border: none;
  color: #fff;
`
export const SuccessTag = styled(ColoredTag)`
  background-color: ${props => props.theme.color.green[1]};
`
export const DangerTag = styled(ColoredTag)`
  background-color: ${props => props.theme.color.red[0]};
`
export const WarningTag = styled(ColoredTag)`
  background-color: ${props => props.theme.color.yellow[0]};
`

export default Tag
