import { actions as acts } from '../constants'

const customLandingPages = (state = [], { type, payload }) => {
  switch (type) {
    case acts.FETCH_CUSTOM_LANDING_PAGES_SUCCESS:
      return payload
    case acts.CREATE_CUSTOM_LANDING_PAGE_SUCCESS:
      return [...state, payload]
    case acts.DELETE_CUSTOM_LANDING_PAGE_SUCCESS:
      return state.filter(clp => clp.id !== payload.id)
    case acts.UPDATE_CUSTOM_LANDING_PAGE_SUCCESS:
      const idx = state.findIndex(clp => clp.id === payload.id)
      if (idx !== -1) {
        const next = [...state]
        next[idx] = {
          ...next[idx],
          ...payload,
        }
        return next
      }
      return state
    default:
      return state
  }
}

export default customLandingPages
