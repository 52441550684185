import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons'
import { message, Modal, Typography, Dropdown, Menu, Tag, Table } from 'antd'
import { Cta as Button } from '../../../components/common'
import UserLink from '../../../components/admin/UserLink'
import CecEditor, { fieldNameLabels } from './CecEditor'
import { actions as acts, paths } from '../../../constants'
import * as adminActs from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import * as urlUtils from '../../../util/url'
import { useStatus, useStatusMsg } from '../../../reducers'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'
import { formatDateTimeTz } from '../../../util/time'

const missingFields = cec =>
  ['name', 'slug', 'customerioSegmentId', 'preTitle', 'preCopy', 'preCtaText'].filter(k =>
    !cec[k] ? true : false,
  )

export const UpdateCec = ({ cec, complete }) => {
  const [editCec, setEditCec] = useState(cec)
  const dispatch = useDispatch()
  const status = useStatus(acts.UPDATE_CUSTOM_EMAIL_CAPTURE)

  useStatusMsg(status, {
    pending: 'Saving...',
    error: 'Failed to update create custom email capture',
    success: 'Custom email capture updated',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.UPDATE_CUSTOM_EMAIL_CAPTURE))
    }
  }, [])

  const onOk = () => {
    const mfs = missingFields(editCec)
    if (!editCec || mfs.length > 0) {
      const labels = mfs.map(mf => fieldNameLabels[mf].fullname).join(', ')
      const msg = `${labels} ${mfs.length > 1 ? 'are' : 'is'} required`
      message.error(msg)
      return
    }
    dispatch(adminActs.updateCec({ ...editCec, cecId: editCec.id })).then(updatedCec => {
      if (typeof complete === 'function') {
        complete(updatedCec)
      }
    })
  }

  return (
    <Modal
      title="Edit custom email capture"
      visible
      okText="Save"
      okButtonProps={{
        disabled: Boolean(status.pending),
        loading: Boolean(status.pending),
      }}
      onCancel={() => (typeof complete === 'function' ? complete() : null)}
      onOk={onOk}
    >
      <CecEditor cec={editCec} onChange={next => setEditCec(next)} />
    </Modal>
  )
}

const CreateCec = ({ complete }) => {
  const [cec, setCec] = useState(null)
  const dispatch = useDispatch()
  const status = useStatus(acts.CREATE_CUSTOM_EMAIL_CAPTURE)

  useStatusMsg(status, {
    pending: 'Creating...',
    error: 'Failed to create custom email capture',
    success: 'Custom email capture created',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_CUSTOM_EMAIL_CAPTURE))
    }
  }, [])

  const onOk = () => {
    const mfs = missingFields(cec)
    if (!cec || mfs.length > 0) {
      const labels = mfs.map(mf => fieldNameLabels[mf].fullname).join(', ')
      const msg = `${labels} ${mfs.length > 1 ? 'are' : 'is'} required`
      message.error(msg)
      return
    }
    dispatch(adminActs.createCec({ ...cec })).then(createdCec => {
      if (typeof complete === 'function') {
        complete(createdCec)
      }
    })
  }

  return (
    <Modal
      title="Create custom email capture"
      visible
      okText="Create"
      okButtonProps={{ disabled: Boolean(status.pending), loading: Boolean(status.pending) }}
      onCancel={() => (typeof complete === 'function' ? complete() : null)}
      onOk={onOk}
    >
      <CecEditor cec={cec} onChange={next => setCec(next)} />
    </Modal>
  )
}

const columns = ({ editCec, deleteCec }) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (val, record) => <Link to={paths.admin.CUSTOM_EMAIL_CAPTURE(record.id)}>{val}</Link>,
  },
  {
    title: 'URL',
    dataIndex: 'slug',
    key: 'slug',
    render: (val, record) => {
      const url = urlUtils.mainSiteCec(val)
      return <a href={url}>{url}</a>
    },
  },
  {
    title: 'Customerio segment',
    dataIndex: 'customerioSegmentId',
    key: 'customerioSegmentId',
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: 175,
    render: (val, record) => (
      <>
        <div>
          <UserLink user={val} />
        </div>
        <div>
          <small>{formatDateTimeTz(record.createdAt)}</small>
        </div>
      </>
    ),
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: 10,
    render: (val, record) => (
      <Dropdown
        overlay={
          <ActionsMenu
            onEdit={() => editCec(record)}
            onDelete={() => deleteCec(record)}
            cec={record}
          />
        }
        trigger={['click']}
      >
        <EllipsisOutlined style={{ fontSize: '1.75em', fontWeight: 'bold' }} />
      </Dropdown>
    ),
  },
]

export const ActionsMenu = ({ onEdit, onDelete, cec }) => {
  const dispatch = useDispatch()
  const deleteStatus = useStatus(acts.DELETE_CUSTOM_EMAIL_CAPTURE)

  useStatusMsg(deleteStatus, {
    pending: 'Deleting custom email capture...',
    error: 'Failed to delete custom email capture',
    success: 'Custom email capture deleted',
  })

  useEffect(() => () => dispatch(clearStatus(acts.DELETE_CUSTOM_EMAIL_CAPTURE)), [])

  const handlActionMenu = e => {
    if (e.key === 'edit') {
      onEdit(cec)
    } else if (e.key === 'delete') {
      Modal.confirm({
        title: 'Confirm',
        okText: 'Delete custom email capture',
        okButtonProps: {
          type: 'danger',
          loading: deleteStatus.pending || false, // cannot be null
          disabled: deleteStatus.pending || false, // cannot be null
        },
        content: (
          <div>
            <p>Are you sure you want to delete the custom email capture "{cec.name}"?</p>
          </div>
        ),
        onOk: () => {
          return dispatch(adminActs.deleteCec({ id: cec.id })).then(() => {
            if (typeof onDelete === 'function') {
              onDelete(cec)
            }
          })
        },
      })
    }
  }

  return (
    <Menu onClick={handlActionMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete" danger>
        Delete
      </Menu.Item>
    </Menu>
  )
}

const CustomEmailCaptures = () => {
  const dispatch = useDispatch()
  const [cecs, setCecs] = useState(null)
  const [creating, setCreating] = useState(false)
  const [editCec, setEditCec] = useState(null) // cec to edit
  const status = useStatus(acts.FETCH_CUSTOM_EMAIL_CAPTURE)

  useEffect(() => {
    dispatch(adminActs.fetchCecs()).then(data => setCecs(data))
  }, [])

  useStatusMsg(status, {
    error: 'Failed to fetch custom email captures',
  })

  if (status.pending) {
    return <LoadingOutlined />
  }

  const cols = columns({
    editCec: cec => {
      setCreating(false)
      setEditCec(cec)
    },
    deleteCec: cec => {
      setCecs(prev => prev.filter(c => c.id !== cec.id))
    },
  })

  return (
    <>
      <PageHeading>
        <PageTitle>Custom Email Captures</PageTitle>
        <Button size="small" type="primary" onClick={() => setCreating(true)}>
          New
        </Button>
      </PageHeading>
      <Table
        rowKey="id"
        rowClassName="cec-row"
        columns={cols}
        dataSource={cecs}
        pagination={false}
      />
      {creating && (
        <CreateCec
          complete={newCec => {
            if (newCec) {
              setCecs(prev => [...prev, newCec])
            }
            setCreating(false)
          }}
        />
      )}
      {editCec && (
        <UpdateCec
          cec={editCec}
          complete={updatedCec => {
            if (updatedCec) {
              setCecs(prev => {
                const next = [...prev]
                const idx = next.findIndex(c => c.id === updatedCec.id)
                if (idx >= 0) {
                  next[idx] = updatedCec
                }
                return next
              })
            }
            setEditCec(null)
          }}
        />
      )}
    </>
  )
}

export default CustomEmailCaptures
