import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Tag } from 'antd'
import { Link } from 'react-router-dom'
import { paths } from '../../constants'

const Card = styled(Link)`
  cursor: pointer;
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  background-color: ${props => props.theme.color.white};
  color: ${props => props.theme.font.color.primary};
  border-radius: ${props => props.theme.br[2]};
  padding: ${props => props.theme.spacing[2]} ${props => props.theme.spacing[3]};
  width: ${props => props.theme.width[5]};

  &:hover {
    color: ${props => props.theme.font.color.primary};
  }
`

const Title = styled.div`
  font-weight: ${props => props.theme.font.weight[5]};
`

const UserCard = ({ user }) => {
  const theme = useContext(ThemeContext)
  return (
    <Card to={paths.admin.USER(user.id)}>
      <Title>
        {user.name} <Tag>{user.type}</Tag>
      </Title>
      <div style={{ color: theme.font.color.secondary }}>{user.email}</div>
    </Card>
  )
}

export default UserCard
