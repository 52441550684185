export const getSetting = (settings, name, defaultValue = null) => {
  if (settings && settings.hasOwnProperty(name)) {
    return settings[name]
  }
  return defaultValue
}

const isSettingAvailable = (allSettings, settings, key) => {
  return settings.hasOwnProperty(allSettings[key].name)
}

// given an allSettings template and a settings object,
// returns a new settings object containing only keys contained in allSettings
export const filterSettings = (allSettings, settings) =>
  Object.keys(allSettings).reduce((acc, curr) => {
    if (isSettingAvailable(allSettings, settings, curr)) {
      acc[curr] = settings[curr]
    }
    return acc
  }, {})

export default {
  getSetting,
  filterSettings,
}
