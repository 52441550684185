import { descConst } from './util'

export const types = {
  ...descConst('CLUB', 'Club', ''),
  ...descConst('PROGRAM', 'Program', ''),
  ...descConst('USER_PROFILE', 'User Profile', ''),
}

export const settingsNames = {
  [types.CLUB.name]: 'clubSettings',
  [types.PROGRAM.name]: 'programSettings',
  [types.USER_PROFILE.name]: 'userProfileSettings',
}
