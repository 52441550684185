import { groupBy, orderBy } from 'lodash'
import dayjs from 'dayjs'

export const groupProgramsByDate = programs => {
  if (!Array.isArray(programs)) return new Map()

  const sortedPrograms = orderBy(programs, p => p.date, ['desc'])
  const groupedProgramsByDate = groupBy(sortedPrograms, p => dayjs(p.date).format('MMM D, YYYY'))

  return new Map(Object.entries(groupedProgramsByDate))
}
