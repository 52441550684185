import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions as acts } from '../../../constants'
import urlUtils from '../../../util/url'
import { searchBenefits } from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import { useStatus, useStatusMsg } from '../../../reducers'
import Search from './search'
import { serializeOrder } from './util'

const columns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'sid',
    key: 'sid',
    dataIndex: 'sid',
  },
]

const forwardPaths = {
  bos: {
    name: 'bos',
    default: true,
    external: true,
    desc: 'Benefit homepage',
    pather: ben => urlUtils.orgBenefitHomepage(ben.org.sid, ben.sid),
  },
}

const BenefitSearch = () => {
  const dispatch = useDispatch()
  const status = useStatus(acts.SEARCH_BENEFITS)
  useStatusMsg(status, { error: 'Failed to search benefits' })
  useEffect(() => {
    return () => dispatch(clearStatus(acts.SEARCH_BENEFITS))
  }, [])

  const search = async ({ input, currentPage, sort, resultsPerPage }) => {
    const res = await dispatch(
      searchBenefits({
        order: serializeOrder(sort),
        searchInput: input,
        limit: resultsPerPage,
        offset: (currentPage - 1) * resultsPerPage,
      }),
    )
    return res
  }

  return (
    <Search
      title="Benefits"
      columns={columns}
      forwardPaths={forwardPaths}
      searcher={search}
      searching={status.pending}
    />
  )
}

export default BenefitSearch
