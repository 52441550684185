import { Modal } from 'antd'

export const JSONModal = ({ title, json, complete }) => {
  let view = json
  if (typeof view === 'string') {
    view = JSON.parse(json)
  }
  const pretty = JSON.stringify(view, null, 4)
  return (
    <Modal visible title={title} onOk={complete} onCancel={complete}>
      <div style={{ whiteSpace: 'pre-wrap' }}>{pretty}</div>
    </Modal>
  )
}

export default JSONModal
