import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Tag as ATag, Table } from 'antd'
import { Link } from 'react-router-dom'
import { fetchPrograms } from '../../../actions/admin'
import { paths, actions as acts } from '../../../constants'
import { PROGRAM } from '../../../constants/marketingLabels'
import programConsts from '../../../constants/program'
import { useStatus } from '../../../reducers'
import TagList from '../../../components/TagList'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'
import { formatDateTimeTz } from '../../../util/time'
import programUtils from '../../../util/program'
import pluralize from 'pluralize'
import capitalize from 'capitalize'

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 275,
    render: (val, record) => (
      <>
        {record.draft && (
          <div>
            <small style={{ fontStyle: 'italic' }}>Draft</small>
          </div>
        )}
        <Link to={paths.admin.PROGRAM(record.id)}>{val}</Link>
        <div
          style={{
            width: '300px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {record.description}
        </div>
      </>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 200,
    render: (val, record) => {
      let color
      switch (val) {
        case programConsts.types.CLASS.name:
          color = '#69c0ff'
          break
        case programConsts.types.COMPETITION.name:
          color = '#ff7875'
          break
        case programConsts.types.COACHING.name:
          color = '#ffd666'
          break
        case programConsts.types.SPINOFF.name:
          color = '#95de64'
          break
        case programConsts.types.EXPERIENCE.name:
          color = '#722ed1'
          break
        default:
          color = 'gray'
          break
      }
      return (
        <>
          <div>{programUtils.subtypeLabel(record)}</div>
          <div>
            <ATag color={color}>{programUtils.typeLabel(record)}</ATag>
          </div>
        </>
      )
    },
  },
  {
    title: 'Time',
    dataIndex: 'date',
    key: 'date',
    width: 200,
    render: (val, record) => {
      // draft classes may not have time set
      if (!record.date) {
        return null
      }
      return (
        <>
          <div>{programUtils.formatDate(record, 'ddd', 'LL')}</div>
          <div>{programUtils.formatTime(record)}</div>
        </>
      )
    },
  },
  {
    title: 'Internal Tags',
    dataIndex: 'internalTags',
    key: 'internalTags',
    width: 200,
    render: (val, record) => (
      <TagList tags={Array.isArray(val) ? val.map(it => it.internalTag) : []} />
    ),
  },
  {
    title: 'Organization',
    dataIndex: 'org',
    key: 'org',
    width: 200,
    render: (val, record) => (
      <>
        <div>{val.name}</div>
        <div>
          <small>Created {formatDateTimeTz(record.createdAt)}</small>
        </div>
      </>
    ),
  },
]

const DEFAULT_PAGE_SIZE = 10
const DEFAULT_PAGE = 1

const Classes = () => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE)
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE)

  const dispatch = useDispatch()
  const { data: programs, total: programsCount } = useSelector(state => state.classes)
  const status = useStatus(acts.FETCH_PROGRAMS)

  useEffect(() => {
    dispatch(fetchPrograms({ limit: pageSize, offset: (currentPage - 1) * pageSize }))
  }, [dispatch, currentPage, pageSize])

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page)
    setPageSize(pageSize)
  }

  if (status.pending) {
    return <LoadingOutlined />
  }
  return (
    <>
      <PageHeading>
        <PageTitle>{capitalize(pluralize(PROGRAM))}</PageTitle>
      </PageHeading>
      <Table
        rowKey="id"
        scroll={{ x: 1300 }}
        rowClassName="program-row"
        columns={columns}
        dataSource={programs}
        pagination={{
          position: ['bottomLeft'],
          total: programsCount,
          onChange: handlePaginationChange,
          current: currentPage,
          pageSize,
          pageSizeOptions: [10, 50, 100],
        }}
      />
    </>
  )
}

export default Classes
