import React from 'react'
import { Tag } from 'antd'
import Attr from './Attr'

const AttributesViewer = ({ attributes }) => {
  const attrMap = attributes.reduce((acc, curr) => {
    if (!(curr.attribute.id in acc)) {
      acc[curr.attribute.id] = { name: curr.attribute.name, values: [] }
    }

    acc[curr.attribute.id].values.push({
      id: curr.value.id,
      name: curr.value.name,
      order: curr.value.order,
    })

    return acc
  }, {})

  const elems = Object.entries(attrMap).map(([id, attr]) => {
    const vals = attr.values
      .sort((a, b) => a.order - b.order)
      .map(v => <Tag key={v.id}>{v.name}</Tag>)
    return (
      <Attr key={id} name={attr.name}>
        <div>{vals}</div>
      </Attr>
    )
  })

  return <div>{elems.length > 0 ? elems : <i>No attributes</i>}</div>
}

export default AttributesViewer
