import { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ThemeContext } from 'styled-components'
import { useStatus, useStatusMsg } from '../../../reducers'
import { clearStatus } from '../../../actions/status'
import { countries } from 'countries-list'
import { Table, Dropdown, Modal, Menu, Form, Input, Radio } from 'antd'
import { EllipsisOutlined, LoadingOutlined, DownOutlined } from '@ant-design/icons'
import { actions as acts, US_STATES, paths } from '../../../constants'
import PageHeading from '../../../components/admin/PageHeading'
import ImagePreview from '../../../components/ImagePreview'
import { AdminVenueSelect } from '../../../components/venue'
import { OrgSelect } from '../../../components/org'
import { SportSelect } from '../../../components/sport'
import Attr from '../../../components/Attr'
import { Reorderable } from '../../../components/Reorderable'
import { Head, Button, Cta, Title, Label, Noner } from '../../../components/common'
import get from 'lodash.get'
import {
  updateBtlFeaturedFacility,
  createBtlFeaturedFacility,
  fetchBtlFeaturedFacilities,
  deleteBtlFeaturedFacility,
  reorderBtlFeaturedFacilities,
} from '../../../actions/admin'
import { useDispatch } from 'react-redux'

const initState = {
  title: null,
  org: null,
  venue: null,
  url: null,
  sport: null,
}

const columns = ({ editFacility, deleteFacility }) => [
  {
    title: 'Order',
    dataIndex: 'order',
    key: 'order',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Org',
    dataIndex: 'org',
    key: 'org',
    render: (val, record) => {
      const country = countries[val]
      return (
        <Noner cond={() => !!val}>
          {val && <Link to={paths.admin.ORG(val.id)}>{val.name}</Link>}
        </Noner>
      )
    },
  },
  {
    title: 'Venue',
    dataIndex: 'venue',
    key: 'venue',
    render: (val, record) => {
      return (
        <Noner cond={() => !!val}>
          {val && <Link to={paths.admin.ORG(val.org)}>{val.name}</Link>}
        </Noner>
      )
    },
  },
  {
    title: 'Sport',
    dataIndex: 'sport',
    key: 'sport',
  },
  {
    title: 'URL',
    dataIndex: 'url',
    key: 'url',
    render: (val, record) => {
      return (
        <Noner cond={() => !!val}>
          <a href={val}>{val}</a>
        </Noner>
      )
    },
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: '10',
    render: (val, record) => (
      <Dropdown
        overlay={<ActionsMenu facility={record} onDelete={deleteFacility} onEdit={editFacility} />}
        trigger={['click']}
      >
        <EllipsisOutlined
          className="ant-dropdown-link"
          style={{ fontSize: '1.75em', fontWeight: 'bold' }}
        />
      </Dropdown>
    ),
  },
]

const getFacilityType = facility => {
  if (facility) {
    const { org, venue } = facility
    if (org) {
      return 'org'
    }
    if (venue) {
      return 'venue'
    }
  }
  return null
}

const FeaturedFacilityEditor = ({ onChange, facility }) => {
  const theme = useContext(ThemeContext)
  const [facilityType, setFacilityType] = useState(getFacilityType(facility))

  if (!facility) {
    if (typeof onChange === 'function') {
      onChange(initState)
    }
    return null
  }

  const { title, org, venue, url, sport } = facility

  const change = update => {
    if (typeof onChange === 'function') {
      onChange({ ...facility, ...update })
    }
  }

  return (
    <Form layout="vertical">
      <Form.Item name="title" label={<Label>Title</Label>} initialValue={title}>
        <Input placeholder="" onChange={e => change({ title: e.target.value })} value={title} />
      </Form.Item>

      <Form.Item name="type" label={<Label>Type</Label>}>
        <div style={{ marginBottom: '1em' }}>
          <Radio.Group
            buttonStyle="solid"
            value={facilityType}
            onChange={e => {
              setFacilityType(e.target.value)
              change({ org: null, venue: null })
            }}
          >
            <Radio.Button value="org">Org</Radio.Button>
            <Radio.Button value="venue">Venue</Radio.Button>
          </Radio.Group>
        </div>
        {facilityType === 'org' && (
          <OrgSelect
            id="org"
            onChange={o => change({ org: o })}
            value={org}
            style={{ width: theme.width[5] }}
            placeholder="Select an org"
          />
        )}

        {facilityType === 'venue' && (
          <AdminVenueSelect
            id="venue"
            onChange={v => change({ venue: v })}
            value={venue}
            style={{ width: theme.width[5] }}
            placeholder="Select a venue"
          />
        )}
      </Form.Item>
      <Form.Item name="sport" label={<Label>Sport</Label>} initialValue={sport}>
        <SportSelect
          id="sport"
          onChange={v => change({ sport: v })}
          style={{ width: theme.width[5] }}
          value={sport}
        />
      </Form.Item>

      <Form.Item
        name="url"
        label={<Label>URL</Label>}
        initialValue={url}
        help="Optional. If not provided, the home urls for org or venue will be used"
      >
        <Input
          placeholder=""
          onChange={e => change({ url: e.target.value })}
          style={{ width: theme.width[5] }}
          value={url}
        />
      </Form.Item>
    </Form>
  )
}

const CreateFeaturedFacilityModal = ({ complete }) => {
  const dispatch = useDispatch()
  const completer = typeof complete === 'function' ? complete : () => {}
  const [facility, setFacility] = useState(null)
  const status = {
    create: useStatus(acts.CREATE_BTL_FEATURED_FACILITY),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_BTL_FEATURED_FACILITY))
    }
  }, [])

  useStatusMsg(status.create, { error: 'Failed to create featured facility' })

  const onOk = async () => {
    // pull out id from org and venue if they exist
    const org = facility.org && typeof facility.org !== 'string' ? facility.org.id : facility.org
    const venue =
      facility.venue && typeof facility.venue !== 'string' ? facility.venue.id : facility.venue
    dispatch(createBtlFeaturedFacility({ ...facility, org, venue }))
      .then(res => completer(res))
      .catch(() => {})
  }

  const formComplete =
    facility &&
    [facility.title, facility.org || facility.venue, facility.sport].reduce(
      (acc, curr) => curr && acc,
      true,
    )

  return (
    <Modal
      title={'Create featured facility'}
      visible
      okText={'Create'}
      okButtonProps={{ disabled: !formComplete }}
      onOk={onOk}
      onCancel={() => completer()}
      confirmLoading={status.create.pending || false}
    >
      <FeaturedFacilityEditor
        facility={facility}
        onChange={updatedFacility => setFacility(updatedFacility)}
      />
    </Modal>
  )
}

const EditFeaturedFacilityModal = ({ complete, facility }) => {
  const dispatch = useDispatch()
  const completer = typeof complete === 'function' ? complete : () => {}
  const [featuredFacility, setFeaturedFacility] = useState(facility)
  const status = {
    update: useStatus(acts.UPDATE_BTL_FEATURED_FACILITY),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.UPDATE_BTL_FEATURED_FACILITY))
    }
  }, [])

  useStatusMsg(status.update, { error: 'Failed to update featured facility' })

  const onOk = async () => {
    // pull out id from org and venue if they exist
    const org =
      featuredFacility.org && typeof featuredFacility.org !== 'string'
        ? featuredFacility.org.id
        : featuredFacility.org
    const venue =
      featuredFacility.venue && typeof featuredFacility.venue !== 'string'
        ? featuredFacility.venue.id
        : featuredFacility.venue
    dispatch(
      updateBtlFeaturedFacility({
        facilityId: featuredFacility.id,
        ...featuredFacility,
        org,
        venue,
      }),
    )
      .then(res => completer(res))
      .catch(() => {})
  }

  const formComplete =
    featuredFacility &&
    [
      featuredFacility.title,
      featuredFacility.org || featuredFacility.venue,
      featuredFacility.sport,
    ].reduce((acc, curr) => curr && acc, true)

  return (
    <Modal
      title={'Edit featured facility'}
      visible
      okText={'Save'}
      okButtonProps={{ disabled: !formComplete }}
      onOk={onOk}
      onCancel={() => completer()}
      confirmLoading={status.update.pending || false}
    >
      <FeaturedFacilityEditor
        facility={featuredFacility}
        onChange={updatedFacility => setFeaturedFacility(updatedFacility)}
      />
    </Modal>
  )
}

const ActionsMenu = ({ facility, onDelete, onEdit }) => {
  const dispatch = useDispatch()
  const deleteStatus = useStatus(acts.DELETE_BTL_FEATURED_FACILITY)

  useStatusMsg(deleteStatus, {
    pending: 'Deleting featured facility...',
    error: 'Failed to delete featured facility',
    success: 'Featured facility deleted',
  })

  useEffect(() => () => dispatch(clearStatus(acts.DELETE_BTL_FEATURED_FACILITY)), [])

  const handleActionMenu = e => {
    if (e.key === 'edit') {
      onEdit(facility)
    } else if (e.key === 'delete') {
      Modal.confirm({
        title: 'Delete featured facility?',
        okText: 'Delete',
        okButtonProps: {
          danger: true,
          loading: deleteStatus.pending || false,
          disabled: deleteStatus.pending || false,
        },
        content: (
          <div>
            <p>Are you sure you want to delete this featured facility?</p>
          </div>
        ),
        onOk: () => {
          return dispatch(deleteBtlFeaturedFacility({ facilityId: facility.id })).then(() => {
            if (typeof onDelete === 'function') {
              onDelete(facility)
            }
          })
        },
      })
    }
  }

  return (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete" danger>
        Delete
      </Menu.Item>
    </Menu>
  )
}

const FeaturedFacility = ({ facility }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Attr name="Title">
        <div>{facility.title}</div>
      </Attr>
      <Attr name="Org">
        <div>
          <Noner cond={() => !!facility.org}>
            {facility.org && <Link to={paths.admin.ORG(facility.org.id)}>{facility.org.name}</Link>}
          </Noner>
        </div>
      </Attr>
      <Attr name="Venue">
        <div>
          <Noner cond={() => !!facility.venue}>
            {facility.venue && (
              <Link to={paths.admin.ORG(facility.venue.org)}>{facility.venue.name}</Link>
            )}
          </Noner>
        </div>
      </Attr>
      <Attr name="Sport">
        <div>{facility.sport}</div>
      </Attr>
      <Attr name="URL">
        <div>
          <Noner cond={() => !!facility.url}>
            <a href={facility.url}>{facility.url}</a>
          </Noner>
        </div>
      </Attr>
    </div>
  )
}

const FeaturedFacilities = () => {
  const dispatch = useDispatch()
  const [creating, setCreating] = useState(false)
  const [editing, setEditing] = useState(null)
  const [reordering, setReordering] = useState(false)
  const [newOrder, setNewOrder] = useState([])
  const [featuredFacilities, setFeaturedFacilities] = useState([])
  const status = {
    fetch: useStatus(acts.FETCH_BTL_FEATURED_FACILITIES),
  }

  useStatusMsg(status.fetch, { error: 'Failed to fetch featured facilities' })

  useEffect(() => {
    dispatch(fetchBtlFeaturedFacilities()).then(facilities => setFeaturedFacilities(facilities))
  }, [])

  const cols = columns({
    editFacility: facility => {
      setEditing(facility)
    },
    deleteFacility: facility => {
      setFeaturedFacilities(prev => prev.filter(f => f.id !== facility.id))
    },
  })

  const handleActionMenu = e => {
    if (e.key === 'create') {
      setCreating(true)
    } else if (e.key === 'reorder') {
      setNewOrder(featuredFacilities)
      setReordering(true)
    }
  }

  const actionMenu = (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="create">Add facility</Menu.Item>
      <Menu.Item key="reorder">Reorder</Menu.Item>
    </Menu>
  )

  const onSaveNewOrder = () => {
    return dispatch(reorderBtlFeaturedFacilities({ facilityIds: newOrder.map(f => f.id) })).then(
      orderedFacilities => {
        setFeaturedFacilities(orderedFacilities)
        setReordering(false)
      },
    )
  }

  return (
    <>
      <Head>
        <Title>Featured facilities</Title>
        <div style={{ flexGrow: 1 }}></div>
        <div style={{ display: 'flex' }}>
          {reordering ? (
            <div>
              <Button onClick={() => setReordering(false)} style={{ marginRight: '.5em' }}>
                Cancel
              </Button>
              <Cta onClick={onSaveNewOrder}>Save</Cta>
            </div>
          ) : (
            <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
              <Button>
                Actions <DownOutlined />
              </Button>
            </Dropdown>
          )}
        </div>
      </Head>
      {!status.fetch.success && <LoadingOutlined />}
      {reordering ? (
        <Reorderable
          items={newOrder}
          keyExtractor={f => f.id}
          renderItem={f => <FeaturedFacility key={f.id} facility={f} />}
          onChange={facilities => setNewOrder(facilities)}
        />
      ) : (
        <Table columns={cols} dataSource={featuredFacilities} pagination={false} />
      )}
      {creating && (
        <CreateFeaturedFacilityModal
          complete={facility => {
            if (facility) {
              setFeaturedFacilities(prev => [...prev, facility])
            }
            setCreating(false)
          }}
        />
      )}
      {editing && (
        <EditFeaturedFacilityModal
          facility={editing}
          complete={facility => {
            if (facility) {
              setFeaturedFacilities(prev => prev.map(f => (facility.id === f.id ? facility : f)))
            }
            setEditing(null)
          }}
        />
      )}
    </>
  )
}

export default FeaturedFacilities
