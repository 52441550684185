import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useStatus, useStatusMsg } from '../../../reducers'
import { actions as acts, paths } from '../../../constants'
import { fetchUsers } from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import urlUtils from '../../../util/url'
import Search from './search'

const columns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'Email',
    key: 'email',
    dataIndex: 'email',
  },
  {
    title: 'Type',
    key: 'type',
    dataIndex: 'type',
  },
]

const forwardPaths = {
  admin: {
    name: 'admin',
    default: true,
    desc: 'User admin',
    pather: user => paths.admin.USER(user.id),
  },
  stripe: {
    name: 'stripe',
    external: true,
    desc: 'User stripe account',
    pather: user => urlUtils.stripeCustomer(user.stripeCustomerId),
  },
}

const UserSearch = () => {
  const dispatch = useDispatch()
  const status = useStatus(acts.FETCH_USERS)
  useStatusMsg(status, { error: 'Failed to search users' })
  useEffect(() => {
    return () => dispatch(clearStatus(acts.FETCH_USERS))
  }, [])

  const searchUsers = async ({ input, currentPage, sort, resultsPerPage }) => {
    const res = await dispatch(
      fetchUsers({
        sort,
        searchInput: input,
        limit: resultsPerPage,
        offset: (currentPage - 1) * resultsPerPage,
      }),
    )
    return {
      data: res.data,
      numResults: res.totalUsers,
    }
  }

  return (
    <Search
      title="Users"
      columns={columns}
      forwardPaths={forwardPaths}
      searcher={searchUsers}
      searching={status.pending}
    />
  )
}

export default UserSearch
