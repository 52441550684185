import { useContext } from 'react'
import { Input } from 'antd'
import { ThemeContext } from 'styled-components'
const { TextArea } = Input

// renders a text input.
// first argument to onChange tries to split input into multiple items by spacing, new line, and comma
// second argument to onChange is the original string input
export const BulkInput = ({ onChange, labelRender, ...props }) => {
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}

  const onInputChange = e => {
    const str = e.target.value
    const parts = Array.from(new Set(str.split(/[\n,\s+]/).filter(Boolean)))
    changer(parts, str)
  }
  return (
    <div>
      <TextArea onChange={onInputChange} {...props} />
      <div style={{ color: theme.font.color.secondary, textAlign: 'right' }}>{labelRender}</div>
    </div>
  )
}
