import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Form, Select, Input } from 'antd'
import { Label } from '../../../components/common'
import { types as userTypes, genders } from '../../../constants/user'

const { Option } = Select

const initState = {
  firstName: null,
  lastName: null,
  email: null,
  type: null,
  gender: null,
}

const UserEditor = ({ user, onChange }) => {
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}

  if (!user) {
    changer(initState)
    return null
  }

  const { firstName, lastName, email, type, gender } = user

  const change = update => {
    changer({ ...user, ...update })
  }

  return (
    <Form layout="vertical">
      <Form.Item name="email" label={<Label>Email</Label>} initialValue={email}>
        <Input
          placeholder="john@example.com"
          onChange={e => change({ email: e.target.value })}
          value={email}
        />
      </Form.Item>
      <Form.Item name="type" label={<Label>User type</Label>} initialValue={type}>
        <Select
          value={type}
          onChange={val => change({ type: val })}
          style={{ maxWidth: theme.width[4] }}
        >
          {Object.values(userTypes).map(ut => (
            <Option key={ut.name} value={ut.name}>
              {ut.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name="first-name" label={<Label>First name</Label>} initialValue={firstName}>
        <Input
          placeholder="John"
          onChange={e => change({ firstName: e.target.value })}
          value={firstName}
        />
      </Form.Item>
      <Form.Item name="last-name" label={<Label>Last name</Label>} initialValue={lastName}>
        <Input
          placeholder="Smith"
          onChange={e => change({ lastName: e.target.value })}
          value={lastName}
        />
      </Form.Item>
      <Form.Item name="gender" label={<Label>Gender</Label>} initialValue={gender}>
        <Select
          value={gender}
          onChange={val => change({ gender: val })}
          style={{ maxWidth: theme.width[4] }}
        >
          {Object.values(genders).map(g => (
            <Option key={g.name} value={g.name}>
              {g.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </Form>
  )
}

export default UserEditor
