import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { Switch, Route, Redirect, useParams } from 'react-router-dom'
import { patterns, paths, actions } from '../../constants'
import { fetchCurrentProgram } from '../../actions/class'
import { useStatus } from '../../reducers'
import Overview from './Overview'
import Bookings from './Bookings'
import Reservations from './Reservations'
import Posts from './Posts'
import Tags from './Tags'
import Duplicate from './Duplicate'
import Container from './Container'
import NewProgramPost from './NewProgramPost'
import Settings from './Settings'
import Benefits from './Benefits'

const Program = () => {
  const { osid, id } = useParams()
  const dispatch = useDispatch()
  const program = useSelector(s => s.currentProgram)
  const fetchStatus = useStatus(actions.FETCH_CURRENT_PROGRAM)
  useEffect(() => {
    dispatch(fetchCurrentProgram(osid, id))
  }, [id])

  if (!fetchStatus.success) {
    return (
      <Container>
        <LoadingOutlined />
      </Container>
    )
  }

  if (program.draft) {
    return <Redirect to={paths.org.RESUME_DRAFT(osid, program.id)} />
  }

  return (
    <Switch>
      <Redirect exact strict from={patterns.org.PROGRAM()} to={patterns.org.OVERVIEW()} />
      <Route path={patterns.org.OVERVIEW()} component={Overview} />
      <Route path={patterns.org.BOOKINGS()} component={Bookings} />
      <Route path={patterns.org.DUPLICATE()} component={Duplicate} />
      <Route path={patterns.org.PROGRAM_POSTS()} component={Posts} />
      <Route path={patterns.org.PROGRAM_TAGS()} component={Tags} />
      <Route path={patterns.org.RESERVATIONS()} component={Reservations} />
      <Route path={patterns.org.PROGRAM_BENEFITS()} component={Benefits} />
      <Route path={patterns.org.PROGRAM_SETTINGS()} component={Settings} />
    </Switch>
  )
}

export default Program
