import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { Link } from 'react-router-dom'
import { Input, Badge } from 'antd'

export const Title = styled.h1`
  font-size: 28px;
  margin-bottom: ${props => props.theme.spacing[0]};
  margin-right: ${props => props.theme.spacing[2]};
  font-weight: ${props => props.theme.font.weight[7]};
`

export const Subtitle = styled.h1`
  font-size: 20px;
  margin-bottom: ${props => props.theme.spacing[0]};
  margin-right: ${props => props.theme.spacing[2]};
  font-weight: ${props => props.theme.font.weight[7]};
`

export const Head = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.spacing[3]};
`

export const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100vw;
  min-height: 100vh;
`

export const PaddedContainer = styled.div`
  padding-top: ${props => props.theme.spacing[4]};
  padding-bottom: ${props => props.theme.spacing[4]};
  padding-left: ${props => props.theme.gutterSpace};
  padding-right: ${props => props.theme.gutterSpace};
`

export const CtaLink = styled(Link)`
  text-decoration: none;

  cursor: pointer;
  color: white;
  border: none;
  line-height: 20px;
  font-weight: ${props => props.theme.font.weight[5]};
  font-size: ${props => props.theme.font.size[6]};
  border-radius: ${props => props.theme.br[2]};
  background-color: ${props => props.theme.color.primary};
  padding: ${props => props.theme.spacing[1]} ${props => props.theme.spacing[2]};
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, ${props => props.theme.color.primary} 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(64 68 82 / 8%) 0px 2px 5px 0px;

  transition: box-shadow 0.08s ease-in, color 0.08s ease-in;

  &:focus {
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(1 150 237 / 36%) 0px 0px 0px 4px,
      rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px,
      rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
      rgb(64 68 82 / 8%) 0px 2px 5px 0px;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, ${props => props.theme.color.primary} 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 3px 9px 0px,
      rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
  }

  &:hover:focus {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(1 150 237 / 36%) 0px 0px 0px 4px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 3px 9px 0px,
      rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #c7c7c7;
  }
`

export const Cta = styled.button`
  cursor: pointer;
  color: white;
  border: none;
  line-height: 20px;
  font-weight: ${props => props.theme.font.weight[5]};
  font-size: ${props => props.theme.font.size[6]};
  border-radius: ${props => props.theme.br[2]};
  background-color: ${props => props.theme.color.primary};
  padding: ${props => props.theme.spacing[1]} ${props => props.theme.spacing[2]};
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, ${props => props.theme.color.primary} 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(64 68 82 / 8%) 0px 2px 5px 0px;

  transition: box-shadow 0.08s ease-in, color 0.08s ease-in;

  &:focus {
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(1 150 237 / 36%) 0px 0px 0px 4px,
      rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px,
      rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
      rgb(64 68 82 / 8%) 0px 2px 5px 0px;
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, ${props => props.theme.color.primary} 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 3px 9px 0px,
      rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
  }

  &:hover:focus {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(1 150 237 / 36%) 0px 0px 0px 4px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 3px 9px 0px,
      rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #c7c7c7;
  }
`

export const Button = styled.button`
  color: ${props => props.theme.font.color.primary};
  cursor: pointer;
  border: none;
  line-height: 20px;
  font-weight: ${props => props.theme.font.weight[5]};
  font-size: ${props => props.theme.font.size[6]};
  border-radius: ${props => props.theme.br[2]};
  padding: ${props => props.theme.spacing[1]} ${props => props.theme.spacing[2]};
  background-color: white;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px,
    rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
    rgb(64 68 82 / 8%) 0px 2px 5px 0px;

  transition: box-shadow 0.08s ease-in, color 0.08s ease-in;

  &:focus {
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(1 150 237 / 36%) 0px 0px 0px 4px,
      rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px,
      rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
      rgb(64 68 82 / 8%) 0px 2px 5px 0px;
  }

  &:hover {
    color: rgb(26, 27, 37);
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 3px 9px 0px,
      rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
  }

  &:hover:focus {
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgb(1 150 237 / 36%) 0px 0px 0px 4px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgba(64, 68, 82, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(64, 68, 82, 0.08) 0px 3px 9px 0px,
      rgba(64, 68, 82, 0.08) 0px 2px 5px 0px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: #c7c7c7;
  }

  &[disabled]:hover {
    color: ${props => props.theme.font.color.primary};
    background-color: #c7c7c7;
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
      rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px,
      rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px,
      rgb(64 68 82 / 8%) 0px 2px 5px 0px;
  }
`
export const Label = styled.span`
  color: rgba(0, 0, 0, 0.65);
  font-weight: ${props => props.theme.font.weight[7]};
`

export const CheckboxHelp = styled.span`
  padding-left: 24px;
`

export const Value = styled.span`
  background-color: #e0e0e0;
  border-radius: ${props => props.theme.br[3]};
  padding: ${props => props.theme.spacing[1]} ${props => props.theme.spacing[2]};
  display: inline-block;
`

export const ID = styled.pre``

// if cond function is present, calls cond and uses output of cond to determine whether to show children or none
// if cond returns truthy, children are displayed
// if cond returns falsy, none is displayed
export const Noner = ({ none = 'None', cond, children }) => {
  const theme = useContext(ThemeContext)
  if (typeof cond === 'function') {
    if (cond()) {
      return children
    }
  } else if (
    (!Array.isArray(children) && children) ||
    (Array.isArray(children) && children.length > 0)
  ) {
    // return children if something is present on children, or if it's an array with elements
    return children
  }
  return (
    <span style={{ color: theme.color.disabled.font }}>
      <i>{none}</i>
    </span>
  )
}

export const CountHeader = ({ title, count }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Badge color={count > 0 ? null : 'gray'} count={count} showZero overflowCount={999} />
      <div style={{ marginLeft: '12px' }}>{title}</div>
    </div>
  )
}

// takes in a decimal and outputs a decimal
export const PercentInput = ({ value, onChange, ...props }) => {
  const changer = typeof onChange === 'function' ? onChange : () => {}
  return (
    <Input
      addonAfter="%"
      type="number"
      placeholder="10"
      value={typeof value === 'number' && value * 100}
      onChange={e => {
        const val = e.target.value
        changer(val ? Number(val) / 100 : val)
      }}
      {...props}
    />
  )
}

export const DollarInput = ({ value, onChange, ...props }) => {
  const changer = typeof onChange === 'function' ? onChange : () => {}
  return (
    <Input
      addonBefore="$"
      type="number"
      placeholder="10.00"
      value={typeof value === 'number' && value / 100}
      onChange={e => {
        const val = e.target.value
        changer(val ? Number(val) * 100 : val)
      }}
      {...props}
    />
  )
}
