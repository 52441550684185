import { useEffect, useState } from 'react'
import { Select } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { Noner } from './common'
import { actions as acts } from '../constants'
import { feeRuleTypes } from '../constants/billing'
import { fetchConstsByName } from '../actions/admin'
import { clearStatus } from '../actions/status'
import { useStatus, useStatusMsg } from '../reducers'
import { useDispatch } from 'react-redux'

const { Option } = Select

export const FeeRuleTypeSelect = ({ value, onChange, ...props }) => {
  const [val, setVal] = useState(value)
  const changer = typeof onChange === 'function' ? onChange : () => {}

  const opts = Object.keys(feeRuleTypes).map(k => {
    const fr = feeRuleTypes[k]
    return (
      <Option key={fr.name} value={fr.name} data-name={fr.label}>
        <div>{fr.label}</div>
      </Option>
    )
  })

  const handleChange = name => {
    setVal(name)
    changer(name)
  }

  return (
    <Select value={val} onChange={handleChange} placeholder="Select a fee rule type" {...props}>
      {opts}
    </Select>
  )
}

export const FeeRuleTypeDisplay = ({ feeRuleType, none = 'No fee rule type' }) => {
  if (!feeRuleType || !feeRuleTypes[feeRuleType]) {
    return <Noner none={none}></Noner>
  }
  return <span>{feeRuleTypes[feeRuleType].label}</span>
}

export const PayoutCountMethodSelect = ({ value, onChange, ...props }) => {
  const dispatch = useDispatch()
  const [val, setVal] = useState(value)
  const [payoutCountMethods, setPayoutCountMethods] = useState(null)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const status = useStatus(acts.FETCH_CONSTS_BY_NAME)

  useEffect(() => {
    dispatch(fetchConstsByName({ name: 'billing' })).then(res =>
      setPayoutCountMethods(res.payoutCountMethods),
    )
    return () => dispatch(clearStatus(acts.FETCH_CONSTS_BY_NAME))
  }, [])

  useStatusMsg(status, { error: 'Failed to fetch billing constants' })

  if (!payoutCountMethods) {
    return <LoadingOutlined />
  }

  const opts = Object.keys(payoutCountMethods).map(k => {
    const cm = payoutCountMethods[k]
    return (
      <Option key={cm.name} value={cm.name} data-name={cm.label}>
        <div>{cm.label}</div>
        <div>
          <small>{cm.description}</small>
        </div>
      </Option>
    )
  })

  const handleChange = name => {
    setVal(name)
    changer(name)
  }

  return (
    <Select value={val} onChange={handleChange} placeholder="Select a count method" {...props}>
      {opts}
    </Select>
  )
}
