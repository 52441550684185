import React, { useState, useEffect, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { FocusHeader } from '../../components/header'
import { Focus as FocusContainer } from '../../components/containers'
import { actions as acts } from '../../constants'
import { clearStatus } from '../../actions/status'
import { useStatus, useStatusMsg } from '../../reducers'
import * as orgActs from '../../actions/org'
import { PostWithComments } from '../../components/posts'

const ClubPost = () => {
  const { osid, id, postId } = useParams()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const [club, setClub] = useState(null)
  const [post, setPost] = useState(null)
  const [comments, setComments] = useState(null)
  const status = {
    fetchClub: useStatus(acts.FETCH_CLUB),
    fetchPost: useStatus(acts.FETCH_CLUB_POST),
    fetchComments: useStatus(acts.FETCH_CLUB_POST_COMMENTS),
    addComment: useStatus(acts.CREATE_CLUB_POST_COMMENT),
    deleteComment: useStatus(acts.DELETE_CLUB_POST_COMMENT),
    updateComment: useStatus(acts.UPDATE_CLUB_POST_COMMENT),
  }
  const isLoading = !post || !comments || !club

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.FETCH_CLUB_POST))
      dispatch(clearStatus(acts.FETCH_CLUB))
      dispatch(clearStatus(acts.FETCH_CLUB_POST_COMMENTS))
      dispatch(clearStatus(acts.CREATE_CLUB_POST_COMMENT))
      dispatch(clearStatus(acts.DELETE_CLUB_POST_COMMENT))
      dispatch(clearStatus(acts.UPDATE_CLUB_POST_COMMENT))
    }
  }, [])

  useEffect(() => {
    dispatch(orgActs.fetchClub(osid, id)).then(c => setClub(c))
    dispatch(orgActs.fetchClubPost(osid, id, postId)).then(p => setPost(p))
    dispatch(orgActs.fetchClubPostComments(osid, id, postId)).then(cmts => setComments(cmts))
  }, [osid, id, postId])

  useStatusMsg(status.fetchPost, {
    error: 'Failed to load post',
  })

  useStatusMsg(status.fetchClub, {
    error: 'Failed to load club',
  })

  useStatusMsg(status.addComment, {
    pending: 'Adding comment...',
    success: 'Comment added',
    error: 'Failed to add comment',
  })

  useStatusMsg(status.deleteComment, {
    pending: 'Deleting comment...',
    success: 'Comment deleted',
    error: 'Failed to delete comment',
  })

  useStatusMsg(status.updateComment, {
    success: 'Comment updated',
    error: 'Failed to update comment',
  })

  if (isLoading) {
    return <LoadingOutlined />
  }

  const updateComments = () =>
    dispatch(orgActs.fetchClubPostComments(osid, id, postId)).then(cmts => {
      setComments(cmts)
      // return a truthy value to clear the comment box
      return true
    })

  const onAddComment = cmnt => {
    const action = cmnt.parentComment
      ? orgActs.createClubPostCommentReply(osid, id, postId, cmnt.parentComment, { ...cmnt })
      : orgActs.createClubPostComment(osid, id, postId, { ...cmnt })
    return dispatch(action).then(() => updateComments())
  }

  const onSaveEditedComment = cmnt =>
    dispatch(orgActs.updateClubPostComment(osid, id, postId, cmnt.id, cmnt)).then(() =>
      updateComments(),
    )

  const onDeleteComment = cmnt =>
    dispatch(orgActs.deleteClubPostComment(osid, id, postId, cmnt.id)).then(() => updateComments())

  return (
    <>
      <FocusHeader title={`Post in ${club.name}`} backIcon="back" />
      <FocusContainer style={{ paddingTop: '20px', paddingBottom: '20px', maxWidth: '600px' }}>
        <div style={{ backgroundColor: 'white', borderRadius: theme.br[2] }}>
          <PostWithComments
            post={post}
            comments={comments}
            onAddComment={onAddComment}
            onSaveEditedComment={onSaveEditedComment}
            onDeleteComment={onDeleteComment}
          />
        </div>
      </FocusContainer>
    </>
  )
}

export default ClubPost
