import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Input, Button, Table, Select, Row, Col, Checkbox } from 'antd'
import {
  CloseOutlined,
  LeftOutlined,
  RightOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from '@ant-design/icons'
import { LoadingOutlined } from '@ant-design/icons'
import { fetchUsers, updateUserRole } from '../../../actions/admin'
import { useStatus, useStatusMsg } from '../../../reducers'
import { actions as acts, paths } from '../../../constants'
import { types as userTypes } from '../../../constants/user'
import { clearStatus } from '../../../actions/status'
import { Link } from 'react-router-dom'
import AddProPageModal from '../Pros/AddProPageModal'
import { numberOfPages } from '../../../util/pagination'

const { Option } = Select

const Users = () => {
  const dispatch = useDispatch()
  const initialNumberOfUsers = useSelector(s => s.users.totalUsers)
  const users = useSelector(s => s.users.data)
  const [creatingPro, setCreatingPro] = useState(false)
  const [pro, setPro] = useState(null)
  const status = {
    fetch: useStatus(acts.FETCH_USERS),
    update: useStatus(acts.UPDATE_USER_TYPE),
  }

  useStatusMsg(status.fetch, { error: 'Failed to fetch users' })
  useStatusMsg(status.update, {
    error: 'Failed to update user type',
    success: 'User type updated',
  })

  const [filteredUsers, setFilteredUsers] = useState(users)
  const [numberOfUsers, setNumberOfUsers] = useState(initialNumberOfUsers)
  const [currentPage, setCurrentPage] = useState(1)
  const [isSearching, setIsSearching] = useState(false)
  const [isEquinoxSearch, setIsEquinoxSearch] = useState(false)
  const [userSearch, setUserSearch] = useState(null)
  const [sort, setSort] = useState({ column: 'name', order: 'asc' })
  const usersPerPage = 50

  const changeUserType = (userId, type) => dispatch(updateUserRole(userId, type))

  const onAddProPage = user => {
    setPro(user)
    setCreatingPro(true)
  }

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (val, record) => <Link to={paths.admin.USER(record.id)}>{val}</Link>,
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      render: (val, record) => (
        <Select value={[val]} onChange={v => changeUserType(record.id, v)}>
          {Object.keys(userTypes).map(k => (
            <Option key={k} value={k}>
              {userTypes[k].label}
            </Option>
          ))}
        </Select>
      ),
    },
    {
      title: 'Actions',
      key: 'type',
      dataIndex: 'type',
      render: (val, record) => {
        if (record.page) {
          return (
            <Link to={paths.admin.PRO_PAGE(record.page.id)}>
              <Button>View pro page</Button>
            </Link>
          )
        }
        if (val === userTypes.PRO.name) {
          return (
            <Button type="primary" onClick={() => onAddProPage(record)}>
              Add pro page
            </Button>
          )
        }
        return null
      },
    },
  ]

  const getUsers = () => {
    if (isSearching) {
      dispatch(
        fetchUsers({
          sort,
          searchInput: userSearch,
          limit: usersPerPage,
          offset: (currentPage - 1) * usersPerPage,
          equinox: isEquinoxSearch,
        }),
      )
    } else {
      dispatch(
        fetchUsers({
          sort,
          searchInput: '',
          limit: usersPerPage,
          offset: (currentPage - 1) * usersPerPage,
          equinox: isEquinoxSearch,
        }),
      )
    }
  }

  useEffect(() => {
    getUsers()
  }, [currentPage, usersPerPage])

  useEffect(() => {
    setCurrentPage(1)
    getUsers()
  }, [isEquinoxSearch, sort])

  useEffect(() => {
    setNumberOfUsers(initialNumberOfUsers)
    setFilteredUsers(users)
  }, [users, initialNumberOfUsers])

  const onCreateProPage = page => {
    setCreatingPro(false)
    setPro(null)
  }

  const handleForward = () => {
    if (currentPage < numberOfPages(numberOfUsers, usersPerPage)) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleSearch = () => {
    setCurrentPage(1)
    setIsSearching(true)
    dispatch(
      fetchUsers({
        sort,
        searchInput: userSearch,
        limit: usersPerPage,
        offset: (currentPage - 1) * usersPerPage,
        equinox: isEquinoxSearch,
      }),
    )
  }

  const handleClearSearch = () => {
    setUserSearch('')
    setIsSearching(false)
    dispatch(
      fetchUsers({
        sort,
        searchInput: '',
        limit: usersPerPage,
        offset: (currentPage - 1) * usersPerPage,
        equinox: isEquinoxSearch,
      }),
    )
  }

  const toggleSort = () => {
    setSort(prev => ({
      ...prev,
      order: prev.order === 'asc' ? 'desc' : 'asc',
    }))
  }

  return (
    <div>
      <h1>Users</h1>
      {status.fetch.pending && <LoadingOutlined />}
      <Row style={{ marginBottom: '1em' }}>
        <Col span={6}>
          <Input
            placeholder="Search input"
            onChange={e => setUserSearch(e.target.value)}
            value={userSearch}
            style={{ width: '50%' }}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleSearch()
              }
            }}
          />
          {isSearching && (
            <Button
              type="text"
              onClick={handleClearSearch}
              disabled={!isSearching}
              style={{ color: 'red' }}
            >
              <CloseOutlined />
            </Button>
          )}
          <Button onClick={handleSearch} style={{ width: '20%' }}>
            Search
          </Button>
        </Col>
      </Row>
      <Row style={{ marginBottom: '1em' }}>
        <Checkbox
          onChange={e => setIsEquinoxSearch(e.target.checked)}
          style={{ marginLeft: '0.5em' }}
        >
          Equinox users only
        </Checkbox>
      </Row>
      <Row style={{ marginBottom: '1em' }}>
        <Col span={6}>
          <Button type="text" onClick={handleBack}>
            <LeftOutlined />
          </Button>
          {currentPage}/{Math.max(numberOfPages(numberOfUsers, usersPerPage), 1)}
          <Button type="text" onClick={handleForward}>
            <RightOutlined />
          </Button>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Button type="text" onClick={toggleSort}>
            {sort.order === 'asc' ? <SortDescendingOutlined /> : <SortAscendingOutlined />}
          </Button>
        </Col>
      </Row>
      {!status.fetch.pending && (
        <Row style={{ marginBottom: '20px' }}>
          <Col span={12}>
            <Table columns={columns} dataSource={filteredUsers} pagination={false} />
          </Col>
        </Row>
      )}
      {creatingPro && <AddProPageModal complete={onCreateProPage} creatingPro={pro} />}
    </div>
  )
}

export default Users
