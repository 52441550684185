export const dollars = amount => {
  if (amount < 0) {
    return `-$${(amount * -1).toFixed(2)}`
  }
  return `$${amount.toFixed(2)}`
}
export const dollarAmount = amount => `${amount.toFixed(2)}`

export const centsToDollars = cents => dollars(cents / 100)
export const centsToDollarAmount = cents => dollarAmount(cents / 100)

// 0.05 => 5.00%
export const percent = percent => `${(percent * 100).toFixed(2)}%`

export default {
  dollars,
  dollarAmount,
  centsToDollars,
  centsToDollarAmount,
  percent,
}
