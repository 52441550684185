import { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { Divider as AntDivider, Tooltip, Typography } from 'antd'
import { InfoCircleFilled } from '@ant-design/icons'
import { Subtitle, Noner } from './common'

export const MiniInfo = ({ title, none = 'None', info, style = {}, moreInfo, mark }) => {
  const styles = {
    display: 'flex',
    flexDirection: 'column',
    marginRight: '1.5em',
    marginLeft: '1.5em',
    ...style,
  }
  const data = <Noner none={none}>{info}</Noner>
  let display = (
    <span>
      {data} {mark ? <Typography.Text type="danger">*</Typography.Text> : null}
    </span>
  )

  if (moreInfo) {
    display = (
      <Tooltip
        title={<div style={{ padding: '1em', maxWidth: '300px' }}>{moreInfo}</div>}
        placement="bottom"
      >
        <div style={{ cursor: 'pointer' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ marginRight: '.70em' }}>
              {data} {mark ? <Typography.Text type="danger">*</Typography.Text> : null}
            </span>
            <InfoCircleFilled style={{ fontSize: '14px' }} />
          </div>
        </div>
      </Tooltip>
    )
  }

  return (
    <div style={styles}>
      <MiniHead style={{ marginBottom: '.25em' }}>{title}</MiniHead>
      {display}
    </div>
  )
}

const MiniHead = ({ children, style, ...props }) => {
  const theme = useContext(ThemeContext)
  return (
    <span style={{ color: theme.font.color.muted, ...style }} {...props}>
      {children}
    </span>
  )
}

const MediumHead = ({ info, button, children }) => {
  const theme = useContext(ThemeContext)
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div>
          <Subtitle style={{ display: 'inline' }}>{children}</Subtitle> <span>{info}</span>
        </div>
        {button}
      </div>
      <AntDivider style={{ margin: `${theme.spacing[2]} 0` }} />
    </>
  )
}

export const MediumInfo = ({ title, info, button, children, style }) => {
  const theme = useContext(ThemeContext)
  return (
    <div style={{ marginTop: theme.spacing[3], ...style }}>
      <MediumHead info={info} button={button}>
        {title}
      </MediumHead>
      {children}
    </div>
  )
}

export const Divider = () => {
  return <AntDivider style={{ height: 'auto' }} type="vertical" />
}
