import React, { useEffect, useState, useReducer } from 'react'
import { useDispatch } from 'react-redux'
import { useStatus, useStatusMsg } from '../../../reducers'
import { actions as acts } from '../../../constants'
import { Form, Input, Button } from 'antd'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'
import { PROGRAM } from '../../../constants/marketingLabels'
import { TextArea, Container, UpcomingSelects, emailState, reducer } from './common'
import Attr from '../../../components/Attr'
import UserSelect from '../../../components/UserSelect'
import { ProgramSelect } from '../../../components/program'
import { generateEmailPreview, sendEmail } from '../../../actions/admin'
import capitalize from 'capitalize'
import pluralize from 'pluralize'

const initState = emailState(
  {
    program: null,
    user: null,
    breakRate: null,
    breakRateDescription: null,
    gamesWon: {
      thisRally: null,
      thisMonth: null,
      thisSeason: null,
      thisYear: null,
    },
    topReps: {
      thisRally: null,
      thisMonth: null,
      thisSeason: null,
      thisYear: null,
    },
  },
  {
    subject: 'Congrats, You Rallied! Unlock Your Stats',
  },
)

const RallyReport = () => {
  const dispatch = useDispatch()
  const [state, dp] = useReducer(reducer, initState)
  const [preview, setPreview] = useState(null)
  const [formComplete, setFormComplete] = useState(false)
  const status = {
    upcoming: useStatus(acts.FETCH_UPCOMING_PROGRAMS),
    preview: useStatus(acts.GENERATE_EMAIL_PREVIEW),
    send: useStatus(acts.SEND_EMAIL),
  }

  useStatusMsg(status.preview, {
    pending: 'Generating preview...',
    error: 'Failed to generate email preview',
    success: 'Preview generated',
  })

  useStatusMsg(status.send, {
    pending: 'Sending email...',
    error: 'Failed to send email',
    success: 'Email sent',
  })

  useEffect(() => {
    if (state.to) {
      setFormComplete(true)
    }
  }, [state])

  const onPreview = async () => {
    const res = await dispatch(generateEmailPreview('break-report-rally', state))
    if (res.html) {
      setPreview({ __html: res.html })
    }
  }

  const onSend = () => dispatch(sendEmail('break-report-rally', state))

  return (
    <>
      <PageHeading>
        <PageTitle>Rally Break Report Email</PageTitle>
      </PageHeading>
      <Container>
        <Form name="program" layout="vertical">
          <Form.Item name="program" label={capitalize(PROGRAM)}>
            <ProgramSelect
              onChange={e => dp({ type: 'data', key: 'program', val: JSON.parse(e) })}
            />
          </Form.Item>
          <Form.Item name="user" label="User">
            <UserSelect
              onChange={user => {
                dp({ type: 'data', key: 'user', val: user })
                dp({ type: 'to', val: user.email })
              }}
            />
          </Form.Item>
          <Attr name="Break Rate">
            <Form.Item name="break-rate" label="Break Rate">
              <Input
                placeholder="e.g. 10"
                style={{ width: '20em' }}
                onChange={e => dp({ type: 'data', key: 'breakRate', val: e.target.value })}
              />
            </Form.Item>
            <Form.Item name="break-rate-description" label="Break Rate Description">
              <TextArea
                recommendedLength={205}
                onChange={e =>
                  dp({ type: 'data', key: 'breakRateDescription', val: e.target.value })
                }
              />
            </Form.Item>
          </Attr>
          <Attr name="Games Won">
            <Form.Item name="games-won-this-rally" label="This Rally">
              <Input
                style={{ width: '20em' }}
                onChange={e => dp({ type: 'data', key: 'gamesWon.thisRally', val: e.target.value })}
              />
            </Form.Item>
            <Form.Item name="games-won-this-month" label="This Month">
              <Input
                style={{ width: '20em' }}
                onChange={e => dp({ type: 'data', key: 'gamesWon.thisMonth', val: e.target.value })}
              />
            </Form.Item>
            <Form.Item name="games-won-this-season" label="This Season">
              <Input
                style={{ width: '20em' }}
                onChange={e =>
                  dp({ type: 'data', key: 'gamesWon.thisSeason', val: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item name="games-won-this-year" label="This Year">
              <Input
                style={{ width: '20em' }}
                onChange={e => dp({ type: 'data', key: 'gamesWon.thisYear', val: e.target.value })}
              />
            </Form.Item>
          </Attr>
          <Attr name="Top Reps">
            <Form.Item name="top-reps-this-rally" label="This Rally">
              <Input
                style={{ width: '20em' }}
                onChange={e => dp({ type: 'data', key: 'topReps.thisRally', val: e.target.value })}
              />
            </Form.Item>
            <Form.Item name="top-reps-this-month" label="This Month">
              <Input
                style={{ width: '20em' }}
                onChange={e => dp({ type: 'data', key: 'topReps.thisMonth', val: e.target.value })}
              />
            </Form.Item>
            <Form.Item name="top-reps-this-season" label="This Season">
              <Input
                style={{ width: '20em' }}
                onChange={e => dp({ type: 'data', key: 'topReps.thisSeason', val: e.target.value })}
              />
            </Form.Item>
            <Form.Item name="top-reps-this-year" label="This Year">
              <Input
                style={{ width: '20em' }}
                onChange={e => dp({ type: 'data', key: 'topReps.thisYear', val: e.target.value })}
              />
            </Form.Item>
          </Attr>
          <Attr
            name={`Upcoming ${pluralize(capitalize(PROGRAM))}`}
            description={`By default the three most recent bookable ${pluralize(
              PROGRAM,
            )} are listed`}
          >
            <Form.Item>
              <UpcomingSelects dp={dp} />
            </Form.Item>
          </Attr>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button type="secondary" onClick={onPreview} loading={status.preview.pending}>
              Preview
            </Button>
            <Button type="primary" onClick={onSend} disabled={!formComplete}>
              Send
            </Button>
          </div>
        </Form>
        <div style={{ marginTop: '2em' }}>
          {preview && <div dangerouslySetInnerHTML={preview}></div>}
        </div>
      </Container>
    </>
  )
}

export default RallyReport
