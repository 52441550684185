import { actions as acts } from '../constants'

export default function success(state = {}, { type, payload }) {
  if (type === acts.CLEAR_SUCCESS) {
    return {
      ...state,
      [payload]: null,
    }
  }

  const matches = /(.*)_(PENDING|SUCCESS)/.exec(type)
  if (!matches) {
    return state
  }
  const [, name, promiseState] = matches
  return {
    ...state,
    // Store success
    // e.g. stores success when receiving ADD_ATTACHMENT_SUCCESS
    //      else clear success when receiving ADD_ATTACHMENT_PENDING
    [name]: promiseState === 'SUCCESS' ? payload : null,
  }
  return state
}

export function getSuccess(state, actionName) {
  return state[actionName]
}
