import moment from 'moment-timezone'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { getTimeZones } from '@vvo/tzdb'

dayjs.extend(customParseFormat)

// expects a moment time
export const formatTime = time => time && time.format('HH:mm')

export const parseTime = timeStr => timeStr && moment(timeStr, ['HH:mm'])

export const diffMins = (a, b) => {
  const diff = b - a
  return Math.abs(Math.round(((diff % 86400000) % 3600000) / 60000))
}

// expects a UTC time for proper tz conversion
export const formatDateTimeTz = date =>
  moment(date).tz('America/New_York').format('MMM DD, YYYY h:mma z')

export const formatDate = date => moment(date).format('MMM DD, YYYY')
export const formatDateUtc = date => moment(date).utc().format('YYYY-MM-DD')
export const formatPermitDateUtc = date => moment(date).utc().format('MM/DD/YYYY')
export const tzByName = name => {
  if (!name) {
    return
  }
  return getTimeZones().find(timeZone => {
    return name === timeZone.name || timeZone.group.includes(name)
  })
}

export const formatWallTime = time => {
  return dayjs(time, 'HH:mm:ss').format('h:mma')
}
