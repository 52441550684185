import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Menu, Dropdown, message } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { deleteProgram } from '../../../actions/class'
import { paths } from '../../../constants'

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Card = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: ${props => props.theme.color.white};
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  border-radius: ${props => props.theme.br[2]};
  padding: ${props => props.theme.spacing[2]};
  padding-left: ${props => props.theme.spacing[3]};
  width: ${props => props.theme.width[5]};
  height: ${props => props.theme.height[6]};
  margin-right: ${props => props.theme.spacing[3]};
  margin-bottom: ${props => props.theme.spacing[3]};
  color: ${props => props.theme.font.color.primary};

  &:hover {
    color: ${props => props.theme.font.color.primary};
  }
`

const More = styled.div`
  display: inline-block;
  padding: ${props => props.theme.spacing[1]};
`
const Title = styled.div`
  max-width: ${props => props.theme.width[4]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const MoreIcon = styled(MoreOutlined)`
  font-size: ${props => props.theme.font.size[3]};
`

const Drafts = ({ osid, programs, onDelete }) => {
  const dispatch = useDispatch()
  const onMenuClick = e => e.stopPropagation()
  const onClickDelete = programId => e => {
    e.stopPropagation()
    dispatch(deleteProgram(osid, programId))
      .then(() => message.success('Draft deleted'))
      .then(() => {
        if (typeof onDelete === 'function') {
          onDelete(programId)
        }
      })
      .catch(() => message.error('Failed to delete draft'))
  }
  const cards = programs.map(c => {
    const menu = (
      <Menu>
        <Menu.Item>
          <a onClick={onClickDelete(c.id)}>Delete</a>
        </Menu.Item>
      </Menu>
    )
    return (
      <Card key={c.id} to={paths.org.RESUME_DRAFT(osid, c.id)}>
        <span>
          <Title>{c.name}</Title>
        </span>
        <Dropdown overlay={menu} trigger={['click']}>
          <More onClick={onMenuClick}>
            <MoreIcon />
          </More>
        </Dropdown>
      </Card>
    )
  })
  return <Container>{cards}</Container>
}

export default Drafts
