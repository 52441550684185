import React from 'react'
import { s3Asset } from '../util/url'

const ImagePreview = ({ asset, width = '300px', height = '300px', ...props }) => {
  if (!asset) {
    return
  }
  return <img src={s3Asset(asset)} width={width} height={height} {...props} />
}

export const LinkedImagePreview = ({ asset, ...props }) => (
  <a href={s3Asset(asset)} target="_blank">
    <ImagePreview asset={asset} {...props} />
  </a>
)

export default ImagePreview
