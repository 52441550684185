import React, { useState, useReducer, useEffect } from 'react'
import { Badge, Popover, Checkbox } from 'antd'
import { DownOutlined, LoadingOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Button, CtaLink, Head, Title, PaddedContainer } from '../../components/common'
import { paths, features, actions as acts } from '../../constants'
import { useStatus } from '../../reducers'
import { clearStatus } from '../../actions/status'
import { fetchClubFilters, searchClubs } from '../../actions/org'
import * as stringUtils from '../../util/string'
import { useOrgNotFoundError } from '../../hooks/use-org-not-found-error'

const Filter = ({ name, values, onChange }) => {
  const [checked, setChecked] = useState(null)

  const updateChecked = checkedValues => {
    setChecked(checkedValues)
    if (typeof onChange === 'function') {
      onChange(checkedValues)
    }
  }

  const onClear = () => {
    updateChecked(null)
  }

  const valueElems = values.map(v => (
    <div key={v.value} style={{ marginTop: '.5em' }}>
      <Checkbox
        value={v.value}
        checked={Array.isArray(checked) ? checked.indexOf(v.value) !== -1 : false}
      >
        {v.name}
      </Checkbox>
    </div>
  ))

  const valueDisplay = (
    <Checkbox.Group onChange={updateChecked} value={checked}>
      {valueElems}
    </Checkbox.Group>
  )

  const popoverTitle = (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <a onClick={onClear}>Clear</a>
    </div>
  )

  return (
    <div style={{ marginRight: '1em' }}>
      <Popover content={valueDisplay} title={popoverTitle} trigger="click" placement="bottomRight">
        <Button>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: '.25em' }}>{name}</div>
            <Badge style={{ marginRight: '.25em' }} count={checked ? checked.length : 0} />
            <DownOutlined style={{ fontSize: '12px' }} />
          </div>
        </Button>
      </Popover>
    </div>
  )
}

const reducer = (state, { type, payload }) => {
  if (type === 'attributes') {
    const next = {
      ...state,
      attributes: state.attributes ? state.attributes : [],
    }
    next.attributes = next.attributes.filter(a => a.attribute !== payload.attribute)
    if (Array.isArray(payload.values) && payload.values.length > 0) {
      next.attributes.push(payload)
    }
    return next
  } else if (type === 'visibility') {
    return {
      ...state,
      visibility: payload,
    }
  }
  return state
}

const initState = {
  visibility: null,
  attributes: null,
}

const ClubFilters = ({ onChange }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [availableFilters, setAvailableFilters] = useState(null)
  const [state, dp] = useReducer(reducer, initState)
  const status = {
    fetchFilters: useStatus(acts.FETCH_CLUB_FILTERS),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.FETCH_CLUB_FILTERS))
    }
  }, [])

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(state)
    }
  }, [state])

  useEffect(() => {
    if (osid) {
      dispatch(fetchClubFilters(osid)).then(fs => setAvailableFilters(fs))
    }
  }, [osid])

  let display = <LoadingOutlined />

  if (status.fetchFilters && availableFilters) {
    const { visibility, attributes } = availableFilters
    const attrFilters = attributes.map(af => (
      <Filter
        key={af.id}
        name={af.name}
        values={af.values.map(v => ({ name: v.name, value: v.id }))}
        onChange={selected =>
          dp({ type: 'attributes', payload: { attribute: af.id, values: selected } })
        }
      />
    ))

    display = (
      <div style={{ display: 'flex' }}>
        <Filter
          name="Visibility"
          values={visibility.map(v => ({ name: stringUtils.title(v), value: v }))}
          onChange={selected => dp({ type: 'visibility', payload: selected })}
        />
        {attrFilters}
      </div>
    )
  }
  return <div>{display}</div>
}

const Club = () => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [clubs, setClubs] = useState(null)
  const status = {
    search: useStatus(acts.SEARCH_CLUBS),
  }

  useOrgNotFoundError(status.search, 'search for clubs', osid)

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.SEARCH_CLUBS))
    }
  }, [])

  const onFilterChange = filters => {
    if (filters && osid) {
      dispatch(searchClubs(osid, filters)).then(cs => setClubs(cs))
    }
  }

  let display = <LoadingOutlined />

  if (Array.isArray(clubs)) {
    if (clubs.length === 0) {
      display = <i>No clubs</i>
    } else {
      display = clubs.map(c => (
        <div key={c.id}>
          <Link to={paths.org.CLUB(osid, c.sid)}>{c.name}</Link>
        </div>
      ))
    }
  }

  return (
    <PaddedContainer>
      <Head>
        <Title>{features.club.name.plural}</Title>
        <CtaLink to={paths.org.NEW_CLUB(osid)}>
          <PlusCircleFilled /> Create {features.club.name.singular.toLowerCase()}
        </CtaLink>
      </Head>
      <ClubFilters onChange={onFilterChange} />
      <div style={{ marginTop: '1em' }}>{display}</div>
    </PaddedContainer>
  )
}

export default Club
