import React, { useState, useEffect, useLayoutEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useParams } from 'react-router-dom'
import { push, replace } from 'connected-react-router'
import { Steps } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import {
  finalizeDraft,
  fetchDraftProgram,
  clearDraftProgram,
  createProgram,
  updateProgram,
} from '../../../actions/class'
import { paths, actions as acts } from '../../../constants'
import { useStatus } from '../../../reducers'
import { initState as draftInitState } from '../../../reducers/draftProgram'
import { lower } from '../../../util/string'
import { typeLabel } from '../../../util/program'
import store from '../../../store'
import ProgramType from './ProgramType'
import BasicInformation from './BasicInformation'
import Venue from './Venue'
import Location from './Location'
import Timing from './Timing'
import Players from './Players'
import PricingType from './PricingType'
import Inclusions from './Inclusions'
import CancellationPolicy from './CancellationPolicy'
import PublishDate from './PublishDate'
import ImageUploads from './ImageUploads'
import Hidden from './Hidden'
import Finalize from './Finalize'
import Attributes from './Attributes'
import Host from './Host'
import Club from './Club'
import Sponsors from './Sponsors'
import Sports from './Sports'
import ClubMembership from './ClubMembership'
import CardRestriction from './CardRestriction'
import { Container, Content, Footer, BackTrack, Next } from './step'

const { Step } = Steps

// Order matters in these sections for certain steps
// The 'applicable' function export of each step should reveal the order
// based on what attributes 'applicable' depends on
const sections = [
  {
    title: 'Basic Information',
    steps: [
      ProgramType,
      BasicInformation,
      Venue,
      Club,
      ClubMembership,
      CardRestriction,
      Sponsors,
      Sports,
      Host,
      Location,
      Hidden,
    ],
  },
  {
    title: 'Schedule',
    steps: [Timing],
  },
  {
    title: 'Pricing',
    steps: [Players, PricingType],
  },
  {
    title: 'Final Details',
    steps: [Inclusions, CancellationPolicy, PublishDate, Attributes, ImageUploads],
  },
  {
    title: 'Lift Off',
    steps: [Finalize],
  },
]

// returns the number of steps for a given section
const numSteps = section => sections[section].steps.length
const getStep = (section, step) => sections[section].steps[step]

const initState = {
  step: 0,
  section: 0,
  direction: null,
}

const isFirstSection = state => state.section === 0

const isLastSection = state => state.section === sections.length - 1

// returns true if current step is first step of section
const isFirstStep = state => state.step === 0

// returns true if current step is last step section
const isLastStep = state => numSteps(state.section) - 1 === state.step

const reducer = (state, { section, step, direction }) => ({
  ...state,
  section,
  step,
  direction,
})

// returns next section/step from given section/step
// returns null if next advance would go past end of all steps and sections
const advance = (section, step) => {
  if (step === numSteps(section) - 1 && section >= sections.length - 1) {
    return null
  }
  if (step === numSteps(section) - 1) {
    return [section + 1, 0]
  }
  return [section, step + 1]
}

// returns previous section/step from given section/step
// returns null if next retreat would go past beginning of all steps and sections
const retreat = (section, step) => {
  if (step === 0 && section === 0) {
    return null
  }
  if (step === 0) {
    const newSection = section - 1
    return [newSection, numSteps(newSection) - 1]
  }
  return [section, step - 1]
}

// using current section and step,
// traverses steps and returns the first section/step where cb returns true
const search = async (dp, state, cb, direction = 'forward') => {
  let section = state.section,
    step = state.step
  const mover = direction === 'backward' ? retreat : advance
  while (mover(section, step) !== null) {
    ;[section, step] = mover(section, step)
    const found = await cb(section, step)
    if (found) {
      dp({ section, step, direction })
      return
    }
  }
}

const CreateForm = () => {
  const { osid, id } = useParams()
  const dispatch = useDispatch()
  // only a new creation if id does not initially exist
  const [newCreation, setNewCreation] = useState(!id)
  const [draftProgram, setDraftProgram] = useState(draftInitState)
  const [isFetching, setIsFetching] = useState(false)
  const [stepState, setStepState] = useState({})
  const [state, dp] = useReducer(reducer, initState)
  const [isStepComplete, setIsStepComplete] = useState(null)
  const [isSearching, setIsSearching] = useState(true)
  const status = {
    fetch: useStatus(acts.FETCH_DRAFT_PROGRAM),
    update: useStatus(acts.UPDATE_PROGRAM),
  }
  const StepContent = getStep(state.section, state.step).step
  const stepComplete = getStep(state.section, state.step).complete

  const isApplicable = async (section, step) => {
    // we need a very fresh copy of the state here since there are some cases
    // when navigating where the useSelector copy of draftProgram does
    // not update before isApplicable is called in nextStep and search functions
    // This could possibly use a TODO to implement a unified way of getting the
    // freshest current state
    return await getStep(section, step).applicable(store.getState().draftProgram)
  }

  const isIncomplete = async (section, step) => {
    const complete = await getStep(section, step).complete(draftProgram, osid)
    const applicable = await isApplicable(section, step)
    return applicable && !complete
  }

  const shouldFetch = id && !draftProgram.id
  const isLoading = shouldFetch ? isFetching : false

  useLayoutEffect(() => {
    // fetch draftProgram if navigated directly by URL
    if (shouldFetch) {
      setIsFetching(true)
      dispatch(fetchDraftProgram(osid, id)).then(p => {
        setDraftProgram(p)
        setIsFetching(false)
      })
    }
  }, [])

  // always navigate to first applicable incomplete step using store's draftProgram
  // on initial load if completely new, this effectively starts from the beginning
  useLayoutEffect(() => {
    const searchStep = async () => await search(dp, state, isIncomplete, 'forward')

    if (draftProgram.id && !newCreation) {
      setIsSearching(true)
      searchStep()
      setIsSearching(false)
    } else {
      setIsSearching(false)
    }
  }, [draftProgram.id, newCreation, isLoading])

  useEffect(() => {
    const checkComplete = async () => {
      // TODO this is not the cleanest way to pass osid (right now used only in
      // attributes step), but the complete steps are just functions and do not
      // have a way to do useParams. Figure out a better way to communicate
      // extraneous info to each complete function
      const complete = await stepComplete({ ...draftProgram, ...stepState }, osid)
      setIsStepComplete(complete)
    }
    if (!isLoading) {
      checkComplete()
    }
  }, [draftProgram, stepState, isLoading])

  // set url to editing specific draft once we have an id
  useEffect(() => {
    if (draftProgram.id && draftProgram.id !== id) {
      dispatch(replace(paths.org.RESUME_DRAFT(osid, draftProgram.id)))
    }
  }, [draftProgram.id])

  const nextStep = async () => {
    if (state.section >= sections.length - 1 && state.step >= numSteps(state.section) - 1) {
      // finished
      dispatch(push(paths.org.PROGRAMS(osid)))
      return
    }
    await search(dp, state, isApplicable, 'forward')
  }

  const prevStep = async () => await search(dp, state, isApplicable, 'backward')

  // if navigating directly to this URL and the creation of this program has
  // already been completed...
  if (draftProgram.draft !== null && !draftProgram.draft) {
    return <Redirect to={paths.org.PROGRAM(osid, id)} />
  }

  const onFirst = isFirstSection(state) && isFirstStep(state)
  const onLast = isLastSection(state) && isLastStep(state)

  const onClickNext = async () => {
    let action
    if (!draftProgram.id) {
      if (onFirst) {
        // on the first step we only locally update the type information
        // because we only create the program on the server when we have
        // the program name which is gathered on the second step
        action = updateProgram(osid, null, stepState, false)
      } else {
        action = createProgram(osid, stepState)
      }
    } else {
      if (onLast) {
        action = finalizeDraft(osid, draftProgram.id)
      } else {
        action = updateProgram(osid, draftProgram.id, stepState)
      }
    }
    try {
      await dispatch(action).then(p => setDraftProgram(prev => ({ ...prev, ...p })))

      // when we're on the first step and we haven't saved to the server yet
      // don't clear stepState so that the next step can save the first step's
      // data to the server
      if (draftProgram.id && !onFirst) {
        setStepState({})
      }
      if (!onLast) {
        nextStep()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const onStepChange = changes => {
    // if this program hasn't been created yet we need to preserve all the data
    // of previous step states until it is created
    if (!draftProgram.id) {
      setStepState(prev => ({ ...prev, ...changes }))
    } else {
      setStepState(changes)
    }
  }

  let nextLabel = 'Next'
  let pendingNextLabel = 'Saving...'
  if (onLast) {
    nextLabel = `Create ${lower(typeLabel(draftProgram))}`
    pendingNextLabel = 'Creating...'
  }

  return (
    <>
      <Steps current={state.section}>
        {sections.map(step => (
          <Step key={step.title} title={step.title} />
        ))}
      </Steps>
      <Container>
        <Content>
          {(isLoading || isSearching) && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <LoadingOutlined style={{ fontSize: '20px' }} />
            </div>
          )}
          {!isLoading && !isSearching && (
            <>
              <StepContent clinic={draftProgram} onChange={onStepChange} />
              <Footer>
                <BackTrack
                  style={{ visibility: onFirst ? 'hidden' : 'visible' }}
                  onBack={onFirst ? null : prevStep}
                />
                <Next disabled={!isStepComplete || status.update.pending} onClick={onClickNext}>
                  {status.update.pending ? pendingNextLabel : nextLabel}
                </Next>
              </Footer>
            </>
          )}
        </Content>
      </Container>
    </>
  )
}

export default CreateForm
