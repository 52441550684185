import { LOCATION_CHANGE } from 'connected-react-router'
import { paths, actions as acts } from '../constants'
import { bookingStatusTypes } from '../constants/program'
import { initState } from './draftProgram'

// returns true if waitlist is from this program, else returns false
const isWaitlistFromProgram = (programId, waitlist) => {
  return Array.isArray(waitlist) && waitlist.find(w => w.program === programId)
}

const currentProgram = (state = initState, { type, payload }) => {
  switch (type) {
    case acts.FETCH_CURRENT_PROGRAM_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case acts.UPDATE_PROGRAM_SUCCESS:
      if (payload.id === state.id) {
        return {
          ...state,
          ...payload,
        }
      }
      break
    case acts.UPDATE_ADMIN_PROGRAM_SUCCESS:
      if (payload.id === state.id) {
        return {
          ...state,
          ...payload,
        }
      }
      break
    case acts.CANCEL_PROGRAM_BOOKING_SUCCESS:
      if (payload.programId === state.id) {
        return {
          ...state,
          bookings: state.bookings.map(b => {
            if (b.id === payload.bookingId) {
              return { ...b, status: bookingStatusTypes.CANCELED.name }
            }
            return b
          }),
        }
      }
      break
    case acts.CHANGE_BOOKING_NOTE_SUCCESS:
      if (payload.programId === state.id) {
        return {
          ...state,
          bookings: state.bookings.map(b => {
            if (b.id === payload.bookingId) {
              return { ...b, note: payload.note }
            }
            return b
          }),
        }
      }
      break
    case acts.CREATE_MANUAL_BOOKING_SUCCESS: {
      if (payload.programId === state.id) {
        return {
          ...state,
          bookings: [...payload.bookings, ...state.bookings],
        }
      }
    }
    case acts.ADD_TO_PROGRAM_WAITLIST_SUCCESS: {
      if (isWaitlistFromProgram(state.id, payload)) {
        return { ...state, waitlist: payload }
      }
    }
    case acts.UPDATE_WAITLIST_USER_SUCCESS: {
      if (isWaitlistFromProgram(state.id, payload)) {
        return { ...state, waitlist: payload }
      }
    }
    case acts.REMOVE_FROM_PROGRAM_WAITLIST_SUCCESS: {
      if (isWaitlistFromProgram(state.id, payload)) {
        return { ...state, waitlist: payload }
      }
    }
    case acts.SET_PROGRAM_ORG_TAGS_SUCCESS: {
      return { ...state, tags: payload }
    }
    case acts.CREATE_PROGRAM_RES_RESERVATIONS_SUCCESS: {
      return { ...state, resourceReservations: payload }
    }
    case acts.DELETE_PROGRAM_RES_RESERVATION_SUCCESS: {
      return { ...state, resourceReservations: payload }
    }
    case acts.ADD_BENEFIT_PROGRAM_SUCCESS: {
      return { ...state, associatedBenefits: [...state.associatedBenefits, payload] }
    }
    case acts.UPDATE_BENEFIT_PROGRAM_SUCCESS: {
      return {
        ...state,
        associatedBenefits: state.associatedBenefits.map(ab => {
          if (ab.id === payload.id) {
            return payload
          }
          return ab
        }),
      }
    }
    case acts.REMOVE_BENEFIT_PROGRAM_SUCCESS: {
      return { ...state, associatedBenefits: payload }
    }
    case acts.FINALIZE_DRAFT_PROGRAM_SUCCESS:
      if (payload.id === state.id) {
        return {
          ...state,
          ...payload,
        }
      }
      break
    // TODO figure out a way to capture this functionality with osid, or without a reducer
    // clear currentClass when navigating away from it
    //case LOCATION_CHANGE:
    //  const path = paths.class.CLASS(state.id)
    //  if (!payload.location.pathname.includes(path)) {
    //    return initState
    //  }
    //  break
    default:
      return state
  }
  return state
}

export default currentProgram
