import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Form, Input } from 'antd'
import SingleImageEditor from '../../components/SingleImageEditor'

const Label = styled.span`
  color: rgba(0, 0, 0, 0.65);
  font-weight: ${props => props.theme.font.weight[7]};
`

const initState = {
  name: null,
  notes: null,
  logo: null,
  icon: null,
  link: null,
}

const SponsorEditor = ({ onChange, sponsor }) => {
  if (!sponsor) {
    if (typeof onChange === 'function') {
      onChange(initState)
    }
    return null
  }

  const { name, notes, logo, icon, link } = sponsor

  const change = update => {
    if (typeof onChange === 'function') {
      onChange({ ...sponsor, ...update })
    }
  }

  return (
    <Form layout="vertical">
      <Form.Item name="name" label={<Label>Name</Label>} initialValue={name}>
        <Input placeholder="" onChange={e => change({ name: e.target.value })} value={name} />
      </Form.Item>
      <Form.Item name="notes" label={<Label>Notes</Label>} initialValue={notes}>
        <Input.TextArea value={notes} onChange={e => change({ notes: e.target.value })} />
      </Form.Item>
      <Form.Item name="logo" label={<Label>Logo</Label>}>
        <SingleImageEditor
          actionText="Select logo"
          onChange={img => change({ logo: img })}
          img={logo}
        />
      </Form.Item>
      <Form.Item name="link" label={<Label>Link</Label>} initialValue={link}>
        <Input
          placeholder="Example: https://wilson.com"
          onChange={e => change({ link: e.target.value })}
          value={link}
        />
      </Form.Item>
      <Form.Item name="icon" label={<Label>Icon</Label>}>
        <SingleImageEditor
          actionText="Select icon"
          onChange={img => change({ icon: img })}
          img={icon}
        />
      </Form.Item>
    </Form>
  )
}

export default SponsorEditor
