import ExploreClubs from './ExploreClubs'
import FeaturedGeos from './FeaturedGeos'
import FeaturedFacilities from './FeaturedFacilities'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'

const BTLBrandSettings = () => {
  return (
    <>
      <PageHeading>
        <PageTitle>Break the Love brand settings</PageTitle>
      </PageHeading>
      <div style={{ marginBottom: '2em' }}>
        <ExploreClubs />
      </div>
      <div style={{ marginBottom: '2em' }}>
        <FeaturedGeos />
      </div>
      <div style={{ marginBottom: '2em' }}>
        <FeaturedFacilities />
      </div>
    </>
  )
}

export default BTLBrandSettings
