import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { CloseOutlined } from '@ant-design/icons'
import { useStatus, useStatusMsg } from '../../../reducers'
import { Editor } from '@tinymce/tinymce-react'
import { tinyMceApiKey } from '../../../config'
import { useDropzone } from 'react-dropzone'
import * as urlUtils from '../../../util/url'
import { formatName } from '../../../util/user'
import { actions as acts } from '../../../constants'
import * as actions from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import Attr from '../../../components/Attr'
import Dropzone from '../../../components/Dropzone'
import ImagePreview from '../../../components/ImagePreview'
import { Input, Modal, Form } from 'antd'
import { v4 as uuid } from 'uuid'

const thumbStyles = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
}

const imgStyles = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

const AddProPageModal = ({ complete, creatingPro, existingProPage, isEdit = false }) => {
  const dispatch = useDispatch()
  const id = isEdit ? existingProPage.id : null
  const [url, setUrl] = useState(existingProPage && existingProPage.url)
  const [description, setDescription] = useState(existingProPage && existingProPage.description)
  const [images, setImages] = useState((existingProPage && existingProPage.images) || [])
  const [deletedImages, setDeletedImages] = useState([])
  const status = {
    create: useStatus(acts.CREATE_PRO_PAGE),
    update: useStatus(acts.UPDATE_PRO_PAGE),
    deleteImage: useStatus(acts.DELETE_PRO_PAGE_IMAGE),
    addImages: useStatus(acts.ADD_PRO_PAGE_IMAGES),
  }

  useStatusMsg(status.create, {
    pending: 'Creating pro page...',
    error: 'Failed to create pro page',
    success: 'Pro page created',
  })

  useStatusMsg(status.update, {
    pending: 'Updating pro page...',
    error: 'Failed to update pro page',
    success: 'Pro page updated',
  })

  useStatusMsg(status.deleteImage, {
    pending: 'Deleting image...',
    error: 'Failed to delete image',
    success: 'Image deleted',
  })

  useStatusMsg(status.addImages, {
    pending: 'Adding images...',
    error: 'Failed to add images',
    success: 'Images added',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_PRO_PAGE))
      dispatch(clearStatus(acts.UPDATE_PRO_PAGE))
      dispatch(clearStatus(acts.DELETE_PRO_PAGE_IMAGE))
      dispatch(clearStatus(acts.ADD_PRO_PAGE_IMAGES))
    }
  }, [])

  // If creating a pro, provide a default suggested url for the pro
  useEffect(() => {
    if (creatingPro && !url) {
      if (creatingPro.firstName && creatingPro.lastName) {
        setUrl(
          [creatingPro.firstName.trim(), creatingPro.lastName.trim()].map(validChars).join('-'),
        )
      }
      setUrl([creatingPro.name.trim()].map(validChars).join('-'))
    }
  }, [])

  const formComplete = [url && url.length > 0, description && description.length > 0].reduce(
    (acc, curr) => curr && acc,
    true,
  )

  // transform string into a valid pro url string
  const validChars = str => {
    const regex = RegExp('^[a-z,0-9,-]+$')
    return [...str.toLowerCase()]
      .map(char => {
        if (regex.test(char)) {
          return char
        }
        return false
      })
      .map(c => (c === false ? '-' : c))
      .join('')
  }

  const updateImages = async page => {
    // add images
    const toAdd = images.filter(i => !!i.preview)
    let newImages = []
    if (toAdd.length > 0) {
      newImages = await dispatch(actions.addProPageImages(page.id, toAdd))
    }
    if (newImages.length > 0) {
      page.images.push(...newImages)
    }
    // delete images
    if (deletedImages.length > 0) {
      await Promise.all(
        deletedImages.map(img => dispatch(actions.deleteProPageImage(page.id, img.id))),
      )
      const deletedIds = deletedImages.map(i => i.id)
      page.images = page.images.filter(i => !deletedIds.includes(i.id))
    }
  }

  const onOk = async () => {
    if (isEdit) {
      const updatedPage = await dispatch(actions.updateProPage({ description, url }, id))
      await updateImages(updatedPage)
      complete(updatedPage)
    } else {
      const page = await dispatch(
        actions.createProPage({ userId: creatingPro.id, description, url }),
      )
      await updateImages(page)
      complete(page)
    }
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/jpeg,image/png',
    multiple: true,
    disabled: status.deleteImage.pending || status.addImages.pending,
    onDrop: acceptedFiles => {
      setImages(prev => {
        const newImages = acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
            id: uuid(),
          }),
        )
        if (prev && Array.isArray(prev)) {
          return prev.concat(newImages)
        }
        return newImages
      })
    },
  })

  const removeImage = image => {
    setImages(prev => prev.filter(i => i.id !== image.id))
    if (image.preview) {
      URL.revokeObjectURL(image.preview)
    } else {
      setDeletedImages(prev => prev.concat(image))
    }
  }

  return (
    <Modal
      title={`${
        isEdit
          ? `Update ${formatName(existingProPage.user)}'s`
          : `Create ${formatName(creatingPro)}'s`
      } pro page`}
      visible
      okText={isEdit ? 'Save' : 'Create'}
      okButtonProps={{ disabled: !formComplete }}
      onOk={onOk}
      onCancel={() => complete()}
      confirmLoading={status.create.pending || status.update.pending || false}
    >
      <Form>
        <Attr name="URL slug">
          <Input
            addonBefore={urlUtils.mainSiteProPage('')}
            placeholder="keenan-smith"
            value={url}
            onChange={e => setUrl(e.target.value)}
          />
        </Attr>
        <Attr name="Description">
          <Editor
            apiKey={tinyMceApiKey}
            value={description}
            init={{
              placeholder: 'Pro page description',
              branding: false,
              height: 300,
              menubar: false,
              plugins: [
                'advlist autolink lists link image charmap print preview anchor',
                'searchreplace visualblocks code fullscreen',
                'insertdatetime media table paste code help wordcount',
              ],
              default_lin_target: '_blank',
              toolbar: 'undo redo | bold italic | link',
            }}
            onEditorChange={content => setDescription(content)}
          />
        </Attr>
        <Attr name="Images">
          <div>
            <Dropzone {...getRootProps()}>
              <input {...getInputProps()} />
              Select images for upload
            </Dropzone>
            <div
              style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: '1em' }}
            >
              {images &&
                images.length > 0 &&
                images.map(i => (
                  <div key={i.id} style={thumbStyles}>
                    {i.preview && <img src={i.preview} height="100px" style={imgStyles} />}
                    {!i.preview && <ImagePreview asset={i} width="auto" height="100px" />}
                    <CloseOutlined style={{ cursor: 'pointer' }} onClick={() => removeImage(i)} />
                  </div>
                ))}
            </div>
          </div>
        </Attr>
      </Form>
    </Modal>
  )
}

export default AddProPageModal
