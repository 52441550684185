import React, { useReducer } from 'react'
import { countries } from 'countries-list'

export const addressComplete = address => {
  let finished = [
    address.name.length >= 2,
    address.addressLine1.length >= 2,
    address.city.length >= 2,
    address.postcode.length >= 2,
    address.state !== undefined,
    !!address.country,
    address.lat !== '',
    address.lon !== '',
  ]
  if (address.country && address.country !== 'US') {
    // postcode and state not required if not US
    finished = [
      address.name.length >= 2,
      address.addressLine1.length >= 2,
      address.city.length >= 2,
      !!address.country,
      address.lat !== '',
      address.lon !== '',
    ]
  }
  return !finished.includes(false)
}

const addressInitState = {
  id: null,
  name: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  state: undefined,
  postcode: '',
  country: 'US',
  locationPage: '',
  lat: '',
  lon: '',
  createdAt: null,
  updatedAt: null,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return addressInitState
    case 'set':
      return {
        ...state,
        [action.key]: action.val,
      }
  }
}

export const useAddress = (initState = addressInitState) => {
  const [state, dp] = useReducer(reducer, initState)

  return {
    address: state,
    reset: () => dp({ type: 'reset' }),
    setName: val => dp({ type: 'set', key: 'name', val }),
    setDescription: val => dp({ type: 'set', key: 'description', val }),
    setLine1: val => dp({ type: 'set', key: 'addressLine1', val }),
    setLine2: val => dp({ type: 'set', key: 'addressLine2', val }),
    setCity: val => dp({ type: 'set', key: 'city', val }),
    setState: val => dp({ type: 'set', key: 'state', val }),
    setPostcode: val => dp({ type: 'set', key: 'postcode', val }),
    setCountry: val => dp({ type: 'set', key: 'country', val }),
    setLocationPage: val => dp({ type: 'set', key: 'locationPage', val }),
    setLat: val => dp({ type: 'set', key: 'lat', val }),
    setLon: val => dp({ type: 'set', key: 'lon', val }),
  }
}

const UsAddress = ({ address, includeName = false, singleLine = false }) => {
  if (singleLine) {
    return [
      includeName ? address.name : null,
      address.addressLine1,
      address.addressLine2 ? address.addressLine2 : null,
      address.city,
      `${address.state} ${address.postcode}`,
      countries[address.country].name,
    ]
      .filter(Boolean)
      .join(', ')
  }
  return (
    <>
      {includeName && <div>{address.name}</div>}
      <div>{address.addressLine1}</div>
      {address.addressLine2 && <div>{address.addressLine2}</div>}
      <div>
        {address.city}, {address.state} {address.postcode}
      </div>
      <div>{countries[address.country].name}</div>
    </>
  )
}

const MxAddress = ({ address, includeName = false, singleLine = false }) => {
  if (singleLine) {
    return [
      includeName ? address.name : null,
      address.addressLine1,
      address.addressLine2 ? address.addressLine2 : null,
      `${address.postcode} ${address.city}`,
      address.state,
      countries[address.country].name,
    ]
      .filter(Boolean)
      .join(', ')
  }
  return (
    <>
      {includeName && <div>{address.name}</div>}
      <div>{address.addressLine1}</div>
      {address.addressLine2 && <div>{address.addressLine2}</div>}
      <div>
        {address.postcode} {address.city}, {address.state}
      </div>
      <div>{countries[address.country].name}</div>
    </>
  )
}

const DefaultAddress = ({ address, includeName = false, singleLine = false }) => {
  if (singleLine) {
    return [
      includeName ? address.name : null,
      address.addressLine1,
      address.addressLine2 ? address.addressLine2 : null,
      address.city,
      `${address.state} ${address.postcode}`,
      countries[address.country].name,
    ]
      .filter(Boolean)
      .join(', ')
  }
  return (
    <>
      {includeName && <div>{address.name}</div>}
      <div>{address.addressLine1}</div>
      {address.addressLine2 && <div>{address.addressLine2}</div>}
      <div>{address.city}</div>
      {address.state && <div>{address.state}</div>}
      <div>{address.postcode}</div>
      <div>{countries[address.country].name}</div>
    </>
  )
}

const Display = ({ address, ...props }) => {
  switch (address.country) {
    case 'US':
      return <UsAddress address={address} {...props} />
    case 'MX':
      return <MxAddress address={address} {...props} />
    default:
      return <DefaultAddress address={address} {...props} />
  }
}

const SummaryLine = ({ address }) => {
  const country = address.country === 'US' ? 'USA' : countries[address.country].name
  return [address.city, address.state, country].filter(Boolean).join(', ')
}

const Address = Display
Address.SummaryLine = SummaryLine

export default Address
