import React from 'react'
import styled from 'styled-components'
import { Input as AntInput } from 'antd'

export const RequiredLabel = styled.label`
  display: flex;
  align-items: center;
  &:before {
    margin-right: ${props => props.theme.spacing[1]};
    color: #f5222d;
    font-size: 14px;
    line-height: 1;
    content: '*';
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
`

export const Content = styled.div`
  margin-top: ${props => props.theme.spacing[3]};
  margin-bottom: ${props => props.theme.spacing[5]};

  padding-top: ${props => props.theme.spacing[5]};
  padding-bottom: ${props => props.theme.spacing[4]};
  padding-left: ${props => props.theme.spacing[4]};
  padding-right: ${props => props.theme.spacing[4]};

  min-height: 200px;
  max-width: ${props => props.theme.width[8]};
  min-width: ${props => props.theme.width[8]};

  border: 1px solid #e9e9e9;
  border-radius: 6px;

  background-color: ${props => props.theme.color.white};
`

export const Title = styled.h2`
  font-size: ${props => props.theme.font.size[3]};
  font-weight: ${props => props.theme.font.weight[7]};
  margin-bottom: ${props => props.theme.spacing[2]};
`

export const InputGroup = styled.div`
  margin-bottom: ${props => props.theme.spacing[3]};
`

export const Desc = styled.div`
  margin-bottom: ${props => props.theme.spacing[2]};
`

export const Footer = styled.div`
  margin-top: ${props => props.theme.spacing[4]};
  display: flex;
  justify-content: space-between;
`

export const Back = styled.button`
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  text-align: left;
  font-weight: ${props => props.theme.font.weight[6]};
  color: ${props => props.theme.color.gray[3]};
  font-size: ${props => props.theme.font.size[6]};
`
export const BackTrack = ({ onCancel, onBack, ...props }) => {
  if (onCancel) {
    return (
      <Back onClick={onCancel} {...props}>
        Cancel
      </Back>
    )
  }
  return (
    <Back onClick={onBack} {...props}>
      Back
    </Back>
  )
}

export const Next = styled.button`
  cursor: pointer;
  line-height: 1.5;
  color: white;
  border: none;
  border-radius: ${props => props.theme.br[2]};
  background-color: ${props => props.theme.color.primary};
  padding: 0 ${props => props.theme.spacing[4]};
  height: ${props => props.theme.height[3]};
  font-weight: ${props => props.theme.font.weight[6]};
  font-size: ${props => props.theme.font.size[5]};

  :disabled {
    background: ${props => props.theme.color.disabled.background};
    color: ${props => props.theme.color.disabled.font};
    border: solid 1px;
    border-color: ${props => props.theme.color.disabled.border};
    cursor: not-allowed;
  }
`

export const Input = styled(AntInput)``

export const SelectContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: justify-between;
  align-items: start;
`
