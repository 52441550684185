import { formatName } from '../util/user'
import { Link } from 'react-router-dom'
import { paths } from '../constants'

export const AdminUserLink = ({ children, user, ...rest }) => {
  let sig = null
  let childs = children

  if (user) {
    sig = typeof user === 'string' ? user : user.id
  }

  if (!sig) {
    return childs
  }

  // render something if children is not passed
  if (!childs) {
    childs = typeof user === 'string' ? user : formatName(user)
  }

  return (
    <Link to={paths.admin.USER(sig)} {...rest}>
      {childs}
    </Link>
  )
}
