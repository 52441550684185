import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { LoadingOutlined, PlusCircleFilled } from '@ant-design/icons'
import { Link, useParams } from 'react-router-dom'
import { CtaLink, Head, Title, PaddedContainer } from '../../components/common'
import { paths, features, actions as acts } from '../../constants'
import { useStatus } from '../../reducers'
import { clearStatus } from '../../actions/status'
import { fetchSponsors } from '../../actions/org'
import { useOrgNotFoundError } from '../../hooks/use-org-not-found-error'

const Sponsors = () => {
  const { osid } = useParams()
  const [sponsors, setSponsors] = useState(null)
  const dispatch = useDispatch()
  const status = useStatus(acts.FETCH_SPONSORS)

  useEffect(() => {
    dispatch(fetchSponsors({ osid })).then(spns => setSponsors(spns))
    return () => {
      dispatch(clearStatus(acts.FETCH_SPONSORS))
    }
  }, [osid])

  useOrgNotFoundError(status, 'fetch sponsors', osid)

  let display = <LoadingOutlined />

  if (Array.isArray(sponsors)) {
    if (sponsors.length === 0) {
      display = <i>No sponsors</i>
    } else {
      display = sponsors.map(s => (
        <div key={s.id}>
          <Link to={paths.org.SPONSOR(osid, s.id)}>{s.name}</Link>
        </div>
      ))
    }
  }

  return (
    <PaddedContainer>
      <Head>
        <Title>{features.sponsor.name.plural}</Title>
        <CtaLink to={paths.org.NEW_SPONSOR(osid)}>
          <PlusCircleFilled /> Create {features.sponsor.name.singular.toLowerCase()}
        </CtaLink>
      </Head>
      <div style={{ marginTop: '1em' }}>{display}</div>
    </PaddedContainer>
  )
}

export default Sponsors
