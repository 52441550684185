import { actions as acts } from '../constants'

// NB init state is used to determine if attributes were ever loaded or not
// null => never loaded
// [] => loaded, but empty
// We may want to make this better in the future
const attributes = (state = null, { type, payload }) => {
  switch (type) {
    case acts.FETCH_ATTRIBUTES_SUCCESS: {
      return payload
    }
    case acts.CREATE_ATTRIBUTE_SUCCESS: {
      if (Array.isArray(state)) {
        return [...state, payload]
      }
      return [payload]
    }
    case acts.DELETE_ATTRIBUTE_SUCCESS: {
      return state.filter(a => a.id !== payload.id)
    }
    case acts.UPDATE_ATTRIBUTE_SUCCESS: {
      const idx = state.findIndex(a => a.id === payload.id)
      if (idx !== -1) {
        const next = [...state]
        next[idx] = {
          ...next[idx],
          ...payload,
        }
        return next
      }
      return state
    }
    default: {
      return state
    }
  }
}

export default attributes
