import { useEffect, useState } from 'react'
import { CtaLink, Head, Title, PaddedContainer } from '../../components/common'
import { useDispatch } from 'react-redux'
import { PlusCircleFilled, LoadingOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import { useParams, Link } from 'react-router-dom'
import { paths, features, actions as acts } from '../../constants'
import * as productConsts from '../../constants/product'
import { useStatus } from '../../reducers'
import { fetchAllProducts } from '../../actions/org'
import { useOrgNotFoundError } from '../../hooks/use-org-not-found-error'

const Products = () => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [products, setProducts] = useState(null)
  const status = useStatus(acts.FETCH_ALL_PRODUCTS)

  useOrgNotFoundError(status, 'load products', osid)

  useEffect(() => {
    dispatch(fetchAllProducts({ osid })).then(prods => setProducts(prods))
  }, [osid])

  let display = <LoadingOutlined />
  if (products) {
    display = products.map(p => (
      <div key={p.id}>
        <Tag>{productConsts.types[p.type].label}</Tag>
        <Link to={paths.org.PRODUCT(osid, p.id)}>{p.name}</Link>
      </div>
    ))
    if (display.length === 0) {
      display = <i>No products</i>
    }
  }

  return (
    <PaddedContainer>
      <Head>
        <Title>{features.product.name.plural}</Title>
        <CtaLink to={paths.org.NEW_PRODUCT(osid)}>
          <PlusCircleFilled /> Create {features.product.name.singular.toLowerCase()}
        </CtaLink>
      </Head>
      {display}
    </PaddedContainer>
  )
}

export default Products
