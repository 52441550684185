import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Modal, Create, Button, Select, Form, Input, Radio } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import * as actions from '../../../actions/admin'
import { UniqueError } from '../../../util/error'
import { clearStatus } from '../../../actions/status'
import * as urlUtils from '../../../util/url'
import { useStatus, useStatusMsg } from '../../../reducers'
import { actions as acts } from '../../../constants'
import Attr from '../../../components/Attr'
import InternalTagLabel from '../../../components/admin/InternalTagLabel'
import get from 'lodash.get'
const { Option } = Select

const genClpType = ({ internalTag, redirectUrl }) => {
  if (internalTag) {
    return 'page'
  } else if (redirectUrl) {
    return 'redirect'
  }
  return null
}

const clpTypeComplete = ({ clpType, internalTagId, redirectUrl }) => {
  if (clpType) {
    if (clpType === 'page') {
      return internalTagId !== null
    } else if (clpType === 'redirect') {
      return redirectUrl && redirectUrl.length > 1
    }
  }
  return false
}

const CreateModal = ({ complete, existingPage, isEdit = false }) => {
  const dispatch = useDispatch()
  const internalTags = useSelector(s => s.internalTags)
  const fetchStatus = useStatus(acts.FETCH_INTERNAL_TAGS)
  const createStatus = useStatus(acts.CREATE_CUSTOM_LANDING_PAGE)
  const updateStatus = useStatus(acts.UPDATE_CUSTOM_LANDING_PAGE)
  const [name, setName] = useState(existingPage && existingPage.name)
  const [description, setDescription] = useState(existingPage && existingPage.description)
  const [internalTagId, setInternalTagId] = useState(
    existingPage && get(existingPage, 'internalTag.id'),
  )
  const [urlSlug, setUrlSlug] = useState(existingPage && existingPage.urlSlug)
  const [redirectUrl, setRedirectUrl] = useState(existingPage && existingPage.redirectUrl)
  const [clpType, setClpType] = useState(existingPage && genClpType(existingPage))

  useEffect(() => {
    if (clpType === 'page') {
      setRedirectUrl(null)
    } else if (clpType === 'redirect') {
      setInternalTagId(null)
    }
  }, [clpType])

  useEffect(() => {
    dispatch(actions.fetchInternalTags())
    return () => {
      dispatch(clearStatus(acts.FETCH_INTERNAL_TAGS))
      dispatch(clearStatus(acts.CREATE_CUSTOM_LANDING_PAGE))
      dispatch(clearStatus(acts.UPDATE_CUSTOM_LANDING_PAGE))
    }
  }, [])

  const statusMsgs = {
    pending: `${isEdit ? 'Updating' : 'Creating'} custom landing page...`,
    error: err => {
      if (err instanceof UniqueError) {
        return `A custom landing page already exists with URL slug "${urlSlug}"`
      } else {
        return `Failed to ${isEdit ? 'update' : 'create'} custom landing page.`
      }
    },
    success: `Custom landing page ${isEdit ? 'updated' : 'created'}!`,
  }

  useStatusMsg(createStatus, statusMsgs)
  useStatusMsg(updateStatus, statusMsgs)

  const internalTagOptions = internalTags.map(it => (
    <Option key={it.id} value={it.id} title={it.name}>
      <InternalTagLabel {...it} />
    </Option>
  ))

  const formComplete = [
    name && name.length > 1,
    description && description.length > 1,
    clpTypeComplete({ clpType, internalTagId, redirectUrl }),
    urlSlug && urlSlug.length > 1,
  ].reduce((acc, curr) => curr && acc, true)

  const onOk = async () => {
    const attrs = {
      name,
      description,
      internalTagId,
      urlSlug,
      redirectUrl,
    }
    if (clpType === 'page') {
      delete attrs.redirectUrl
    } else if (clpType === 'redirect') {
      delete attrs.internalTagId
    }
    try {
      if (isEdit) {
        await dispatch(actions.updateCustomLandingPage({ ...attrs, id: existingPage.id }))
      } else {
        await dispatch(actions.createCustomLandingPage(attrs))
      }
      complete()
    } catch (err) {}
  }

  const restrictKeys = e => {
    const acceptedKeys = new RegExp('[a-zA-Z0-9-]')
    const pressedKey = String.fromCharCode(!e.charCode ? e.which : e.charCode)
    if (!acceptedKeys.test(pressedKey)) {
      e.preventDefault()
      return false
    }
  }

  return (
    <Modal
      title={`${isEdit ? 'Update' : 'Create new'} custom landing page`}
      visible={true}
      okText={isEdit ? 'Save' : 'Create'}
      okButtonProps={{ disabled: !formComplete }}
      onOk={onOk}
      onCancel={() => complete()}
      confirmLoading={createStatus.pending || false /* antd fails here on null */}
    >
      <Form>
        <Attr name="Name">
          <Input
            placeholder="e.g. Back to School"
            value={name}
            defaultValue={name}
            onChange={e => setName(e.target.value)}
          />
        </Attr>
        <Attr name="URL slug" description="Letters, numbers, and dashes only">
          <Input
            placeholder="back-to-school"
            onKeyPress={restrictKeys}
            value={urlSlug}
            defaultValue={urlSlug}
            onChange={e => setUrlSlug(e.target.value)}
            addonBefore={urlUtils.mainSite('')}
          />
        </Attr>
        <Attr name="Description">
          <Input.TextArea
            rows="4"
            value={description}
            defaultValue={description}
            onChange={e => setDescription(e.target.value)}
            placeholder="e.g. Get ready for a back to school tennis camp"
          />
        </Attr>
        <Attr
          name="Page or redirect?"
          description="Would you like a page to be generated from an internal tag, or simply redirect the URL slug to another URL?"
        >
          <Radio.Group value={clpType} onChange={e => setClpType(e.target.value)}>
            <Radio value="page">Page</Radio>
            <Radio value="redirect">Redirect</Radio>
          </Radio.Group>
        </Attr>
        {clpType === 'page' && (
          <Attr
            name="Internal tag"
            description="Only bookable programs tagged with the following tag will appear in the custom landing page"
          >
            <div>
              <Select
                showSearch
                style={{ minWidth: '200px' }}
                placeholder="Select an internal tag"
                defaultValue={internalTagId}
                optionFilterProp="children"
                loading={fetchStatus.pending}
                onSelect={value => setInternalTagId(value)}
                filterOption={(input, option) =>
                  option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {internalTagOptions}
              </Select>
            </div>
          </Attr>
        )}
        {clpType === 'redirect' && (
          <Attr name="Redirect" description="Must include http:// or https:// in the url">
            <Input
              placeholder="https://accesscourts.breakthelove.com"
              value={redirectUrl}
              defaultValue={redirectUrl}
              onChange={e => setRedirectUrl(e.target.value)}
            />
          </Attr>
        )}
      </Form>
    </Modal>
  )
}

export default CreateModal
