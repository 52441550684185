import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons'
import { Modal, Typography, Dropdown, Menu, Button, Tag, Table } from 'antd'

import CreateModal from './CreateModal'
import { actions as acts } from '../../../constants'
import * as actions from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import * as urlUtils from '../../../util/url'
import { useStatus, useStatusMsg } from '../../../reducers'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'
import { fetchCustomLandingPages } from '../../../actions/admin'
import { formatDateTimeTz } from '../../../util/time'

const columns = ({ editClp }) => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (val, record) => <span>{val}</span>,
  },
  {
    title: 'URL',
    dataIndex: 'urlSlug',
    key: 'urlSlug',
    render: (val, record) => {
      const url = urlUtils.mainSite(val)
      return <a href={url}>{url}</a>
    },
  },
  {
    title: 'Type',
    render: (val, record) => {
      if (record.redirectUrl) {
        return <Tag>Redirect</Tag>
      } else if (record.internalTag) {
        return <Tag>Internal Tag</Tag>
      }
    },
  },
  {
    title: 'Value',
    render: (val, record) => {
      if (record.redirectUrl) {
        return <a href={record.redirectUrl}>{record.redirectUrl}</a>
      } else if (record.internalTag) {
        return <Tag>{record.internalTag.name}</Tag>
      }
    },
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: 175,
    render: (val, record) => (
      <>
        <div>{val.name}</div>
        <div>
          <small>{formatDateTimeTz(record.createdAt)}</small>
        </div>
      </>
    ),
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: 10,
    render: (val, record) => (
      <Dropdown
        overlay={<ActionsMenu onEdit={() => editClp(record)} record={record} />}
        trigger={['click']}
      >
        <EllipsisOutlined style={{ fontSize: '1.75em', fontWeight: 'bold' }} />
      </Dropdown>
    ),
  },
]

const ActionsMenu = ({ onEdit, record }) => {
  const dispatch = useDispatch()
  const deleteStatus = useStatus(acts.DELETE_CUSTOM_LANDING_PAGE)
  useEffect(() => () => dispatch(clearStatus(acts.DELETE_CUSTOM_LANDING_PAGE)), [])
  const warning = () => {
    Modal.confirm({
      title: 'Confirm',
      okText: 'Delete custom landing page',
      okButtonProps: {
        type: 'danger',
        loading: deleteStatus.pending || false, // cannot be null
        disabled: deleteStatus.pending || false, // cannot be null
      },
      content: (
        <div>
          <p>Are you sure you want to delete custom landing page "{record.name}"?</p>
        </div>
      ),
      onOk: async () => await dispatch(actions.deleteCustomLandingPage(record.id)),
    })
  }
  return (
    <Menu>
      <Menu.Item key="0">
        <a onClick={onEdit}>Edit</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a onClick={warning}>
          <Typography.Text type="danger">Delete</Typography.Text>
        </a>
      </Menu.Item>
    </Menu>
  )
}

const CustomLandingPages = () => {
  const dispatch = useDispatch()
  const clps = useSelector(state => state.customLandingPages)
  const status = useStatus(acts.FETCH_CUSTOM_LANDING_PAGES)
  const [creating, setCreating] = useState(false)
  const [editing, setEditing] = useState(false)
  const [editClp, setEditClp] = useState(null) // clp to edit
  useEffect(() => {
    dispatch(fetchCustomLandingPages())
  }, [])

  useStatusMsg(status, {
    error: 'Failed to fetch custom landing pages',
  })

  if (status.pending) {
    return <LoadingOutlined />
  }

  const cols = columns({
    editClp: clp => {
      setCreating(false)
      setEditing(true)
      setEditClp(clp)
    },
  })

  return (
    <>
      {(editing || creating) && (
        <CreateModal
          isEdit={editing}
          existingPage={editClp}
          complete={() => {
            setCreating(false)
            setEditing(false)
            setEditClp(null)
          }}
        />
      )}
      <PageHeading>
        <PageTitle>Custom Landing Pages</PageTitle>
        <Button size="small" type="primary" onClick={() => setCreating(true)}>
          New
        </Button>
      </PageHeading>
      <Table
        rowKey="id"
        rowClassName="clp-row"
        columns={cols}
        dataSource={clps}
        pagination={false}
      />
    </>
  )
}

export default CustomLandingPages
