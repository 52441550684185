import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useParams, Link } from 'react-router-dom'
import { Head, Title, PaddedContainer, CtaLink } from '../../components/common'
import { useSelector, useDispatch } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { fetchLocations } from '../../actions/location'
import { features, actions as acts, paths } from '../../constants'
import { useStatus } from '../../reducers'
import { clearStatus } from '../../actions/status'
import { PlusCircleFilled } from '@ant-design/icons'
import Address from '../../components/Address'
import { useOrgNotFoundError } from '../../hooks/use-org-not-found-error'

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;
`

const LocationCard = styled(Link)`
  border-radius: ${props => props.theme.br[2]};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: white;
  box-shadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2);

  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
`

const DetailsContainer = styled.div`
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const AddressContainer = styled.div`
  color: ${props => props.theme.color.gray[3]};
  font-size: 12px;
  margin-bottom: ${props => props.theme.spacing[2]};
`

const LocationList = () => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const locations = useSelector(s => s.locations)
  const status = useStatus(acts.FETCH_LOCATIONS)

  useEffect(() => {
    dispatch(fetchLocations(osid))
    return () => dispatch(clearStatus(acts.FETCH_LOCATIONS))
  }, [])

  useOrgNotFoundError(status, 'fetch locations', osid)

  let display = <LoadingOutlined />

  let locs
  if (status.success) {
    locs = locations.map(l => {
      const numEvents = l.programLocations.length
      const stat =
        numEvents === 1
          ? `${numEvents} event is located here`
          : `${numEvents} events are located here`
      return (
        <LocationCard key={l.id} to={paths.org.LOCATION(osid, l.id)}>
          <DetailsContainer>
            <h3>{l.name}</h3>
            <AddressContainer>
              <Address address={l} />
            </AddressContainer>
            <div>{stat}</div>
          </DetailsContainer>
        </LocationCard>
      )
    })
  }
  if (locs) {
    display = <Container>{locs}</Container>
  }

  return (
    <PaddedContainer>
      <Head>
        <Title>{features.location.name.plural}</Title>
        <CtaLink to={paths.org.CREATE_LOCATION(osid)}>
          <PlusCircleFilled /> Create {features.location.name.singular.toLowerCase()}
        </CtaLink>
      </Head>
      {display}
    </PaddedContainer>
  )
}

export default LocationList
