import React, { useState, useEffect } from 'react'
import { Checkbox } from 'antd'
import { settingsNames } from '../constants/attribute'
import Attr from './Attr'
import get from 'lodash.get'

// Given an attr, returns true if it has a setting named "required" which is set to true, else returns false
// This works across all attribute setting types
const isRequired = attr => {
  const sn = settingsNames[attr.type]
  if (sn) {
    const settings = get(attr, sn, null)
    if (settings) {
      return get(settings, 'required', false)
    }
  }
  return false
}

const SelectAttributes = ({ onChange, allAttributes, attributes }) => {
  const [selectedAttrs, setSelectedAttrs] = useState(attributes)

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(selectedAttrs)
    }
  }, [selectedAttrs])

  if (!allAttributes || (Array.isArray(allAttributes) && allAttributes.length === 0)) {
    return <i>No attributes available</i>
  }

  const onCheckChange = (attribute, checkedValues) => {
    let newAttrs = []
    // append other attrs that are already checked
    if (Array.isArray(selectedAttrs)) {
      newAttrs = selectedAttrs.filter(a => a.attribute.id !== attribute.id)
    }
    newAttrs.push(
      ...checkedValues.map(cv => ({
        attribute,
        value: { id: cv },
      })),
    )
    setSelectedAttrs(newAttrs)
  }

  const attrForms = allAttributes.map(a => {
    const opts = a.values.map(v => ({
      label: v.name,
      value: v.id,
    }))
    // add already-checked attrs if they exist
    let defaults = null
    if (Array.isArray(selectedAttrs)) {
      defaults = selectedAttrs.filter(at => at.attribute.id === a.id).map(at => at.value.id)
    }
    return (
      <div key={a.id}>
        <Attr name={a.name} label={isRequired(a) ? '(required)' : null}>
          <div>
            <Checkbox.Group
              style={{
                width: '100%',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px',
              }}
              options={opts}
              onChange={v => onCheckChange(a, v)}
              defaultValue={defaults}
            />
          </div>
        </Attr>
      </div>
    )
  })
  return <div>{attrForms}</div>
}

export default SelectAttributes
