import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Select, Tag, Space } from 'antd'
import { paths, actions as acts } from '../constants'
import { useStatus, useStatusMsg } from '../reducers'
import { fetchTags } from '../actions/org'

const { Option } = Select

export const TagItem = ({ tag, onClose, link = false }) => {
  const { osid } = useParams()
  const closable = typeof onClose === 'function'

  const tagElem = (
    <Tag color={tag.color ? tag.color : null} closable={closable} onClose={onClose}>
      {tag.name}
    </Tag>
  )
  if (link) {
    return <Link to={paths.org.ORG_TAG(osid, tag.name)}>{tagElem}</Link>
  }
  return tagElem
}

export const TagList = ({ tags, onRemove, link = false }) => {
  const removable = typeof onRemove === 'function'
  let display = <i>No tags</i>
  if (Array.isArray(tags) && tags.length > 0) {
    display = tags.map(tag => (
      <TagItem key={tag.id} tag={tag} onClose={removable ? () => onRemove(tag) : null} />
    ))
  }
  return (
    <Space size={[0, 8]} wrap>
      {display}
    </Space>
  )
}

export const TagSelect = ({ value, onChange, ...props }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const [tags, setTags] = useState({}) // map of tag id to tag object
  const [val, setVal] = useState(null)
  const status = useStatus(acts.FETCH_ORG_TAGS)

  useEffect(() => {
    dispatch(fetchTags({ osid })).then(tgs => {
      setTags(
        tgs.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {}),
      )
    })
  }, [osid])

  useEffect(() => {
    // convert value to tag id when tag list or value changes
    if (tags && Object.keys(tags).length > 0 && value) {
      setVal(value.id)
    }
  }, [tags, value])

  useStatusMsg(status, { error: 'Failed to fetch tags' })

  if (!status.success) {
    return 'Loading...'
  }

  const opts = Object.keys(tags).map(id => {
    const t = tags[id]
    return (
      <Option key={t.id} value={t.id}>
        {t.name}
      </Option>
    )
  })

  const handleChange = tid => {
    setVal(tid)
    if (onChange) {
      onChange(tags[tid])
    }
  }

  return (
    <Select
      value={val}
      onChange={handleChange}
      showSearch
      filterOption={(input, option) => {
        const inp = input.toLowerCase()
        const value = option.children
        // value[0] references the tag's name
        const matchesOption = value[0].toLowerCase().indexOf(inp) >= 0
        return matchesOption || option.value.toLowerCase().indexOf(inp) >= 0
      }}
      placeholder="Select a tag"
      {...props}
    >
      {opts}
    </Select>
  )
}
