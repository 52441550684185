import React, { useEffect, useState, useReducer, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Affix, Form, Checkbox, message, Dropdown, Modal, Button, Menu, Input } from 'antd'
import { Comment } from '@ant-design/compatible'
import * as paginationUtils from '../util/pagination'
import ReactHtmlParser from 'react-html-parser'
import {
  PushpinOutlined,
  PushpinFilled,
  EditOutlined,
  DeleteOutlined,
  EllipsisOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons'
import * as timeUtils from '../util/time'
import * as postUtils from '../util/post'
import { paths } from '../constants'
import { Cta as BTLButton } from '../components/common'
import Editor from '../components/Editor'
import Avatar, { BTLAvatar } from './Avatar'
import Attr from './Attr'
import SingleImageEditor from './SingleImageEditor'
import ImagePreview from './ImagePreview'

const { TextArea } = Input

const PostCardContainer = styled.div`
  max-width: 475px;
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  background-color: ${props => props.theme.color.white};
  border-radius: ${props => props.theme.br[2]};
  padding-left: ${props => props.theme.spacing[3]};
  padding-right: ${props => props.theme.spacing[3]};
  padding-top: ${props => props.theme.spacing[3]};
  padding-bottom: ${props => props.theme.spacing[3]};
  margin-right: ${props => props.theme.spacing[3]};
  margin-bottom: ${props => props.theme.spacing[3]};
`

const PostCardDate = ({ date }) => {
  return <small>{timeUtils.formatDateTimeTz(date)}</small>
}

const PostCardPinned = ({ post }) => {
  if (post.pinned) {
    return (
      <div style={{ color: 'rgba(29, 28, 29, 0.7)', fontSize: '12px' }}>
        <PushpinFilled style={{ color: 'rgb(232, 145, 45)' }} /> Pinned
      </div>
    )
  }
  return null
}

const HudContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${props => props.theme.color.gray[0]};
  padding: 0.5em 1em;
  border-radius: ${props => props.theme.br[2]}
  margin-bottom: .5em;
`

// onAddComment must return some sort of truthy value for the comment input area to be cleared
const CommentBox = ({
  post,
  onAddComment,
  replyTo,
  setReplyTo,
  editComment,
  setEditComment,
  onSaveEditedComment,
}) => {
  const theme = useContext(ThemeContext)
  const currentUser = useSelector(s => s.self)
  const [comment, setComment] = useState(null)
  const [isAdminComment, setIsAdminComment] = useState(true)

  let avatar = <BTLAvatar size="md" style={{ marginRight: '0.5em' }} />
  if (!isAdminComment) {
    avatar = <Avatar user={currentUser} size="sm" style={{ marginRight: '0.5em' }} />
  }

  const resetInputs = () => {
    setComment(null)
    setIsAdminComment(true)
  }

  useEffect(() => {
    if (editComment) {
      setComment(editComment.comment)
      setIsAdminComment(editComment.isAdminComment)
    } else {
      resetInputs()
    }
  }, [editComment])

  const onCancelCommentEdit = () => {
    setComment(null)
    setEditComment(null)
  }

  const onSaveCommentEdit = () => {
    if (typeof onSaveEditedComment === 'function') {
      onSaveEditedComment({ id: editComment.id, comment, isAdminComment }).then(success => {
        if (success) {
          resetInputs()
          setEditComment(null)
        }
      })
    } else {
      onCancelCommentEdit()
    }
  }

  const onComment = () => {
    if (typeof onAddComment === 'function') {
      const newComment = {
        comment,
        isAdminComment,
      }
      if (replyTo) {
        newComment.parentComment = replyTo.id
      }
      onAddComment(newComment).then(success => {
        if (success) {
          setComment(null)
          setReplyTo(null)
        }
      })
    }
  }

  let boxHud = null
  if (replyTo) {
    boxHud = (
      <HudContainer>
        <div>
          <div style={{ color: theme.color.green[2] }}>
            Replying to{' '}
            <span style={{ fontWeight: theme.font.weight[6] }}>
              {replyTo.isAdminComment ? 'Break the Love' : replyTo.creator.name}
            </span>
          </div>
          <div>{replyTo.comment}</div>
        </div>
        <div>
          <CloseCircleOutlined style={{ fontSize: '16px' }} onClick={() => setReplyTo(null)} />
        </div>
      </HudContainer>
    )
  }
  if (editComment) {
    boxHud = (
      <HudContainer>
        <div style={{ color: theme.color.green[2] }}>Editing comment</div>
        <div>
          <CloseCircleOutlined style={{ fontSize: '16px' }} onClick={() => setEditComment(null)} />
        </div>
      </HudContainer>
    )
  }

  let boxContent = (
    <div>
      {boxHud}
      <TextArea
        rows={1}
        value={comment}
        onChange={e => setComment(e.target.value)}
        placeholder="Add a comment..."
        style={{ marginBottom: '1em', border: 'none' }}
      />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
        <Checkbox checked={isAdminComment} onChange={e => setIsAdminComment(e.target.checked)}>
          Comment as admin
        </Checkbox>
        <BTLButton disabled={!comment || comment.length === 0} onClick={onComment}>
          Comment
        </BTLButton>
      </div>
    </div>
  )
  if (editComment) {
    boxContent = (
      <div>
        {boxHud}
        <TextArea
          rows={1}
          value={comment}
          onChange={e => setComment(e.target.value)}
          style={{ marginBottom: '1em', border: 'none' }}
        />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
          <Checkbox checked={isAdminComment} onChange={e => setIsAdminComment(e.target.checked)}>
            Comment as admin
          </Checkbox>
          <Button type="link" onClick={onCancelCommentEdit}>
            Cancel
          </Button>
          <BTLButton disabled={!comment || comment.length === 0} onClick={onSaveCommentEdit}>
            Save
          </BTLButton>
        </div>
      </div>
    )
  }
  return (
    <Affix offsetBottom={10}>
      <Comment
        avatar={avatar}
        content={boxContent}
        style={{
          backgroundColor: 'white',
          padding: '0 1em',
          borderRadius: theme.br[2],
          boxShadow:
            'rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px',
        }}
      />
    </Affix>
  )
}

const CommentCard = ({ comment, onClickReply, onClickEdit, onClickDelete }) => {
  const currentUser = useSelector(s => s.self)
  const [hideReplies, setHideReplies] = useState(false)

  let creatorName = <Link to={paths.admin.USER(comment.creator.id)}>{comment.creator.name}</Link>
  let avatar = (
    <Link
      to={paths.admin.USER(comment.creator.id)}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <Avatar user={comment.creator} size="sm" style={{ marginRight: '0.5em' }} />
    </Link>
  )
  if (comment.isAdminComment) {
    creatorName = 'Break the Love'
    avatar = <BTLAvatar size="md" style={{ marginRight: '0.5em' }} />
  }

  const onReply = replyComment => {
    if (typeof onClickReply === 'function') {
      onClickReply(replyComment)
    }
  }

  const onEdit = editComment => {
    if (typeof onClickEdit === 'function') {
      onClickEdit(editComment)
    }
  }

  const onDelete = deleteComment => {
    if (typeof onClickDelete === 'function') {
      onClickDelete(deleteComment)
    }
  }

  const hasReplies = comment.replies.length > 0
  let replies = null
  if (!hideReplies) {
    replies = comment.replies.map(reply => {
      return (
        <CommentCard
          key={reply.id}
          comment={reply}
          onClickReply={deeperReply => onReply(deeperReply ? deeperReply : reply)}
          onClickEdit={deeperReply => onEdit(deeperReply ? deeperReply : reply)}
          onClickDelete={deeperReply => onDelete(deeperReply ? deeperReply : reply)}
        />
      )
    })
  }

  const commentActions = []
  if (!comment.deleted) {
    commentActions.push(<span onClick={() => onReply(comment)}>Reply</span>)
  }
  if (hasReplies) {
    const numReplies = postUtils.countComments({ comments: comment.replies })
    commentActions.push(
      <span onClick={() => setHideReplies(prev => !prev)}>
        {hideReplies ? 'Show' : 'Hide'} {numReplies} repl{numReplies === 1 ? 'y' : 'ies'}
      </span>,
    )
  }
  if (!comment.deleted && comment.creator.id === currentUser.id) {
    const handleActionMenu = e => {
      if (e.key === 'edit') {
        onEdit(comment)
      } else if (e.key === 'delete-post') {
        Modal.confirm({
          title: `Delete comment?`,
          okText: 'Delete',
          okButtonProps: {
            type: 'primary',
            danger: true,
          },
          onOk: () => onDelete(comment),
        })
      }
    }
    const actionMenu = (
      <Menu onClick={handleActionMenu}>
        <Menu.Item key="edit">
          <EditOutlined /> Edit
        </Menu.Item>
        <Menu.Item danger key="delete-post">
          <DeleteOutlined /> Delete
        </Menu.Item>
      </Menu>
    )
    commentActions.push(
      <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
        <EllipsisOutlined style={{ fontSize: '16px' }} />
      </Dropdown>,
    )
  }

  return (
    <Comment
      author={creatorName}
      avatar={avatar}
      content={comment.comment}
      actions={commentActions}
      datetime={`${
        comment.isAdminComment ? `by ${comment.creator.name} on ` : ''
      }${timeUtils.formatDateTimeTz(comment.createdAt)}`}
    >
      {replies}
    </Comment>
  )
}

export const PostWithComments = ({
  post,
  comments,
  onAddComment,
  onSaveEditedComment,
  onDeleteComment,
}) => {
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const [replyTo, setReplyTo] = useState(null)
  const [editComment, setEditComment] = useState(null)

  const onEditStart = editCmnt => {
    setReplyTo(null)
    setEditComment(editCmnt)
  }

  const onReplyStart = replyCmnt => {
    setReplyTo(replyCmnt)
    setEditComment(null)
  }

  let commentDisplay = null
  if (Array.isArray(comments) && comments.length > 0) {
    commentDisplay = comments.map(cmt => (
      <CommentCard
        key={cmt.id}
        comment={cmt}
        onClickReply={onReplyStart}
        onClickDelete={onDeleteComment}
        onClickEdit={onEditStart}
      />
    ))
  }

  return (
    <div style={{ padding: '1.5em' }}>
      <PostHeadInfo post={post} />
      <PostContent post={post} />
      <div style={{ borderRadius: '0.25rem', margin: '0.5em', overflow: 'hidden', maxHeight: 500 }}>
        {post.images && post.images.length > 0 ? (
          <ImagePreview
            asset={post.images[0].image}
            width="auto"
            height="150px"
            style={{ height: '100%', width: '100%' }}
          />
        ) : (
          ''
        )}
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: '1.5em',
          marginBottom: '1em',
          fontWeight: theme.font.weight[7],
        }}
      >
        {postUtils.countComments({ comments })} comments
      </div>
      <div>{commentDisplay}</div>
      <CommentBox
        post={post}
        onAddComment={onAddComment}
        replyTo={replyTo}
        setReplyTo={setReplyTo}
        editComment={editComment}
        setEditComment={setEditComment}
        onSaveEditedComment={onSaveEditedComment}
      />
    </div>
  )
}

export const PostHeadInfo = ({ post, actionMenu = null }) => {
  let creatorName = (
    <Link
      to={paths.admin.USER(post.creator.id)}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      {post.creator.name}
    </Link>
  )
  let avatar = (
    <Link
      to={paths.admin.USER(post.creator.id)}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <Avatar user={post.creator} size="sm" style={{ marginRight: '0.5em' }} />
    </Link>
  )
  if (post.isAdminPost) {
    creatorName = 'Break the Love'
    avatar = <BTLAvatar size="md" style={{ marginRight: '0.5em' }} />
  }

  return (
    <>
      <div style={{ marginBottom: '0.5em' }}>
        <PostCardPinned post={post} />
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {avatar}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {creatorName}
            <PostCardDate date={post.createdAt} />
          </div>
        </div>
        {actionMenu}
      </div>
    </>
  )
}

const PostContent = ({ post, style }) => {
  return (
    <div style={{ marginTop: '1em', marginLeft: '1em', ...style }}>
      {ReactHtmlParser(post.content)}
    </div>
  )
}

const PostCard = ({ post, pinPost, unpinPost, deletePost, editPostPath, viewPostPath }) => {
  const history = useHistory()

  const handleActionMenu = e => {
    if (e.key === 'edit') {
      history.push(editPostPath(post.id))
    } else if (e.key === 'delete-post') {
      Modal.confirm({
        title: `Delete post?`,
        okText: 'Delete',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => deletePost(post.id),
      })
    } else if (e.key === 'toggle-pin') {
      if (post.pinned) {
        unpinPost(post.id)
      } else {
        pinPost(post.id)
      }
    }
  }

  const actionMenu = (
    <Menu onClick={handleActionMenu}>
      <Menu.Item key="edit">
        <EditOutlined /> Edit
      </Menu.Item>
      <Menu.Item key="toggle-pin">
        <PushpinOutlined /> {post.pinned ? 'Unpin' : 'Pin'}
      </Menu.Item>
      <Menu.Item danger key="delete-post">
        <DeleteOutlined /> Delete
      </Menu.Item>
    </Menu>
  )

  const actionDropdown = (
    <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
      <Button size="small">
        <EllipsisOutlined />
      </Button>
    </Dropdown>
  )

  return (
    <PostCardContainer>
      <PostHeadInfo post={post} actionMenu={actionDropdown} />
      <PostContent post={post} />
      <div style={{ maxHeight: 400, overflow: 'hidden', borderRadius: '0.25rem' }}>
        {post.images && post.images.length > 0 ? (
          <ImagePreview asset={post.images[0].image} style={{ width: '100%', height: '100%' }} />
        ) : null}
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '1em' }}>
        <Link to={viewPostPath(post.id)}>View comments</Link>
      </div>
    </PostCardContainer>
  )
}

export const Posts = ({
  pinned,
  posts,
  hasMorePosts,
  loadMorePosts,
  pinPost,
  unpinPost,
  deletePost,
  editPostPath,
  viewPostPath,
}) => {
  let pinnedElems = []
  let postElems = []
  if (Array.isArray(pinned) && pinned.length > 0) {
    pinnedElems = pinned.map(p => (
      <PostCard
        key={p.id}
        post={p}
        pinPost={pinPost}
        unpinPost={unpinPost}
        deletePost={deletePost}
        editPostPath={editPostPath}
        viewPostPath={viewPostPath}
      />
    ))
  }
  if (Array.isArray(posts) && posts.length > 0) {
    postElems = posts.map(p => (
      <PostCard
        key={p.id}
        post={p}
        pinPost={pinPost}
        unpinPost={unpinPost}
        deletePost={deletePost}
        editPostPath={editPostPath}
        viewPostPath={viewPostPath}
      />
    ))
  }
  if (postElems.length === 0 && pinnedElems.length === 0) {
    return (
      <div>
        <i>No posts</i>
      </div>
    )
  }
  return (
    <div>
      {pinnedElems.length > 0 && <Attr name="Pinned posts">{pinnedElems}</Attr>}
      <Attr name="Posts">{postElems}</Attr>
      {hasMorePosts && (
        <Button size="small" onClick={() => loadMorePosts()}>
          Load more
        </Button>
      )}
      {!hasMorePosts && <i>No more posts</i>}
    </div>
  )
}

const Label = styled.span`
  color: rgba(0, 0, 0, 0.65);
  font-weight: ${props => props.theme.font.weight[7]};
`

const postInitState = {
  content: null,
  isAdminPost: true,
  pinned: false,
  notify: false,
  images: [],
}

export const PostEditor = ({ onChange, post, allowNotify = false, notifyCopy }) => {
  if (!post) {
    if (typeof onChange === 'function') {
      onChange(postInitState)
    }
    return null
  }

  const { content, isAdminPost, pinned, notify, images } = post

  const change = update => {
    if (typeof onChange === 'function') {
      onChange({ ...post, ...update })
    }
  }

  return (
    <Form layout="vertical">
      <Form.Item name="content" label={<Label>Post</Label>} initialValue={content}>
        <Editor value={content} onChange={content => change({ content })} />
      </Form.Item>
      <Form.Item name="Image">
        {/* TODO Make multiple image editor */}
        <SingleImageEditor
          actionText="Select image"
          onChange={img => change({ images: [img] })}
          img={images.length > 0 && images[0] !== null ? images[0].image : ''}
        />
      </Form.Item>
      <Form.Item name="pinned" initialValue={pinned}>
        <div style={{ display: 'grid' }}>
          <Checkbox checked={pinned} onChange={e => change({ pinned: e.target.checked })}>
            <Label>Pinned</Label>
          </Checkbox>
          <span style={{ paddingLeft: '24px' }}>
            Pinned posts always show up at the top of the posts feed until unpinned
          </span>
        </div>
      </Form.Item>
      <Form.Item name="isAdminPost" initialValue={isAdminPost}>
        <div style={{ display: 'grid' }}>
          <Checkbox checked={isAdminPost} onChange={e => change({ isAdminPost: e.target.checked })}>
            <Label>Post as admin</Label>
          </Checkbox>
          <span style={{ paddingLeft: '24px' }}>
            Posting as admin will make the post appear to be created by Break the Love instead of
            the currently logged in user
          </span>
        </div>
      </Form.Item>
      {allowNotify && (
        <Form.Item name="notify" initialValue={notify}>
          <div style={{ display: 'grid' }}>
            <Checkbox checked={notify} onChange={e => change({ notify: e.target.checked })}>
              <Label>Send notification emails</Label>
            </Checkbox>
            <span style={{ paddingLeft: '24px' }}>{notifyCopy}</span>
          </div>
        </Form.Item>
      )}
    </Form>
  )
}

const postsInitialState = {
  posts: null,
  pinned: null,
}

const postsReducer = (state, action) => {
  switch (action.type) {
    case 'DELETE_POST':
      return {
        ...state,
        posts: state.posts.filter(p => p.id !== action.payload),
        pinned: state.pinned.filter(p => p.id !== action.payload),
      }
    case 'LOAD_MORE_POSTS':
      return {
        ...state,
        posts: [...state.posts, ...action.payload],
      }
    case 'SET_PINNED': {
      return {
        ...state,
        pinned: action.payload,
      }
    }
    case 'SET_POSTS': {
      return {
        ...state,
        posts: action.payload,
      }
    }
    case 'PIN_POST': {
      const post = state.posts.find(p => p.id === action.payload)
      const pinned = [...state.pinned]
      if (post) {
        pinned.push({ ...post, pinned: true })
        pinned.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      }
      return {
        ...state,
        posts: state.posts.filter(p => p.id !== action.payload),
        pinned,
      }
    }
    case 'UNPIN_POST': {
      const post = state.pinned.find(p => p.id === action.payload)
      const posts = [...state.posts]
      if (post) {
        posts.push({ ...post, pinned: false })
        posts.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
      }
      return {
        ...state,
        pinned: state.pinned.filter(p => p.id !== action.payload),
        posts,
      }
    }
  }
}

export const usePosts = (
  orgId,
  { loadPosts, loadPinned, pinPost, unpinPost, deletePost, editPostPath, viewPostPath },
) => {
  const [state, dp] = useReducer(postsReducer, postsInitialState)
  const dispatch = useDispatch()
  const [hasMorePosts, setHasMorePosts] = useState(true)
  const [postsCursor, setPostsCursor] = useState(null)
  const [cursorLimit, setCursorLimit] = useState(20)

  return {
    posts: state.posts,
    pinned: state.pinned,
    hasMorePosts,
    editPostPath,
    viewPostPath,
    pinPost: postId => {
      return dispatch(pinPost(postId))
        .then(() => {
          dp({ type: 'PIN_POST', payload: postId })
          message.success('Post pinned')
        })
        .catch(e => {
          console.log(e)
          message.error('Failed to pin post')
        })
    },
    unpinPost: postId => {
      return dispatch(unpinPost(postId))
        .then(() => {
          dp({ type: 'UNPIN_POST', payload: postId })
          message.success('Post unpinned')
        })
        .catch(e => {
          console.log(e)
          message.error('Failed to unpin post')
        })
    },
    deletePost: postId => {
      return dispatch(deletePost(postId))
        .then(() => {
          dp({ type: 'DELETE_POST', payload: postId })
          message.success('Post deleted')
        })
        .catch(e => {
          console.log(e)
          message.error('Failed to delete post')
        })
    },
    loadPinned: () => {
      return dispatch(loadPinned())
        .then(posts => dp({ type: 'SET_PINNED', payload: posts }))
        .catch(e => {
          console.log(e)
          message.error('Failed to load pinned posts')
        })
    },
    loadPosts: () => {
      return dispatch(loadPosts(null, cursorLimit))
        .then(res => {
          const { data, nextCursor, hasMore } = paginationUtils.deserializePage(res)
          setHasMorePosts(hasMore)
          setPostsCursor(nextCursor)
          dp({ type: 'SET_POSTS', payload: data })
        })
        .catch(e => {
          console.log(e)
          message.error('Failed to load posts')
        })
    },
    loadMorePosts: () => {
      return dispatch(loadPosts(postsCursor, cursorLimit))
        .then(res => {
          const { data, nextCursor, hasMore } = paginationUtils.deserializePage(res)
          setHasMorePosts(hasMore)
          setPostsCursor(nextCursor)
          dp({ type: 'LOAD_MORE_POSTS', payload: data })
        })
        .catch(e => {
          console.log(e)
          message.error('Failed to load more posts')
        })
    },
  }
}
