import dayjs from 'dayjs'
import { useContext, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useParams, useHistory } from 'react-router-dom'
import { DownOutlined, EllipsisOutlined, LoadingOutlined } from '@ant-design/icons'
import { ThemeContext } from 'styled-components'
import { Button, Noner, ID, Title, Cta } from '../../../components/common'
import { useStatusMsg, useStatus } from '../../../reducers'
import { clearStatus } from '../../../actions/status'
import {
  Menu,
  Dropdown,
  DatePicker,
  Input,
  Radio,
  Form,
  message,
  Modal,
  Breadcrumb,
  Table,
  Tag,
} from 'antd'
import { actions as acts, paths } from '../../../constants'
import Attr from '../../../components/Attr'
import { RedemptionTerm, AdminPackageLink, AdminPackageSetLink } from '../../../components/package'
import { ConstSelect } from '../../../components/consts'
import { AdminOrgLink, OrgSelect } from '../../../components/org'
import { AdminVenueLink, AdminVenueSelect } from '../../../components/venue'
import {
  fetchPackageSet,
  fetchPackageSets,
  createPackageSet,
  updatePackageSet,
  deletePackageSet,
} from '../../../actions/admin'
import { centsToDollars } from '../../../util/format'
import { formatDateTimeTz } from '../../../util/time'
import * as sortUtils from '../../../util/sort'

const packageCols = () => [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    render: (val, record) => (
      <div>
        <AdminPackageLink pkg={record} />
        <div>
          <small>{record.id}</small>
        </div>
      </div>
    ),
  },
  {
    title: 'Order',
    key: 'order',
    dataIndex: 'order',
    sorter: (a, b) => a.order - b.order,
    defaultSortOrder: 'ascend',
  },
  {
    title: 'Price',
    key: 'priceCents',
    dataIndex: 'priceCents',
    render: (val, record) => <div>{centsToDollars(val)}</div>,
  },
  {
    title: 'Purchase status',
    key: 'purchaseStatus',
    dataIndex: 'purchaseStatus',
    render: (val, record) => <Tag>{val}</Tag>,
  },
  {
    title: 'Expiration Date',
    key: 'expirationDate',
    dataIndex: 'expirationDate',
    render: (val, record) => <Noner>{val}</Noner>,
  },
  {
    title: 'Redemption Term',
    key: 'redemptionDateStart',
    dataIndex: 'redemptionDateStart',
    render: (val, record) => <RedemptionTerm {...record} />,
  },
  {
    title: 'Created',
    key: 'createdAt',
    dataIndex: 'createdAt',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => <div>{formatDateTimeTz(val)}</div>,
  },
]

export const PackageSet = () => {
  const { setId } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const [set, setSet] = useState(null)
  const [editing, setEditing] = useState(false)
  const theme = useContext(ThemeContext)

  useEffect(() => {
    dispatch(fetchPackageSet({ setId })).then(s => setSet(s))
    return () => dispatch(clearStatus(acts.FETCH_PACKAGE_SET))
  }, [])

  if (!set) {
    return <LoadingOutlined />
  }

  const onDelete = () => {
    history.push(paths.admin.PACKAGE_SETS())
  }

  return (
    <div>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={paths.admin.PACKAGE_SETS()}>Packages sets</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{set.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          display: 'flex',
          marginTop: theme.spacing[3],
          marginBottom: theme.spacing[3],
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Title>{set.name}</Title>
        <Dropdown
          overlay={
            <PackageSetActionsMenu set={set} onEdit={() => setEditing(true)} onDelete={onDelete} />
          }
          trigger={['click']}
        >
          <Button>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      </div>
      <div>
        <Attr name="ID">
          <ID>{set.id}</ID>
        </Attr>
        <Attr name="Description">
          <div>{set.description}</div>
        </Attr>
        <Attr name="Expiration date">
          <div>
            <Noner none="Never expires">{set.expirationDate}</Noner>
          </div>
        </Attr>
        <Attr name="Visibility">
          <div>
            <Tag>{set.visibility}</Tag>
          </div>
        </Attr>
        <Attr name="Org">
          <div>
            <AdminOrgLink org={set.org}>
              <Noner none="No org">{set.org ? set.org.name : null}</Noner>
            </AdminOrgLink>
          </div>
        </Attr>
        <Attr name="Venue">
          <div>
            <AdminVenueLink venue={set.venue}>
              <Noner none="No venue">{set.venue ? set.venue.name : null}</Noner>
            </AdminVenueLink>
          </div>
        </Attr>
        <Attr name="Packages">
          <div style={{ marginBottom: theme.spacing[2] }}>{set.packages?.length || 0} packages</div>
          <Table
            size="small"
            columns={packageCols()}
            dataSource={set.packages}
            pagination={false}
          />
        </Attr>
      </div>
      {editing && (
        <EditPackageSet
          set={set}
          complete={updatedSet => {
            if (updatedSet) {
              setSet(updatedSet)
            }
            setEditing(false)
          }}
        />
      )}
    </div>
  )
}

const PackageSetActionsMenu = ({ set, onEdit, onDelete }) => {
  const dispatch = useDispatch()
  const deleteStatus = useStatus(acts.DELETE_PACKAGE_SET)
  useEffect(() => () => dispatch(clearStatus(acts.DELETE_PACKAGE_SET)), [])

  useStatusMsg(deleteStatus, {
    pending: 'Deleting package set...',
    error: e => `Error: ${e}`,
    success: 'Package set deleted',
  })

  const handleActionsMenu = e => {
    if (e.key === 'edit') {
      if (typeof onEdit === 'function') {
        onEdit(set)
      }
    } else if (e.key === 'delete') {
      Modal.confirm({
        title: 'Delete package set?',
        okText: 'Delete',
        okButtonProps: {
          danger: true,
          loading: deleteStatus.pending || false,
          disabled: deleteStatus.pending || false,
        },
        content: (
          <div>
            <p>Are you sure you want to delete the package set "{set.name}"?</p>
          </div>
        ),
        onOk: () => {
          return dispatch(deletePackageSet({ id: set.id })).then(() => {
            if (typeof onDelete === 'function') {
              onDelete(set)
            }
          })
        },
      })
    }
  }

  return (
    <Menu onClick={handleActionsMenu}>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="delete" danger>
        Delete
      </Menu.Item>
    </Menu>
  )
}

export const packageSetCols = ({ onEdit, onDelete }) => {
  const compStr = sortUtils.compareStrs({ nullsFirst: true })
  return [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      sorter: (a, b) => compStr(a.name, b.name),
      render: (val, record) => <Link to={paths.admin.PACKAGE_SET(record.id)}>{val}</Link>,
    },
    {
      title: 'Visibility',
      key: 'visibility',
      dataIndex: 'visibility',
      render: (val, record) => <Tag>{val}</Tag>,
    },
    {
      title: 'Expiration date',
      key: 'expirationDate',
      dataIndex: 'expirationDate',
      render: (val, record) => <Noner none="Never expires">{val}</Noner>,
    },
    {
      title: 'Org',
      key: 'org',
      dataIndex: 'org',
      sorter: (a, b) => compStr(a.org?.name, b.org?.name),
      render: (val, record) => {
        if (!val) {
          return <Noner none="No org" />
        }
        return <AdminOrgLink org={val} />
      },
    },
    {
      title: 'Venue',
      key: 'venue',
      dataIndex: 'venue',
      sorter: (a, b) => compStr(a.venue?.name, b.venue?.name),
      render: (val, record) => {
        if (!val) {
          return <Noner none="No venue" />
        }
        return <AdminVenueLink venue={val} />
      },
    },
    {
      title: '# of packages',
      key: 'packages',
      dataIndex: 'packages',
      render: (val, record) => <Noner none="None">{val?.length}</Noner>,
    },
    {
      title: 'Created',
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
      render: (val, record) => <div>{formatDateTimeTz(val)}</div>,
    },
    {
      title: 'Actions',
      key: 'id',
      render: (val, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <Dropdown
              overlay={<PackageSetActionsMenu set={record} onEdit={onEdit} onDelete={onDelete} />}
              trigger={['click']}
            >
              <Button>
                <EllipsisOutlined />
              </Button>
            </Dropdown>
          </div>
        )
      },
    },
  ]
}

const initState = {
  name: null,
  org: null,
  venue: null,
  visibility: null,
  description: null,
  expirationDate: null,
}

const PackageSetEditor = ({ packageSet, onChange }) => {
  packageSet = packageSet ?? initState
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const theme = useContext(ThemeContext)
  const [ownerType, setOwnerType] = useState(packageSet?.venue ? 'venue' : 'org')
  const [expires, setExpires] = useState(packageSet?.expirationDate ? true : false)

  const change = update => changer({ ...packageSet, ...update })

  const { name, venue, org, visibility, description, expirationDate } = packageSet

  const owner = venue ?? org

  const onChangeOwner = val => {
    if (ownerType === 'venue') {
      change({ org: null, venue: val?.id })
    } else if (ownerType === 'org') {
      change({ org: val?.id, venue: null })
    }
  }

  const onChangeOwnerType = e => {
    setOwnerType(e.target.value)
    change({ org: null, venue: null })
  }

  const onChangeExpires = e => {
    setExpires(e.target.value)
    change({ expirationDate: null })
  }

  return (
    <Form layout="vertical">
      <Form.Item required name="name" label="Name" initialValue={name}>
        <Input placeholder="Package set name" onChange={e => change({ name: e.target.value })} />
      </Form.Item>
      <Form.Item name="description" label="Description" initialValue={description}>
        <Input.TextArea onChange={e => change({ description: e.target.value })} />
      </Form.Item>
      <Form.Item required name="owner" label="Owner">
        <div style={{ marginBottom: theme.spacing[2] }}>
          <Radio.Group
            optionType="button"
            buttonStyle="solid"
            value={ownerType}
            onChange={onChangeOwnerType}
          >
            <Radio value="org">Org</Radio>
            <Radio value="venue">Venue</Radio>
          </Radio.Group>
        </div>
        {ownerType === 'org' && (
          <OrgSelect value={owner} onChange={onChangeOwner} placeholder="Select an org" />
        )}
        {ownerType === 'venue' && (
          <AdminVenueSelect value={owner} onChange={onChangeOwner} placeholder="Select a venue" />
        )}
      </Form.Item>
      <Form.Item required name="visibility" label="Visibility" initialValue={visibility}>
        <ConstSelect
          name="package-set"
          constKey="visibilityOptions"
          value={visibility}
          onChange={val => change({ visibility: val })}
        />
      </Form.Item>
      <Form.Item required name="expiration" label="Expiration" initialValue={expirationDate}>
        <label
          htmlFor="expiration"
          style={{ display: 'inline-block', marginBottom: theme.spacing[2] }}
        >
          If expiration is set, this package set will only visible on the site up to the expiration
          date
        </label>
        <Radio.Group
          optionType="button"
          buttonStyle="solid"
          value={expires}
          onChange={onChangeExpires}
        >
          <Radio value={false}>Never expires</Radio>
          <Radio value={true}>Expires</Radio>
        </Radio.Group>
        <div style={{ marginTop: theme.spacing[2] }}>
          {expires && (
            <DatePicker
              value={expirationDate && dayjs(expirationDate, 'YYYY-MM-DD')}
              format="YYYY-MM-DD"
              onChange={(_, dateString) => change({ expirationDate: dateString })}
            />
          )}
        </div>
      </Form.Item>
    </Form>
  )
}

export const EditPackageSet = ({ set, complete }) => {
  const completer = typeof complete === 'function' ? complete : () => {}
  const dispatch = useDispatch()
  const [editSet, setEditSet] = useState(set)
  const status = useStatus(acts.UPDATE_PACKAGE_SET)

  useStatusMsg(status, {
    pending: 'Updating...',
    error: e => `Error: ${e}`,
    success: 'Package set updated',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.UPDATE_PACKAGE_SET))
    }
  }, [])

  const onOk = () => {
    // Required fields
    const mfs = ['name', 'visibility'].filter(k => !editSet || !editSet[k])
    if (!editSet || mfs.length > 0) {
      const labels = mfs.join(', ')
      const msg = `${labels} ${mfs.length > 1 ? 'are' : 'is'} required`
      message.error(msg)
      return
    }

    const nextSet = { ...editSet }
    if (nextSet.org && typeof nextSet.org === 'object') {
      nextSet.org = nextSet.org.id
    }
    if (nextSet.venue && typeof nextSet.venue === 'object') {
      nextSet.venue = nextSet.venue.id
    }

    dispatch(updatePackageSet({ id: set.id, packageSet: nextSet })).then(updatedSet =>
      completer(updatedSet),
    )
  }

  return (
    <Modal
      title="Update package set"
      open={true}
      okText="Save"
      okButtonProps={{ disabled: Boolean(status.pending), loading: Boolean(status.pending) }}
      onCancel={() => completer()}
      onOk={onOk}
    >
      <PackageSetEditor packageSet={editSet} onChange={set => setEditSet(set)} />
    </Modal>
  )
}

const CreatePackageSet = ({ complete }) => {
  const completer = typeof complete === 'function' ? complete : () => {}
  const dispatch = useDispatch()
  const [packageSet, setPackageSet] = useState(null)
  const status = useStatus(acts.CREATE_PACKAGE_SET)

  useStatusMsg(status, {
    pending: 'Creating...',
    error: e => `Error: ${e}`,
    success: 'Package set created',
  })
  const onOk = () => {
    // Required fields
    const mfs = ['name', 'visibility'].filter(k => !packageSet || !packageSet[k])
    if (!packageSet || mfs.length > 0) {
      const labels = mfs.join(', ')
      const msg = `${labels} ${mfs.length > 1 ? 'are' : 'is'} required`
      message.error(msg)
      return
    }

    dispatch(createPackageSet({ packageSet })).then(createdSet => completer(createdSet))
  }

  return (
    <Modal
      title="Create package set"
      open={true}
      okText="Create"
      okButtonProps={{ disabled: Boolean(status.pending), loading: Boolean(status.pending) }}
      onCancel={completer}
      onOk={onOk}
    >
      <PackageSetEditor packageSet={packageSet} onChange={set => setPackageSet(set)} />
    </Modal>
  )
}

export const PackageSets = () => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [sets, setSets] = useState(null)
  const [creating, setCreating] = useState(false)
  const [editing, setEditing] = useState(null)

  useEffect(() => {
    dispatch(fetchPackageSets()).then(s => setSets(s))
  }, [])

  if (!sets) {
    return <LoadingOutlined />
  }

  const onDelete = deletedSet => {
    setSets(prev => prev.filter(s => s.id !== deletedSet.id))
  }

  return (
    <>
      <div>
        <div
          style={{
            display: 'flex',
            marginTop: theme.spacing[3],
            marginBottom: theme.spacing[3],
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>Package Sets</Title>
          <Cta onClick={() => setCreating(true)}>New</Cta>
        </div>
        <div>
          <Table
            size="small"
            columns={packageSetCols({ onEdit: setEditing, onDelete })}
            dataSource={sets}
            pagination={false}
          />
        </div>
      </div>
      {creating && (
        <CreatePackageSet
          complete={set => {
            if (set) {
              setSets(prev => [...prev, set])
            }
            setCreating(false)
          }}
        />
      )}
      {editing && (
        <EditPackageSet
          set={editing}
          complete={updatedSet => {
            if (updatedSet) {
              setSets(prev => {
                const next = [...prev]
                const idx = next.findIndex(s => s.id === updatedSet.id)
                if (idx !== -1) {
                  next[idx] = updatedSet
                }
                return next
              })
            }
            setEditing(null)
          }}
        />
      )}
    </>
  )
}
