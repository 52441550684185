import React from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import NycPermitAccounts, { NycPermitAccount } from './NycPermitAccounts'
import NycPermitCards, { NycPermitCard } from './NycPermitCards'
import PurchasablePermits from './PurchasablePermits'
import UserPermits from './UserPermits'
import UserPermit from './UserPermit'
import { paths } from '../../../constants'

const Home = () => {
  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>Permits</Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <Link to={paths.admin.USER_PERMITS()}>User permits</Link>
      </div>
      <div>
        <Link to={paths.admin.PURCHASABLE_PERMITS()}>Purchasable permits</Link>
      </div>
      <div>
        <Link to={paths.admin.NYC_PERMIT_ACCOUNTS()}>NYC permit accounts</Link>
      </div>
      <div>
        <Link to={paths.admin.NYC_PERMIT_CARDS()}>NYC permit cards</Link>
      </div>
    </div>
  )
}

const Permits = () => {
  return (
    <Switch>
      <Route exact path={'/admin/permits/user-permits/:id'} component={UserPermit} />
      <Route exact path={'/admin/permits/user-permits'} component={UserPermits} />
      <Route exact path={'/admin/permits/nyc-permit-accounts/:id'} component={NycPermitAccount} />
      <Route exact path={'/admin/permits/nyc-permit-accounts'} component={NycPermitAccounts} />
      <Route exact path={'/admin/permits/nyc-permit-cards/:id'} component={NycPermitCard} />
      <Route exact path={'/admin/permits/nyc-permit-cards'} component={NycPermitCards} />
      <Route exact path={'/admin/permits/purchasable'} component={PurchasablePermits} />
      <Route exact path={'/admin/permits'} component={Home} />
    </Switch>
  )
}

export default Permits
