import { useContext, useState, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import { EditFilled, LoadingOutlined } from '@ant-design/icons'
import { fetchConstsByName, updateClubSettings } from '../../actions/org'
import { useParams } from 'react-router-dom'
import { useStatus, useStatusMsg } from '../../reducers'
import { useDispatch } from 'react-redux'
import { clearStatus } from '../../actions/status'
import { actions as acts } from '../../constants'
import { Settings } from '../../components/settings'
import { Button, Cta } from '../../components/common'
import { ClubContext } from './Club'
import { MediumInfo } from '../../components/info'

const ClubSettings = () => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const { club, setClub } = useContext(ClubContext)
  const [allSettings, setAllSettings] = useState(null)
  const [editing, setEditing] = useState(false)
  const [settings, setSettings] = useState(club.settings)
  const status = {
    save: useStatus(acts.UPDATE_CLUB_SETTINGS),
    fetchSettings: useStatus(acts.FETCH_CONSTS_BY_NAME),
  }

  useEffect(() => {
    dispatch(fetchConstsByName({ osid, name: 'club' })).then(consts =>
      setAllSettings(consts.settings),
    )

    return () => {
      dispatch(clearStatus(acts.UPDATE_CLUB_SETTINGS))
      dispatch(clearStatus(acts.FETCH_CONSTS_BY_NAME))
    }
  }, [])

  useStatusMsg(status.save, {
    error: 'Failed to save settings',
    success: 'Settings saved',
  })

  useStatusMsg(status.fetchSettings, {
    error: 'Failed to fetch settings',
  })

  if (status.fetchSettings.pending) {
    return <LoadingOutlined />
  }

  if (!allSettings || Object.keys(allSettings).length === 0) {
    return <i>No settings available</i>
  }

  const onSaveSettings = () => {
    dispatch(updateClubSettings({ osid, clubId: club.id, settings }))
      .then(updatedClub => setClub(prev => ({ ...prev, settings: updatedClub.settings })))
      .catch(() => {})
  }

  const onCancelEdit = () => {
    setEditing(false)
    setSettings(club.settings)
  }

  let buttons = (
    <Button onClick={() => setEditing(true)}>
      <EditFilled /> Edit
    </Button>
  )
  if (editing) {
    buttons = (
      <div>
        <Button
          style={{ marginRight: theme.spacing[2] }}
          disabled={status.save.pending}
          onClick={onCancelEdit}
        >
          Cancel
        </Button>
        <Cta onClick={onSaveSettings} disabled={status.save.pending}>
          {status.save.pending ? 'Saving...' : 'Save'}
        </Cta>
      </div>
    )
  }
  return (
    <MediumInfo title="Settings" style={{ marginTop: 0 }} button={buttons}>
      <div style={{ maxWidth: '700px' }}>
        <Settings
          allSettings={allSettings}
          settings={settings}
          editing={editing}
          onChange={updatedSettings => setSettings(updatedSettings)}
        />
      </div>
    </MediumInfo>
  )
}

export default ClubSettings
