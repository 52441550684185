import React, { useEffect, useReducer } from 'react'
import { Radio } from 'antd'
import { InputGroup, SelectContainer, Title, Desc } from './step'
import UserSelect from '../../../components/UserSelect'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import Attr from '../../../components/Attr'

// Determines the value of info.hasHost
// Protects against cases where info.hasHost does not exist
// (i.e. when program is no longer a draft)
const hasHostValue = program => {
  if (program.draft && program.info) {
    return program.info.hasHost
  } else {
    if (program.host) {
      return true
    }
    return false
  }
  return null
}

const initState = program => ({
  host: program.host ? program.host : undefined,
  info: {
    hasHost: hasHostValue(program),
  },
})

const reducer = (state, action) => ({ ...state, ...action })

const Host = ({ clinic, onChange }) => {
  const program = clinic
  const [state, dp] = useReducer(reducer, initState(program))

  useEffect(() => {
    if (typeof onChange === 'function') {
      const data = {
        ...state,
        host: state.host ? state.host.id : null,
      }
      if (program.draft) {
        onChange(data)
      } else {
        onChange({ ...data, info: undefined })
      }
    }
  }, [state])

  const onSelectChange = e => {
    dp({ info: { hasHost: e.target.value } })
    if (!e.target.value) {
      dp({ host: undefined })
    }
  }

  return (
    <>
      <InputGroup>
        <Title>Host</Title>
        <Desc>
          <p>
            Select a user to host this {lower(typeLabel(program))}. If no host is selected this{' '}
            {lower(typeLabel(program))} will appear to be hosted by the current organization.
          </p>
          <p>
            Note: if the host is a pro, their hosted events also appear on the pro's personal event
            search page.
          </p>
        </Desc>
        <SelectContainer>
          <Radio.Group onChange={onSelectChange} value={state.info.hasHost}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </SelectContainer>
      </InputGroup>
      {state.info.hasHost && (
        <UserSelect
          placeholder="Select a user"
          style={{ width: '100%' }}
          value={state.host}
          onChange={user => dp({ host: user })}
        />
      )}
    </>
  )
}

const Viewer = ({ program }) => {
  return (
    <Attr>
      {program.host && program.host.name}
      {!program.host && <i>No host</i>}
    </Attr>
  )
}

const step = Host

const complete = ({ draft, info, host }) => {
  if (draft) {
    if (!info || typeof info.hasHost !== 'boolean' || (info.hasHost && !host)) {
      return false
    }
  }
  return true
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
