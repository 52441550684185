import React, { useState, useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled, { ThemeContext } from 'styled-components'
import Attr from '../../../components/Attr'
import { deleteProShopItem } from '../../../actions/admin'
import * as urlUtils from '../../../util/url'
import { actions as acts } from '../../../constants'
import { useStatus, useStatusMsg } from '../../../reducers'
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons'
import { Dropdown, Modal, Menu, Button } from 'antd'
import AddShopItemModal from './AddShopItemModal'
import ReactHtmlParser from 'react-html-parser'

const Card = styled.div`
  border: 1px solid hsla(0, 0%, 0%, 0.2);
  background-color: ${props => props.theme.color.white};
  border-radius: ${props => props.theme.br[2]};
  padding-left: ${props => props.theme.spacing[3]};
  padding-right: ${props => props.theme.spacing[3]};
  padding-top: ${props => props.theme.spacing[3]};
  padding-bottom: ${props => props.theme.spacing[3]};
  max-width: ${props => props.theme.width[6]};
`

const Shop = ({ pro, shop, createShop, upsertItem, deleteItem }) => {
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [addingItem, setAddingItem] = useState(false)
  const [editingItem, setEditingItem] = useState(false)
  const [editItem, setEditItem] = useState(null)
  const status = {
    deleteItem: useStatus(acts.DELETE_SHOP_ITEM),
  }

  useStatusMsg(status.deleteItem, {
    error: 'Failed to delete item',
  })

  if (!shop) {
    return (
      <div>
        <Button type="primary" onClick={() => createShop()}>
          Create shop
        </Button>
      </div>
    )
  }

  const onDeleteItem = item => () => {
    dispatch(deleteProShopItem(pro.page.id, item.id)).then(deleteItem(item))
  }

  const handleMenuClick = item => e => {
    if (e.key === 'delete') {
      Modal.confirm({
        title: 'Delete item?',
        okText: 'Delete',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: onDeleteItem(item),
      })
    }
  }
  const actionMenu = item => (
    <Menu onClick={handleMenuClick(item)}>
      <Menu.Item key="delete">Delete</Menu.Item>
    </Menu>
  )

  let items = []
  if (shop.items) {
    items = shop.items.map(i => (
      <Card key={i.id}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'top' }}>
          <div
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setEditItem(i)
              setEditingItem(true)
            }}
          >
            <h3>{i.name}</h3>
            <div>{ReactHtmlParser(i.description)}</div>
          </div>
          <Dropdown overlay={actionMenu(i)} trigger={['click']}>
            <EllipsisOutlined style={{ fontSize: '1.5em' }} />
          </Dropdown>
        </div>
      </Card>
    ))
  }

  const onAddShopItem = item => {
    if (item) {
      upsertItem(item)
    }
    setAddingItem(false)
    setEditingItem(false)
    setEditItem(null)
  }

  const onDeleteShopItem = item => {
    deleteItem(item)
  }

  return (
    <div>
      <div style={{ marginBottom: '1em' }}>
        <a href={urlUtils.mainSiteProShop(pro.page.url)}>View pro shop</a>
      </div>
      <Attr name="Name">
        <div>{shop.name}</div>
      </Attr>
      <Attr name="Description">
        <div>{shop.description}</div>
      </Attr>
      <Attr name="Items">
        <div
          style={{
            marginBottom: '2em',
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr 1fr',
            gridGap: theme.spacing[3],
          }}
        >
          {items}
        </div>
        <div>
          <Button type="primary" onClick={() => setAddingItem(true)}>
            <PlusOutlined /> Add item
          </Button>
        </div>
      </Attr>
      {(addingItem || editingItem) && (
        <AddShopItemModal
          pro={pro}
          isEdit={editingItem}
          existingItem={editItem}
          complete={onAddShopItem}
        />
      )}
    </div>
  )
}

export default Shop
