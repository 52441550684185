import fetchData, { fetchCsv } from '../util/fetch'
import { paths, actions as acts, urls } from '../constants'
import act from './act'

export const clearDraftProgram = () => dispatch => dispatch({ type: acts.CLEAR_DRAFT_PROGRAM })

export const fetchDraftProgram = (osid, id) => async dispatch => {
  dispatch({ type: acts.FETCH_DRAFT_PROGRAM_PENDING, payload: id })
  try {
    const program = await fetchData({ url: urls.org.PROGRAM(osid, id) })
    dispatch({ type: acts.FETCH_DRAFT_PROGRAM_SUCCESS, payload: program })
    return program
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_DRAFT_PROGRAM_FAILURE, payload: err })
  }
}

export const fetchCurrentProgram = (osid, id) => async dispatch => {
  dispatch({ type: acts.FETCH_CURRENT_PROGRAM_PENDING, payload: { osid, id } })
  try {
    const clinic = await fetchData({ url: urls.org.PROGRAM(osid, id) })
    dispatch({ type: acts.FETCH_CURRENT_PROGRAM_SUCCESS, payload: clinic })
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_CURRENT_PROGRAM_FAILURE, payload: err })
  }
}

export const fetchUpcomingPrograms = osid => async dispatch => {
  dispatch({ type: acts.FETCH_UPCOMING_PROGRAMS_PENDING })
  try {
    const programs = await fetchData({ url: urls.org.UPCOMING_PROGRAMS(osid) })
    dispatch({ type: acts.FETCH_UPCOMING_PROGRAMS_SUCCESS, payload: programs })
    return programs
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_UPCOMING_PROGRAMS_FAILURE, payload: err })
  }
}

export const fetchPastPrograms = osid => async dispatch => {
  dispatch({ type: acts.FETCH_PAST_PROGRAMS_PENDING })
  try {
    const programs = await fetchData({ url: urls.org.PAST_PROGRAMS(osid) })
    dispatch({ type: acts.FETCH_PAST_PROGRAMS_SUCCESS, payload: programs })
    return programs
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_PAST_PROGRAMS_FAILURE, payload: err })
  }
}

export const fetchDraftPrograms = osid => async dispatch => {
  dispatch({ type: acts.FETCH_DRAFT_PROGRAMS_PENDING })
  try {
    const programs = await fetchData({ url: urls.org.DRAFT_PROGRAMS(osid) })
    dispatch({ type: acts.FETCH_DRAFT_PROGRAMS_SUCCESS, payload: programs })
    return programs
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.FETCH_DRAFT_PROGRAMS_FAILURE, payload: err })
  }
}

// A name should be the minimum provided
// name, description, readBeforeBooking, type, subtype
export const createProgram = (osid, attrs) => async dispatch => {
  const args = {
    url: urls.org.CREATE_PROGRAM(osid),
    type: 'POST',
    body: attrs,
  }
  dispatch({ type: acts.CREATE_PROGRAM_DRAFT_PENDING, payload: { osid, attrs } })
  try {
    const newProgram = await fetchData(args)
    dispatch({ type: acts.CREATE_PROGRAM_DRAFT_SUCCESS, payload: newProgram })
    return newProgram
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.CREATE_PROGRAM_DRAFT_FAILURE, payload: err })
  }
}

// This action only updates the server if updateServer is set to true
export const updateProgram =
  (osid, classId, attrs, updateServer = true) =>
  async dispatch => {
    dispatch({ type: acts.UPDATE_PROGRAM_PENDING, payload: { id: classId, ...attrs } })
    try {
      if (updateServer) {
        let body = { attrs }
        if (attrs.images) {
          const fd = new FormData()
          attrs.images.forEach(i => fd.append('image', i))
          fd.append('attrs', JSON.stringify(attrs))
          body = fd
        }
        const res = await fetchData({
          type: 'PATCH',
          url: urls.org.PROGRAM(osid, classId),
          body,
          isUpload: !!attrs.images,
        })
        dispatch({ type: acts.UPDATE_PROGRAM_SUCCESS, payload: res })
        return res
      } else {
        dispatch({ type: acts.UPDATE_PROGRAM_SUCCESS, payload: attrs })
        return attrs
      }
    } catch (err) {
      console.log(err)
      dispatch({ type: acts.UPDATE_PROGRAM_FAILURE, payload: err })
    }
  }

export const deleteProgram = (osid, programId) => async dispatch => {
  const args = {
    type: 'DELETE',
    url: urls.org.PROGRAM(osid, programId),
  }
  dispatch({ type: acts.DELETE_PROGRAM_PENDING, payload: { osid, programId } })
  try {
    await fetchData(args)
    dispatch({ type: acts.DELETE_PROGRAM_SUCCESS, payload: { osid, programId } })
  } catch (err) {
    console.log(err)
    dispatch({ type: acts.DELETE_PROGRAM_FAILURE, payload: err })
    throw err
  }
}

export const cancelBooking = (osid, programId, bookingId) => async dispatch => {
  try {
    dispatch({ type: acts.CANCEL_PROGRAM_BOOKING_PENDING, payload: { osid, programId, bookingId } })
    await fetchData({
      type: 'POST',
      url: urls.org.CANCEL_BOOKING(osid, programId, bookingId),
    })
    dispatch({ type: acts.CANCEL_PROGRAM_BOOKING_SUCCESS, payload: { osid, programId, bookingId } })
    return Promise.resolve()
  } catch (err) {
    dispatch({ type: acts.CANCEL_PROGRAM_BOOKING_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const changeBookingNote = (osid, programId, bookingId, note) => async dispatch => {
  try {
    dispatch({
      type: acts.CHANGE_BOOKING_NOTE_PENDING,
      payload: { osid, programId, bookingId, note },
    })
    await fetchData({
      type: 'POST',
      url: urls.org.PROGRAM_BOOKING_NOTE(osid, programId, bookingId),
      body: { note },
    })
    dispatch({
      type: acts.CHANGE_BOOKING_NOTE_SUCCESS,
      payload: { osid, programId, bookingId, note },
    })
    return Promise.resolve()
  } catch (err) {
    dispatch({ type: acts.CHANGE_BOOKING_NOTE_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const finalizeDraft = (osid, id) => async dispatch => {
  dispatch({ type: acts.FINALIZE_DRAFT_PROGRAM_PENDING, payload: { osid, id } })
  try {
    const program = await fetchData({
      type: 'POST',
      url: urls.org.FINALIZE_DRAFT_PROGRAM(osid, id),
    })
    dispatch({ type: acts.FINALIZE_DRAFT_PROGRAM_SUCCESS, payload: program })
    return program
  } catch (err) {
    dispatch({ type: acts.FINALIZE_DRAFT_PROGRAM_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const duplicateProgram = (osid, programId, attrs) => async dispatch => {
  dispatch({ type: acts.DUPLICATE_PROGRAM_PENDING, payload: { programId, attrs } })
  try {
    let body = { attrs }
    if (attrs.images) {
      const fd = new FormData()
      attrs.images.forEach(i => fd.append('image', i))
      fd.append('attrs', JSON.stringify(attrs))
      body = fd
    }
    const program = await fetchData({
      type: 'POST',
      url: urls.org.DUPLICATE_PROGRAM(osid, programId),
      body,
      isUpload: !!attrs.images,
    })
    dispatch({ type: acts.DUPLICATE_PROGRAM_SUCCESS, payload: program })
    return program
  } catch (err) {
    dispatch({ type: acts.DUPLICATE_PROGRAM_FAILURE, payload: err })
    console.log(err)
    throw err
  }
}

export const addToWaitlist = act(
  acts.ADD_TO_PROGRAM_WAITLIST,
  ({ osid, programId, userId, notes }) =>
    fetchData({
      type: 'POST',
      url: urls.org.PROGRAM_WAITLIST(osid, programId),
      body: { userId, notes },
    }),
)

export const updateWaitlistUser = act(
  acts.UPDATE_WAITLIST_USER,
  ({ osid, programId, userId, notes }) =>
    fetchData({
      type: 'PATCH',
      url: urls.org.PROGRAM_WAITLIST_USER(osid, programId, userId),
      body: { notes },
    }),
)

export const removeFromWaitlist = act(
  acts.REMOVE_FROM_PROGRAM_WAITLIST,
  ({ osid, programId, userId }) =>
    fetchData({
      type: 'DELETE',
      url: urls.org.PROGRAM_WAITLIST_USER(osid, programId, userId),
    }),
)

export const downloadProgramBookingsCsv = act(
  acts.DOWNLOAD_PROGRAM_BOOKINGS_CSV,
  ({ osid, programId }) => fetchCsv({ url: urls.org.PROGRAM_BOOKINGS_CSV(osid, programId) }),
)
