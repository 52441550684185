import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Input } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { InputGroup, Title, Desc } from './step'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import Attr from '../../../components/Attr'

const InclusionOptions = ({ inclusions, setInclusionOptions }) => {
  const update = (id, values) => {
    const newOpts = [...inclusions]
    const idx = newOpts.findIndex(o => o.id === id)
    newOpts.splice(idx, 1, { ...newOpts[idx], ...values })
    setInclusionOptions(newOpts)
  }

  let currNewId = inclusions.filter(no => no.id.indexOf('new-') !== -1).length

  const newOption = () => ({
    id: `new-${currNewId++}`,
    description: '',
  })

  const remove = id => {
    setInclusionOptions(inclusions.filter(o => o.id !== id))
  }

  const onChangeDescription = (e, opt) => {
    const val = e.target.value
    update(opt.id, { description: val })
  }

  const opts = inclusions.map(opt => (
    <Row key={opt.id}>
      <Col span={24} style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
        <Input
          type="text"
          size="large"
          defaultValue={opt.description}
          onChange={e => onChangeDescription(e, opt)}
          value={opt.description}
        />
        <MinusCircleOutlined onClick={() => remove(opt.id)} style={{ marginLeft: '8px' }} />
      </Col>
    </Row>
  ))

  return (
    <InputGroup>
      {opts}
      <Button
        type="dashed"
        style={{ width: '100%' }}
        onClick={() => setInclusionOptions([...inclusions, newOption()])}
      >
        <PlusOutlined /> Add option
      </Button>
    </InputGroup>
  )
}

const Inclusions = ({ onChange, clinic }) => {
  const [inclusions, setInclusionOptions] = useState(clinic.inclusions)

  useEffect(() => {
    if (typeof onChange === 'function') {
      const opts = inclusions
        .map(opt => {
          if (opt.id.indexOf('new-') !== -1) {
            if (opt.description === '') {
              return false
            }
            return { ...opt, id: undefined }
          }
          return opt
        })
        .filter(Boolean)
      onChange({ inclusions: opts })
    }
  }, [inclusions])

  return (
    <>
      <InputGroup>
        <Title>What's Included</Title>
        <Desc>
          Enter a few items the customer will receive or experience in this{' '}
          {lower(typeLabel(clinic))}?
        </Desc>
      </InputGroup>
      <InclusionOptions inclusions={inclusions} setInclusionOptions={setInclusionOptions} />
    </>
  )
}

const Viewer = ({ program }) => {
  return (
    <Attr>
      {program.inclusions.map(opt => (
        <div key={opt.id}>
          <span>{opt.description}</span>
        </div>
      ))}
    </Attr>
  )
}

const step = Inclusions

const complete = ({ inclusions }) => {
  return inclusions.length >= 1 && inclusions.reduce((acc, curr) => acc || curr.description, false)
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
