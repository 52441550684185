import { actions as acts } from '../constants'

const initState = {
  id: null,
  sid: null,
  name: null,
  createdAt: null,
  updatedAt: null,
}

const org = (state = initState, { type, payload }) => {
  switch (type) {
    case acts.SET_ORG_SUCCESS:
      return payload
  }
  return state
}

export default org
