import { useEffect, useState, useContext } from 'react'
import { ThemeContext } from 'styled-components'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { useStatus, useStatusMsg } from '../../reducers'
import { actions as acts } from '../../constants'
import { clearStatus } from '../../actions/status'
import { addBenefitProgram, updateBenefitProgram, removeBenefitProgram } from '../../actions/org'
import { PlusOutlined } from '@ant-design/icons'
import { Noner, Cta as CtaButton, Button } from '../../components/common'
import Attr from '../../components/Attr'
import { BeneficiaryGroupCheckbox, BenefitSelect } from '../../components/benefit'
import {
  Input,
  Radio,
  Typography,
  Menu,
  Button as AntButton,
  Dropdown,
  Tag,
  Modal,
  Table,
  message,
} from 'antd'
import { EllipsisOutlined } from '@ant-design/icons'
import Container from './Container'

const cols = (program, onEdit) => [
  {
    title: 'Benefit',
    dataIndex: 'benefit',
    key: 'benefit',
    render: (val, record) => <div>{val.name}</div>,
  },
  {
    title: 'Checkin instructions',
    dataIndex: 'checkinInstructions',
    key: 'checkinInstructions',
    render: (val, record) => {
      return (
        <div>
          <Noner>{val}</Noner>
        </div>
      )
    },
  },
  {
    title: 'Exclusive groups',
    dataIndex: 'exclusiveGroups',
    key: 'exclusiveGroups',
    render: (val, record) => {
      let display = <Noner>{null}</Noner>
      if (Array.isArray(val) && val.length > 0) {
        display = val.map(eg => <Tag>{eg.beneficiaryGroup.name}</Tag>)
      }
      return <div>{display}</div>
    },
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: 5,
    render: (val, record) => (
      <Dropdown
        overlay={<BenefitActionsMenu program={program} benefitProgram={record} onEdit={onEdit} />}
        trigger={['click']}
      >
        <AntButton type="text" style={{ display: 'flex', alignItems: 'center' }}>
          <EllipsisOutlined style={{ fontSize: '1.75em', fontWeight: 'bold' }} />
        </AntButton>
      </Dropdown>
    ),
  },
]

const BenefitActionsMenu = ({ program, benefitProgram, onEdit }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()

  const confirmRemove = () => {
    Modal.confirm({
      title: 'Confirm remove',
      content: (
        <div>
          Remove {program.name} from the {benefitProgram.benefit.name} benefit?
        </div>
      ),
      okText: 'Remove',
      okButtonProps: { danger: true },
      onOk: () =>
        dispatch(
          removeBenefitProgram({
            osid,
            programId: program.id,
            benefitProgramId: benefitProgram.id,
          }),
        ),
    })
  }

  return (
    <Menu>
      <Menu.Item onClick={() => onEdit(benefitProgram)}>
        <Typography.Text>Edit</Typography.Text>
      </Menu.Item>
      <Menu.Item onClick={confirmRemove}>
        <Typography.Text type="danger">Remove benefit</Typography.Text>
      </Menu.Item>
    </Menu>
  )
}

const initState = {
  program: null,
  benefit: null,
  checkinInstructions: null,
  exclusiveGroups: null,
}

const BenefitProgramEditor = ({ benefitEditable = true, onChange, benefitProgram }) => {
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [exclusivity, setExclusivity] = useState(
    Array.isArray(benefitProgram?.exclusiveGroups) && benefitProgram.exclusiveGroups.length > 0
      ? 'specific-groups'
      : 'all-groups',
  )

  useEffect(() => {
    change({})
  }, [exclusivity])

  const change = update => {
    const next = { ...benefitProgram, ...update }
    if (exclusivity === 'all-groups') {
      next.exclusiveGroups = null
    } else {
      next.exclusiveGroups = next.exclusiveGroups?.map(eg =>
        typeof eg === 'string' ? eg : eg.beneficiaryGroup.id,
      )
    }
    changer(next)
  }

  if (!benefitProgram) {
    change(initState)
    return
  }

  const { benefit, program, checkinInstructions, exclusiveGroups } = benefitProgram

  const hasBenefit = Boolean(benefit)

  return (
    <div>
      <Attr name="Benefit">
        {benefitEditable && (
          <BenefitSelect
            benefit={benefit}
            onChange={newBenefit => change({ benefit: newBenefit })}
            style={{ width: '100%' }}
            autoFocus
          />
        )}
        {!benefitEditable && <div>{benefit.name}</div>}
      </Attr>
      {hasBenefit && (
        <div>
          <Attr
            name="Checkin instructions"
            description="Any special checkin instructions for the venue operator?"
          >
            <Input.TextArea
              value={checkinInstructions}
              onChange={e => change({ checkinInstructions: e.target.value })}
            />
          </Attr>
          <Attr name="Exclusivity" description="Which beneficiary groups can book this program?">
            <div>
              <Radio.Group
                defaultValue="all-groups"
                buttonStyle="solid"
                value={exclusivity}
                onChange={e => setExclusivity(e.target.value)}
              >
                <Radio.Button value="all-groups">All groups</Radio.Button>
                <Radio.Button value="specific-groups">Specific groups</Radio.Button>
              </Radio.Group>
            </div>
          </Attr>
        </div>
      )}
      {exclusivity === 'specific-groups' && (
        <BeneficiaryGroupCheckbox
          benefitId={benefit.id}
          value={exclusiveGroups?.map(eg => (typeof eg === 'string' ? eg : eg.beneficiaryGroup.id))}
          onChange={groupIds => change({ exclusiveGroups: groupIds })}
        />
      )}
    </div>
  )
}

const EditBenefitModal = ({ benefitProgram, complete }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const program = useSelector(s => s.currentProgram)
  const [bp, setBp] = useState(benefitProgram)
  const status = useStatus(acts.UPDATE_BENEFIT_PROGRAM)
  const completer = typeof complete === 'function' ? complete : () => {}

  useEffect(() => {
    return () => dispatch(clearStatus(acts.UPDATE_BENEFIT_PROGRAM))
  }, [])

  useStatusMsg(status, {
    pending: 'Saving benefit program...',
    error: 'Failed update benefit program',
    success: 'Benefit program updated',
  })

  const onSave = () => {
    dispatch(
      updateBenefitProgram({
        osid,
        programId: program.id,
        benefitProgramId: bp.id,
        checkinInstructions: bp.checkinInstructions,
        exclusiveGroups: bp.exclusiveGroups?.map(eg =>
          typeof eg === 'string' ? eg : eg.beneficiaryGroup.id,
        ),
      }),
    ).then(completer)
  }
  return (
    <Modal
      title="Edit benefit program"
      visible={true}
      onOk={onSave}
      okText="Save"
      onCancel={() => completer()}
    >
      <BenefitProgramEditor
        benefitProgram={bp}
        onChange={updatedBp => setBp(updatedBp)}
        benefitEditable={false}
      />
    </Modal>
  )
}

const AddToBenefitModal = ({ complete }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const program = useSelector(s => s.currentProgram)
  const completer = typeof complete === 'function' ? complete : () => {}
  const status = useStatus(acts.ADD_BENEFIT_PROGRAM)
  const [benefitProgram, setBenefitProgram] = useState(null)

  useEffect(() => {
    return () => dispatch(clearStatus(acts.ADD_BENEFIT_PROGRAM))
  }, [])

  useStatusMsg(status, {
    pending: 'Adding program to benefit...',
    error: 'Failed to add program to benefit',
    success: 'Program added to benefit',
  })

  const onAdd = () => {
    if (!benefitProgram.benefit) {
      message.error('Benefit required')
      return
    }
    dispatch(
      addBenefitProgram({
        osid,
        programId: program.id,
        benefitId: benefitProgram.benefit.id,
        checkinInstructions: benefitProgram.checkinInstructions,
        exclusiveGroups: benefitProgram.exclusiveGroups,
      }),
    ).then(completer)
  }

  return (
    <Modal
      title="Add program to benefit"
      visible={true}
      onOk={onAdd}
      okText="Add benefit"
      onCancel={() => completer()}
    >
      <BenefitProgramEditor
        benefitProgram={benefitProgram}
        onChange={bp => setBenefitProgram(bp)}
      />
    </Modal>
  )
}

const Benefits = () => {
  const program = useSelector(s => s.currentProgram)
  const [addingBenefit, setAddingBenefit] = useState(false)
  const [editing, setEditing] = useState(null)

  const onEdit = benefitProgram => setEditing(benefitProgram)

  return (
    <Container>
      <h1>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <span>Benefits</span>
          <CtaButton onClick={() => setAddingBenefit(true)}>
            <PlusOutlined /> Add to benefit
          </CtaButton>
        </div>
      </h1>
      <Table
        rowKey="id"
        columns={cols(program, onEdit)}
        dataSource={program.associatedBenefits}
        pagination={false}
      />
      {addingBenefit && <AddToBenefitModal complete={() => setAddingBenefit(false)} />}
      {editing && <EditBenefitModal benefitProgram={editing} complete={() => setEditing(null)} />}
    </Container>
  )
}

export default Benefits
