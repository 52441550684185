import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions as acts, paths } from '../../../constants'
import urlUtils from '../../../util/url'
import programUtils from '../../../util/program'
import { quickSearchPrograms } from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import { useStatus, useStatusMsg } from '../../../reducers'
import Search from './search'
import { InfoTags } from '../../../components/program'
import { Noner } from '../../../components/common'

const columns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
    render: (val, record) => {
      return (
        <div>
          {val}
          <InfoTags program={record} style={{ fontSize: '10px' }} />
        </div>
      )
    },
  },
  {
    title: 'Time',
    key: 'date',
    dataIndex: 'date',
    render: (val, record) => {
      const dateSummary = programUtils.dateSummary(record)
      const timeSummary = programUtils.timeSummary(record)
      return (
        <div>
          <div>
            <Noner none="No date">{dateSummary}</Noner>
          </div>
          <div style={{ lineHeight: 0.35 }}>
            <small>
              <Noner none="No time">{timeSummary}</Noner>
            </small>
          </div>
        </div>
      )
    },
  },
  {
    title: 'Bookings',
    key: 'bookings',
    dataIndex: 'bookings',
    render: (val, record) => {
      const bookings = programUtils.countBookings(val)
      return (
        <div>
          <small>
            {bookings} / {record.maxParticipants} booked
          </small>
        </div>
      )
    },
  },
  {
    title: 'Org/venue',
    key: 'org',
    dataIndex: 'org',
    render: (val, record) => {
      return (
        <div>
          <div>{!val ? <Noner none="No org"></Noner> : val.name}</div>
          <div>{!record.venue ? <Noner none="No venue"></Noner> : record.venue.name}</div>
        </div>
      )
    },
  },
]

const forwardPaths = {
  pp: {
    name: 'pp',
    default: true,
    desc: 'Program partner portal page',
    pather: program => paths.org.PROGRAM(program.org.sid, program.id),
  },
  btl: {
    name: 'btl',
    external: true,
    desc: 'Break the Love program page',
    pather: program => urlUtils.mainSiteProgram(program.id),
  },
  admin: {
    name: 'admin',
    desc: 'Admin program page',
    pather: program => paths.admin.PROGRAM(program.id),
  },
  checkin: {
    name: 'checkin',
    external: true,
    desc: 'Program checkin page',
    pather: program => {
      return urlUtils.bosOrgCheckinDate(program.org.sid, program.date)
    },
  },
}

const sortOptions = [
  {
    name: 'Newest',
    value: '-date,-startTime,-endTime',
    default: true,
  },
  {
    name: 'Oldest',
    value: 'date,startTime,endTime',
  },
  {
    name: 'Name ascending',
    value: 'name',
  },
  {
    name: 'Name descending',
    value: '-name',
  },
]

const ProgramSearch = () => {
  const dispatch = useDispatch()
  const status = useStatus(acts.QUICK_SEARCH_PROGRAMS)
  useStatusMsg(status, { error: 'Failed to search programs' })
  useEffect(() => {
    return () => dispatch(clearStatus(acts.QUICK_SEARCH_PROGRAMS))
  }, [])

  const search = async ({ input, currentPage, sort, resultsPerPage }) => {
    const res = await dispatch(
      quickSearchPrograms({
        order: sort,
        searchInput: input,
        limit: resultsPerPage,
        offset: (currentPage - 1) * resultsPerPage,
      }),
    )
    return res
  }

  return (
    <Search
      title="Programs"
      columns={columns}
      forwardPaths={forwardPaths}
      searcher={search}
      searching={status.pending}
      sortOptions={sortOptions}
    />
  )
}

export default ProgramSearch
