import React, { useContext, useRef, useState, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import {
  InfoCircleFilled,
  CloseCircleOutlined,
  PlusOutlined,
  LoadingOutlined,
  MinusCircleOutlined,
  SettingOutlined,
  EditFilled,
} from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Tooltip, Button as AntButton, DatePicker, Select, Switch, InputNumber, Input } from 'antd'
import { useStatus, useStatusMsg } from '../reducers'
import { updateProgram } from '../actions/class'
import { clearStatus } from '../actions/status'
import { fetchConstsByName } from '../actions/org'
import { actions as acts } from '../constants'
import { getSetting } from '../util/settings'
import { Cta, Button, Label, Value } from './common'
import { Noner } from './common'
import * as settingConsts from '../constants/settings'
import moment from 'moment-timezone'
import { isValidEmail } from '../util/email'
import Editor from './Editor'
import { MediumInfo } from './info'

const { Option } = Select

const SettingValue = ({ label, description, ...props }) => {
  const hasDescription = Boolean(description)
  let display = (
    <Value>
      {label}
      {hasDescription && (
        <span>
          {' '}
          <InfoCircleFilled style={{ color: 'gray' }} />
        </span>
      )}
    </Value>
  )
  if (hasDescription) {
    display = (
      <Tooltip title={description} placement="right">
        {display}
      </Tooltip>
    )
  }
  return <span {...props}>{display}</span>
}

// only runs the useEffect after initial mount/render
const useMountedEffect = (fn, deps) => {
  const mounted = useRef(false)
  useEffect(() => {
    if (mounted.current) {
      fn()
    } else {
      mounted.current = true
    }
  }, deps)
}

const SettingHead = ({ label, description }) => {
  const theme = useContext(ThemeContext)
  return (
    <div style={{ marginBottom: theme.spacing[2] }}>
      <Label style={{ fontSize: theme.font.size[5] }}>{label}</Label>
      <div>{description}</div>
    </div>
  )
}

// this does not handle multi settings
const BoolSetting = ({ setting, value, editing, onChange }) => {
  const theme = useContext(ThemeContext)
  if (editing) {
    return (
      <div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <SettingHead label={setting.label} description={setting.description} />
          <Switch checked={value} onChange={onChange} />
        </div>
      </div>
    )
  }
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <SettingHead label={setting.label} description={setting.description} />
      </div>
      <SettingValue label={String(value)} />
    </div>
  )
}

// numSelected indicates the current number of things selected for a setting
const canAdd = ({ setting, numSelected }) => {
  // multi does not apply
  if (!setting.multi) {
    return false
  }

  // no multiMax specified, which means we can have an unlimited amount
  if (typeof setting.max !== 'number') {
    return true
  }

  return numSelected < setting.max
}

// numSelected indicates the current number of things selected for a setting
const canRemove = ({ setting, numSelected }) => {
  // multi does not apply
  if (!setting.multi) {
    return false
  }

  // no multiMin specified, which means we can have 0
  if (typeof setting.min !== 'number') {
    return true
  }

  return numSelected > setting.min
}

const ObjectSetting = ({ setting, value, editing, onChange }) => {
  const theme = useContext(ThemeContext)
  const [selectedValues, setSelectedValues] = useState(Array.isArray(value) ? value : [value])
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const { label, description, schema, multi } = setting

  useMountedEffect(() => {
    if (multi) {
      const newValue = selectedValues.filter(Boolean)
      // null is significant, as this will cause the setting to appear as null in the jsonb.
      // (as opposed to undefined which deletes it from the jsonb)
      // this is useful for knowing that a setting has been set to null instead of never being set
      changer(newValue.length > 0 ? newValue : null)
    } else {
      changer(selectedValues[0])
    }
  }, [selectedValues])

  const addValue = () =>
    setSelectedValues(prev => {
      const newValue = JSON.parse(JSON.stringify(schema))
      Object.keys(newValue).forEach(k => {
        newValue[k] = newValue[k].defaultValue
      })
      return [...prev, newValue]
    })
  const removeValue = index => setSelectedValues(prev => prev.filter((v, i) => i !== index))

  const updateSelectedValue = (index, newValue) => {
    setSelectedValues(prev => {
      const next = [...prev]
      next[index] = newValue
      return next
    })
  }

  let display = selectedValues.filter(Boolean).map(sv => (
    <div
      style={{
        margin: theme.spacing[3],
        padding: theme.spacing[3],
        borderWidth: 1,
        borderStyle: 'solid',
        borderRadius: 5,
      }}
    >
      <Settings allSettings={schema} settings={sv} editing={editing} />
    </div>
  ))
  display = <Noner>{display}</Noner>

  if (editing) {
    display = (
      <div>
        {selectedValues.map((s, i) => (
          <>
            {selectedValues[i] && (
              <div
                style={{
                  margin: theme.spacing[3],
                  padding: theme.spacing[3],
                  borderWidth: 1,
                  borderStyle: 'solid',
                  borderRadius: 5,
                }}
              >
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Settings
                    allSettings={schema}
                    settings={selectedValues[i]}
                    editing={editing}
                    onChange={updatedSettings => updateSelectedValue(i, updatedSettings)}
                  />
                  {canRemove({ setting, numSelected: selectedValues.length }) && (
                    <AntButton onClick={() => removeValue(i)} danger>
                      Remove value
                    </AntButton>
                  )}
                </div>
              </div>
            )}
          </>
        ))}
        {canAdd({ setting, numSelected: selectedValues.length }) && (
          <div style={{ marginBottom: theme.spacing[2] }}>
            <Button onClick={addValue}>
              <PlusOutlined /> Add value
            </Button>
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      <SettingHead label={label} description={description} />
      {display}
    </div>
  )
}

const SelectSetting = ({ setting, value, editing, onChange }) => {
  const theme = useContext(ThemeContext)
  const [selectedValues, setSelectedValues] = useState(Array.isArray(value) ? value : [value])
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const { label, description, selectOptions, multi } = setting

  useMountedEffect(() => {
    if (multi) {
      const newValue = selectedValues.filter(Boolean)
      // null is significant, as this will cause the setting to appear as null in the jsonb.
      // (as opposed to undefined which deletes it from the jsonb)
      // this is useful for knowing that a setting has been set to null instead of never being set
      changer(newValue.length > 0 ? newValue : null)
    } else {
      changer(selectedValues[0])
    }
  }, [selectedValues])

  const addValue = () => setSelectedValues(prev => [...prev, null])
  const removeValue = index => setSelectedValues(prev => prev.filter((v, i) => i !== index))

  const updateSelectedValue = (index, newSelectedValue) => {
    setSelectedValues(prev => {
      const next = [...prev]
      next[index] = newSelectedValue
      return next
    })
  }

  let display = selectedValues.filter(Boolean).map(sv => {
    const v = selectOptions[sv]
    return (
      <div style={{ marginBottom: theme.spacing[2] }}>
        <SettingValue label={v.label} description={v.description} />
      </div>
    )
  })
  display = <Noner>{display}</Noner>

  if (editing) {
    const opts = Object.values(selectOptions).map(so => (
      <Option key={so.name} value={so.name} title={so.description}>
        <div>{so.label}</div>
        <div>
          <small style={{ color: theme.font.color.secondary, whiteSpace: 'normal' }}>
            {so.description}
          </small>
        </div>
      </Option>
    ))

    const selects = selectedValues.map((s, i) => {
      return (
        <div
          key={`ss-${setting.name}-${i}`}
          style={{ marginBottom: theme.spacing[2], display: 'flex', alignItems: 'center' }}
        >
          <Select
            key={`${setting.name}-${i}`}
            value={selectedValues[i] ? selectOptions[selectedValues[i]].label : null}
            onChange={val => updateSelectedValue(i, val)}
            style={{ minWidth: '200px' }}
          >
            {opts}
          </Select>
          {canRemove({ setting, numSelected: selectedValues.length }) && (
            <AntButton size="small" danger type="link" onClick={() => removeValue(i)}>
              <CloseCircleOutlined />
            </AntButton>
          )}
        </div>
      )
    })
    display = (
      <div>
        {canAdd({ setting, numSelected: selectedValues.length }) && (
          <div style={{ marginBottom: theme.spacing[2] }}>
            <Button onClick={addValue}>
              <PlusOutlined /> Add value
            </Button>
          </div>
        )}
        {selects}
      </div>
    )
  }

  return (
    <div>
      <div>
        <SettingHead label={label} description={description} />
        <div style={{ display: 'flex', flexDirection: 'column' }}>{display}</div>
      </div>
    </div>
  )
}

const DateSetting = ({ setting, value, editing, onChange }) => {
  const theme = useContext(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [dates, setDates] = useState(Array.isArray(value) ? value : [value])
  const { label, description, multi, multiMax, multiMin } = setting

  useMountedEffect(() => {
    if (multi) {
      const newValue = dates.filter(Boolean)
      changer(newValue.length > 0 ? newValue : undefined)
    } else {
      // try to get the first value
      if (Array.isArray(dates)) {
        changer(dates[0])
      }
    }
  }, [dates])

  let display = dates.filter(Boolean).map(d => {
    return (
      <div style={{ marginBottom: theme.spacing[2] }}>
        <SettingValue label={d} />
      </div>
    )
  })
  display = <Noner>{display}</Noner>

  if (editing) {
    const addDate = () => {
      setDates(prev => [...prev, null])
    }

    const updateDate = (index, dateStr) => {
      setDates(prev => {
        const next = [...prev]
        next[index] = dateStr
        return next.sort()
      })
    }

    const removeDate = index => {
      setDates(prev => prev.filter((e, i) => i !== index).sort())
    }

    const pickers = dates.map((d, i) => (
      <div style={{ marginBottom: theme.spacing[2], display: 'flex', alignItems: 'center' }}>
        <DatePicker
          key={i}
          onChange={(date, dateStr) => {
            if (date) {
              updateDate(i, dateStr)
            }
          }}
          value={dates[i] ? moment(dates[i]) : null}
          style={{ minWidth: '125px' }}
        />
        {canRemove({ setting, numSelected: dates.length }) && (
          <AntButton size="small" danger type="link" onClick={() => removeDate(i)}>
            <CloseCircleOutlined />
          </AntButton>
        )}
      </div>
    ))

    display = (
      <div>
        {canAdd({ setting, numSelected: dates.length }) && (
          <div style={{ marginBottom: theme.spacing[2] }}>
            <Button onClick={addDate}>
              <PlusOutlined /> Add date
            </Button>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>{pickers}</div>
      </div>
    )
  }

  return (
    <div>
      <div>
        <SettingHead label={label} description={description} />
        {display}
      </div>
    </div>
  )
}

const NumberSetting = ({ setting, value, editing, onChange, min = 0, ...props }) => {
  const theme = useContext(ThemeContext)
  const [numbers, setNumbers] = useState(Array.isArray(value) ? value : [value])
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const { label, description, multi } = setting

  useMountedEffect(() => {
    if (multi) {
      const newValue = numbers.filter(Boolean)
      changer(newValue.length > 0 ? newValue : undefined)
    } else {
      // try to get the first value
      if (Array.isArray(numbers)) {
        changer(numbers[0])
      }
    }
  }, [numbers])

  let display = numbers.filter(Boolean).map(t => {
    return (
      <div style={{ marginBottom: theme.spacing[2] }}>
        <SettingValue label={t} />
      </div>
    )
  })
  display = <Noner>{display}</Noner>

  if (editing) {
    const addNumber = () => {
      setNumbers(prev => [...prev, 0])
    }

    const updateNumber = (index, text) => {
      setNumbers(prev => {
        const next = [...prev]
        next[index] = text
        return next
      })
    }

    const removeNumber = index => {
      setNumbers(prev => prev.filter((e, i) => i !== index))
    }

    const inputs = numbers.map((t, i) => (
      <div style={{ marginBottom: theme.spacing[2], display: 'flex', alignItems: 'center' }}>
        <InputNumber
          key={i}
          onChange={n => updateNumber(i, n)}
          value={numbers[i]}
          style={{ minWidth: '125px' }}
          min={min}
          {...props}
        />
        {canRemove({ setting, numSelected: numbers.length }) && (
          <AntButton size="small" danger type="link" onClick={() => removeNumber(i)}>
            <CloseCircleOutlined />
          </AntButton>
        )}
      </div>
    ))

    display = (
      <div>
        {canAdd({ setting, numSelected: numbers.length }) && (
          <div style={{ marginBottom: theme.spacing[2] }}>
            <Button onClick={addNumber}>
              <PlusOutlined /> Add number
            </Button>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>{inputs}</div>
      </div>
    )
  }
  return (
    <div>
      <SettingHead label={label} description={description} />
      <div>{display}</div>
    </div>
  )
}

const TextSetting = ({ setting, value, editing, onChange, min = 0, ...props }) => {
  const theme = useContext(ThemeContext)
  const [texts, setTexts] = useState(Array.isArray(value) ? value : [value])
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const { label, description, multi } = setting

  useMountedEffect(() => {
    if (multi) {
      const newValue = texts.filter(Boolean)
      changer(newValue.length > 0 ? newValue : undefined)
    } else {
      // try to get the first value
      if (Array.isArray(texts)) {
        changer(texts[0])
      }
    }
  }, [texts])

  let display = texts.filter(Boolean).map(t => {
    return (
      <div style={{ marginBottom: theme.spacing[2] }}>
        <SettingValue label={t} />
      </div>
    )
  })
  display = <Noner>{display}</Noner>

  if (editing) {
    const addText = () => {
      setTexts(prev => [...prev, ''])
    }

    const updateText = (index, text) => {
      setTexts(prev => {
        const next = [...prev]
        next[index] = text
        return next
      })
    }

    const removeText = index => {
      setTexts(prev => prev.filter((e, i) => i !== index))
    }

    const inputs = texts.map((t, i) => (
      <div style={{ marginBottom: theme.spacing[2], display: 'flex', alignItems: 'center' }}>
        <Input
          key={i}
          onChange={e => updateText(i, e.target.value)}
          value={texts[i]}
          style={{ minWidth: '125px' }}
          {...props}
        />
        {canRemove({ setting, numSelected: texts.length }) && (
          <AntButton size="small" danger type="link" onClick={() => removeText(i)}>
            <CloseCircleOutlined />
          </AntButton>
        )}
      </div>
    ))

    display = (
      <div>
        {canAdd({ setting, numSelected: texts.length }) && (
          <div style={{ marginBottom: theme.spacing[2] }}>
            <Button onClick={addText}>
              <PlusOutlined /> Add text
            </Button>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>{inputs}</div>
      </div>
    )
  }
  return (
    <div>
      <SettingHead label={label} description={description} />
      <div>{display}</div>
    </div>
  )
}

const RichTextSetting = ({ setting, value, editing, onChange, min = 0, ...props }) => {
  const theme = useContext(ThemeContext)
  const [texts, setTexts] = useState(Array.isArray(value) ? value : [value])
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const { label, description, multi } = setting

  useMountedEffect(() => {
    if (multi) {
      const newValue = texts.filter(Boolean)
      changer(newValue.length > 0 ? newValue : undefined)
    } else {
      // try to get the first value
      if (Array.isArray(texts)) {
        changer(texts[0])
      }
    }
  }, [texts])

  let display = texts.filter(Boolean).map(t => {
    return (
      <div style={{ marginBottom: theme.spacing[2] }}>
        <SettingValue label={t} />
      </div>
    )
  })
  display = <Noner>{display}</Noner>

  if (editing) {
    const addText = () => {
      setTexts(prev => [...prev, ''])
    }

    const updateText = (index, text) => {
      setTexts(prev => {
        const next = [...prev]
        next[index] = text
        return next
      })
    }

    const removeText = index => {
      setTexts(prev => prev.filter((e, i) => i !== index))
    }

    const inputs = texts.map((t, i) => (
      <div style={{ marginBottom: theme.spacing[2], display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '100%' }}>
          <Editor key={i} onChange={n => updateText(i, n)} value={texts[i]} {...props} />
        </div>

        {canRemove({ setting, numSelected: texts.length }) && (
          <AntButton size="small" danger type="link" onClick={() => removeText(i)}>
            <CloseCircleOutlined />
          </AntButton>
        )}
      </div>
    ))

    display = (
      <div>
        {canAdd({ setting, numSelected: texts.length }) && (
          <div style={{ marginBottom: theme.spacing[2] }}>
            <Button onClick={addText}>
              <PlusOutlined /> Add text
            </Button>
          </div>
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>{inputs}</div>
      </div>
    )
  }
  return (
    <div>
      <SettingHead label={label} description={description} />
      <div>{display}</div>
    </div>
  )
}

const EmailSetting = ({ setting, value, editing, onChange, ...props }) => {
  const change = typeof onChange === 'function' ? onChange : () => {}
  const { label, description, multi } = setting
  const theme = useContext(ThemeContext)
  const [emails, setEmails] = useState(Array.isArray(value) ? value : [value])

  useMountedEffect(() => {
    if (multi) {
      const newValue = emails.filter(email => isValidEmail(email))
      change(newValue)
    } else {
      // try to get the first value
      if (Array.isArray(emails)) {
        change(emails[0])
      }
    }
  }, [emails])

  useEffect(() => {
    //Since editing and viewing are on the same component, we need to reset values when you click on cancel
    //and had invalid changes in the input
    if (!editing) {
      setEmails(Array.isArray(value) ? value : [value])
    }
  }, [editing])

  let display = (
    <Noner>
      {value.map(email => (
        <div key={email} style={{ margin: `${theme.spacing[3]} 0` }}>
          <SettingValue label={email} />
        </div>
      ))}
    </Noner>
  )
  if (editing) {
    display = (
      <>
        {emails &&
          emails.map((email, i) => {
            let isValid = isValidEmail(email)
            return (
              <>
                <div style={{ display: 'flex', marginTop: theme.spacing[3] }}>
                  <Input
                    type="text"
                    size="large"
                    onChange={e => {
                      setEmails(prev => {
                        const newEmails = [...prev]
                        newEmails[i] = e.target.value
                        return newEmails
                      })
                    }}
                    value={email}
                    style={{ border: `${!isValid ? '2px solid red' : ''}` }}
                  />
                  {canRemove({ setting, numSelected: emails.length }) && (
                    <MinusCircleOutlined
                      style={{ marginLeft: theme.spacing[2], alignSelf: 'center' }}
                      onClick={() => {
                        const newValue = [...emails]
                        newValue.splice(i, 1)
                        setEmails(newValue)
                      }}
                    />
                  )}
                </div>
                {!isValid ? (
                  <div style={{ color: 'red', marginTop: theme.spacing[2] }}>Invalid email</div>
                ) : null}
              </>
            )
          })}
        {canAdd({ setting, numSelected: emails.length }) && (
          <Button
            style={{ minWidth: theme.width[6], marginTop: theme.spacing[3] }}
            onClick={() => setEmails(prev => [...prev, null])}
          >
            <PlusOutlined /> Add option
          </Button>
        )}
      </>
    )
  }
  return (
    <div {...props}>
      <div>
        <SettingHead label={label} description={description} />
        <div>{display}</div>
      </div>
    </div>
  )
}

const Setting = ({ setting, onChange, ...props }) => {
  const change = newValue => {
    if (typeof onChange === 'function') {
      onChange({ [setting.name]: newValue })
    }
  }

  switch (setting.type) {
    case settingConsts.types.BOOLEAN: {
      return <BoolSetting setting={setting} onChange={change} {...props} />
    }
    case settingConsts.types.SELECT: {
      return <SelectSetting setting={setting} onChange={change} {...props} />
    }
    case settingConsts.types.DATE: {
      return <DateSetting setting={setting} onChange={change} {...props} />
    }
    case settingConsts.types.NUMBER: {
      return <NumberSetting setting={setting} onChange={change} {...props} />
    }
    case settingConsts.types.EMAIL: {
      return <EmailSetting setting={setting} onChange={change} {...props} />
    }
    case settingConsts.types.OBJECT: {
      return <ObjectSetting setting={setting} onChange={change} {...props} />
    }
    case settingConsts.types.TEXT: {
      return <TextSetting setting={setting} onChange={change} {...props} />
    }
    case settingConsts.types.RICHTEXT: {
      return <RichTextSetting setting={setting} onChange={change} {...props} />
    }
    default: {
      return
    }
  }
}

export const Settings = ({ allSettings, settings, editing, onChange }) => {
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const theme = useContext(ThemeContext)
  if (!allSettings || Object.keys(allSettings).length === 0) {
    return <i>No settings available</i>
  }

  const onSettingChange = updatedSettings => {
    changer({
      ...settings,
      ...updatedSettings,
    })
  }
  const settingForms = Object.keys(allSettings).map(k => {
    const setting = allSettings[k]
    return (
      <div key={setting.name} style={{ marginBottom: theme.spacing[4] }}>
        <Setting
          setting={setting}
          value={getSetting(settings, setting.name, setting.defaultValue)}
          editing={editing}
          onChange={onSettingChange}
        />
      </div>
    )
  })
  return <div style={{ maxWidth: '700px' }}>{settingForms}</div>
}

export const ProgramSettings = ({ program }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [allSettings, setAllSettings] = useState(null)
  const [editing, setEditing] = useState(false)
  const [settings, setSettings] = useState(program.settings)
  const status = {
    update: useStatus(acts.UPDATE_PROGRAM),
    fetchSettings: useStatus(acts.FETCH_CONSTS_BY_NAME),
  }

  useEffect(() => {
    dispatch(fetchConstsByName({ osid, name: 'program' })).then(consts =>
      setAllSettings(consts.settings),
    )

    return () => {
      dispatch(clearStatus(acts.FETCH_CONSTS_BY_NAME))
      dispatch(clearStatus(acts.UPDATE_PROGRAM))
    }
  }, [])

  useStatusMsg(status.update, {
    error: 'Failed to update settings',
    success: 'Setting updated',
  })

  useStatusMsg(status.fetchSettings, {
    error: 'Failed to fetch settings',
  })

  if (status.fetchSettings.pending) {
    return <LoadingOutlined />
  }

  if (!allSettings || Object.keys(allSettings).length === 0) {
    return <i>No settings available</i>
  }

  const onSaveSettings = () => {
    dispatch(updateProgram(osid, program.id, { settings }))
      .then(updatedProgram => {
        setSettings(updatedProgram.settings)
        setEditing(false)
      })
      .catch(() => {})
  }

  const onCancelEdit = () => {
    setEditing(false)
    setSettings(program.settings)
  }

  let buttons = (
    <Button onClick={() => setEditing(true)}>
      <EditFilled /> Edit
    </Button>
  )
  if (editing) {
    buttons = (
      <div>
        <Button
          style={{ marginRight: theme.spacing[2] }}
          disabled={status.update.pending}
          onClick={onCancelEdit}
        >
          Cancel
        </Button>
        <Cta onClick={onSaveSettings} disabled={status.update.pending}>
          {status.update.pending ? 'Saving...' : 'Save'}
        </Cta>
      </div>
    )
  }

  return (
    <MediumInfo style={{ marginTop: 0 }} button={buttons}>
      <div style={{ maxWidth: '700px', marginTop: '1em' }}>
        <Settings
          allSettings={allSettings}
          settings={settings}
          editing={editing}
          onChange={updatedSettings => setSettings(updatedSettings)}
        />
      </div>
    </MediumInfo>
  )
}
