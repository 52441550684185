import React, { useEffect, useState } from 'react'
import { message, Button } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useStatus, useStatusMsg } from '../../reducers'
import { createProgramPost } from '../../actions/org'
import { clearStatus } from '../../actions/status'
import { FocusHeader } from '../../components/header'
import { Cta } from '../../components/common'
import { Focus as FocusContainer } from '../../components/containers'
import { paths, actions as acts } from '../../constants'
import { newSid } from '../../util/id'
import { PostEditor } from '../../components/posts'
import get from 'lodash.get'

const NewProgramPost = () => {
  const { osid, id } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()
  const [post, setPost] = useState(null)
  const status = {
    create: useStatus(acts.CREATE_PROGRAM_POST),
  }

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.CREATE_PROGRAM_POST))
    }
  }, [])

  useStatusMsg(status.create, {
    pending: 'Creating...',
    error: 'Failed to create post',
    success: 'Post created',
  })

  const missingFields = () => {
    const missing = ['content'].filter(k => {
      if (!post[k]) {
        return true
      }
      return false
    })
    if (missing.length > 0) {
      const msg = `${missing.join(', ')} ${missing.length > 1 ? 'are' : 'is'} required`
      message.error(`${msg.charAt(0).toUpperCase()}${msg.substr(1)}`)
      return true
    }
    return false
  }

  const submitProgramPost = createAnother => () => {
    if (missingFields()) {
      return
    }
    const newPost = {
      content: post.content,
      isAdminPost: post.isAdminPost,
      pinned: post.pinned,
      images: post.images,
    }
    dispatch(createProgramPost(osid, id, post.notify, newPost))
      .then(post => {
        if (createAnother) {
          setPost(null)
        } else {
          history.push(paths.org.PROGRAM_POSTS(osid, id))
        }
      })
      .catch(() => {})
  }

  const notifyCopy =
    'Send a notification email to all participants with a completed booking. The creator of the post will not receive a notification email.'
  return (
    <>
      <FocusHeader
        title="Create a program post"
        actions={
          <>
            <Button style={{ marginRight: '.5em' }} onClick={submitProgramPost(true)} size="small">
              Post and create another
            </Button>
            <Cta onClick={submitProgramPost(false)}>Post</Cta>
          </>
        }
      />
      <FocusContainer style={{ paddingTop: '20px', paddingBottom: '20px' }}>
        <PostEditor post={post} onChange={p => setPost(p)} allowNotify notifyCopy={notifyCopy} />
      </FocusContainer>
    </>
  )
}

export default NewProgramPost
