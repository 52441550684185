import { useRef, useState, useEffect } from 'react'
import { ThemeContext, useTheme } from 'styled-components'
import { Select, Input, Table } from 'antd'
import { CloseOutlined, LoadingOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import { Head, Title, Button, Cta } from '../../../components/common'
import { numberOfPages } from '../../../util/pagination'

const { Option } = Select

const Paginator = ({ visible = true, currentPage, maxPages, onBack, onForward }) => {
  const theme = useTheme()
  const visibility = visible ? 'visible' : 'hidden'
  return (
    <div style={{ visibility }}>
      <Button type="text" onClick={onBack} style={{ marginRight: theme.spacing[2] }}>
        <LeftOutlined style={{ visibility }} />
      </Button>
      {currentPage} / {maxPages}
      <Button type="text" onClick={onForward} style={{ marginLeft: theme.spacing[2] }}>
        <RightOutlined style={{ visibility }} />
      </Button>
    </div>
  )
}

const getForwardPath = ({ paths, name }) => {
  let path = paths[name]
  if (!path) {
    return Object.values(paths).find(p => p.default)
  }
  return path
}

// fp is a forwardPath from a quick search page
// forwardPath and extraPath are optional
// if forwardPath provided, but it is not found in paths,
// then forward to the default path and append forwardPath as an extra path to the end
// (this takes care of the case where you want to navigate to the default url, but with extra paths appended)
// if forwardPath and extra path provided, append extraPath to the right of extraPath
const getPath = ({ fp, data, forwardPath, extraPath }) => {
  const path = fp.pather(data)
  if (forwardPath && fp.name !== forwardPath && !Boolean(extraPath)) {
    return `${path}/${forwardPath}`
  }
  if (Boolean(extraPath)) {
    return `${path}/${extraPath}`
  }
  return path
}

// selects on of property default, else selects the first one
// if no options passed, returns null
const getDefaultOption = options => {
  if (!Array.isArray(options) || options.length === 0) {
    return null
  }
  const opt = options.find(o => o.default)
  return opt ? opt : options[0]
}

const Sorter = ({ value, options, onChange }) => {
  const theme = useTheme(ThemeContext)
  const defaultOption = getDefaultOption(options)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [val, setVal] = useState(defaultOption ? defaultOption.name : null)
  const [opts, setOpts] = useState({}) // map of option names to option object

  useEffect(() => {
    setOpts(
      options.reduce((acc, curr) => {
        acc[curr.name] = curr
        return acc
      }, {}),
    )
    const def = getDefaultOption(options)
    setVal(def ? def.name : null)
    changer(def)
  }, [options])

  useEffect(() => {
    if (options && Object.keys(options).length > 0 && value) {
      setVal(value.name)
    }
  }, [value, options])

  const optElems = options.map(o => {
    let Component = () => <span>{o.name}</span>
    if (o.render) {
      Component = o.render
    }
    return (
      <Option key={o.name} value={o.name}>
        <Component />
      </Option>
    )
  })

  const handleChange = name => {
    setVal(name)
    if (typeof onChange === 'function') {
      onChange(opts[name])
    }
  }

  return (
    <Select
      placeholder="Sort"
      value={val}
      onChange={handleChange}
      style={{ minWidth: theme.width[4] }}
    >
      {optElems}
    </Select>
  )
}

const defaultSortOptions = [
  {
    name: 'Name ascending',
    value: { column: 'name', order: 'asc' },
    default: true,
    render: () => {
      return 'Name ascending'
    },
  },
  {
    name: 'Name descending',
    value: { column: 'name', order: 'desc' },
    render: () => {
      return 'Name descending'
    },
  },
]

// fp is a forward path
const moveForward = (history, fp, path, { recordHistory = false } = {}) => {
  if (recordHistory) {
    if (fp.external) {
      window.location.href = path
    } else {
      history.push(path)
    }
  } else {
    if (fp.external) {
      window.location.replace(path)
    } else {
      history.replace(path)
    }
  }
}

const initSortValue = options => {
  const opt = getDefaultOption(options)
  return opt ? opt.value : null
}

const resultsPerPage = 50
const Search = ({
  title,
  columns,
  forwardPaths,
  searcher,
  searching,
  sortOptions = defaultSortOptions,
}) => {
  const firstRender = useRef(true)
  const inputRef = useRef(null)
  const textRef = useRef('') // the text of the input search bar. we're using a ref here for performance reasons to not make the table re-render as much
  const { searchInput, forwardPath, extraPath } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const [data, setData] = useState([])
  const [numResults, setNumResults] = useState(0)
  const [nextPath, setNextPath] = useState(forwardPath)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchValue, setSearchValue] = useState(textRef.current)
  const [initLoading, setInitLoading] = useState(true)
  const [sort, setSort] = useState(initSortValue(sortOptions))
  const fp = getForwardPath({ paths: forwardPaths, name: nextPath })

  useEffect(() => {
    if (typeof searchInput === 'string' && searchInput.length > 0) {
      textRef.current = searchInput
      setSearchValue(textRef.current)
      searcher({ input: searchInput, currentPage, sort, resultsPerPage }).then(res => {
        if (res.numResults === 1) {
          const path = getPath({ fp, data: res.data[0], forwardPath, extraPath })
          moveForward(history, fp, path)
          return
        }
        setData(res.data)
        setNumResults(res.numResults)
        setInitLoading(false)
      })
    } else {
      setInitLoading(false)
    }
  }, [])

  useEffect(() => {
    if (inputRef.current) {
      // without a small timeout, the input focus does not get set
      setTimeout(() => inputRef.current.focus(), 10)
    }
  }, [inputRef.current])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    searcher({ input: searchValue, currentPage, sort, resultsPerPage }).then(res => {
      setData(res.data)
      setNumResults(res.numResults)
      return res
    })
  }, [firstRender.current, searchValue, currentPage, sort, resultsPerPage])

  const handleForward = () => {
    if (currentPage < numberOfPages(numResults, resultsPerPage)) {
      setCurrentPage(currentPage + 1)
    }
  }

  const handleBack = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleSearch = () => {
    setCurrentPage(1)
    setSearchValue(textRef.current)
  }

  const onSort = sortOption => {
    setSort(sortOption.value)
    setCurrentPage(1)
  }

  if (initLoading) {
    return (
      <div
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '3em' }}
      >
        <LoadingOutlined />
      </div>
    )
  }

  const cols = [
    ...columns,
    {
      title: '',
      width: 10,
      render: (val, record) => {
        const path = getPath({ fp, data: record, forwardPath, extraPath })
        return (
          <a href={path} onClick={e => e.stopPropagation()}>
            <Button>Go</Button>
          </a>
        )
      },
    },
  ]

  return (
    <div
      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '3em' }}
    >
      <div style={{ minWidth: '800px' }}>
        <Head>
          <Title>{title}</Title>
        </Head>
        <div style={{ marginBottom: '1em' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              ref={inputRef}
              autoFocus
              style={{ marginRight: '1em' }}
              placeholder="Search"
              defaultValue={textRef.current}
              onChange={e => {
                textRef.current = e.target.value
              }}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleSearch()
                }
              }}
            />
            <Cta onClick={handleSearch}>Search</Cta>
          </div>
        </div>
        <div style={{ marginBottom: '1em' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Paginator
              currentPage={currentPage}
              maxPages={Math.max(numberOfPages(numResults, resultsPerPage), 1)}
              onBack={handleBack}
              onForward={handleForward}
              visible={!searching || currentPage > 1}
            />
            <Sorter options={sortOptions} onChange={onSort} />
          </div>
        </div>
        {searching && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '50vh',
            }}
          >
            <LoadingOutlined />
          </div>
        )}
        {!searching && (
          <div style={{ marginBottom: '20px' }}>
            <div>
              <Table
                size="small"
                onRow={record => ({
                  onClick: () => {
                    const path = getPath({ fp, data: record, forwardPath, extraPath })
                    moveForward(history, fp, path, { recordHistory: true })
                    return
                  },
                  style: { cursor: 'pointer' },
                })}
                columns={cols}
                dataSource={data}
                pagination={false}
              />
            </div>
          </div>
        )}
        <Paginator
          currentPage={currentPage}
          maxPages={Math.max(numberOfPages(numResults, resultsPerPage), 1)}
          onBack={handleBack}
          onForward={handleForward}
          visible={!searching || currentPage > 1}
        />
      </div>
    </div>
  )
}

export default Search
