import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { actions as acts, paths } from '../../../constants'
import urlUtils from '../../../util/url'
import { searchOrgs } from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import { useStatus, useStatusMsg } from '../../../reducers'
import Search from './search'
import { serializeOrder } from './util'

const columns = [
  {
    title: 'Name',
    key: 'name',
    dataIndex: 'name',
  },
  {
    title: 'sid',
    key: 'sid',
    dataIndex: 'sid',
  },
]

const forwardPaths = {
  admin: {
    name: 'admin',
    default: true,
    desc: 'Org admin',
    pather: org => paths.admin.ORG(org.sid),
  },
  bos: {
    name: 'bos',
    external: true,
    desc: 'Org BreakOS',
    pather: org => urlUtils.bosOrgHomepage(org.sid),
  },
  ben: {
    name: 'ben',
    external: true,
    desc: 'Org benefits',
    pather: org => urlUtils.orgBenefits(org.sid),
  },
  pp: {
    name: 'pp',
    desc: 'Org partner portal',
    pather: org => paths.org.HOME(org.sid),
  },
}

const OrgSearch = () => {
  const dispatch = useDispatch()
  const status = useStatus(acts.SEARCH_ORGS)
  useStatusMsg(status, { error: 'Failed to search orgs' })
  useEffect(() => {
    return () => dispatch(clearStatus(acts.SEARCH_ORGS))
  }, [])

  const search = async ({ input, currentPage, sort, resultsPerPage }) => {
    const res = await dispatch(
      searchOrgs({
        order: serializeOrder(sort),
        searchInput: input,
        limit: resultsPerPage,
        offset: (currentPage - 1) * resultsPerPage,
      }),
    )
    return res
  }

  return (
    <Search
      title="Orgs"
      columns={columns}
      forwardPaths={forwardPaths}
      searcher={search}
      searching={status.pending}
    />
  )
}

export default OrgSearch
