import React, { useContext, useState } from 'react'
import { Button } from 'antd'
import { ThemeContext } from 'styled-components'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import { paths } from '../../constants'
import { groupProgramsByDate } from './utils/groupProgramsByDate'

const ClubProgramsList = ({ club, onRemove }) => {
  const theme = useContext(ThemeContext)
  const [showPastPrograms, setShowPastPrograms] = useState(false)

  const today = dayjs()
  const groupedProgramsByDate = groupProgramsByDate(club.programs)

  const pastProgramsCount = Array.from(groupedProgramsByDate)
    .filter(([date]) => dayjs(date).isBefore(today, 'day'))
    .reduce((count, [_, programs]) => count + programs.length, 0)

  const filteredDates = Array.from(groupedProgramsByDate).filter(([date]) => {
    const isPastDate = dayjs(date).isBefore(today, 'day')
    return showPastPrograms || !isPastDate
  })

  return (
    <>
      {pastProgramsCount > 0 && (
        <Button
          type="link"
          onClick={() => setShowPastPrograms(!showPastPrograms)}
          style={{ padding: '0px' }}
        >
          {showPastPrograms
            ? `Hide past programs (${pastProgramsCount})`
            : `Show past programs (${pastProgramsCount})`}
        </Button>
      )}
      {filteredDates.map(([date, datePrograms]) => (
        <div key={date} style={{ marginBottom: theme.spacing[2] }}>
          <div
            style={{
              fontWeight: 'bold',
              marginLeft: theme.spacing[2],
              marginTop: theme.spacing[1],
            }}
          >
            {date}
          </div>

          {datePrograms.map(program => (
            <div
              key={program.id}
              style={{
                marginLeft: theme.spacing[3],
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: `${theme.spacing[1]}px 0`,
              }}
            >
              <Link to={paths.org.PROGRAM(program.org, program.id)}>{program.name}</Link>
              {onRemove && (
                <Button type="link" danger onClick={() => onRemove(program.id)}>
                  Remove program
                </Button>
              )}
            </div>
          ))}
        </div>
      ))}
    </>
  )
}

export default ClubProgramsList
