import React from 'react'
import { title } from '../util/string'

const PaymentMethod = ({ method }) => {
  if (!method) {
    return <i>No payment method</i>
  }
  return (
    <span>
      {title(method.cardBrand)} {method.cardLast4}
    </span>
  )
}

export default PaymentMethod
