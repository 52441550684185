import { actions as acts } from '../constants'

const users = (state = [], { type, payload }) => {
  switch (type) {
    case acts.FETCH_USERS_SUCCESS:
      return { data: [...payload.data], totalUsers: payload.totalUsers }

    case acts.UPDATE_USER_TYPE_SUCCESS:
      const user_id = state.data.findIndex(user => user.id === payload.id)
      if (user_id !== -1) {
        const data = [...state.data]
        data[user_id] = {
          ...data[user_id],
          ...payload,
        }
        return { data, totalUsers: payload.totalUsers }
      }
      return state
    case acts.UPDATE_USER_LEVEL_SUCCESS:
      const idx = state.data.findIndex(user => user.id === payload.id)
      if (idx !== -1) {
        const data = [...state.data]
        data[idx] = {
          ...data[idx],
          playerLevel: payload.level,
        }
        return { data, totalUsers: payload.totalUsers }
      }
      return state
    default:
      return state
  }
}

export default users
