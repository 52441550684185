import { LOCATION_CHANGE } from 'connected-react-router'
import { paths, actions as acts } from '../constants'

export const initState = {
  id: null,
  org_id: null,
  name: null,
  createAt: null,
  updatedAt: null,
  description: null,
  date: null,
  startTime: null,
  endTime: null,
  hidden: false,
  minParticipants: null,
  maxParticipants: null,
  participantPriceCents: null,
  cancellationPolicy: null,
  publishDate: null,
  draft: null,
  type: null,
  subtype: null,
  internalTags: [],
  location: null,
  pricingType: null,
  bookings: [],
  images: [],
  allowedBookingCardBrand: null,
}

const draftProgram = (state = initState, { type, payload }) => {
  switch (type) {
    case acts.CLEAR_DRAFT_PROGRAM:
      return {
        ...initState,
      }
    case acts.FETCH_DRAFT_PROGRAM_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case acts.SET_DRAFT_PROGRAM:
      return {
        ...payload,
      }
    case acts.CREATE_PROGRAM_DRAFT_SUCCESS:
      return {
        ...state,
        ...payload,
      }
    case acts.UPDATE_PROGRAM_SUCCESS:
      // a non-existant payload id implies this is a local (non-server-saved) update
      // and we should update redux regardless
      if (!payload.id) {
        return {
          ...state,
          ...payload,
        }
      }
      if (payload.id === state.id) {
        return {
          ...state,
          ...payload,
        }
      }
      break
    case acts.FINALIZE_DRAFT_PROGRAM_SUCCESS:
      if (payload.id === state.id) {
        return {
          ...state,
          ...payload,
        }
      }
      break
    // TODO figure out a way to capture this functionality with osid, or without a reducer
    // clear draftProgram when navigating away from it
    //case LOCATION_CHANGE:
    //  const draftPaths = [
    //    paths.org.CREATE_PROGRAM(osid),
    //    paths.org.RESUME_DRAFT(osid, state.id),
    //  ]
    //  if (!draftPaths.includes(payload.location.pathname)) {
    //    return initState
    //  }
    //  break
    default:
      return state
  }
  return state
}

export default draftProgram
