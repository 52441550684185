import React, { useEffect, useState } from 'react'
import { message, Tag, Input, Typography, Modal, Dropdown, Menu, Button, Table } from 'antd'
import { EllipsisOutlined, LoadingOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import * as actions from '../../../actions/admin'
import { useStatus, useStatusMsg } from '../../../reducers'
import { actions as acts } from '../../../constants'
import { PROGRAM } from '../../../constants/marketingLabels'
import { formatDateTimeTz } from '../../../util/time'
import { UniqueError } from '../../../util/error'
import PageTitle from '../../../components/admin/PageTitle'
import PageHeading from '../../../components/admin/PageHeading'
import { lower } from '../../../util/string'
import pluralize from 'pluralize'

const columns = () => [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: '200',
    render: (val, record) => <Tag>{val}</Tag>,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: '275',
  },
  {
    title: 'Creator',
    dataIndex: 'creator',
    key: 'creator',
    width: '175',
    render: (val, record) => (
      <>
        <div>{val.name}</div>
        <div>
          <small>{formatDateTimeTz(record.createdAt)}</small>
        </div>
      </>
    ),
  },
  {
    title: '',
    dataIndex: 'actions',
    key: 'actions',
    width: '10',
    render: (val, record) => (
      <Dropdown overlay={<ActionsMenu record={record} />} trigger={['click']}>
        <EllipsisOutlined
          className="ant-dropdown-link"
          style={{ fontSize: '1.75em', fontWeight: 'bold' }}
        />
      </Dropdown>
    ),
  },
]

const CreateModal = ({ complete }) => {
  const dispatch = useDispatch()
  const status = useStatus(acts.CREATE_INTERNAL_TAG)
  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)

  useStatusMsg(status, {
    pending: 'Creating internal tag...',
    error: err =>
      err instanceof UniqueError
        ? `An internal tag already exists with name "${name}"`
        : 'Could not create internal tag',
    success: 'Internal tag created',
  })

  const onOk = async () => {
    dispatch(actions.createInternalTag(name, description))
      .then(() => complete())
      .catch(() => {})
  }

  const onCancel = () => complete()

  const formComplete = [name && name.length > 1, description && description.length > 1].reduce(
    (acc, curr) => acc && curr,
    true,
  )

  return (
    <Modal
      title="Create new internal tag"
      visible
      okText="Create"
      onOk={onOk}
      okButtonProps={{ disabled: !formComplete }}
      onCancel={onCancel}
      confirmLoading={status.pending}
    >
      <Input placeholder="Name" value={name} onChange={e => setName(e.target.value)} />
      <Input.TextArea
        placeholder="Description"
        rows={4}
        value={description}
        onChange={e => setDescription(e.target.value)}
      />
    </Modal>
  )
}

const ActionsMenu = record => {
  const dispatch = useDispatch()

  const warning = () => {
    Modal.confirm({
      title: 'Warning',
      okText: 'Delete internal tag',
      okButtonProps: { type: 'danger' },
      content: (
        <div>
          <p>Deleting this internal tag will remove it from all {lower(pluralize(PROGRAM))}</p>
        </div>
      ),
      onOk: async () => await dispatch(actions.deleteInternalTag(record.record.id)),
    })
  }

  return (
    <Menu>
      <Menu.Item key="0">
        <a onClick={warning}>
          <Typography.Text type="danger">Delete</Typography.Text>
        </a>
      </Menu.Item>
    </Menu>
  )
}

const InternalTags = () => {
  const dispatch = useDispatch()
  const internalTags = useSelector(s => s.internalTags)
  const status = useStatus(acts.FETCH_INTERNAL_TAGS)
  const [creating, setCreating] = useState(false)
  useEffect(() => {
    dispatch(actions.fetchInternalTags())
  }, [])

  if (status.pending) {
    return <LoadingOutlined />
  }

  const cols = columns()
  return (
    <>
      {creating && <CreateModal complete={() => setCreating(false)} />}
      <PageHeading>
        <PageTitle>Internal Tags</PageTitle>
        <Button size="small" type="primary" onClick={() => setCreating(true)}>
          New
        </Button>
      </PageHeading>
      <Table
        rowKey="id"
        rowClassName="it-row"
        columns={cols}
        dataSource={internalTags}
        pagination={false}
      />
    </>
  )
}

export default InternalTags
