import moment from 'moment-timezone'
import { formatTime, parseTime } from './time'
import { DEFAULT_TIMEZONE } from '../constants/time'
import { statuses } from '../constants/program'

// returns true if class is live/published on BTL's main site
export const isBookable = clinic => {
  if (clinic.draft) {
    return false
  }

  if (clinic.status === statuses.CANCELED.name) {
    return false
  }

  const now = moment()
  const pubDate = moment(clinic.publishDate)
  if (!isPast(clinic)) {
    // TODO also check if lateral entry is possible if isInProgress()
    // if not, return false
    if (now.isAfter(pubDate, 'minute')) {
      return true
    }
  }
  return false
}

export const isInProgress = clinic => {
  const now = moment()
  const startTime = parseTime(clinic.startTime)
  const endTime = parseTime(clinic.endTime)
  const start = moment(clinic.date).set({
    hour: startTime.hour(),
    minute: startTime.minute(),
  })
  const end = moment(clinic.date).set({
    hour: endTime.hour(),
    minute: endTime.minute(),
  })
  if (now.isBetween(start, end, 'minute', '[]')) {
    return true
  }
  return false
}

export const isUpcoming = clinic => {
  const now = moment().tz(clinic.tz || DEFAULT_TIMEZONE)
  const startTime = parseTime(clinic.startTime)
  // moment.tz(...) takes in the date and assumes the timezone is the one passed
  // instead of doing a conversion
  const start = moment.tz(clinic.date, 'YYYY-MM-DD', clinic.tz || DEFAULT_TIMEZONE).set({
    hour: startTime.hour(),
    minute: startTime.minute(),
  })
  if (now.isBefore(start, 'minute')) {
    return true
  }
  return false
}

export const isPast = clinic => {
  const now = moment()
  const endTime = parseTime(clinic.endTime)
  const end = moment(clinic.date).set({
    hour: endTime.hour(),
    minute: endTime.minute(),
  })
  if (now.isAfter(end, 'minute')) {
    return true
  }
  return false
}
