import React from 'react'
import { Redirect, useParams } from 'react-router-dom'
import { paths } from '../constants'

const OrgHome = () => {
  const { osid } = useParams()
  return <Redirect to={paths.org.PROGRAMS(osid)} />
}

export default OrgHome
