import React from 'react'
import styled from 'styled-components'
import { Form, Input } from 'antd'
import { mainSiteCec } from '../../../util/url'
import Attr from '../../../components/Attr'
import SingleImageEditor from '../../../components/SingleImageEditor'

const { TextArea } = Input

const Label = styled.span`
  color: rgba(0, 0, 0, 0.65);
  font-weight: ${props => props.theme.font.weight[7]};
`

const initState = {
  name: null,
  slug: null,
  customerioSegmentId: null,
  image: null,
  preTitle: null,
  preCopy: null,
  preCtaText: 'Submit',
  postTitle: null,
  postCopy: 'Thank you! Your email has been submitted.',
  postCtaText: null,
  postCtaUrl: null,
}

export const fieldNameLabels = {
  name: { label: 'Name (internal)', fullname: 'Name' },
  slug: { label: 'Slug', fullname: 'Slug' },
  customerioSegmentId: { label: 'Customerio segment id', fullname: 'Customerio segment id' },
  image: { label: 'Image', fullname: 'Image' },
  preTitle: { label: 'Title', fullname: 'Pre-submission title' },
  preCopy: { label: 'Copy', fullname: 'Pre-submission copy' },
  preCtaText: { label: 'CTA text', fullname: 'Pre-submission CTA text' },
  postTitle: { label: 'Title', fullname: 'Post-submission title' },
  postCopy: { label: 'Copy', fullname: 'Post-submission copy' },
  postCtaText: { label: 'CTA text', fullname: 'Post-submission CTA text' },
  postCtaUrl: { label: 'CTA URL', fullname: 'Post-submission CTA URL' },
}

const CecEditor = ({ onChange, cec }) => {
  if (!cec) {
    if (typeof onChange === 'function') {
      onChange(initState)
    }
    return null
  }

  const {
    name,
    slug,
    customerioSegmentId,
    image,
    preTitle,
    preCopy,
    preCtaText,
    postTitle,
    postCopy,
    postCtaText,
    postCtaUrl,
  } = cec

  const change = update => {
    if (typeof onChange === 'function') {
      onChange({ ...cec, ...update })
    }
  }

  return (
    <Form layout="vertical" requiredMark={'optional'}>
      <Form.Item
        required
        name="name"
        label={<Label>{fieldNameLabels.name.label}</Label>}
        initialValue={name}
      >
        <Input placeholder="" onChange={e => change({ name: e.target.value })} value={name} />
      </Form.Item>
      <Form.Item
        required
        name="slug"
        label={<Label>{fieldNameLabels.slug.label}</Label>}
        initialValue={slug}
      >
        <Input
          addonBefore={mainSiteCec(' ')}
          placeholder=""
          onChange={e => change({ slug: e.target.value })}
          value={slug}
        />
      </Form.Item>
      <Form.Item
        required
        name="customerio-segment-id"
        label={<Label>{fieldNameLabels.customerioSegmentId.label}</Label>}
        initialValue={customerioSegmentId}
      >
        <Input
          placeholder="kWqSes"
          onChange={e => change({ customerioSegmentId: e.target.value })}
          value={customerioSegmentId}
        />
      </Form.Item>
      <Form.Item
        required
        name="image"
        label={<Label>{fieldNameLabels.image.label}</Label>}
        initialValue={image}
      >
        <SingleImageEditor
          actionText="Select image"
          onChange={img => change({ image: img })}
          img={image}
        />
      </Form.Item>
      <Attr
        name="Pre-submission"
        description="Copy to show before a user has submitted their email"
      >
        <Form.Item
          required
          name="pre-title"
          label={<Label>{fieldNameLabels.preTitle.label}</Label>}
          initialValue={preTitle}
        >
          <Input
            placeholder="Wilson Gift of Game"
            onChange={e => change({ preTitle: e.target.value })}
            value={preTitle}
          />
        </Form.Item>
        <Form.Item
          required
          name="pre-copy"
          label={<Label>{fieldNameLabels.preCopy.label}</Label>}
          initialValue={preCopy}
        >
          <TextArea
            placeholder=""
            onChange={e => change({ preCopy: e.target.value })}
            value={preCopy}
          />
        </Form.Item>
        <Form.Item
          required
          name="pre-cta-text"
          label={<Label>{fieldNameLabels.preCtaText.label}</Label>}
          initialValue={preCtaText}
        >
          <Input
            placeholder=""
            onChange={e => change({ preCtaText: e.target.value })}
            value={preCtaText}
          />
        </Form.Item>
      </Attr>
      <Attr
        name="Post-submission"
        description="Copy to show after a user has submitted their email"
      >
        <Form.Item
          name="post-title"
          label={<Label>{fieldNameLabels.postTitle.label}</Label>}
          initialValue={postTitle}
        >
          <Input
            placeholder="Thank you!"
            onChange={e => change({ postTitle: e.target.value })}
            value={postTitle}
          />
          <span>
            If post-submission title not specified, the pre-submission title will be used instead
          </span>
        </Form.Item>
        <Form.Item
          name="post-copy"
          label={<Label>{fieldNameLabels.postCopy.label}</Label>}
          initialValue={postCopy}
        >
          <TextArea
            placeholder=""
            onChange={e => change({ postCopy: e.target.value })}
            value={postCopy}
          />
        </Form.Item>
        <Form.Item
          name="post-cta-text"
          label={<Label>{fieldNameLabels.postCtaText.label}</Label>}
          initialValue={postCtaText}
        >
          <Input
            placeholder=""
            onChange={e => change({ postCtaText: e.target.value })}
            value={postCtaText}
          />
        </Form.Item>
        <Form.Item
          name="post-cta-url"
          label={<Label>{fieldNameLabels.postCtaUrl.label}</Label>}
          initialValue={postCtaUrl}
        >
          <Input
            placeholder=""
            onChange={e => change({ postCtaUrl: e.target.value })}
            value={postCtaUrl}
          />
        </Form.Item>
      </Attr>
    </Form>
  )
}

export default CecEditor
