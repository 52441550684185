import React, { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ThemeContext } from 'styled-components'
import { useStatus, useStatusMsg } from '../reducers'
import { actions as acts } from '../constants'
import { fetchClubs } from '../actions/org'
import { fetchAllClubs } from '../actions/admin'
import { Select } from 'antd'

const { Option } = Select

const ClubSelect = ({ value, onChange, ...props }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [clubs, setClubs] = useState({}) // map of club id to club object
  const [val, setVal] = useState(null)
  const status = {
    org: useStatus(acts.FETCH_CLUBS),
    all: useStatus(acts.FETCH_ALL_CLUBS),
  }

  useEffect(() => {
    dispatch(osid ? fetchClubs(osid) : fetchAllClubs()).then(clbs => {
      setClubs(
        clbs.reduce((acc, curr) => {
          acc[curr.id] = curr
          return acc
        }, {}),
      )
    })
  }, [osid])

  useEffect(() => {
    // convert value to club id when club list or value changes
    if (clubs && Object.keys(clubs).length > 0 && value) {
      setVal(value.id)
    }
  }, [clubs, value])

  useStatusMsg(status, { error: 'Failed to fetch clubs' })

  const withinOrgContext = Boolean(osid)

  const opts = Object.keys(clubs).map(id => {
    const c = clubs[id]
    return (
      <Option key={c.id} value={c.id} data-name={c.name}>
        <div>
          {c.name}
          {withinOrgContext ? (
            <span style={{ fontSize: '12px', color: theme.font.color.secondary }}> {c.sid}</span>
          ) : (
            <>
              <span style={{ fontSize: '12px', color: theme.font.color.secondary }}>
                {' '}
                {c.org.name}:{' '}
              </span>
              <span
                style={{ fontSize: '12px', color: theme.font.color.secondary, fontWeight: 'bold' }}
              >
                {c.sid}
              </span>
            </>
          )}
        </div>
      </Option>
    )
  })

  const handleChange = cid => {
    setVal(cid)
    if (onChange) {
      const club = clubs[cid]
      onChange(club)
    }
  }
  if (!status.org.success && !status.all.success) {
    return 'Loading...'
  }

  return (
    <Select
      value={val}
      onChange={handleChange}
      showSearch
      filterOption={(input, option) => {
        const inp = input ? input.trim().toLowerCase() : ''
        const matchesOption = option['data-name'].trim().toLowerCase().indexOf(inp) >= 0
        return matchesOption || option.value.toLowerCase().indexOf(inp) >= 0
      }}
      {...props}
    >
      {opts}
    </Select>
  )
}

export default ClubSelect
