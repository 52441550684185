import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import { actions as acts } from '../../../constants'
import { generateEmailPreview } from '../../../actions/admin'
import { useStatus } from '../../../reducers'

const Preview = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [preview, setPreview] = useState(null)
  const status = useState(acts.GENERATE_EMAIL_PREVIEW)

  useEffect(() => {
    dispatch(generateEmailPreview(id, {})).then(res => {
      if (res.html) {
        setPreview({ __html: res.html })
      }
    })
  }, [id])

  return (
    <div style={{ marginTop: '2em' }}>
      {status.pending && <LoadingOutlined />}
      {preview && <div dangerouslySetInnerHTML={preview}></div>}
    </div>
  )
}

export default Preview
