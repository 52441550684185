import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { Tag, Table, Menu, Button, Dropdown } from 'antd'
import { LoadingOutlined, DownOutlined } from '@ant-design/icons'
import { paths, actions as acts } from '../../../constants'
import { useStatus, useStatusMsg } from '../../../reducers'
import * as actions from '../../../actions/admin'
import formatUtils from '../../../util/format'
import * as timeUtils from '../../../util/time'
import PageHeading from '../../../components/admin/PageHeading'
import PageTitle from '../../../components/admin/PageTitle'
import PromoCodeEditor from './PromoCodeEditor'
import { isExpired, neverExpires } from './utils'

const columns = [
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    width: 275,
    render: (val, record) => (
      <div>
        <Link to={paths.admin.billing.PROMO_CODE(record.id)}>{val}</Link>{' '}
        {!record.active && <Tag>Inactive</Tag>} {isExpired(record) && <Tag>Expired</Tag>}
      </div>
    ),
  },
  {
    title: 'Note',
    dataIndex: 'note',
    key: 'note',
    width: 275,
  },
  {
    title: 'Discount',
    dataIndex: 'discountCents',
    key: 'discountCents',
    width: 275,
    render: (val, record) => {
      const discounts = []
      if (record.discountPercent) {
        discounts.push(formatUtils.percent(record.discountPercent))
      }
      if (record.discountCents) {
        discounts.push(formatUtils.centsToDollars(record.discountCents))
      }
      return (
        <>
          <span>{discounts.join(', ')} </span>
          <span>{record.entireInvoiceApplicable ? <Tag>Entire Invoice</Tag> : null}</span>
        </>
      )
    },
  },
  {
    title: 'Valid Period (inclusive)',
    dataIndex: 'validFrom',
    key: 'validperiod',
    defaultSortOrder: 'descend',
    sorter: (a, b) => {
      if (neverExpires(a) && neverExpires(b)) {
        return 0
      } else if (neverExpires(a)) {
        return 1
      } else if (neverExpires(b)) {
        return -1
      }
      return new Date(a.validTo) - new Date(b.validTo)
    },
    width: 275,
    render: (val, record) => {
      if (neverExpires(record)) {
        return 'Never expires'
      }
      return `${timeUtils.formatDate(record.validFrom)} - ${timeUtils.formatDate(record.validTo)}`
    },
  },
]

const PromoCodes = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [creating, setCreating] = useState(false)
  const [promoCodes, setPromoCodes] = useState([])
  const status = {
    fetch: useStatus(acts.FETCH_ALL_PROMO_CODES),
  }

  useStatusMsg(status.fetch, { error: 'Failed to fetch promo codes' })

  useEffect(() => {
    dispatch(actions.fetchAllPromoCodes()).then(codes => setPromoCodes(codes))
  }, [])

  const handleMenuClick = e => {
    if (e.key === 'create') {
      setCreating(true)
    }
  }

  const onCreate = code => {
    if (code) {
      history.push(paths.admin.billing.PROMO_CODE(code.id))
    }
    setCreating(false)
  }

  const actionMenu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="create">Create</Menu.Item>
    </Menu>
  )

  return (
    <>
      <PageHeading>
        <PageTitle>Promo Codes</PageTitle>
        <Dropdown overlay={actionMenu} trigger={['click']}>
          <Button>
            Actions <DownOutlined />
          </Button>
        </Dropdown>
      </PageHeading>
      {!status.fetch.success && <LoadingOutlined />}
      {promoCodes && <Table columns={columns} dataSource={promoCodes} pagination={false} />}
      {creating && <PromoCodeEditor complete={onCreate} />}
    </>
  )
}

export default PromoCodes
