import React, { useContext, useReducer, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import { Select, Radio } from 'antd'
import { InputGroup, SelectContainer, Title, Desc } from './step'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import { cardBrands } from '../../../constants/billing'
import Attr from '../../../components/Attr'

const { Option } = Select

const hasCardRestriction = program => {
  if (program.draft && program.info) {
    return program.info.hasCardRestriction
  }
  return !!program.allowedBookingCardBrand
}

const initState = program => {
  return {
    allowedBookingCardBrand: program.allowedBookingCardBrand,
    info: {
      hasCardRestriction: hasCardRestriction(program),
    },
  }
}

const reducer = (state, action) => ({ ...state, ...action })

const CardRestriction = ({ onChange, clinic }) => {
  const theme = useContext(ThemeContext)
  const [state, dp] = useReducer(reducer, initState(clinic))

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange({
        allowedBookingCardBrand: state.allowedBookingCardBrand,
        info: state.info,
      })
    }
  }, [state])

  const opts = Object.entries(cardBrands).map(([key, value]) => (
    <Option key={key} value={key}>
      {value.label}
    </Option>
  ))

  const onSelectChange = e => {
    const update = {
      info: { hasCardRestriction: e.target.value },
    }
    if (!e.target.value) {
      // if we selected no, reset all fields
      update.allowedBookingCardBrand = null
    }
    dp(update)
  }

  const subStep = (
    <div>
      <Attr name="Card brand">
        <div>
          <Select
            value={state.allowedBookingCardBrand}
            onChange={brand => dp({ allowedBookingCardBrand: brand })}
            style={{ width: theme.spacing[7] }}
          >
            {opts}
          </Select>
        </div>
      </Attr>
    </div>
  )

  return (
    <>
      <InputGroup>
        <Title>Card restrictions</Title>
        <Desc>
          Would you like to restrict bookings for this {lower(typeLabel(clinic))} to users with a
          specific credit card brand?
        </Desc>
        <SelectContainer>
          <Radio.Group onChange={onSelectChange} value={state.info.hasCardRestriction}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </SelectContainer>
      </InputGroup>
      {state.info.hasCardRestriction && subStep}
    </>
  )
}

const Viewer = ({ program }) => {
  return (
    <div>
      {program.allowedBookingCardBrand ? (
        cardBrands[program.allowedBookingCardBrand].label
      ) : (
        <i>None</i>
      )}
    </div>
  )
}

const step = CardRestriction

const complete = ({ draft, info, allowedBookingCardBrand }) => {
  if (draft) {
    if (typeof info.hasCardRestriction !== 'boolean') {
      return false
    }
    if (info.hasCardRestriction && !allowedBookingCardBrand) {
      return false
    }
  }
  return true
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
