import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { InfoCircleOutlined, EllipsisOutlined } from '@ant-design/icons'
import { Breadcrumb, Tooltip, Select, Tag, Menu, Dropdown, Button, Input, Table, Modal } from 'antd'
import { useDispatch } from 'react-redux'
import Attr from '../../../components/Attr'
import * as adminActs from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import { useStatus, useStatusMsg } from '../../../reducers'
import { paths, actions as acts } from '../../../constants'
import { purchasablePermitStatuses } from '../../../constants/permit'
import { centsToDollars } from '../../../util/format'

const { TextArea } = Input
const { Option } = Select

const cols = ({ setEditing }) => [
  {
    title: '',
    width: '10px',
    dataIndex: 'status',
    key: 'status',
    render: (val, record) => {
      const status = purchasablePermitStatuses[val]
      if (!status) {
        return <Tag>Unknown</Tag>
      }
      return (
        <Tooltip title={status.description}>
          <Tag
            icon={<InfoCircleOutlined />}
            color={val === purchasablePermitStatuses.ENABLED.name ? 'green' : null}
          >
            {status.label}
          </Tag>
        </Tooltip>
      )
    },
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'SKU',
    dataIndex: 'sku',
    key: 'sku',
  },
  {
    title: 'Price',
    dataIndex: 'priceCents',
    key: 'priceCents',
    render: (val, record) => centsToDollars(val),
  },
  {
    title: '',
    width: '10px',
    render: (val, record) => {
      const handleMenuClick = e => {
        if (e.key === 'edit') {
          setEditing(record)
        }
      }
      const actionMenu = (
        <Menu onClick={handleMenuClick}>
          <Menu.Item key="edit">Edit</Menu.Item>
        </Menu>
      )
      return (
        <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
          <Button size="small">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      )
    },
  },
]

const EditPermitModal = ({ permit, complete }) => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState(permit.status)
  const updateStatus = useStatus(acts.UPDATE_PURCHASABLE_PERMIT)

  useStatusMsg(updateStatus, {
    pending: 'Updating permit...',
    success: 'Permit updated',
    error: 'Failed to update permit',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.UPDATE_PURCHASABLE_PERMIT))
    }
  }, [])

  const onOk = () => {
    dispatch(adminActs.updatePurchasablePermit(permit.id, { status })).then(updated => {
      if (typeof complete === 'function') {
        complete(updated)
      }
    })
  }
  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  const statusOptions = Object.values(purchasablePermitStatuses).map(s => (
    <Option key={s.name} value={s.name}>
      {s.label}
    </Option>
  ))

  return (
    <Modal title="Update purchasable permit" visible onOk={onOk} onCancel={onCancel}>
      <Attr name="Name">
        <div>{permit.name}</div>
      </Attr>
      <Attr name="SKU">
        <div>{permit.sku}</div>
      </Attr>
      <Attr name="Price">
        <div>{centsToDollars(permit.priceCents)}</div>
      </Attr>
      <Attr name="Status">
        <div>
          <Select value={status} onChange={v => setStatus(v)} style={{ width: '120px' }}>
            {statusOptions}
          </Select>
        </div>
      </Attr>
    </Modal>
  )
}

const PurchasablePermits = () => {
  const dispatch = useDispatch()
  const [permits, setPermits] = useState(null)
  const [editing, setEditing] = useState(null)
  const status = useStatus(acts.FETCH_PURCHASABLE_PERMITS)

  useEffect(() => {
    dispatch(adminActs.fetchPurchasablePermits()).then(ps => setPermits(ps))

    return () => {
      dispatch(clearStatus(acts.FETCH_PURCHASABLE_PERMITS))
    }
  }, [])

  useStatusMsg(status, {
    error: 'Failed to fetch purchasable permits',
  })

  const onEditComplete = editedPermit => {
    if (editedPermit) {
      setPermits(prev => {
        const next = [...prev]
        const idx = next.findIndex(a => a.id === editedPermit.id)
        if (idx > -1) {
          next[idx] = editedPermit
        }
        return next
      })
    }
    setEditing(null)
  }

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.admin.PERMITS()}>Permits</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Purchasable permits</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h1 style={{ marginRight: '.5em' }}>Purchasable Permits</h1>
      </div>
      <Table rowKey="id" columns={cols({ setEditing })} dataSource={permits} pagination={false} />
      {editing && <EditPermitModal permit={editing} complete={onEditComplete} />}
    </div>
  )
}

export default PurchasablePermits
