import { useState } from 'react'
import { Table } from 'antd'
import { paths } from '../../../constants'
import { AdminPackageSetLink } from '../../../components/package'
import { EditPackageSet, packageSetCols } from '../packages/sets'

export const PackageSets = ({ sets, onChange, ...rest }) => {
  const changer = typeof onChange === 'function' ? onChange : null
  const [editing, setEditing] = useState(null)

  const onEdit = set => setEditing(set)

  const onDelete = deletedSet => {}

  const excludedCols = ['org', 'venue']
  const cols = packageSetCols({ onEdit, onDelete }).filter(col => !excludedCols.includes(col.key))

  const onEditComplete = updatedSet => {
    if (updatedSet) {
      const next = [...sets]
      const idx = next.findIndex(s => s.id === updatedSet.id)
      if (idx !== -1) {
        next[idx] = updatedSet
      }
      changer(next)
    }
    setEditing(null)
  }

  return (
    <div>
      <Table size="small" columns={cols} dataSource={sets} {...rest} />
      {editing && <EditPackageSet set={editing} complete={onEditComplete} />}
    </div>
  )
}
