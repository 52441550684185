import { actions as acts } from '../constants'

export default function loading(state = {}, { type, payload, loadId }) {
  // TODO this clear may not work with loadId (and maybe it shouldn't work with it)
  if (type === acts.CLEAR_PENDING) {
    return {
      ...state,
      [payload]: null,
    }
  }

  const matches = /(.*)_(PENDING|FAILURE|SUCCESS)/.exec(type)
  if (!matches) {
    return state
  }
  const [, name, promiseState] = matches

  // Store loading
  // e.g. sets value to true when ADD_ATTACHMENT_PENDING received
  //      else value to false when ADD_ATTACHMENT_SUCCESS
  //      or ADD_ATTACHMENT_FAILURE received
  // if loadId is provided, the value will be an array of loadIds.
  // when PENDING is received the loadId is appended to the value
  // else, the loadId is removed from the value
  const newState = { ...state }
  if (promiseState === 'PENDING') {
    if (typeof loadId !== 'undefined') {
      newState[name] = !Array.isArray(newState[name]) ? [loadId] : [...newState[name], loadId]
    } else {
      newState[name] = true
    }
  } else {
    if (typeof loadId !== 'undefined') {
      newState[name] = !Array.isArray(newState[name])
        ? []
        : newState[name].filter(id => id !== loadId)
    } else {
      newState[name] = false
    }
  }
  return newState
}

// if loadId is provided, returns true if loadId is found in action,
// else returns the value of the action map
export function getLoading(state, actionName, loadId) {
  if (typeof loadId !== 'undefined') {
    const vals = state[actionName]
    if (Array.isArray(vals)) {
      const i = vals.indexOf(loadId)
      return i !== -1 ? true : false
    }
  }
  return state[actionName]
}
