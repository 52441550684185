import React, { useState } from 'react'
import { Modal, Input } from 'antd'

const NoteEditor = ({ title = 'Note', note, complete }) => {
  const [input, setInput] = useState(note)

  const onOk = () => {
    if (typeof complete === 'function') {
      complete(input)
    }
  }

  return (
    <Modal
      visible
      title={title}
      okText="Save"
      onOk={onOk}
      onCancel={() => typeof complete === 'function' && complete(note)}
    >
      <Input.TextArea rows={4} value={input} onChange={e => setInput(e.target.value)} />
    </Modal>
  )
}

export default NoteEditor
