export const act = (actionName, action) => {
  return (...args) =>
    async dispatch => {
      dispatch({ type: `${actionName}_PENDING`, payload: args })
      try {
        const res = await action.apply(this, args)
        dispatch({ type: `${actionName}_SUCCESS`, payload: res })
        return res
      } catch (err) {
        dispatch({ type: `${actionName}_FAILURE`, payload: err })
        console.log(err)
        throw err
      }
    }
}

export default act
