import { useContext, useState, useEffect } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { ThemeContext } from 'styled-components'
import { Tag, Table, Breadcrumb } from 'antd'
import { Link, useParams } from 'react-router-dom'
import { actions as acts, paths } from '../../../constants'
import { useDispatch } from 'react-redux'
import { fetchConstsByName, fetchPackage } from '../../../actions/admin'
import Attr from '../../../components/Attr'
import { Noner, ID, Title } from '../../../components/common'
import { AdminOrgLink } from '../../../components/org'
import { AdminVenueLink } from '../../../components/venue'
import {
  UserPackageInvoiceLink,
  PackageItems,
  RedemptionTerm,
  AdminPackageSetLink,
} from '../../../components/package'
import { AdminUserLink } from '../../../components/user'
import { AdminInvoiceLink } from '../../../components/invoice'
import { formatDateTimeTz } from '../../../util/time'
import { centsToDollars } from '../../../util/format'

const userPackageCols = ({ orderConsts }) => [
  {
    title: 'User package',
    key: 'id',
    dataIndex: 'id',
    render: (val, record) => (
      <div>
        <Link to={paths.admin.USER_PACKAGE(val)}>{record.name}</Link>
      </div>
    ),
  },
  {
    title: 'User',
    key: 'user',
    dataIndex: 'user',
    render: (val, record) => (
      <div>
        <AdminUserLink user={val} />
      </div>
    ),
  },
  {
    title: 'Purchaser',
    key: 'purchaser',
    dataIndex: 'purchaser',
    render: (val, record) => (
      <div>
        <AdminUserLink user={val} />
      </div>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status',
    render: (val, record) => <Tag>{val}</Tag>,
  },
  {
    title: 'Invoice',
    key: 'orderItems',
    dataIndex: 'orderItems',
    render: (val, record) => {
      return <UserPackageInvoiceLink orderItems={val}>View invoice</UserPackageInvoiceLink>
    },
  },
  {
    title: 'Created',
    key: 'createdAt',
    dataIndex: 'createdAt',
    defaultSortOrder: 'descend',
    sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    render: (val, record) => <div>{formatDateTimeTz(val)}</div>,
  },
]

export const Package = () => {
  const { packageId } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [pkg, setPkg] = useState(null)
  const [orderConsts, setOrderConsts] = useState(null)

  useEffect(() => {
    dispatch(fetchPackage({ packageId })).then(pkg => setPkg(pkg))
    dispatch(fetchConstsByName({ name: 'order' })).then(consts => setOrderConsts(consts))
  }, [])

  if (!pkg || !orderConsts) {
    return <LoadingOutlined />
  }

  return (
    <>
      <Breadcrumb separator=">">
        <Breadcrumb.Item>
          <Link to={paths.admin.PACKAGES}>Packages</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{pkg.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div>
        <div
          style={{
            display: 'flex',
            marginTop: theme.spacing[3],
            marginBottom: theme.spacing[3],
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title>{pkg.name}</Title>
        </div>
        <Attr name="ID">
          <ID>{pkg.id}</ID>
        </Attr>
        <Attr name="Description">
          <div>
            <Noner none="No description">{pkg.description}</Noner>
          </div>
        </Attr>
        <Attr name="Purchase status">
          <div>
            <Tag>{pkg.purchaseStatus}</Tag>
          </div>
        </Attr>
        <Attr name="Order in set">
          <div>{pkg.order}</div>
        </Attr>
        <Attr name="Org">
          <div>
            <AdminOrgLink org={pkg.set.org}>
              <Noner cond={pkg.set.org}>{pkg.set.org?.name}</Noner>
            </AdminOrgLink>
          </div>
        </Attr>
        <Attr name="Venue">
          <div>
            <AdminVenueLink venue={pkg.set.venue}>
              <Noner cond={pkg.set.venue}>{pkg.set.venue?.name}</Noner>
            </AdminVenueLink>
          </div>
        </Attr>
        <Attr name="Set">
          <div>
            <AdminPackageSetLink set={pkg.set}>
              <Noner cond={pkg.set}>{pkg.set?.name}</Noner>
            </AdminPackageSetLink>
          </div>
        </Attr>
        <Attr name="Price">
          <div>{centsToDollars(pkg.priceCents)}</div>
        </Attr>
        <Attr name="Redemption term">
          <RedemptionTerm {...pkg} />
        </Attr>
        <Attr name="Purchase expiration date">
          <div>
            <Noner none="No expiration">{pkg.expirationDate}</Noner>
          </div>
        </Attr>
        <Attr name="Items">
          <PackageItems {...pkg} />
        </Attr>
        <Attr name="User purchases">
          <Table
            size="small"
            columns={userPackageCols({ orderConsts })}
            dataSource={pkg.userPackages}
            pagination={false}
          />
        </Attr>
      </div>
    </>
  )
}

export default Package
