import { useContext } from 'react'
import { ConstsContext } from '../contexts/consts'
import { paths } from '../constants'
import { Link } from 'react-router-dom'
import { Tag, Table } from 'antd'
import { Noner } from './common'
import { ConstSelect } from './consts'
import { AdminInvoiceLink } from './invoice'
import Attr from './Attr'

export const PackageSetVisibilitySelector = ({ value, onChange }) => {
  return (
    <ConstSelect
      name="package-set"
      constKey="visibilityOptions"
      value={value}
      onChange={onChange}
    />
  )
}

export const AdminPackageLink = ({ children, pkg, ...rest }) => {
  let sig = null
  let childs = children

  if (pkg) {
    sig = typeof pkg === 'string' ? pkg : pkg.id
  }

  if (!sig) {
    return childs
  }

  // render something if children is not passed
  if (!childs) {
    childs = typeof pkg === 'string' ? pkg : pkg.name
  }

  return (
    <Link to={paths.admin.PACKAGE(sig)} {...rest}>
      {childs}
    </Link>
  )
}

export const AdminPackageSetLink = ({ children, set, ...rest }) => {
  let sig = null
  let childs = children

  if (set) {
    sig = typeof set === 'string' ? set : set.id
  }

  if (!sig) {
    return childs
  }

  // render something if children is not passed
  if (!childs) {
    childs = typeof set === 'string' ? set : set.name
  }

  return (
    <Link to={paths.admin.PACKAGE_SET(sig)} {...rest}>
      {childs}
    </Link>
  )
}

export const RedemptionTerm = ({ redemptionDateStart, redemptionDateEnd, redemptionTermDays }) => {
  if (!redemptionDateStart && !redemptionDateEnd && !redemptionTermDays) {
    return <div>Unlimited</div>
  }
  return (
    <div>
      {redemptionDateStart && (
        <Attr name="Start" style={{ margin: 0 }}>
          {redemptionDateStart}
        </Attr>
      )}
      {redemptionDateEnd && (
        <Attr name="End" style={{ margin: 0 }}>
          {redemptionDateEnd}
        </Attr>
      )}
      {redemptionTermDays && (
        <Attr name="Days" style={{ margin: 0 }}>
          {redemptionTermDays}
        </Attr>
      )}
    </div>
  )
}

const packageItemsCols = () => [
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (val, record) => {
      if (!val) {
        return <Noner none="No type" />
      }
      return <Tag>{val}</Tag>
    },
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
  },
]

export const PackageItems = ({ items }) => {
  return <Table size="small" columns={packageItemsCols()} dataSource={items} pagination={false} />
}

// given a set of order items that a user package is associated with, filters items and returns a link
// to the the invoice that was used to purchase this user package.
// expects order to be expanded on order item
export const UserPackageInvoiceLink = ({ children, orderItems, none = 'No invoice' }) => {
  const { order: orderConsts } = useContext(ConstsContext)

  if (!Array.isArray(orderItems) || orderItems.length === 0) {
    return <Noner none={none} />
  }
  const packageOrderItem = orderItems.find(v => v.type === orderConsts.orderItemTypes.PACKAGE)
  return (
    <Noner none={none}>
      {packageOrderItem ? (
        <AdminInvoiceLink invoice={packageOrderItem.order.invoice}>{children}</AdminInvoiceLink>
      ) : null}
    </Noner>
  )
}
