import React, { useReducer, useEffect } from 'react'
import { Radio } from 'antd'
import { InputGroup, SelectContainer, Title, Desc } from './step'
import ClubSelect from '../../../components/ClubSelect'
import { ClubList } from '../../../components/club'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import { ClubLink } from '../../../components/club'
import Attr from '../../../components/Attr'

// Determines the value of info.hasClub
// Protects against cases where info.hasClub does not exist
// (i.e. when program is no longer a draft)
const hasClubValue = program => {
  if (program.draft && program.info) {
    return program.info.hasClub
  } else {
    if (Array.isArray(program.clubs) && program.clubs.length > 0) {
      return true
    }
    return false
  }
  return null
}

// flattens any club-program to a club if they exist
const normalizeClubs = clubs => clubs.map(s => (s.club ? s.club : s))

const initState = program => ({
  clubs: Array.isArray(program.clubs) ? normalizeClubs(program.clubs) : [],
  info: {
    hasClub: hasClubValue(program),
  },
})

const reducer = (state, action) => ({ ...state, ...action })

const Clubs = ({ clinic, onChange }) => {
  const program = clinic
  const [state, dp] = useReducer(reducer, initState(program))

  useEffect(() => {
    if (typeof onChange === 'function') {
      const data = {
        ...state,
        clubs: state.clubs,
      }
      if (program.draft) {
        onChange(data)
      } else {
        onChange({ ...data, info: undefined })
      }
    }
  }, [state])

  const onSelectChange = e => {
    const update = {
      info: { hasClub: e.target.value },
    }
    if (!e.target.value) {
      update.clubs = []
    }
    dp(update)
  }

  let clubsDisplay = null

  if (state.info.hasClub) {
    let clubList = <i>No clubs</i>
    if (Array.isArray(state.clubs) && state.clubs.length > 0) {
      clubList = (
        <ClubList
          clubs={normalizeClubs(state.clubs)}
          onRemove={clb => dp({ clubs: state.clubs.filter(c => c.id !== clb.id) })}
        />
      )
    }

    const onRemove = clb => dp({ clubs: state.clubs.filter(c => c.id !== clb.id) })
    const onSelectClub = club => dp({ clubs: [...state.clubs, club] })
    clubsDisplay = (
      <div>
        <ClubSelect
          placeholder="Select a club"
          style={{ width: '100%' }}
          value={null}
          onChange={onSelectClub}
        />
        <ClubList clubs={state.clubs} onRemove={onRemove} />
      </div>
    )
  }

  return (
    <>
      <InputGroup>
        <Title>Clubs</Title>
        <Desc>
          <p>
            Select a set of clubs to host this {lower(typeLabel(program))}. If no club is selected
            this {lower(typeLabel(program))} will appear to be hosted by the current organization.
          </p>
        </Desc>
        <SelectContainer>
          <Radio.Group onChange={onSelectChange} value={state.info.hasClub}>
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </SelectContainer>
      </InputGroup>
      {clubsDisplay}
    </>
  )
}

const Viewer = ({ program }) => {
  if (!Array.isArray(program.clubs) || program.clubs.length === 0) {
    return <i>No clubs</i>
  }
  return <ClubList clubs={normalizeClubs(program.clubs)} />
}

const step = Clubs

const complete = ({ draft, info, clubs }) => {
  if (draft) {
    const clubsExists = Array.isArray(clubs) && clubs.length > 0
    if (!info || typeof info.hasClub !== 'boolean' || (info.hasClub && !clubsExists)) {
      return false
    }
  }
  return true
}

const applicable = () => true

export default { step, Viewer, complete, applicable }
