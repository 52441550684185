import React, { useEffect, useReducer } from 'react'
import { InputGroup, Input, Title, Desc } from './step'
import { typeLabel } from '../../../util/program'
import { lower } from '../../../util/string'
import Attr from '../../../components/Attr'
import ReactHtmlParser from 'react-html-parser'
import Editor from '../../../components/Editor'

const initState = ({ name, description, readBeforeBooking }) => ({
  name: name ? name : '',
  description: description ? description : '',
  readBeforeBooking: readBeforeBooking ? readBeforeBooking : '',
})

const reducer = (state, action) => ({ ...state, ...action })

const BasicInformation = ({ clinic, onChange }) => {
  const [state, dp] = useReducer(reducer, initState(clinic))

  useEffect(() => {
    if (onChange && typeof onChange === 'function') {
      onChange(state)
    }
  }, [state])

  return (
    <>
      <InputGroup>
        <Title>Name your {lower(typeLabel(clinic))}</Title>
        <Desc>Give a memorable name for users to remember</Desc>
        <Input
          value={state.name}
          placeholder="Example: Cardio Tennis"
          onChange={e => dp({ name: e.target.value })}
        />
      </InputGroup>
      <InputGroup>
        <Title>Describe your {lower(typeLabel(clinic))}</Title>
        <Desc>What should players expect from this {lower(typeLabel(clinic))}?</Desc>

        <Editor value={state.description} onChange={content => dp({ description: content })} />
      </InputGroup>
      <InputGroup>
        <Title>Read Before Booking</Title>
        <Desc>What should players know before booking this {lower(typeLabel(clinic))}?</Desc>
        <Editor
          value={state.readBeforeBooking}
          onChange={content => dp({ readBeforeBooking: content })}
        />
      </InputGroup>
    </>
  )
}

const Viewer = ({ program }) => {
  return (
    <>
      <Attr name="Name">
        <div>{program.name}</div>
      </Attr>
      <Attr name="Description">
        <div>{ReactHtmlParser(program.description)}</div>
      </Attr>
      <Attr name="Read before booking">
        {program.readBeforeBooking ? (
          <div>{ReactHtmlParser(program.readBeforeBooking)}</div>
        ) : (
          <div>
            <i>Empty</i>
          </div>
        )}
      </Attr>
    </>
  )
}

const step = BasicInformation

const complete = ({ name, description }) =>
  name && name.length > 0 && description && description.length > 0

const applicable = () => true

export default { step, Viewer, complete, applicable }
