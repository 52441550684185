import { descConst as dc } from './util'

export const purchasablePermitStatuses = {
  ...dc('ENABLED', 'Enabled', 'Permit is purchasable on website'),
  ...dc('DISABLED', 'Disabled', 'Permit is not purchasable on website'),
}

export const scraperStatuses = {
  ...dc('NOT_STARTED', 'Not started', 'The scraping phase has not been started'),
  ...dc(
    'READY_TO_START',
    'Ready to start',
    'The scraping phase has begun and we are waiting the scraper to start',
  ),
  ...dc('STARTED', 'Started', 'The permit is currently being scraped'),
  ...dc('SUCCESS', 'Success', 'The permit was successfully scraped'),
  ...dc('FAILED', 'Failed', 'Failed to scrape the permit'),
  ...dc('CANCELED', 'Canceled', 'The scraping job was canceled for the permit'),
}

export const fulfillmentStatuses = {
  ...dc('NOT_STARTED', 'Not started', 'The fulfillment phase has not been started'),
  ...dc('AUTOMATIC_STARTED', 'Automatic started', 'Automatic fulfillment has begun'),
  ...dc('AUTOMATIC_SUCCESS', 'Automatic success', 'Automatic fulfillment has succeeded'),
  ...dc('AUTOMATIC_FAILED', 'Automatic failed', 'Automatic fulfillment has failed'),
  ...dc('MANUAL_SUCCESS', 'Manual success', 'Manual fulfillment has succeeded'),
}
