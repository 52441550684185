import React, { useLayoutEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { matchPath, useLocation, useParams, Redirect, Switch, Route } from 'react-router-dom'
import { isLoggedIn } from '../util/auth'
import { setOrg } from '../actions/org'
import CreateClass from '../containers/CreateClass'
import Roles from '../containers/roles'
import Role from '../containers/roles/Role'
import Programs from '../containers/Programs'
import Program from '../containers/Program'
import EditProgramPost from '../containers/Program/EditProgramPost'
import NewProgramPost from '../containers/Program/NewProgramPost'
import ProgramPost from '../containers/Program/ProgramPost'
import Locations from '../containers/Locations'
import NewLocation from '../containers/Locations/NewLocation'
import EditLocation from '../containers/Locations/EditLocation'
import Clubs from '../containers/clubs'
import Club from '../containers/clubs/Club'
import NewClub from '../containers/clubs/NewClub'
import EditClub from '../containers/clubs/EditClub'
import NewClubPost from '../containers/clubs/NewClubPost'
import EditClubPost from '../containers/clubs/EditClubPost'
import ClubPost from '../containers/clubs/ClubPost'
import Sponsors from '../containers/sponsors'
import Sponsor from '../containers/sponsors/Sponsor'
import NewSponsor from '../containers/sponsors/NewSponsor'
import EditSponsor from '../containers/sponsors/EditSponsor'
import Products from '../containers/products'
import NewProduct from '../containers/products/NewProduct'
import Product from '../containers/products/Product'
import EditProduct from '../containers/products/EditProduct'
import Orders from '../containers/orders'
import Order from '../containers/orders/Order'
import Pos from '../containers/pos'
import Tags from '../containers/tags'
import NewOrgTag from '../containers/tags/NewOrgTag'
import Tag from '../containers/tags/Tag'
import EditTag from '../containers/tags/EditTag'
import OrgHome from '../containers/OrgHome'
import Header, { FocusHeader } from '../components/header'
import OrgSettings from '../containers/org-settings'
import NewClubVideo from '../containers/clubs/NewClubVideo'
import EditClubVideo from '../containers/clubs/EditClubVideo'

const OrgRoute = ({ component: Component, ...props }) => (
  <Route
    {...props}
    render={props => {
      if (!isLoggedIn()) {
        return <Redirect to="/login" />
      }
      return <Component {...props} />
    }}
  />
)

const NavRoute = ({ component: Component, ...props }) => (
  <OrgRoute
    {...props}
    component={props => {
      return (
        <>
          <Header />
          <Component {...props} />
        </>
      )
    }}
  />
)

const NavlessRoute = ({ component: Component, ...props }) => (
  <OrgRoute {...props} component={props => <Component {...props} />} />
)

const OrgRoutes = () => {
  const loc = useLocation()
  const dispatch = useDispatch()
  const org = useSelector(s => s.org)
  const match = matchPath(loc.pathname, { path: '/orgs/:osid' })

  useLayoutEffect(() => {
    if (match) {
      const { osid } = match.params
      if (!org || osid !== org.sid) {
        // get org for osid in url
        dispatch(setOrg(osid))
      }
    }
  }, [loc.pathname])

  return (
    <Switch>
      <NavRoute path="/orgs/:osid/programs/create/:id" component={CreateClass} />
      <NavRoute path="/orgs/:osid/programs/create" component={CreateClass} />
      <NavlessRoute
        path="/orgs/:osid/programs/:id/posts/:postId/edit"
        component={EditProgramPost}
      />
      <NavlessRoute path="/orgs/:osid/programs/:id/posts/new" component={NewProgramPost} />
      <NavlessRoute path="/orgs/:osid/programs/:id/posts/:postId" component={ProgramPost} />
      <NavRoute path="/orgs/:osid/programs/:id" component={Program} />
      <NavRoute path="/orgs/:osid/programs" component={Programs} />
      <NavRoute path="/orgs/:osid/roles/:id" component={Role} />
      <NavRoute path="/orgs/:osid/roles" component={Roles} />
      <NavlessRoute path="/orgs/:osid/products/new" component={NewProduct} />
      <NavlessRoute path="/orgs/:osid/products/:id/edit" component={EditProduct} />
      <NavRoute path="/orgs/:osid/products/:id" component={Product} />
      <NavRoute path="/orgs/:osid/products" component={Products} />
      <NavlessRoute path="/orgs/:osid/locations/:id/edit" component={EditLocation} />
      <NavlessRoute path="/orgs/:osid/locations/new" component={NewLocation} />
      <NavRoute path="/orgs/:osid/locations" component={Locations} />
      <NavlessRoute path="/orgs/:osid/clubs/:id/posts/:postId/edit" component={EditClubPost} />
      <NavlessRoute path="/orgs/:osid/clubs/:id/posts/new" component={NewClubPost} />
      <NavlessRoute path="/orgs/:osid/clubs/:id/posts/:postId" component={ClubPost} />
      <NavlessRoute path="/orgs/:osid/clubs/:id/videos/:videoId/edit" component={EditClubVideo} />
      <NavlessRoute path="/orgs/:osid/clubs/:id/videos/new" component={NewClubVideo} />
      <NavlessRoute path="/orgs/:osid/clubs/:id/edit" component={EditClub} />
      <NavlessRoute path="/orgs/:osid/clubs/new" component={NewClub} />
      <NavRoute path="/orgs/:osid/clubs/:id" component={Club} />
      <NavRoute path="/orgs/:osid/clubs" component={Clubs} />
      <NavlessRoute path="/orgs/:osid/sponsors/:id/edit" component={EditSponsor} />
      <NavlessRoute path="/orgs/:osid/sponsors/new" component={NewSponsor} />
      <NavRoute path="/orgs/:osid/sponsors/:id" component={Sponsor} />
      <NavRoute path="/orgs/:osid/sponsors" component={Sponsors} />
      <NavRoute path="/orgs/:osid/orders/:orderId" component={Order} />
      <NavRoute path="/orgs/:osid/orders" component={Orders} />
      <NavRoute path="/orgs/:osid/pos" component={Pos} />
      <NavlessRoute path="/orgs/:osid/tags/:sig/edit" component={EditTag} />
      <NavlessRoute path="/orgs/:osid/tags/new" component={NewOrgTag} />
      <NavRoute path="/orgs/:osid/tags/:sig" component={Tag} />
      <NavRoute path="/orgs/:osid/tags" component={Tags} />
      <NavRoute path="/orgs/:osid/settings" component={OrgSettings} />
      <NavRoute path="/orgs/:osid" component={OrgHome} />
    </Switch>
  )
}

export default OrgRoutes
