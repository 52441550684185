import React from 'react'
import { ProgramCard } from '../../../components/program'
import { formatName } from '../../../util/user'

const Programs = ({ pro, programs = [] }) => {
  const prgs = programs.map(prg => <ProgramCard key={prg.id} program={prg} />)
  return (
    <>
      <div style={{ marginBottom: '2em' }}>
        Programs currently bookable and visible on {formatName(pro.page.user)}'s page
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>{prgs}</div>
    </>
  )
}

export default Programs
