import React from 'react'
import { Link } from 'react-router-dom'
import { paths } from '../../constants'

const UserLink = ({ user }) => {
  if (!user) {
    return null
  }

  return <Link to={paths.admin.USER(user.id)}>{user.name}</Link>
}

export default UserLink
