import React, { useEffect, useState } from 'react'
import { Button, Modal, Breadcrumb, Tabs, Menu, Tag, Dropdown } from 'antd'
import { Cta, Head, Title, PaddedContainer } from '../../components/common'
import Attr from '../../components/Attr'
import { EllipsisOutlined, LoadingOutlined, DownOutlined } from '@ant-design/icons'
import { features, paths, actions as acts } from '../../constants'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchRole, assignRoleToUser, unassignRoleFromUser } from '../../actions/org'
import { useStatus, useStatusMsg } from '../../reducers'
import { clearStatus } from '../../actions/status'
import UserSelect from '../../components/UserSelect'

const { TabPane } = Tabs

const Overview = ({ role }) => {
  const perms = role.perms.map(p => <div key={p.id}>{p.perm.label}</div>)
  return (
    <div>
      <Attr name="Description">
        <div>{role.description}</div>
      </Attr>
      <Attr name="Permissions">{perms}</Attr>
      <Attr name="Created">
        <div>{role.createdAt}</div>
      </Attr>
    </div>
  )
}

const Users = ({ role, onUsersChange }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()

  const onUnassignRole = (roleId, userId) => () => {
    dispatch(unassignRoleFromUser(osid, roleId, userId))
      .then(users => {
        if (typeof onUsersChange === 'function') {
          onUsersChange(users)
        }
      })
      .catch(e => console.log(e))
  }

  const handleActionMenu = ur => e => {
    // TODO enable this once we have endpoints to unassign role on the backend
    //if (e.key === 'unassign') {
    //  Modal.confirm({
    //    title: `Unassign ${ur.user.name} from role "${role.name}"?`,
    //    okText: 'Unassign',
    //    okButtonProps: {
    //      type: 'primary',
    //      danger: true,
    //    },
    //    onOk: onUnassignRole(role.id, ur.user.id),
    //  })
    //}
  }

  const actionMenu = ur => (
    <Menu onClick={handleActionMenu(ur)}>
      <Menu.Item key="unassign">Unassign role</Menu.Item>
    </Menu>
  )

  const users = role.users.map(ur => (
    <div
      key={ur.id}
      style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 0.3fr 0.1fr', marginBottom: '2em' }}
    >
      <div style={{ marginRight: '1em' }}>
        {ur.user.name}
        <small style={{ display: 'block' }}>{ur.user.email}</small>
      </div>
      <div>{ur.src}</div>
      <div>{ur.createdAt}</div>
      <div>
        <Dropdown overlay={actionMenu(ur)} trigger={['click']} placement="bottomRight">
          <Button size="small">
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      </div>
    </div>
  ))

  return (
    <>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 0.3fr 0.1fr',
          marginBottom: '2em',
          textTransform: 'uppercase',
          fontSize: '11px',
        }}
      >
        <span>Name</span>
        <span>Source</span>
        <span>Date role assigned</span>
        <div></div>
      </div>
      <div>{users}</div>
    </>
  )
}

const AssignRoleModal = ({ role, complete }) => {
  const { osid, id } = useParams()
  const dispatch = useDispatch()
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const status = useStatus(acts.ASSIGN_ROLE_TO_USER)

  useEffect(() => {
    return () => dispatch(clearStatus(acts.ASSIGN_ROLE_TO_USER))
  }, [])

  useStatusMsg(status, {
    pending: 'Assigning role...',
    error: 'Failed to assign role',
    success: 'Role assigned',
  })

  const formComplete = user !== null

  const onOk = () => {
    dispatch(assignRoleToUser(osid, id, user.id))
      .then(users => {
        if (typeof complete === 'function') {
          complete(users)
        }
      })
      .catch(e => console.log(e))
  }

  const onCancel = () => {
    if (typeof complete === 'function') {
      complete()
    }
  }

  return (
    <Modal
      title={`Assign role "${role.name}" to a user`}
      visible
      okText="Assign"
      onOk={onOk}
      okButtonProps={{
        disabled: !formComplete || status.pending,
        loading: !!status.pending,
      }}
      onCancel={onCancel}
    >
      <Attr name="User">
        <div>
          <UserSelect
            placeholder="Search users by name or id"
            style={{ width: '100%' }}
            value={user}
            onChange={e => setUser(e)}
          />
        </div>
      </Attr>
    </Modal>
  )
}

const Role = () => {
  const { osid, id } = useParams()
  const dispatch = useDispatch()
  const [role, setRole] = useState(null)
  const [assigningRole, setAssigningRole] = useState(false)
  const status = useStatus(acts.FETCH_ROLE)
  const loading = status.pending || !role

  useEffect(() => {
    dispatch(fetchRole(osid, id)).then(r => setRole(r))
  }, [])

  let display = <LoadingOutlined />

  if (!loading) {
    const handleActionMenu = e => {
      if (e.key === 'assign') {
        setAssigningRole(true)
      }
    }

    const actionMenu = (
      <Menu onClick={handleActionMenu}>
        <Menu.Item key="assign">Assign role</Menu.Item>
      </Menu>
    )

    display = (
      <>
        <Head>
          <Title>{role.name}</Title>
          {role.systemCode ? <Tag>{role.systemCode}</Tag> : null}
          <div style={{ flexGrow: 1 }}></div>
          <Dropdown overlay={actionMenu} trigger={['click']} placement="bottomRight">
            <Cta>
              Actions <DownOutlined />
            </Cta>
          </Dropdown>
        </Head>
        <Tabs defaultActiveKey="overview">
          <TabPane tab="Overview" key="overview">
            <Overview role={role} />
          </TabPane>
          <TabPane tab="Users" key="users">
            <Users role={role} onUsersChange={users => setRole(prev => ({ ...prev, users }))} />
          </TabPane>
        </Tabs>
      </>
    )
  }

  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.org.ROLES(osid)}>{features.role.name.plural}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{loading ? id : role.name}</Breadcrumb.Item>
      </Breadcrumb>
      {display}
      {assigningRole && (
        <AssignRoleModal
          role={role}
          complete={users => {
            if (users) {
              setRole(prev => ({ ...prev, users }))
            }
            setAssigningRole(false)
          }}
        />
      )}
    </PaddedContainer>
  )
}

export default Role
