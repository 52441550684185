import { useState } from 'react'
import { Select } from 'antd'
import { btlSports } from '../constants'
import { Noner } from './common'

const { Option } = Select

export const SportSelect = ({ value, onChange, ...props }) => {
  const [val, setVal] = useState(value)

  const opts = Object.keys(btlSports).map(k => {
    const s = btlSports[k]
    return (
      <Option key={s.name} value={s.name} data-name={s.label}>
        <div>{s.label}</div>
      </Option>
    )
  })

  const handleChange = name => {
    setVal(name)
    if (typeof onChange === 'function') {
      onChange(name)
    }
  }

  return (
    <Select
      value={val}
      onChange={handleChange}
      showSearch
      placeholder="Select a sport"
      filterOption={(input, option) => {
        const inp = input ? input.toLowerCase() : ''
        const matchesOption = option['data-name'].trim().toLowerCase().indexOf(inp) >= 0
        return matchesOption || option.value.toLowerCase().indexOf(inp) >= 0
      }}
      {...props}
    >
      {opts}
    </Select>
  )
}

export const SportDisplay = ({ sport, none = 'No sport' }) => {
  if (!sport || !btlSports[sport]) {
    return <Noner none={none}></Noner>
  }
  return <span>{btlSports[sport].label}</span>
}
