import { v4 as uuid } from 'uuid'
import { useEffect, useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useDropzone } from 'react-dropzone'
import Papa from 'papaparse'
import { Link, useParams } from 'react-router-dom'
import { ThemeContext, useTheme } from 'styled-components'
import { paths, actions as acts } from '../../../constants'
import { createVenue } from '../../../actions/admin'
import { clearStatus } from '../../../actions/status'
import { useStatus, useStatusMsg } from '../../../reducers'
import { Steps, Select, Breadcrumb, Dropdown, Menu, Modal, Table, message } from 'antd'
import { Button, Title, PaddedContainer, Head, Cta as CtaButton } from '../../../components/common'
import Attr from '../../../components/Attr'
import Dropzone from '../../../components/Dropzone'

const FileUpload = ({ onLoad }) => {
  const theme = useTheme(ThemeContext)
  const [file, setFile] = useState(null)
  const [message, setMessage] = useState(
    <>
      <div style={{ marginBottom: '.5em' }}>Select a CSV file with venue data.</div>
      <div>Ensure column headers are present.</div>
    </>,
  )
  const loader = typeof onLoad === 'function' ? onLoad : () => {}

  const onDrop = useCallback(acceptedFiles => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()
      reader.addEventListener('progress', event => {
        if (event.loaded && event.total) {
          const percent = (event.loaded / event.total) * 100
          setMessage(`Loading... ${Math.round(percent)}%`)
        }
      })
      reader.onabort = () => alert('File reading was aborted')
      reader.onerror = () => alert('File reading has failed')
      reader.onload = () => loader({ text: reader.result, file })
      setFile(file)
      reader.readAsText(file)
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop, multi: false })

  return (
    <Dropzone {...getRootProps()}>
      <input {...getInputProps()} />
      <div style={{ padding: theme.spacing[3], color: theme.font.color.primary }}>{message}</div>
    </Dropzone>
  )
}

const venueFields = [
  { name: 'name', label: 'Name', required: true, unique: false },
  { name: 'sid', label: 'Sid', required: true, unique: true },
  { name: 'bookability', label: 'Bookability', required: true, unique: false, values: [] },
  { name: 'phoneNumber', label: 'Phone number', required: false, unique: false },
  { name: 'email', label: 'Email', required: false, unique: false },
  { name: 'tz', label: 'Timezone', required: true, unique: false, values: [] },
  { name: 'officialWebsiteUrl', label: 'Official website', required: false, unique: false },
]

const addressFields = [
  { name: 'line1', label: 'Line 1', required: true, unique: false },
  { name: 'line2', label: 'Line 2', required: false, unique: false },
  { name: 'city', label: 'City', required: true, unique: false },
  { name: 'state', label: 'State', required: false, unique: false },
  { name: 'postcode', label: 'Postcode', required: false, unique: false },
  { name: 'country', label: 'Country', required: true, unique: false, values: [] },
  { name: 'lat', label: 'Latitude', required: true, unique: false },
  { name: 'lon', label: 'Longitude', required: true, unique: false },
  { name: 'url', label: 'URL', required: false, unique: false },
]

const homepageFields = [
  { name: 'title', label: 'Title', required: true, unique: false },
  { name: 'description', label: 'Description', required: false, unique: false },
]

const fieldGroups = [
  { name: 'Venue', fields: venueFields },
  { name: 'Address', fields: addressFields },
  { name: 'Homepage', fields: homepageFields },
]

const FieldGroup = ({ group, csvHeaders, csvData, onChange }) => {
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [mappings, setMappings] = useState({})

  const headerOptions = csvHeaders.map(h => ({
    value: h,
    label: h,
  }))

  useEffect(() => {
    changer(mappings)
  }, [mappings])

  const changeMapping = ({ name, csvName }) => {
    setMappings(prev => ({
      ...prev,
      [name]: csvName,
    }))
  }

  const fields = group.fields.map(f => {
    return (
      <div
        key={f.name}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '.5em',
          maxWidth: '500px',
        }}
      >
        <div title={f.required ? 'Required' : null}>
          <span style={{ color: 'red', visibility: f.required ? 'visible' : 'hidden' }}>*</span>{' '}
          {group.name} - {f.label}
        </div>
        <div>
          <Select
            showSearch
            style={{ minWidth: '163px' }}
            placeholder="Select CSV column"
            optionFilterProp
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
            }
            options={headerOptions}
            value={mappings[f.name]}
            onChange={v => changeMapping({ name: f.name, csvName: v })}
          />
        </div>
      </div>
    )
  })
  return (
    <div style={{ marginBottom: '1em' }}>
      <b>{group.name}</b>
      <div>{fields}</div>
    </div>
  )
}

// UI to map csv headers to venue fields
const FieldMapperStep = ({ visible, mappings, headers, data, onChange }) => {
  const theme = useTheme(ThemeContext)
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [groupMappings, setGroupMappings] = useState({})
  const hasHeaders = Array.isArray(headers) && headers.length > 0

  // always show required fields
  // render fields that appear in mappings
  // render fields as a field group
  // { 'Venue': { [fieldName]: csvName } }

  useEffect(() => {
    changer(groupMappings)
  }, [groupMappings])

  const changeMappings = ({ group, mappings }) => {
    setGroupMappings(prev => ({
      ...prev,
      [group]: mappings,
    }))
  }
  return (
    <div style={{ display: visible ? null : 'none' }}>
      <div style={{ marginBottom: theme.spacing[4] }}>
        <h3 style={{ marginBottom: 0, fontWeight: theme.font.weight[7] }}>Field mapper</h3>
        <div style={{ fontSize: theme.font.size[6], color: theme.font.color.secondary }}>
          Map CSV fields to venue fields
        </div>
      </div>
      {hasHeaders &&
        fieldGroups.map(fg => (
          <FieldGroup
            key={fg.name}
            group={fg}
            csvHeaders={headers}
            csvData={data}
            onChange={m => changeMappings({ group: fg.name, mappings: m })}
          />
        ))}
      <small>
        <span style={{ color: 'red' }}>*</span> Required field for upload
      </small>
    </div>
  )
}

const columns = [
  {
    title: 'Venue - Name',
    dataIndex: ['name'],
    fixed: 'left',
  },
  {
    title: 'Venue - Sid',
    dataIndex: ['sid'],
  },
  {
    title: 'Venue - Bookability',
    dataIndex: ['bookability'],
  },
  {
    title: 'Venue - Phone number',
    dataIndex: ['phoneNumber'],
  },
  {
    title: 'Venue - Email',
    dataIndex: ['email'],
  },
  {
    title: 'Venue - Timezone',
    dataIndex: ['tz'],
  },
  {
    title: 'Venue - Official website',
    dataIndex: ['officialWebsiteUrl'],
  },
  {
    title: 'Address - Line 1',
    dataIndex: ['address', 'line1'],
  },
  {
    title: 'Address - Line 2',
    dataIndex: ['address', 'line2'],
  },
  {
    title: 'Address - City',
    dataIndex: ['address', 'city'],
  },
  {
    title: 'Address - State',
    dataIndex: ['address', 'state'],
  },
  {
    title: 'Address - Postcode',
    dataIndex: ['address', 'postcode'],
  },
  {
    title: 'Address - Country',
    dataIndex: ['address', 'country'],
  },
  {
    title: 'Address - Latitude',
    dataIndex: ['address', 'lat'],
  },
  {
    title: 'Address - Longitude',
    dataIndex: ['address', 'lon'],
  },
  {
    title: 'Address - URL',
    dataIndex: ['address', 'url'],
  },
  {
    title: 'Homepage - Title',
    dataIndex: ['homepage', 'title'],
  },
  {
    title: 'Homepage - Description',
    dataIndex: ['homepage', 'description'],
  },
]

const uploadColumns = ({ onUpload }) => {
  const cols = columns.map(c => {
    return {
      ...c,
      dataIndex: ['venue', ...c.dataIndex],
    }
  })
  return [
    ...cols,
    {
      title: 'Action',
      dataIndex: 'status',
      fixed: 'right',
      render: (val, record) => {
        return (
          <>
            <div>{uploadStatuses[val].label}</div>
            {uploadableStates.includes(uploadStatuses[val].name) && (
              <Button onClick={() => onUpload(record)}>Upload</Button>
            )}
          </>
        )
      },
    },
  ]
}

// this is an editor that takes in a list of venues to create and launches onChange events for specific venues
const ReviewVenuesStep = ({ visible, mappings, data, onChange }) => {
  const changer = typeof onChange === 'function' ? onChange : () => {}
  const [venues, setVenues] = useState([])

  useEffect(() => {
    if (mappings && Array.isArray(data)) {
      const vs = data.map(d => {
        const venue = venueFields.reduce((acc, curr) => {
          if (mappings['Venue']) {
            acc[curr.name] = d[mappings['Venue'][curr.name]]
          }
          return acc
        }, {})

        venue.address = addressFields.reduce((acc, curr) => {
          if (mappings['Address']) {
            acc[curr.name] = d[mappings['Address'][curr.name]]
          }
          return acc
        }, {})

        venue.homepage = homepageFields.reduce((acc, curr) => {
          if (mappings['Homepage']) {
            acc[curr.name] = d[mappings['Homepage'][curr.name]]
          }
          return acc
        }, {})

        venue.id = uuid()

        return venue
      })
      setVenues(vs)
      changer(vs)
    }
  }, [mappings, data])

  const cols = columns
  return (
    <div style={{ display: visible ? null : 'none' }}>
      <Table dataSource={venues} pagination={false} columns={cols} scroll={{ x: 'max-content' }} />
    </div>
  )
}

const steps = [
  { title: 'Upload CSV' },
  { title: 'Map fields' },
  { title: 'Review venues' },
  { title: 'Upload venues' },
]

// give every record a unique id
// useReducer for venues records and mappings records
// be able to update a single column while retaining changes to other columns
const CSVUploadStep = ({ visible, onLoad }) => {
  const theme = useTheme(ThemeContext)
  const loader = typeof onLoad === 'function' ? onLoad : () => {}
  const [file, setFile] = useState(null)
  const hasUploaded = file !== null

  const load = ({ text, file }) => {
    setFile(file)
    loader(text)
  }

  return (
    <div style={{ display: visible ? null : 'none' }}>
      {!hasUploaded && <FileUpload onLoad={load} />}
      {hasUploaded && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ marginBottom: theme.spacing[3] }}>Currently reading</div>
          <pre style={{ marginBottom: theme.spacing[3] }}>{file.name}</pre>
          <div>
            <Button onClick={() => setFile(null)}>Change file</Button>
          </div>
        </div>
      )}
    </div>
  )
}

const uploadStatuses = {
  NOT_STARTED: {
    name: 'NOT_STARTED',
    label: 'Not started',
  },
  PENDING: {
    name: 'PENDING',
    label: 'Pending',
  },
  UPLOADING: {
    name: 'UPLOADING',
    label: 'Uploading...',
  },
  SUCCESS: {
    name: 'SUCCESS',
    label: 'Success',
  },
  FAILURE: {
    name: 'FAILURE',
    label: 'Failure',
  },
}
const uploadableStates = [
  uploadStatuses.NOT_STARTED.name,
  uploadStatuses.PENDING.name,
  uploadStatuses.FAILURE.name,
]
const nonUploadableStates = Object.keys(uploadStatuses).filter(s => !uploadableStates.includes(s))

const initUploadables = venues => {
  if (!Array.isArray(venues)) {
    return []
  }
  return venues.map(v => ({
    id: uuid(),
    venue: v,
    status: uploadStatuses.NOT_STARTED.name,
  }))
}

const UploadVenuesStep = ({ visible, venues, freezeChanges }) => {
  const { sig } = useParams()
  const dispatch = useDispatch()
  const freezer = typeof freezeChanges === 'function' ? freezeChanges : () => {}
  const theme = useTheme(ThemeContext)
  const [uploadables, setUploadables] = useState(initUploadables(venues))
  const [uploading, setUploading] = useState(false)
  const [currentUpload, setCurrentUpload] = useState(null)
  const status = useStatus(acts.CREATE_VENUE)

  useStatusMsg(status, {
    error: err => `${err}`,
  })

  useEffect(() => {
    return () => dispatch(clearStatus(acts.CREATE_VENUE))
  }, [])

  useEffect(() => {
    setUploadables(initUploadables(venues))
  }, [venues])

  const updateStatus = (id, status) => {
    setUploadables(prev => {
      const idx = prev.findIndex(p => p.id === id)
      const next = [...prev]
      next[idx] = {
        ...prev[idx],
        status,
      }
      return next
    })
  }

  const uploadOne = ({ id, venue, status }) => {
    setCurrentUpload({ id, venue, status })
    updateStatus(id, uploadStatuses.UPLOADING.name)
    return dispatch(createVenue({ orgSig: sig, venue, homepage: venue.homepage }))
      .then(v => {
        updateStatus(id, uploadStatuses.SUCCESS.name)
        setCurrentUpload(null)
        return v
      })
      .catch(e => {
        console.log(e)
        updateStatus(id, uploadStatuses.FAILURE.name)
        setCurrentUpload(null)
      })
  }

  const uploadAll = async () => {
    setUploading(true)
    freezer()
    const toUpload = uploadables.filter(up => uploadableStates.includes(up.status))
    toUpload.map(up => updateStatus(up.id, uploadStatuses.PENDING.name))
    for (var i = 0; i < toUpload.length; i++) {
      console.log(toUpload[i])
      try {
        await uploadOne(toUpload[i])
      } catch (err) {
        setUploading(false)
        // TODO seems like this doesn't break out of the loop.
        break
      }
    }
    setUploading(false)
  }

  // show a list of things that will be uploaded
  // upload button per line; upload all button at the top of list
  const cols = uploadColumns({ onUpload: uploadOne })
  return (
    <div style={{ display: visible ? null : 'none' }}>
      <div
        style={{
          marginBottom: theme.spacing[4],
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3 style={{ marginBottom: 0, fontWeight: theme.font.weight[7] }}>Upload venues</h3>
        <CtaButton disabled={uploading} onClick={() => uploadAll()}>
          {uploading ? 'Uploading...' : 'Upload all'}
        </CtaButton>
      </div>
      {currentUpload && (
        <div style={{ textAlign: 'right' }}>Uploading... {currentUpload.venue.name}</div>
      )}
      <Table
        dataSource={uploadables}
        pagination={false}
        columns={cols}
        scroll={{ x: 'max-content' }}
      />
    </div>
  )
}

const VenueUpload = () => {
  const { sig } = useParams()
  const theme = useTheme(ThemeContext)
  const [rawCsv, setRawCsv] = useState(null)
  const [step, setStep] = useState(0)
  const [parsedCsv, setParsedCsv] = useState(null)
  const [mappings, setMappings] = useState(null)
  const [venues, setVenues] = useState(null)
  const csvUploaded = typeof rawCsv === 'string'

  useEffect(() => {
    if (rawCsv) {
      setParsedCsv(Papa.parse(rawCsv, { header: true }))
    }
  }, [rawCsv])

  const prevStep = () => {
    setStep(prev => (prev <= 0 ? 0 : prev - 1))
  }

  const nextStep = () => {
    setStep(prev => (prev === steps.length - 1 ? prev : prev + 1))
  }

  return (
    <PaddedContainer>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={paths.admin.ORGS()}>Orgs</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={paths.admin.ORG(sig)}>{sig}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Upload venues</Breadcrumb.Item>
      </Breadcrumb>
      <Head
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: theme.spacing[3],
          alignItems: 'center',
        }}
      >
        <Title>Upload venues</Title>
      </Head>
      <div style={{ backgroundColor: 'white', padding: '2em' }}>
        <div style={{ marginBottom: theme.spacing[4] }}>
          <Steps progressDot current={step} items={steps} />
        </div>
        <CSVUploadStep
          visible={step === 0}
          onLoad={text => {
            setRawCsv(text)
            nextStep()
          }}
        />
        <FieldMapperStep
          visible={step === 1}
          headers={parsedCsv && parsedCsv.meta.fields}
          onChange={m => setMappings(m)}
        />
        <ReviewVenuesStep
          visible={step === 2}
          mappings={mappings}
          data={parsedCsv && parsedCsv.data}
          onChange={v => setVenues(v)}
        />
        <UploadVenuesStep visible={step === 3} venues={venues} onChange={v => console.log(v)} />
        <div style={{ display: 'flex', marginTop: theme.spacing[3] }}>
          <div style={{ flexGrow: '1' }}></div>
          <Button
            style={{ marginRight: theme.spacing[2] }}
            disabled={step <= 0}
            onClick={() => prevStep()}
          >
            Back
          </Button>
          <CtaButton
            style={{ marginRight: theme.spacing[2] }}
            disabled={step === steps.length - 1}
            onClick={() => nextStep()}
          >
            Next
          </CtaButton>
        </div>
      </div>
    </PaddedContainer>
  )
}

export default VenueUpload
