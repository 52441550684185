import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { loadStripeTerminal } from '@stripe/terminal-js'
import { createTerminalConnectionToken } from '../actions/org'

export const useTerminal = (osid, { onUnexpectedReaderDisconnect, onConnectionStatusChange }) => {
  const dispatch = useDispatch()
  const [StripeTerminal, setStripeTerminal] = useState(null)
  const [terminal, setTerminal] = useState(null)

  const fetchConnectionToken = async () => {
    const { secret } = await dispatch(createTerminalConnectionToken({ osid }))
    return secret
  }

  useEffect(() => {
    async function load() {
      const res = await loadStripeTerminal()
      setStripeTerminal(res)
    }
    load()
  }, [])

  useEffect(() => {
    if (StripeTerminal) {
      setTerminal(
        StripeTerminal.create({
          onFetchConnectionToken: fetchConnectionToken,
          onUnexpectedReaderDisconnect,
          onConnectionStatusChange,
        }),
      )
    }
  }, [StripeTerminal])

  function discoverReaders(locationId) {
    const config = { simulated: false, location: locationId }
    return terminal.discoverReaders(config)
  }

  async function connectReader(reader) {
    return terminal.connectReader(reader)
  }

  return {
    terminal,
    discoverReaders,
    connectReader,
  }
}
