import { scraperStatuses, fulfillmentStatuses } from '../constants/permit'

export const canChangePermitNycDetails = permit => {
  return (
    [scraperStatuses.NOT_STARTED.name, scraperStatuses.READY_TO_START.name].includes(
      permit.scraperStatus,
    ) && !isPermitFulfilled(permit)
  )
}

export const isPermitFulfilled = ({ fulfillmentStatus }) =>
  [fulfillmentStatuses.AUTOMATIC_SUCCESS.name, fulfillmentStatuses.MANUAL_SUCCESS.name].includes(
    fulfillmentStatus,
  )
