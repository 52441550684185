import { useReducer, useContext, useEffect, useState } from 'react'
import { ThemeContext } from 'styled-components'
import { Link, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Input, Tag, InputNumber, message, Select, Tooltip, Badge, Modal, Typography } from 'antd'
import { features, paths, actions as acts } from '../../constants'
import * as billingConsts from '../../constants/billing'
import { Cta, Button, CtaLink, Head, Title, PaddedContainer } from '../../components/common'
import { useTerminal } from '../../components/terminal'
import { useStatus, useStatusMsg } from '../../reducers'
import { LoadingOutlined } from '@ant-design/icons'
import { Noner } from '../../components/common'
import formatUtils from '../../util/format'
import urlUtils from '../../util/url'
import Attr from '../../components/Attr'
import { EstimatedInvoice } from '../admin/invoice/Invoice'
import { clearStatus } from '../../actions/status'
import {
  fetchAllProducts,
  fetchProductVariants,
  fetchProductVariantOptions,
  estimateInvoice,
  startTerminalOrder,
  cancelInvoice,
  capturePayment,
  emailReceipt,
} from '../../actions/org'

const { Option } = Select

const ReaderStatus = ({ status }) => {
  switch (status) {
    case 'offline': {
      return (
        <Tooltip title="Offline">
          <Badge status="default" />
        </Tooltip>
      )
    }
    case 'online': {
      return (
        <Tooltip title="Online">
          <Badge status="success" />
        </Tooltip>
      )
    }
    default: {
      return (
        <Tooltip title={status}>
          <Badge status="default" />
        </Tooltip>
      )
    }
  }
}
//{
//  "id": "tmr_5jxba5hVkMcv4i8ZZlXiu2z5",
//  "object": "terminal.reader",
//  "action": null,
//  "device_sw_version": null,
//  "device_type": "bbpos_wisepos_e",
//  "ip_address": "192.168.2.2",
//  "label": "Blue Rabbit",
//  "livemode": false,
//  "location": null,
//  "metadata": {},
//  "serial_number": "123-456-789",
//  "status": "online"
//}
const Reader = ({ reader }) => {
  return (
    <div>
      <div>
        <ReaderStatus status={reader.status} />
        {reader.label}
      </div>
      <small>{reader.serial_number}</small>
      <div></div>
    </div>
  )
}

const ReaderList = ({ readers, disableConnects, onSelect }) => {
  const elems = readers.map(r => (
    <div
      key={r.id}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1em',
      }}
    >
      <Reader reader={r} />
      <Cta disabled={disableConnects} onClick={() => onSelect(r)}>
        Connect
      </Cta>
    </div>
  ))
  return <Noner none="No readers">{elems}</Noner>
}

const Discover = ({ discoverReaders, connectingReader, onSelectReader }) => {
  const theme = useContext(ThemeContext)
  const [discoverResult, setDiscoverResult] = useState(null)
  const [discoveredReaders, setDiscoveredReaders] = useState([])
  const [discovering, setDiscovering] = useState(false)
  const [error, setError] = useState(null)

  const onClickDiscover = () => {
    setDiscovering(true)
    setError(null)
    setDiscoveredReaders(discoveredReaders)
    discoverReaders().then(res => {
      setDiscovering(false)
      if (res.error) {
        setError(res.error)
        return
      }
      setDiscoveredReaders(res.discoveredReaders)
    })
  }

  return (
    <div>
      {error ? <Typography danger>{error}</Typography> : null}
      <ReaderList
        readers={discoveredReaders}
        onSelect={onSelectReader}
        disableConnects={connectingReader}
      />
      <div style={{ textAlign: 'center', marginBottom: theme.spacing[2] }}>
        {connectingReader ? <Badge status="processing" text="Connecting..." /> : null}
      </div>
      <div style={{ textAlign: 'center' }}>
        <Button disabled={discovering} onClick={onClickDiscover}>
          {discovering ? 'Discovering...' : 'Discover'}
        </Button>
      </div>
    </div>
  )
}

const ConnectReader = ({ discoverReaders, connectReader, complete }) => {
  const theme = useContext(ThemeContext)
  const [selectedReader, setSelectedReader] = useState(null)
  const [connectingReader, setConnectingReader] = useState(false)
  const [connectError, setConnectError] = useState(null)
  const completer = typeof complete === 'function' ? complete : () => {}

  const onSelectReader = reader => {
    setConnectingReader(true)
    connectReader(reader).then(res => {
      setConnectingReader(false)
      if (res.error) {
        setConnectError(res.error.message)
        console.log(res.error)
        return
      }
      completer(res.reader)
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          minWidth: '400px',
          backgroundColor: 'white',
          borderRadius: theme.br[3],
          padding: theme.spacing[3],
        }}
      >
        <div style={{ textAlign: 'center', marginBottom: theme.spacing[3] }}>
          <strong>Connect to a reader</strong>
        </div>
        <Discover
          discoverReaders={discoverReaders}
          onSelectReader={onSelectReader}
          connectingReader={connectingReader}
        />
        {connectError && <Typography type="danger">{connectError}</Typography>}
      </div>
    </div>
  )
}

const ConnectionStatus = ({ status }) => {
  switch (status) {
    case 'connecting': {
      return <Badge status="processing" text={<small>Connecting...</small>} />
    }
    case 'connected': {
      return <Badge status="success" text={<small>Connected</small>} />
    }
    case 'not_connected':
    default: {
      return <Badge status="error" text={<small>Not connected</small>} />
    }
  }
}

const ReaderPreview = ({ reader, onClearDisplay, onReconnect }) => {
  if (!reader) {
    return <small>No reader</small>
  }
  return (
    <div>
      <div>
        <small>{reader.label}</small>
        <div>
          <small>{reader.serial_number}</small>
        </div>
      </div>
      <Button onClick={onClearDisplay} style={{ marginRight: '1em' }}>
        Clear display
      </Button>
      <Button onClick={onReconnect}>Reconnect</Button>
    </div>
  )
}

const VariantOptionSelect = ({ variantOption, onChange }) => {
  const theme = useContext(ThemeContext)
  const opts = variantOption.values.map(vov => (
    <Option key={vov.id} value={vov.id}>
      {vov.name}
    </Option>
  ))

  return (
    <Attr name={variantOption.name}>
      <div>
        <Select style={{ minWidth: theme.width[4] }} onChange={onChange}>
          {opts}
        </Select>
      </div>
    </Attr>
  )
}

const AddProductModal = ({ product, complete }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()

  const [variantOptions, setVariantOptions] = useState(null)
  const [productVariants, setProductVariants] = useState(null)

  const [selectedVariantOptions, setSelectedVariantOptions] = useState([])
  const [quantity, setQuantity] = useState(1)

  //  { productVariantId: [variantOptionValueId, ...] }
  const [productVariantToValuesMap, setProductVariantToValuesMap] = useState({})

  const completer = complete ? complete : () => {}
  const status = {
    vo: useStatus(acts.FETCH_PRODUCT_VARIANT_OPTIONS),
    pv: useStatus(acts.FETCH_PRODUCT_VARIANTS),
  }

  useEffect(() => {
    if (productVariants) {
      const map = productVariants.reduce((acc, curr) => {
        acc[curr.id] = curr.variantOptionValues.map(vov => vov.variantOptionValue.id)
        return acc
      }, {})
      setProductVariantToValuesMap(map)
    }
  }, [productVariants])

  useEffect(() => {
    dispatch(fetchProductVariantOptions({ osid, productId: product.id })).then(vo =>
      setVariantOptions(vo),
    )
    dispatch(fetchProductVariants({ osid, productId: product.id })).then(pv =>
      setProductVariants(pv),
    )
  }, [])

  useStatusMsg(status.vo, {
    error: 'Could not fetch product variant options',
  })

  useStatusMsg(status.pv, {
    error: 'Could not fetch product variants',
  })

  const onOk = () => {
    // get product variant from selectedVariants
    const foundProductVariant = Object.entries(productVariantToValuesMap).find(e => {
      if (!selectedVariantOptions.length === e[1].length) {
        return false
      }
      return e[1]
        .map(vov => selectedVariantOptions.includes(vov))
        .reduce((acc, curr) => acc && curr, true)
    })

    if (!foundProductVariant) {
      message.error('All variant options must be filled in')
      return
    }
    if (quantity <= 0) {
      message.error('Quantity must be greater than zero')
      return
    }
    completer({ productVariantId: foundProductVariant[0], quantity })
  }

  const onCancel = () => {
    completer()
  }

  let display = <LoadingOutlined />
  if (productVariants && variantOptions) {
    const selects = variantOptions.map(vo => (
      <VariantOptionSelect
        key={vo.id}
        variantOption={vo}
        onChange={v => setSelectedVariantOptions(prev => [...prev, v])}
      />
    ))
    display = (
      <div>
        {selects}
        <Attr name="Quantity">
          <div>
            <InputNumber
              type="number"
              name="quantity"
              value={quantity}
              onChange={v => setQuantity(v)}
            />
          </div>
        </Attr>
      </div>
    )
  }

  return (
    <Modal
      visible
      onOk={onOk}
      onCancel={onCancel}
      okText="Add to cart"
      title={`Add ${product.name}`}
    >
      {display}
    </Modal>
  )
}

const initCart = cart => {
  if (cart && Array.isArray(cart.items)) {
    return cart
  }
  return {
    items: [],
  }
}

const reducer = (state, { type, payload }) => {
  if (type === 'CLEAR') {
    return initCart()
  }
  if (type === 'ADD_PRODUCT') {
    const next = { ...state }
    const idx = next.items.findIndex(itm => itm.id === payload.id)
    if (idx !== -1) {
      next.items[idx] = {
        ...next.items[idx],
        quantity: next.items[idx].quantity + payload.quantity,
      }
      return next
    }
    return {
      ...state,
      items: [
        ...state.items,
        {
          sku: billingConsts.skus.PRODUCT,
          id: payload.id,
          quantity: payload.quantity,
        },
      ],
    }
  }
  return { ...state }
}

const Shop = ({ cart, onCartChange, onCheckout }) => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [products, setProducts] = useState(null)
  const [addingProduct, setAddingProduct] = useState(null)
  const [estimatedInvoice, setEstimatedInvoice] = useState(null)
  const [internalCart, dp] = useReducer(reducer, initCart(cart))
  const checkout = typeof onCheckout === 'function' ? onCheckout : () => {}

  useEffect(() => {
    dispatch(fetchAllProducts({ osid })).then(ps => setProducts(ps))
  }, [])

  useEffect(() => {
    if (internalCart) {
      dispatch(estimateInvoice({ osid, cart: internalCart })).then(
        ({ unappliedPromos, estimatedInvoice }) => setEstimatedInvoice(estimatedInvoice),
      )
    } else {
      setEstimatedInvoice(null)
    }
    if (typeof onCartChange === 'function') {
      onCartChange(internalCart)
    }
  }, [internalCart])

  const onSelectProduct = product => {
    setAddingProduct(product)
  }

  let display = <LoadingOutlined />
  if (products) {
    display = products.map(p => (
      <a
        key={p.id}
        style={{
          borderRadius: theme.br[3],
          background: 'white',
          padding: theme.spacing[3],
          margin: theme.spacing[2],
          minWidth: theme.width[5],
          textDecoration: 'inherit',
          color: 'inherit',
        }}
        onClick={() => onSelectProduct(p)}
      >
        {p.name}
      </a>
    ))
  }

  return (
    <>
      <div style={{ display: 'flex', flexWrap: 'wrap', marginBottom: theme.spacing[4] }}>
        {display}
      </div>
      <div>
        <Attr name="Estimated invoice">
          <Button style={{ marginRight: theme.spacing[3] }} onClick={() => dp({ type: 'CLEAR' })}>
            Clear cart
          </Button>
          <Cta onClick={() => checkout()}>Checkout</Cta>
          <EstimatedInvoice invoice={estimatedInvoice} />
        </Attr>
      </div>
      {addingProduct && (
        <AddProductModal
          product={addingProduct}
          complete={({ productVariantId, quantity } = {}) => {
            if (productVariantId) {
              dp({ type: 'ADD_PRODUCT', payload: { id: productVariantId, quantity } })
            }
            setAddingProduct(null)
          }}
        />
      )}
    </>
  )
}

const CheckoutModal = ({ terminal, cart, complete }) => {
  const { osid } = useParams()
  const theme = useContext(ThemeContext)
  const dispatch = useDispatch()
  const [paymentIntent, setPaymentIntent] = useState({})
  const [clientSecret, setClientSecret] = useState(null)
  const [invoice, setInvoice] = useState(null)
  const [askEmailReceipt, setAskEmailReceipt] = useState(false)
  const [receiptEmail, setReceiptEmail] = useState(null)
  const [collectingPayment, setCollectingPayment] = useState(false)
  const status = {
    emailReceipt: useStatus(acts.EMAIL_RECEIPT),
  }

  const [sendingReceiptEmail, setSendingReceiptEmail] = useState(false)
  const [sendReceiptSuccess, setSendReceiptSuccess] = useState(false)

  const completer = typeof complete === 'function' ? complete : () => {}

  useStatusMsg(status.emailReceipt, {
    error: 'Failed to email receipt',
  })

  useEffect(() => {
    return () => {
      dispatch(clearStatus(acts.EMAIL_RECEIPT))
    }
  }, [])

  useEffect(() => {
    console.log(paymentIntent)
    if (paymentIntent && paymentIntent.status === 'succeeded') {
      if (paymentIntent.status === 'succeeded') {
        setAskEmailReceipt(true)
      }
    }
  }, [paymentIntent])

  useEffect(() => {
    dispatch(startTerminalOrder({ osid, cart }))
  }, [])

  const tryPayment = args => {
    console.log(args)
    const items = args.invoice.lineItems
      .filter(itm => billingConsts.purchasableSkus.includes(itm.sku))
      .map(itm => ({
        description: itm.description,
        amount: itm.totalPriceCents,
        quantity: itm.quantity,
      }))
    const tax = args.invoice.lineItems
      .filter(itm => [billingConsts.skus.FEE, billingConsts.skus.TAX].includes(itm.sku))
      .reduce((acc, curr) => acc + curr.totalPriceCents, 0)
    terminal.setReaderDisplay({
      type: 'cart',
      cart: {
        line_items: items,
        tax,
        total: args.invoice.amountBilledCents,
        currency: 'usd',
      },
    })
    terminal.collectPaymentMethod(args.clientSecret).then(res => {
      if (res.error) {
        console.log(res.error)
        message.error(res.error.message)
        setCollectingPayment(false)
        return
      }
      setPaymentIntent(res.paymentIntent)
      terminal.processPayment(res.paymentIntent).then(result => {
        if (result.error) {
          console.log(res.error)
          message.error(res.error.message)
          setCollectingPayment(false)
          return
        }
        setPaymentIntent(result.paymentIntent)
        dispatch(capturePayment({ osid, paymentIntentId: result.paymentIntent.id })).then(
          newPaymentIntent => {
            setPaymentIntent(newPaymentIntent)
            setCollectingPayment(false)
          },
        )
      })
    })
  }

  const onCollectPayment = () => {
    setCollectingPayment(true)
    terminal.clearReaderDisplay()
    if (clientSecret && invoice) {
      tryPayment({ clientSecret, invoice })
    }
    dispatch(startTerminalOrder({ osid, cart })).then(({ client_secret, invoice }) => {
      setClientSecret(client_secret)
      setInvoice(invoice)
      tryPayment({ clientSecret: client_secret, invoice })
    })
  }

  const onCancel = () => {
    if (invoice) {
      // cancel the invoice
      dispatch(cancelInvoice({ osid, invoiceId: invoice.id })).then(() => completer())
    } else {
      completer()
    }
  }

  const onOk = () => {
    completer(invoice)
  }

  const onSendReceipt = () => {
    if (!receiptEmail) {
      message.error('Email required')
      return
    }
    setSendingReceiptEmail(true)
    setSendReceiptSuccess(false)
    dispatch(emailReceipt({ osid, paymentIntentId: paymentIntent.id, email: receiptEmail })).then(
      res => {
        console.log(res)
        setSendReceiptSuccess(true)
        setSendingReceiptEmail(false)
      },
    )
  }

  let display
  if (askEmailReceipt) {
    if (sendReceiptSuccess) {
      display = <div>Receipt sent to {receiptEmail}!</div>
    } else {
      display = (
        <div>
          <Input
            name="email"
            value={receiptEmail}
            onChange={e => setReceiptEmail(e.target.value)}
            style={{ marginRight: theme.spacing[2] }}
          />
          <Button disabled={sendingReceiptEmail} onClick={onSendReceipt}>
            {sendingReceiptEmail ? 'Sending receipt...' : 'Send receipt'}
          </Button>
        </div>
      )
    }
  } else {
    display = (
      <div style={{ textAlign: 'center' }}>
        <Cta disabled={collectingPayment} onClick={onCollectPayment}>
          {collectingPayment ? 'Collecting payment...' : 'Collect payment'}
        </Cta>
      </div>
    )
  }

  let footerButtons = [<Button onClick={onOk}>Close</Button>]
  if (!askEmailReceipt) {
    footerButtons = [<Button onClick={onCancel}>Cancel payment</Button>, ...footerButtons]
  }

  return (
    <Modal visible title="Checkout" onCancel={onCancel} onOk={onOk} footer={footerButtons}>
      {display}
      <div>
        <Attr name="Payment intent status">
          <div>
            <Noner none="No payment intent">
              {paymentIntent.id ? (
                <a target="_blank" href={urlUtils.stripePayment(paymentIntent.id)}>
                  {paymentIntent.id}
                </a>
              ) : null}
            </Noner>
          </div>
          <div>
            <Noner none="No status">{paymentIntent.status}</Noner>
          </div>
        </Attr>
      </div>
    </Modal>
  )
}

const Pos = () => {
  const { osid } = useParams()
  const dispatch = useDispatch()
  const theme = useContext(ThemeContext)
  const [reader, setReader] = useState(null)
  const [connectionStatus, setConnectionStatus] = useState(null)
  const [checkingOutCart, setCheckingOutCart] = useState(null)
  const [cart, setCart] = useState(null)
  const [invoices, setInvoices] = useState([])
  const { terminal, discoverReaders, connectReader } = useTerminal(osid, {
    onUnexpectedReaderDisconnect: () => {
      setReader(null)
    },
    onConnectionStatusChange: status => {
      console.log('CONNECTION STATUS CHANGE', status)
      setConnectionStatus(status)
    },
  })

  useEffect(() => {
    if (terminal) {
      const status = terminal.getConnectionStatus()
      setConnectionStatus(status)
    }
  }, [terminal, reader])

  const onCheckout = () => {
    setCheckingOutCart(cart)
  }

  let display = <LoadingOutlined />
  if (terminal) {
    if (!reader) {
      display = (
        <ConnectReader
          discoverReaders={discoverReaders}
          connectReader={connectReader}
          complete={r => {
            if (r) {
              setReader(r)
            }
          }}
        />
      )
    } else {
      display = (
        <Shop cart={cart} onCartChange={newCart => setCart(newCart)} onCheckout={onCheckout} />
      )
    }
  }

  const invoiceElems = invoices.map(inv => (
    <div key={inv.id} style={{ display: 'flex', marginBottom: theme.spacing[2] }}>
      <div style={{ marginRight: theme.spacing[3] }}>
        <Link to={paths.admin.billing.INVOICE(inv.id)} target="_blank">
          {inv.id}
        </Link>
      </div>
      <div style={{ marginRight: theme.spacing[3] }}>
        <Tag>{inv.status}</Tag>
      </div>
      <div style={{ marginRight: theme.spacing[3] }}>
        {formatUtils.centsToDollars(inv.amountBilledCents)}
      </div>
      <div style={{ marginRight: theme.spacing[3] }}>{inv.createdAt}</div>
    </div>
  ))

  return (
    <PaddedContainer>
      <div>
        <Title>{features.pos.name.plural}</Title>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <ConnectionStatus status={connectionStatus} />
        <ReaderPreview
          reader={reader}
          onClearDisplay={() => terminal.clearReaderDisplay()}
          onReconnect={() => {
            terminal.disconnectReader()
            setReader(null)
          }}
        />
      </div>
      <div style={{ marginTop: '1em' }}>{display}</div>
      <div>
        <Attr name="Invoices">{invoiceElems}</Attr>
      </div>
      {checkingOutCart && (
        <CheckoutModal
          cart={checkingOutCart}
          terminal={terminal}
          complete={invoice => {
            if (invoice) {
              setInvoices(prev => [...prev, invoice])
            }
            setCheckingOutCart(null)
          }}
        />
      )}
    </PaddedContainer>
  )
}

export default Pos
